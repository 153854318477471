/**
 * Api for work with viewer from react
 * @file
 */
import { log } from "../../utilities/logger"
import {Viewer2d} from "./viewer"

export class Viewer2dApi {
  init = options => {
    if (this.v) return
    // console.log("INIT CANVAS")
    this.v = new Viewer2d(options)
  }

  setProps = (props = {}) => {
    this.v.setProps(props)
  }

  connect = funcs => {
    if (this.v && this.v.help) {
      if (funcs.prodPosition) this.v.help.prodPosition = funcs.prodPosition
      if (funcs.scrollbarProp) this.v.help.scrollbarProp = funcs.scrollbarProp
      if (funcs.setActivePart) this.v.help.setActivePart = funcs.setActivePart
      if (funcs.getActiveObj) this.v.help.getActiveObj = funcs.getActiveObj
    }
  }
  getProduct = ()=>{
    return this.v.getProduct()
  }
  getPart = ()=>{
    return this.v.getPart()
  }
  getImages = ()=>{
    return this.v.getImages()
  }
  getCanvas = ()=>{
    return this.v.getCanvas()
  }

  resize = (size, info) => {
    //this.v.resize(size, info)
  }

  createProduct = (windowSize, productSize, stepSize, fabricQtyByUnit) => {
    this.v.createProduct(windowSize, productSize, stepSize, fabricQtyByUnit)
  }
  
  updateProduct = (windowSize, productSize,stepSize,callback)=>{
    this.v.updateProduct(windowSize, productSize, stepSize).then(callback)
  }
  selectImage = (url)=>{
    log(url, `selected Image in collage displayGallery`)
    this.v.selectImage(url)
  }

  rotateImage = (url, toAngle)=>{
    this.v.rotateImage(url, toAngle)
  }

  dispose = ()=>{
    this.v && this.v.dispose()
  }

  createPart = (number, id, size) => {
    this.v.createPart(number, id, size)
  }

  removePart = id => {
    this.v.removePart(id)
  }

  changePartLength = (id, length) => {
    this.v.changePartLength(id, length)
  }

  scrollToPart = id => {
    // this.v.discardActiveObj()
    this.v.scrollToPart(id)
  }

  addImgToPart = (id, img, size, image_size_ht, image_part_width, image_part_height, repeatType, info, objToPopulate, actualResolution) => {
    this.v.addImgToPart(id, img, size, image_size_ht, image_part_width, image_part_height, repeatType, info, objToPopulate, actualResolution)
  }

  addImagesToPart = (id, imageArray) => {
    this.v.addImagesToPart(id, imageArray)
  }

  setImageSize = (id, img_url, image_part_width, image_part_height, size) => {
    this.v.setImageSize(id, img_url, image_part_width, image_part_height, size)
  }

  setImageSizeCmsByWidth = (id, img_url,sz_wd, sz_ht, lockedAR, actualResolution, isFieldEmpty) => {
    this.v.setImageSizeCmsByWidth(id, img_url, sz_wd, sz_ht, lockedAR, actualResolution, isFieldEmpty)
  }

  setImageSizeCmsByHeight = (id, img_url, sz_wd, sz_ht, lockedAR, actualResolution, isFieldEmpty) => {
    this.v.setImageSizeCmsByHeight(id, img_url, sz_wd, sz_ht, lockedAR, actualResolution, isFieldEmpty)
  }

  removeImageFromPart = (id, img_url, image_part) => {
    this.v.removeImageFromPart(id, img_url, image_part)
  }

  repeatImg = (id, type) => {
    this.v.repeatImg(id, type)
  }

  claerParts = () => {
    this.v.claerParts()
  }

  clearImages = () => {
    this.v.clearImages()
  }

  addupdataText = (id, opt) => {
    this.v.addupdataText(id, opt)
  }

  addToCart = (data, callback = () => {}, indata = {}) => {
    this.v.addToCart(data, callback, indata)
  }
  scrollHandler = delta => this.v._setProductPosition(delta)
}

let V = undefined

export const getSingletonViewerInstance = () => {
  if (!V)
    V = new Viewer2dApi()
  return V
}

export const deAllocSingletonViewerInstance = () => {
  V = undefined
}


