import React, { useState, useEffect, useCallback, useRef, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"

import styles from "./body.module.scss"
import LeftWorkAreaContainer from "../LeftWorkArea/LeftWorkAreaContainer/LeftWorkAreaContainer"
import Scen from "../Scen/Scen.tsx"
import ATCbutton from "../Buttons/ATCbutton"
import Ruler from "../Ruler/Ruler"
import Scrollbar from "../Scrollbar/Scrollbar"
import { debounce } from "throttle-debounce"


import { setActivePart, toggleTextMenu } from "../../redux/actions/parts"
import { setSpinner } from "../../redux/actions/spinner"
import { gsetActiveElement } from "../../redux/actions/activeelement"
import useIframe from "../../hooks/useIframe"
import alertify from '../../libs/alert'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next"
import { log } from "../../utilities/logger"
import { DECREMENT_NO_OF_FABRIC_UNITS, INCREMENT_NO_OF_FABRIC_UNITS, SET_CONFIGURATION } from "../../redux/reducers/settings"
import { AddToCartBtn } from "../addtocart"
import LIbrary from "../LeftWorkArea/LIbrary/LIbrary"
import { Fabric } from "../Scen/Fabric"
import { addFabricToCart, setUpFabric } from "../../redux/reducers/fabric/actions"
import { FabricCanvas } from "../Scen/FabricCanvas"
import { SEGMENTS as GridSegment } from "../Scen/ScenSegment"
import { getSingletonViewerInstance } from "../../libs/viewer"
import { roundOff } from "../../utilities"
import { CanvasGrid } from "../../App"
import i18next from "i18next"
import { View3DPanel } from "../LeftWorkArea/View3D/View3DPanel"

/**
 * Container component for all ui elements
 * @component
 * @example
 * return (
 *  <Body />
 * )
 */
const Body = (props) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const parts = useSelector(state => state.parts)
  const { rulerUnits, configuration, unitType } = useSelector(state => state.settings)
 
  const product = useSelector(state => state.product)
  const activeelement = useSelector(state => state.activeelement)

  const [fcSize, setFcSize] = useState({ width: 0, height: 0 })
  const fabricContainer = useRef(null)

  const [height, setHeight] = useState(0)
  const [prodPosition, setProdPosition] = useState(0)
  const [apId, setApId] = useState(null)
  const [activeElement, setActiveElement] = useState(null)

  const iframe = useIframe()

  const [curFocusedImg, setCurFocusedImg] = useState(undefined)

  // const [curFocusedImgSize, setCurFocusedImgSize] = useState(undefined)
  const [curFocusedImgWidth, setCurFocusedImgWidth] = useState(undefined)
  const [curFocusedImgHeight, setCurFocusedImgHeight] = useState(undefined)

  const [curFocusedImgLimitsWidth, setCurFocusImgLimitsWidth] = useState(undefined)
  const [curFocusedImgLimitsHeight, setCurFocusImgLimitsHeight] = useState(undefined)
  const canvasGrid = useContext(CanvasGrid)

  const fbdata = useSelector(state => state.fbdata)
  const stepLength = fbdata.fabricUnitSizeCm
  const rows = fbdata.rows || canvasGrid.rows || 1
  const columns = fbdata.columns || canvasGrid.columns || 1
  
  const isSplit = useSelector(state => state.settings.configuration?.split)


  const language = fbdata.lang
  const fabricUnit = unitType
  
  log({language, fabricUnit}, ' FIREBASE LANGUAGE ')

  useEffect(()=>{
    i18next.changeLanguage(language)
  },[language])


  const [partLength, setPartLength] = useState(stepLength || 1)


 

  useEffect(() => {

    if (configuration) {

      const currentSections = Math.ceil((partLength) / configuration.stepSize)
      if (partLength > configuration.stepSize) {
        dispatch({ type: INCREMENT_NO_OF_FABRIC_UNITS, payload: currentSections })
      } else if (partLength < configuration.stepSize) {
        dispatch({ type: DECREMENT_NO_OF_FABRIC_UNITS, payload: currentSections })
      }
    }

  }, [partLength])

  useEffect(() => {
    dispatch({
      type: SET_CONFIGURATION,
      payload: {
        stepSize: stepLength,
        stepUnitType: fabricUnit,
        split: (rows || columns) ? {
          rows,
          columns
        } : undefined
      }
    })
  }, [stepLength, fabricUnit, rows, columns])

  let isNonRepeat = parts && parts.length && parts[0].image_repeat ? (parts[0].image_repeat === 'NON_REPEAT') : false
  let productMaxLength = product?.totalLength || 0
  let productNRBlanketMaxLength = (fbdata.fabricNonRepeatMaxLen || 20)
  const maxFabricLength = (isNonRepeat ? (productMaxLength < productNRBlanketMaxLength ? productMaxLength : productNRBlanketMaxLength) : productMaxLength)

 
  const V = useRef(isSplit ? undefined : getSingletonViewerInstance()).current
  const currentPartLength = useRef(0)


  const notify = (msg) => toast.info(msg)

  const getFcSize = useCallback(
    () =>
      debounce(10, () => {
        setFcSize({
          width: fabricContainer.current?.offsetWidth,
          height: fabricContainer.current?.offsetHeight,
        })
      })(),
    [fabricContainer.current],
  )
  useEffect(() => {
    if (product) {
      setProdPosition(0)
    }
  }, [product])

  useEffect(() => {
    if (product && !isSplit) {
      V.connect({
        prodPosition: setProdPosition,
        setActivePart: setApId,
        getActiveObj: setActiveElement,
      })
    }
  }, [product])

  useEffect(() => {
    if (apId) {
      dispatch(setActivePart(apId, isSplit))
    }
  }, [apId, dispatch])

  useEffect(() => {
    dispatch(gsetActiveElement(activeElement))
  }, [activeElement, dispatch])

  useEffect(() => {
    if (activeelement && activeelement.type === "text") {
      dispatch(setActivePart(activeelement.id, isSplit))
      dispatch(toggleTextMenu(activeelement.id, true, isSplit))
    }
  }, [activeelement, dispatch])

  useEffect(() => {
    if (!activeElement || (activeelement && activeelement.type !== "text")) {
      dispatch(toggleTextMenu(apId, false, isSplit))
    }
  }, [activeElement, activeelement, apId, dispatch])

  useEffect(() => {
    if (product && fcSize.width) {
      let fabricWidthSM = product.width * 100
      let fabricWidthPX = fcSize.width

      let PXperSM = fabricWidthPX / fabricWidthSM

      let fabricHeightPX = PXperSM * 100

      setHeight(Math.round(parseFloat(fabricHeightPX)))
    }
  }, [fcSize.width, fcSize.height, product])

  useEffect(() => {
    getFcSize()

    window.addEventListener("resize", getFcSize)
    window.addEventListener("orientationchange", getFcSize)
    return () => {
      window.removeEventListener("resize", getFcSize)
      window.removeEventListener("orientationchange", getFcSize)
    }
  }, [getFcSize, height, isSplit])

  const addToCartHandler = (opt) => {
    const url = window.location !== window.parent.location
                  ? document.referrer
                  : document.location.origin

    console.groupCollapsed("ADD_TO_CART")
    log(JSON.stringify(opt))
    console.groupEnd()

    if (!opt.content) {
      console.error("Can't save the data")
    } else {
      window.parent.postMessage(JSON.stringify(opt), url)
    }
  }

  /**
   * Add to cart
   * @method
   */
  const addToCart = useCallback(
    e => {
      e.preventDefault()
      dispatch(addFabricToCart(() => {
        dispatch(setSpinner(true))
      
      }, (opt) => {
        dispatch(setSpinner(false))
        addToCartHandler(opt)
      }))
    },
    [dispatch, parts, product],
  )

  useEffect(() => {
    if (iframe === 1) {
      const url =
        window.location !== window.parent.location
          ? document.referrer
          : document.location.origin

      let data = { action: "APP_CHANGES" }

      if (
        (parts.length &&
          (parts.find(i => i.image) || parts.find(i => i.text.length))) ||
        parts.length > 1
      ) {
        window.parent.postMessage(JSON.stringify({ ...data, type: true }), url)
      } else {
        window.parent.postMessage(JSON.stringify({ ...data, type: false }), url)
      }
    } else {
      return
    }
  }, [iframe, parts])

  let [isLoading, setLoading] = useState(false)

  const clearSelections = useCallback(() => {
    setCurFocusedImg('')
    setCurFocusedImgHeight(0)
    setCurFocusedImgWidth(0)
    setCurFocusImgLimitsHeight(undefined)
    setCurFocusImgLimitsWidth(undefined)
  }, [])

  const [view3dDisplay, setView3dDisplay] = useState(false)
  const showView3D = useRef(false)

  useEffect(() => {
    if (view3dDisplay) {
      showView3D.current = true
    }
  }, [view3dDisplay])

  useEffect(() => {

    if (props.forceClear !== undefined) {
      clearSelections()
    }

  }, [props.forceClear])

  const SEGMENTS = 1
  currentPartLength.current = partLength

  log({partLength}, 'part length update TRACK xxx')

  return (
    <div style={{height: '100%', width:'100%'}}>
       <ToastContainer hideProgressBar={true} />
    <main className={styles.container}>

      <div className={styles.leftWorkArea} >
        <LeftWorkAreaContainer
          clearSelections={clearSelections}
          showLibrary={props.showLibrary}
          fabricSizeUnit={fabricUnit}
          maxFabricLength={maxFabricLength}
          galleryHeight={fcSize.height + 40}
          addToCartWidget={<AddToCartBtn
            handler={addToCart}
            title={t("editor.add_to_cart")}
            name="addToCart"
            active={product ? isNonRepeat ? ((parts[0].length || stepLength) > maxFabricLength) : false : true} />
          }
          parts={() => {  }}
          selCurImage={curFocusedImg}
          selCurImageWidth={curFocusedImgWidth}
          selCurImageHeight={curFocusedImgHeight}
          widthLimits={curFocusedImgLimitsWidth}
          heightLimits={curFocusedImgLimitsHeight}
          curPartLength={partLength}
          fcSize={fcSize}
          imageUploadStart={(progress) => {

            log({ progress, isNonRepeat, length: parts[0].length, maxFabricLength }, 'hello load complete 1')
            if (progress) {
              const overflow = isNonRepeat ? ((parts[0].length || stepLength) > maxFabricLength) : false
              if (overflow) {
                alertify.alert(
                  t("editor.alert_warning_title2"),
                  `The max length for No Repeat is ${maxFabricLength} metres.`,
                  t("i18n.ok"))
              }
            }
            setLoading(progress)
          }}
          aspectRatioConstrained={activeElement ? activeElement.lockedAR : undefined}
          open3dView={()=>{
            setView3dDisplay(true)
          }} />
      </div>
      <div className={styles.rightPanel}>
      {product && unitType && (
        <div className={styles.rulerUnits}>{t("editor.width_units")}</div>
        )}
        {product && (
        <div className={styles.rulerHorizontal}>
          
            <Ruler
              fabricWidth={product.width/SEGMENTS}
              containerSize={fcSize.width}
              start={0}
              type={'HORIZONTAL'}
              partsCount={parts.length}
              partSize={partLength}
              containerWidth={fcSize.width}
              containerHeight={height}
            />
        </div>
        )}
        {!isSplit ? <div style={{ height: `${height}px` }} className={styles.rulerVertical}>
          {product && (
            <Ruler
              pxPerCm={fcSize.width / ((product.width/SEGMENTS) * 100)}
              fabricWidth={product.width/SEGMENTS}
              containerSize={height}
              containerWidth={fcSize.width}
              containerHeight={height}
              partsCount={rulerUnits}
              start={prodPosition}
              stepSize={stepLength}
              partSize={partLength}//{parts.length}
              type="VERTICAL"
              partLengthChanged={partLength}
            />
          )}
        </div> : null}

        <div
          className={isSplit ? styles.fabricScenGrid : styles.fabric}
          style={{ height: `${height}px`}}
          ref={fabricContainer}
        >
          {isSplit ? <div className={styles.rulerVerticalScenGrid}>
            {product && (
              <Ruler
                pxPerCm={(fcSize.width-40) / ((product.width/SEGMENTS) * 100)}
                fabricWidth={product.width/SEGMENTS}
                containerSize={height}
                containerWidth={(fcSize.width-40)}
                containerHeight={height}
                partsCount={rulerUnits}
                start={prodPosition}
                stepSize={stepLength}
                partSize={partLength}//{parts.length}
                type="VERTICAL"
                fabricSizeUnit={fabricUnit}
                partLengthChanged={partLength}
              />
            )}
          </div> : null}
          {!isSplit ? <Scen
            fabricSizeUnit={fabricUnit}
            containerSize={fcSize}
            activeUrl={(url) => { setCurFocusedImg(url) }}
            loadImage={(isLoading) => {
              setLoading(isLoading)
            }}
            imageSelectionChange1={(wd, limits) => {
              setCurFocusedImgWidth(wd)
              limits && setCurFocusImgLimitsWidth(limits)
            }}
            imageSelectionChange2={(ht, limits) => {
              setCurFocusedImgHeight(ht)
              limits && setCurFocusImgLimitsHeight(limits)
            }}
            activePart={parts}
            partLengthChanged={(length) => {
              setPartLength(roundOff(length,2))
            }}
            toastify={(msg) => { notify(msg) }} /> :
            <div className={styles.fabricCanvasDiv}>
              {product && fcSize ? <FabricCanvas containerWidth={fcSize.width - 40}
                productWidth={product.width / SEGMENTS} unit={fabricUnit}
                fabricSizeUnit={fabricUnit}
                containerSize={{
                  width: fcSize.width -40,
                  height: fcSize.height
                }}
                activeUrl={(url) => { setCurFocusedImg(url) }}
                loadImage={(isLoading) => {
                  setLoading(isLoading)
                }}
                imageSelectionChange1={(wd, limits) => {
                  setCurFocusedImgWidth(wd)
                  limits && setCurFocusImgLimitsWidth(limits)
                }}
                imageSelectionChange2={(ht, limits) => {
                  setCurFocusedImgHeight(ht)
                  limits && setCurFocusImgLimitsHeight(limits)
                }}
                // activePart={parts}
                partLengthChanged={(length, change) => {
                  let partLength = currentPartLength.current
                  log({ partLength, length, change: change||'NA' }, 'part length update TRACK xxx')
                  if (change) {
                    setPartLength(roundOff(partLength+(change*isSplit.rows), 2))
                  } else {
                  setPartLength(roundOff(length,2))
                  }
                }}
                toastify={(msg) => { notify(msg) }}
              /> : null}
            </div>
          }
          {isLoading ?
            <div style={{
              left: fcSize.width / 2 - 50, top: height / 2 - 50,
              position: 'absolute', color: 'white',
              fontSize: 20,
              backgroundColor: "#4A69FF", border: '1px solid white',
              borderRadius: 5, padding: 10
            }}>
              {t("editor.loading")}
            </div>
            : null
          }
        </div>

        {isSplit ? null : <div className={styles.scrollbar} style={{ height: `${height}px` }}>
          <Scrollbar trackerHeight={fcSize.height} contentHeight={height} height={height} />
        </div>}
        
      </div>

      <div className={styles.addToCart}>
        <ATCbutton
          handler={addToCart}
          title={t("editor.add_to_cart")}
          name="addToCart"
          active={product ? (isNonRepeat ? ((parts[0].length || stepLength) > maxFabricLength) : false) : true} />
      </div>
    </main>
    </div>
  )
}
export default Body
