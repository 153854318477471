import React, { useState, useEffect, useCallback, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import {useDecode} from "../hooks/useDecode"
import 'rc-slider/assets/index.css'
import { useTranslation } from "react-i18next"
import "./styles.scss"
import { addFabricToCart } from "../redux/reducers/fabric/actions"
import { setSpinner } from "../redux/actions/spinner"
import { log } from "../utilities/logger"
import { RepeatPanel } from "./RepeatPanel"
import { FabricDimensions } from "./FabricDimensions"
import { TileSize } from "./PatternSize"

export type ControlPanelProps = {
  fabricWidth: number
  fabricLength: number
  activePart: any
  fabricMaxLength: number
  stepSize:number
}

export const ControlPanel = (props:ControlPanelProps) => {

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const parts = useSelector((state:any) => state.parts)
  const product = useSelector((state:any) => state.product)
  const [data, setData] = useState({length: 0, price: 0})
  const [size, setSize] = useState({width: 0, height: 0})
  const settings = useSelector((state:any) => state.settings)||{}
  const part = useMemo(() => { return parts.find((part: any) => part.active) }, [parts])

  const currency = useDecode(
    product && product.currency ? product.currency : "",
  )

  const sendToHost = useCallback((action) => {
    let url =
        window.location !== window.parent.location
            ? document.referrer
            : document.location.origin

    window.parent.postMessage(
        JSON.stringify({
            action
        }),
        url,
    )
  }, [window])

  useEffect(() => {
    if (settings.display && settings.configuration) {
      let {currentLength} = settings.display
     
      setData({length:currentLength, price: currentLength*product?.price||0})
    }
  }, [settings, product])

  const openSidebarHandler = () => {
    sendToHost('OPEN_EDITOR_SIDEBAR');
  }

  const addToCart = useCallback(
    e => {
      e.preventDefault()
      dispatch(addFabricToCart(() => {
        dispatch(setSpinner(true))
      
      }, (opt) => {
        dispatch(setSpinner(false))
        addToCartHandler(opt)
      }))
    },
    [dispatch, parts, product],
  )

  const addToCartHandler = (opt:any) => {
    const url = window.location !== window.parent.location
                  ? document.referrer
                  : document.location.origin

    console.groupCollapsed("ADD_TO_CART")
    log(JSON.stringify(opt))
    console.groupEnd()

    if (!opt.content) {
      console.error("Can't save the data")
    } else {
      window.parent.postMessage(JSON.stringify(opt), url)
    }
  }

  return (
    <div className={"page"}>
      <div className={"toolPanel"}>
        <div className={"patternName"}>{part?.content_images[0]?.file?.name?.substring(0, part?.content_images[0]?.file?.name?.lastIndexOf('.')) || "Pattern Name"}</div>

        <div className={"hr"}/>
        <div className={"sizeParent"}>
          <TileSize part={part} />
          <div className={"advisoryLabel"}>
            Review this preview closely. What you see here is how this design will print. Adjust DPI to change scale if needed.
          </div>
          <div className={"hr"}/>
        </div>

        <div className={"repeatParent"}>
          <RepeatPanel partId={part?._id} />
          <div className={"hr"}/>
        </div>
        
        <div className={"fabricParent"}>
          <div className={"fabricType"}>
            <div className={"fabricLabel"}>Fabric Type </div>
            <div className={"fabricEdit"} onClick={openSidebarHandler}>
              <div className={"fabricEditDiv"}>
                CHANGE FABRIC
              </div>
              
            </div>
          </div>
          <div className="fabricName">
            <div>{product?.name}</div>
            {product?.price ? <div>
              <div>{product && product.currency ? currency : ""}{" "}{`${product?.price.toFixed(2)}`}</div>
            </div> : null}
          </div>

          <div className={"hr"}/>

          <div className={"fabricInfo"}>
            <FabricDimensions stepSize={props.stepSize} activePart={props.activePart} maxLength={props.fabricMaxLength} fabricWidth={props.fabricWidth} currentPartLength={props.fabricLength} />
            <div className={"fabricPrice"}>
              <div className={"priceLabel"}>Price</div>
              <div className={"priceValue"}>{product && product.currency ? currency : ""}{" "}{product && product.price ? data.price.toFixed(2) : ""}</div>
              <div className={"priceInfo"}>See my price</div>
            </div>
          </div>

          <div className={"hr"}/>
        </div>

        <div className={"optionsParent"}>
          <div className={"addToCartDiv"}>
            <button className={"addToCartBtn"}
                    onClick={addToCart}
                    >Add to cart</button>
          </div>
        </div>
      </div>
    </div>
  )
  
}

