import React, { useEffect, useState } from 'react'
import { fireDir } from '../firebase'
import { log } from '../utilities/logger'


export type MLImage = {
    catName: string[]
    url: string
    id: string
    urlThumbnail: string
    isFirebase: boolean
    scaleFactor?: number
}

const useIframeMediaLibrary = (args: { userId: string, auth: number, mediaLibTitle: string }) => {
    const [MLImages, setMLImages] = useState([] as MLImage[])

    let {userId, auth, mediaLibTitle} = args
    log({userId, auth, mediaLibTitle}, 'DATA firebase')

    useEffect(() => {
        if (userId && auth === 1 && mediaLibTitle) {
            const mlImages: MLImage[] = []
            const tenantRef = fireDir.doc(userId)
            const mlRootRef = tenantRef.collection("Media")
            mlRootRef
                .get()
                .then(mlRootSnap => {
                    let counter = 0
                    for (const mldRef of mlRootSnap.docs) {
                        const mlSourceRef = mlRootRef.doc(mldRef.id)
                        const mlImagesRef = mlSourceRef.collection("Images")
                        mlImagesRef
                            .get()
                            .then(mlImagesSnap => {
                                for (const mlImageRef of mlImagesSnap.docs) {
                                    const mlImage = mlImageRef.data()
                                    log({mlImageId: mlImageRef.id}, 'DATA firebase')
                                    if (mlImage.Name === mediaLibTitle) {

                                        let imageTile:MLImage = {
                                            catName: mlImage.Categories||[],
                                            id: mlImageRef.id||mlImage.SourceID||counter++,
                                            url: mlImage.FullSizeURL,
                                            urlThumbnail: mlImage.FullSizeURL,
                                            isFirebase: true,
                                            scaleFactor: mlImage.ScaleFactor
                                        }

                                        mlImages.push(imageTile)
                                        break;
                                    }
                                }
                                setMLImages(mlImages)
                            })
                            .catch(err => {
                                console.error("Error getting MediaLibrary(Images)", err)
                            })
                    }
                })
                .catch(err => {
                    console.error("Error getting MediaLibrary(root)", err)
                })
        }
    }, [userId, auth])

    return MLImages
}
export default useIframeMediaLibrary