import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setProduct, setProducts } from '../redux/actions/product';
import { setSpinner } from '../redux/actions/spinner';
import alertify from "./../libs/alert"

export const useProductsJson = (isWebPage: boolean, isAuthenticated: boolean) => {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const [result, setResult] = useState({ inProgress: false });

    useEffect(() => {
        if (isWebPage && isAuthenticated) {
            setResult({ inProgress: true })
            fetch("/testdata/productsUS.json")
                .then(res => res.json())
                .then(res => {
                    if (res.products && res.products.length) {
                        console.log(res.products)
                        if (process.env.NODE_ENV === "development") {
                            dispatch(setProducts(res.products))
                            dispatch(setProduct(res.products[0]))
                        } else {
                            dispatch(setProducts(res.products))
                            dispatch(setProduct(res.products[0]))
                        }
                        dispatch(setSpinner(false))
                    }
                })
                .catch(err => {

                    alertify.alert(t("editor.alert_warning_title2"), t("editor.alert.warning_msg1"), t("i18n.ok"), () => {
                        dispatch(setSpinner(false))
                    })
                }).finally(()=>{
                    setResult({inProgress: false})
                })
        }
    }, [isWebPage, isAuthenticated]);
    return result

}