import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useStore } from 'react-redux';
import { changePartLength } from '../../redux/actions/parts';
import { DECREMENT_NO_OF_FABRIC_UNITS, INCREMENT_NO_OF_FABRIC_UNITS, UPDATE_DISPLAY_UNITS } from '../../redux/reducers/settings';
import { DebouncedInput } from '../DebouncedInput';
import "./styles.scss";
import alertify from "../../libs/alert"

export type FabricDimensionsProps = {
    fabricWidth: number
    currentPartLength: number
    maxLength: number
    stepSize: number
    activePart: any
}

export const FabricDimensions = (props: FabricDimensionsProps) => {
    const { fabricWidth, activePart, maxLength, stepSize, currentPartLength } = props
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [validationErrorMessage, setValidationErrorMessage] = useState('');

    const displayError = useCallback(() => {
        validationErrorMessage && alertify.alert(t("editor.alert_warning_title1"), validationErrorMessage, t("i18n.ok"),
            () => { }).set('modal', true)
    }, [validationErrorMessage])

    const validateInput = useCallback((value) => {
        value = value;
        const isValueTooBig = !!(value && (value > maxLength));
        const isValueTooSmall = !!(value && (value < 0.5));
        if (isValueTooBig) {
            setValidationErrorMessage(`${t("editor.fabric_exceeds_max_length")}: ${maxLength} m.`)
        } else if (isValueTooSmall) {
            setValidationErrorMessage('')
        }
        return !isValueTooBig && !isValueTooSmall
    }, [maxLength])

    const onFabricLengthInputHandler = useCallback((value) => {
        value = value;
        const currentSections = Math.ceil((value) / stepSize)
        if (value > currentPartLength) {
          dispatch({ type: INCREMENT_NO_OF_FABRIC_UNITS, payload: currentSections })
        } else if (value < currentPartLength) {
          dispatch({ type: DECREMENT_NO_OF_FABRIC_UNITS, payload: currentSections })
        }
        dispatch(changePartLength(activePart, value, maxLength, t, true))
        dispatch({
            type: UPDATE_DISPLAY_UNITS,
            payload:{
              currentLength: value
            }
        });
    }, [currentPartLength, activePart, maxLength]);

    return <div className="fabricLength">
        <div className="fabricLengthLabel">Amount of fabric</div>
        <div className="fabricLengthControl">
            <DebouncedInput min={0.5} useCeiling displayError={displayError} validateInput={validateInput} currentValue={currentPartLength} onValueChanged={onFabricLengthInputHandler} className="inputNumber" />
            {`x ${fabricWidth} m`}
        </div>
    </div>
}