import * as THREE from "three"
import glo from "./glo";
import { log } from "../../../../../../../utilities/logger"

const SVG2Mesh = require('svg-mesh-3d');


export default class SVGMesh extends THREE.Group {
    private dataset: any = null;
    private meshes: THREE.Mesh[] = [];

    public ProccessGroup(dataset: any, object: THREE.Group): void {
        this.clear();
        const boxes: { [name: string]: THREE.Mesh } = {};
        object.traverse(item => {
            const mesh = item as THREE.Mesh;
            if (!(mesh instanceof THREE.Mesh))
                return;
            if (!mesh.name)
                return;
            mesh.geometry.computeBoundingBox();
            boxes[mesh.name] = mesh;
        });

        for (let name in boxes) {
            const mesh = boxes[name];
            const sname = name + "-Bleedline";
            const bmesh = boxes[sname];
            if (bmesh)
                glo.Set(mesh, "bleed", bmesh);

            this.AddUVToMesh(mesh, bmesh);
            this.add(mesh);
        }
    }

    protected AddUVToMesh(mesh: THREE.Mesh, bmesh: THREE.Mesh): void {
        if (!mesh || !mesh?.geometry?.attributes || !mesh?.geometry?.attributes?.position)
            return;

        const box = bmesh ? bmesh.geometry.boundingBox! : mesh.geometry.boundingBox!;
        const minBox = box.min;
        const maxBox = box.max;
        const dBox = new THREE.Vector3();

        dBox.copy(maxBox);
        dBox.sub(minBox);

        const geom = mesh.geometry;
        const uv: number[] = [];

        const position = geom.attributes.position;

        let i, len = position.count, u, v;
        for (i = 0; i < len; ++i) {
            u = (position.getX(i) - minBox.x) / dBox.x;
            v = (position.getY(i) - minBox.y) / dBox.y;

            uv.push(u, v);
        }

        const baUV = new THREE.Float32BufferAttribute(uv, 2);
        geom.setAttribute("uv", baUV);
    }

    public Load(dataset: any, doc: Document): void {
        this.clear();
        this.dataset = dataset;
        let elms: any = doc.getElementsByTagName("polygon");
        for (let i = 0; i < elms?.length; ++i) {
            const id: string = elms?.[i]?.attributes?.id?.nodeValue;
            if (!id) continue;
            const names = id.split("-");
            const name = names?.[1] === "Sewline" ? names[0] : null;
            log(names, name);
            const points = elms?.[i]?.attributes?.points?.nodeValue;
            this.CreateMesh(name, SVG2Mesh("M " + points + " z", { normalize: false }));
        }
        elms = doc.getElementsByTagName("polyline");
        for (let i = 0; i < elms?.length; ++i) {
            const id: string = elms?.[i]?.attributes?.id?.nodeValue;
            if (!id) continue;
            const names = id.split("-");
            const name = names?.[1] === "Sewline" ? names[0] : null;
            log(names, name);
            const points = elms?.[i]?.attributes?.points?.nodeValue;
            this.CreateMesh(name, SVG2Mesh("M " + points + " z", { normalize: false }));
        }
        elms = doc.getElementsByTagName("path");
        for (let i = 0; i < elms?.length; ++i) {
            const id: string = elms?.[i]?.attributes?.id?.nodeValue;
            if (!id) continue;
            const names = id.split("-");
            const name = names?.[1] === "Sewline" ? names[0] : null;
            console.log(names, name);
            const points = elms?.[i]?.attributes?.d?.nodeValue;
            this.CreateMesh(name, SVG2Mesh("" + points + "", { normalize: false }));
        }
    }

    protected CreateMesh(name: string | null, meshData: any): void {
        if (!meshData || !meshData.cells || !meshData.positions)
            return;

        let i, j;
        const { cells, positions } = meshData;
        const triLen: number = cells.length;

        const MAX_VAL: number = 1000000;
        const minBox = new THREE.Vector3(MAX_VAL, MAX_VAL, MAX_VAL);
        const maxBox = new THREE.Vector3(-MAX_VAL, -MAX_VAL, -MAX_VAL);
        const dBox = new THREE.Vector3();
        const geom = new THREE.BufferGeometry();
        const vertices: number[] = [];
        const normals: number[] = [];
        const uv: number[] = [];

        const TO = [0, 2, 1];
        for (i = 0; i < triLen; ++i) {
            const tri = cells[i];
            for (j = 0; j < 3; ++j) {
                const pos = positions[tri[TO[j]]];
                vertices.push(pos[0], pos[1], pos[2]);
                normals.push(0, 0, 1);
                minBox.set(Math.min(minBox.x, pos[0]), Math.min(minBox.y, pos[1]), Math.min(minBox.z, pos[2]));
                maxBox.set(Math.max(maxBox.x, pos[0]), Math.max(maxBox.y, pos[1]), Math.max(maxBox.z, pos[2]));
            }
        }
        dBox.copy(maxBox);
        dBox.sub(minBox);
        console.log(minBox, maxBox, dBox);

        for (i = 0; i < triLen; ++i) {
            const tri = cells[i];
            for (j = 0; j < 3; ++j) {
                const pos = positions[tri[TO[j]]];
                uv.push((pos[0] - minBox.x) / dBox.x, (pos[1] - minBox.y) / dBox.y);
            }
        }

        const baPos = new THREE.Float32BufferAttribute(vertices, 3);
        const baNrm = new THREE.Float32BufferAttribute(normals, 3);
        const baUV = new THREE.Float32BufferAttribute(uv, 2);

        geom.addAttribute("position", baPos);
        geom.addAttribute("normal", baNrm);
        geom.addAttribute("uv", baUV);

        //geom.computeVertexNormals();
        const meshMat = new THREE.MeshPhongMaterial({ color: 0x6f6f6f });
        meshMat.opacity = 0.8;
        const mesh = new THREE.Mesh(geom, meshMat);
        this.meshes.push(mesh);
        glo.Set(mesh, "uv", uv);
        if (name)
            mesh.name = name;
        else {
            mesh.position.z -= 1;
            mesh.updateMatrix();
        }
        this.add(mesh);
    }
}