import { IFabric } from "."
import { ContainerPart, FabricImage, Product } from "../../../libs/viewer/viewerHelp"
import { ICanvasImages } from "../images"
import { addToCart, FabricSegmentOutput } from "./addtocart.helpers"

export const SETUP_FABRIC = "setup_fabric"
export const UPDATE_GRID_CONFIG = "update_grid_config"
export const SEGMENT_IN_FOCUS = "segment_in_focus"
export const SEGMENT_SETTINGS_UPDATE = "segment_settings_update"
export const REMOVE_SEGMENT_SETTINGS = "remove_segment_settings"
export const ADD_FABRIC_TO_CART = "add_fabric_to_cart"

type SetUpConfig = {
  productWidth: number
  productHeight: number
  fabricCanvasWidthPx: number
  unit: 'YARD' | 'METER' | 'DECIMETER'
  grid: { columns: number, rows: number }

}

export const addFabricToCart = (creatingCart:()=>void, onAddToCartComplete:(obj:any)=>void) => async (dispatch: any, getState: any) => {

  const { fabric, product, apikey, settings } = getState()
  const segments:FabricSegmentOutput[] = ((fabric as IFabric).segmentSettings||[]).map(segment =>({
    fabricCanvas: segment.parentCanvas.getCanvas() as fabric.Canvas,
    part: segment.parentCanvas.getPart() as ContainerPart,
    images: segment.parentCanvas.getImages() as FabricImage[],
    columnIndex: segment.columnIndex,
    rowIndex: segment.rowIndex,
    
  }))
  
  const grid = (fabric as IFabric).settings?.grid || { rows: 1, columns: 1 }

  const segmentProduct = fabric.segmentSettings[0].parentCanvas.getProduct() as Product
  const isSplit = settings.configuration?.split
  const { widthPx, heightPx } = (fabric as IFabric).settings?.fabricDimensionsPx || {}
  const canvasWindow = { width: widthPx || 0, height: heightPx || 0 }
  const fabricDim = {
    width: product.width, length: (settings.display?.currentLength),// product.length  10,
    rows: (fabric as IFabric).settings?.grid?.rows || 0, unit: settings.unitType
  }
  const object = await addToCart(product.productID, segments, segmentProduct, canvasWindow, product.sku || 'skunotfound', apikey,
    product.name, fabricDim, grid, undefined, creatingCart, isSplit)
  onAddToCartComplete(object)
}

export const setUpFabric = (config: SetUpConfig) => {
  const { productHeight, productWidth, fabricCanvasWidthPx, unit, grid } = config
  let scaleSections = 0
  let subUnits = 0

  switch (unit) {
    case 'YARD':
      subUnits = 36
      scaleSections = productWidth * subUnits //(1 yard = 36 inches)
      break;
    case 'METER':
      subUnits = 100
      scaleSections = productWidth * subUnits //(1 meter = 100 cm)
      break;
    case 'DECIMETER':
      subUnits = 10
      scaleSections = productWidth * subUnits //(1 decimeter = 10 cm)
      break;
    default:
      break;
  }

  scaleSections = Math.ceil(scaleSections);
  const pxPerUnit = fabricCanvasWidthPx / scaleSections
  return {
    type: SETUP_FABRIC,
    payload: {
      pxPerUnitFabric: pxPerUnit,
      grid,
      unitType: unit,
      fabricDimensionsPx: {
        widthPx: fabricCanvasWidthPx,
        heightPx: pxPerUnit * productHeight * subUnits
      }
    }
  }
}