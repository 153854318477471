import React, { useEffect, useRef, useState } from 'react'
import { createApi } from 'unsplash-js'
import { ApiResponse } from 'unsplash-js/dist/helpers/response'
import { Basic } from 'unsplash-js/dist/methods/photos/types'
import { Photos } from 'unsplash-js/dist/methods/search/types/response'
import * as User from 'unsplash-js/dist/methods/users/types'
import { Nullable } from 'unsplash-js/dist/helpers/typescript'
import { DEBUG, log } from '../utilities/logger'

const UNSPLASH_ACCESS_KEY = `RxYltfazzeMKTwRJmKtV-I0nL0InMVjHCB0o2jPxbkY`

export type UnsplashImage = {
    catName: string[]
    url: any
    id: string
    urlThumbnail: string
    isUnsplash: boolean
    userName: string,
    userPortfolio: Nullable<string>,
   
    
}

// var unsplash = createApi({
//     accessKey: UNSPLASH_ACCESS_KEY
// })
// unsplash.photos.list({}).then((result)=>{
//     let results = ((result.response?.results || []) as UnsplashImageResp[]).map((image) => ({
//         catName: image.categories || [],
//         url:image.urls.full,
//         tracker: `${image.links.download_location}&client_id=${UNSPLASH_ACCESS_KEY}`,
//         id: image.id,
//         urlThumbnail: image.urls.thumb,
//         isUnsplash: true,
//         userName: image.user.name,
//         userPortfolio: image.user.portfolio_url,
//     }))
//     unsplash.photos.trackDownload({downloadLocation:results[0].tracker}).then(()=>{
//         console.log("tracked ------>"+results[0].url)
//     })
// })

export const useUnsplashLib = (query: string, page: number, isUnsplashTabVisible: boolean) => {

    const [unsplashImages, setUnsplashImages] = useState([] as UnsplashImage[])
    const [totalImages, setTotalImages] = useState(0)

    useEffect(() => {
        if(!isUnsplashTabVisible){
            return;
        }
        if (query) {
            createApi({
                accessKey: UNSPLASH_ACCESS_KEY
            }).search.getPhotos({
                query,
                page,
                perPage: 9
            }).then((result: ApiResponse<Photos>) => {
                let totalImages = result.response?.total
                let results = ((result.response?.results || []) as UnsplashImageResp[]).map((image) => ({
                    catName: image.categories || [],
                    url: image.urls.full,
                    tracker: `${image.links.download_location}&client_id=${UNSPLASH_ACCESS_KEY}`,
                    id: image.id,
                    urlThumbnail: image.urls.thumb,
                    isUnsplash: true,
                    userName: image.user.name,
                    userPortfolio: image.user.portfolio_url,
                }))
                log({ result, totalImages }, 'UNSPLASH RESPONSE')
                setTotalImages(totalImages || 0)
                setUnsplashImages(results)
            })
        } else {
            createApi({
                accessKey: UNSPLASH_ACCESS_KEY
            }).photos.list({
                page,
                perPage: 9
            }).then((result:ApiResponse<{results:Basic[], total:number}>) => {
                let totalImages = result.response?.total
                let results = ((result.response?.results || []) as UnsplashImageResp[]).map((image) => ({
                    catName: image.categories || [],
                    url: image.urls.full,
                    //tracker: `https://api.unsplash.com/photos/${image.id}/download/?client_id=${UNSPLASH_ACCESS_KEY}`,
                    tracker: `${image.links.download_location}&client_id=${UNSPLASH_ACCESS_KEY}`,
                    id: image.id,
                    urlThumbnail: image.urls.thumb,
                    isUnsplash: true,
                    userName: image.user.name,
                    userPortfolio: image.user.portfolio_url,
                }))
                log({ result, totalImages }, 'UNSPLASH RESPONSE')
                setTotalImages(totalImages || 0)
                setUnsplashImages(results)
            })
        }

    }, [query, page])

    return { unsplashImages, totalImages }

}


export interface UnsplashImageResp extends Basic {
    id: string;
    created_at: string;
    updated_at: string;
    urls: Urls;
    categories?: string[]
    likes: number;
    liked_by_user: boolean;
    current_user_collections?: string[]
    sponsorship?: null;
    exif: Exif;
    location: Location;
    views: number;
    downloads: number;
    user: User.Basic;
}
export interface Urls {
    raw: string;
    full: string;
    regular: string;
    small: string;
    thumb: string;
}

export interface Exif {
    make: string;
    model: string;
    exposure_time: string;
    aperture: string;
    focal_length: string;
    iso: number;
}
export interface Location {
    title: string;
    name: string;
    city: string;
    country: string;
    position: Position;
}
export interface Position {
    latitude: number;
    longitude: number;
}
