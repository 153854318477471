import React, { useState, useEffect, useCallback, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import styles from "./library.module.scss"
import { toggleLibsMenu, addImagesToPart } from "../../../redux/actions/parts"
import { setSpinner } from "../../../redux/actions/spinner"
import alertify from "../../../libs/alert"
import { useTranslation } from "react-i18next"
import EXIF from "exif-js"
import {log} from "../../../utilities/logger"
import { useUnsplashLib } from "../../../hooks/useUnsplashLib"
import { ACTIONS_SET_IMAGES_COPYRIGHT_COMPLIANT } from "../../../redux/reducers/images"
import { FabricDialog } from "../../CopyrightDialog"
import { useHistory } from 'react-router-dom'

const COPYRIGHT_WARNING = {

  title: "i18n.intelWarn.copyrightTitle",

  text: ["i18n.intelWarn.p1",

  "i18n.intelWarn.p2",

  "i18n.intelWarn.p3"],

  checkbox: "i18n.intelWarn.check"
}

// import {EXIF} from "exif-js"

/**
 * Add image to fabric part from library
 * @component
 * @param {object} props
 * @param {string} props.id - part id
 * @example
 *
 * const id = 'asdasdasdasdasdasds'
 * return (
 *  <LIbrary id={id}/>
 * )
 */

const LIbrary = props => {
  const { id = "" } = props
  const dispatch = useDispatch()
  const [selectedImg, setSelectedImg] = useState("")
  const [selectedImgId, setSelectedImgId] = useState("")
  const [scaleFactor, setScaleFactor] = useState(undefined)
  const [imgSourceCloud, setImgSourceCloud] = useState(false)
  const [tracker, setDownloadTracker] = useState("")
  const hostSiteImgs = useSelector(state => state.imgslib)

  const fbdata = useSelector(state => state.fbdata)
  const product = useSelector(state => state.product)

  const [filter, setFilter] = useState("SELECT_ALL")
  const [cats, setCats] = useState(null)
  //const [imgslib, setImgsLib] = useState([])
  const [query, setQuery] = useState('')
  const [libraryPage, setLibraryPage] = useState(1)

  const isUnsplashTabVisible = fbdata.showUnsplash !== undefined?fbdata.showUnsplash:false
  const unsplashLibrary = useUnsplashLib(query, libraryPage, isUnsplashTabVisible)
  const [libSource, setLibSource] = useState('local')
  

  const APP_NAME = "Evcustomizer"
  log({libSource},"TRACING 1")

  let imgslib = (libSource === 'local') ? [...hostSiteImgs, ...props.firebaseImages || []] : [...unsplashLibrary.unsplashImages]

  

  useEffect(() => {
    if (imgslib.length) {
      let catsArr = imgslib
        .map(item => [...item.catName])
        .flat()
        .reduce((acc, item) => {
          if (!acc.includes(item)) {
            acc.push(item)
          }
          return acc
        }, [])
      setCats(catsArr)
    } else {
      setCats([])
    }
  }, [JSON.stringify(imgslib)])

  // useEffect(()=>{
  //   log({imgslib, cats},'Library')
  // },[imgslib, cats])

  log({ imgslib, cats }, 'Library')




  const { t } = useTranslation()
  const history = useHistory()
  const isSplit = useSelector((state) => state.settings?.configuration?.split)

  const handleClose = e => {
    e.preventDefault()
    dispatch(toggleLibsMenu(id, isSplit))
  }

  const handleSelectImg = e => {
    if (e.target) {
      let src = e.target.dataset.url
      let id = e.target.dataset.itemid
      if (src) {
        setSelectedImg(prev => (prev !== src ? src : ""))
        setSelectedImgId(prev => (prev !== id ? id : ""))
      }
    }
  }


  const handleSaveFirebase = () => {
    dispatch(setSpinner(true))
    const getIMG = () =>
      new Promise((resolve, reject) => {
        log({tracker},"display data TRACKER")
        if(tracker){
          fetch(tracker).then((resp)=>resp.json()).then((result)=>{
            log({val: result},"display data TRACKED")
          })
        }
        
        fetch(selectedImg)
          .then(response => response.blob())
          .then((blob) => {
            resolve(blob)
          })
          .catch(err => {
            log({ err }, 'response _ error')
            reject(err)
            dispatch(setSpinner(false))
          })
      })
    getIMG()
      .then(blob => {
        let url = window.URL || window.webkitURL
        let image = new Image()
        image.src = url.createObjectURL(blob)
        image.onload = function () {
          log({ objectUrl: image.src }, 'display data -4')
          EXIF.getData(this, function () {
            let allMetaData = EXIF.getAllTags(this)
            log({ allMetaData }, 'display data allMetaData')
            let curResolution = allMetaData.XResolution
              ? allMetaData.XResolution.valueOf()
              : 150
            curResolution = curResolution < 150 ? 150 : curResolution
            dispatch(addImagesToPart(id, [{
              src: this.src,
              width: this.width,
              height: this.height,
              minResolution: fbdata.minimalResolution || 96,
              partWidth: product.width,
              curResolution,
              fcSize: props.fcSize,
              file: blob,
              scaleFactor,
              image_id: selectedImgId
            }], scaleFactor, t,isSplit ))
            setSelectedImg("")
            setSelectedImgId('')
            dispatch(toggleLibsMenu(id, isSplit))
            dispatch(setSpinner(false))
          })
        }
      })
      .catch(err => {
        log(`ERROR:${err}`, 'display data error')
        dispatch(setSpinner(false))
        alertify.alert(t("editor.alert_warning_title2"), t("editor.alert.warning_msg1") + " " + err.message, t("i18n.ok"), () => {
          setSelectedImg("")
          setSelectedImgId('')
          dispatch(toggleLibsMenu(id, isSplit))
          return
        })
      })
  }

  const handleSave = () => {

    dispatch(setSpinner(true))

    const url =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.origin

    const urlForRequest = url.split("/")[2]
    // const urlForRequest = `myalloverprint.com`
    // console.log("URL", urlForRequest)

    const getIMG = () =>
      new Promise((resolve, reject) => {
        fetch(`//${urlForRequest}/wp-json/ev-admin/v1/get-image-base64`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({ image: selectedImg }),
          })
          .then(res => res.json())
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
            dispatch(setSpinner(false))
          })
      })

    getIMG()
      .then(img => {
        fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let url = window.URL || window.webkitURL
            let image = new Image()
            image.src = url.createObjectURL(blob)
            let curResolution = 150
            image.onload = function () {
              EXIF.getData(this, function () {
                let allMetaData = EXIF.getAllTags(this)
                log({ allMetaData }, 'allMetaData')
                let curResolution = allMetaData.XResolution
                  ? allMetaData.XResolution.valueOf()
                  : 150
                curResolution = curResolution < 150 ? 150 : curResolution
                //props.imageUploadStart(true)
                dispatch(addImagesToPart(id, [{
                  src: this.src,
                  width: this.width,
                  height: this.height,
                  minResolution: fbdata.minimalResolution || 96,
                  partWidth: product.width,
                  curResolution,
                  fcSize: props.fcSize,
                  file: blob,
                  scaleFactor,
                }], scaleFactor, t, isSplit))
                setSelectedImg("")
                setSelectedImgId('')
                dispatch(toggleLibsMenu(id, isSplit))
                dispatch(setSpinner(false))
              })
            }
          })
          .catch(err => {
            log(`ERROR:${err}`)
            alertify.alert(t("editor.alert_warning_title2"), t("editor.alert.warning_msg1"), t("i18n.ok"), () => {
              setSelectedImg("")
              dispatch(toggleLibsMenu(id, isSplit))
              dispatch(setSpinner(false))
              return
            })
          })
      })
      .catch(err => {
        log(`ERROR:${err}`)
        alertify.alert(t("editor.alert_warning_title2"), t("editor.alert.warning_msg1"), t("i18n.ok"), () => {
          setSelectedImg("")
          setSelectedImgId('')
          dispatch(toggleLibsMenu(id, isSplit))
          dispatch(setSpinner(false))
          return
        })
      })
  }

  const handleFilter = ({ target }) => {
    setFilter(target.name)
  }


  const filteredImages = useCallback(() => {
    if (imgslib.length) {
      if (filter === "SELECT_ALL") {
        return imgslib.map((item, index) => {
          log({source: item.url},"TRACING 2")
         return <div
            onClick={(e) => {
            setScaleFactor(item.scaleFactor)
              setImgSourceCloud(item.isFirebase || (libSource === 'unsplash'))
              setDownloadTracker(item.tracker)
              handleSelectImg(e)
            }}
            data-url={item.url}
            data-itemid={item.id}
            key={item.id}
            className={`${styles.img} ${styles.unSplashContainer} ${selectedImg === item.url ? styles.img_selected : ""
              }`}
          >
            <img className={styles.unSplashImage} src={item.urlThumbnail || item.url} alt="library img" />
            {libSource === 'unsplash' ? <div className={
              `${styles.unSplashMiddle} ${(index+1) % 2 ? styles.unSplashMiddle_left : ''}`}>
              <div className={styles.unSplashText}>Photo by <a href= {`${item.userPortfolio}?utm_source=${APP_NAME}&utm_medium=referral` }>{item.userName}</a> on <a href={`https://unsplash.com/?utm_source=${APP_NAME}&utm_medium=referral`}>Unsplash</a></div>
            </div> : null}
          </div>
        })
      } else {
        return imgslib.map(item =>
          item.catName.includes(filter) ? (
            <div 
              onClick={(e) => {
              setScaleFactor(item.scaleFactor)
              setScaleFactor(item.scaleFactor)
                setImgSourceCloud(item.isFirebase || libSource === 'unsplash')
                setDownloadTracker(item.tracker)
                handleSelectImg(e)
              }}
              data-url={item.url}
              data-itemid={item.id}
              key={item.id}
              className={`${styles.img} ${selectedImg === item.url ? styles.img_selected : ""
                }`} 
            >
              <img src={item.urlThumbnail || item.url} alt="library img" />
            </div>
          ) : null,
        )
      }
    }
  }, [filter, imgslib, selectedImg])

  let compliant = useSelector((state)=>state.canvasImages?.isCopyrightCompliant)

  let [copyrightWarning, showCopyrightWarning] = useState(false)
  let [copyrightCompliance, setCopyrightCompliance] = useState(compliant)

  useEffect(()=>{setCopyrightCompliance(compliant)},[compliant])
  
  return (
    <div className={styles.container}>
      <div className={styles.title}>Select pattern from library</div>
      {
      isUnsplashTabVisible?<div className={styles.tabLayout}>
        <div className={`${styles.tabBut} ${libSource=='local'?styles.tabBut_selected:""}`}
          onClick={() => {
            setLibSource('local')
          }}>LOCAL</div>
        <div className={`${styles.tabBut} ${libSource=='unsplash'?styles.tabBut_selected:""}`}
          onClick={() => {
            setLibSource('unsplash')
          }}>UNSPLASH</div>
      </div>:null
      }
      {libSource === 'unsplash' ? <input style={{ marginLeft: 5, marginRight: 5, marginTop:10 }} type="search" onChange={(e) => {
        setLibraryPage(1)
        setQuery(e.target.value)
      }} placeholder={"type search here"} /> : null}
      <div className={styles.img_cont}>
        {cats && cats.length > 1 ? (
          <div className={styles.img_filters}>
            <button
              className={`${styles.filter} ${filter === "SELECT_ALL" ? styles.filter_active : ""
                }`}
              onClick={handleFilter}
              name={"SELECT_ALL"}
            >
              All categories
            </button>
            {cats.map(item => (
              <button
                key={`_key_categories_${item}$`}
                className={`${styles.filter} ${filter === item ? styles.filter_active : ""
                  }`}
                onClick={handleFilter}
                name={item}
              >
                {item}
              </button>
            ))}
          </div>
        ) : null}

        {imgslib.length ? (
          filteredImages()
        ) : (
          <div className={styles.notf}>{props.progress?'Loading ...':`image not found`}</div>
        )}
      </div>
      {libSource === 'unsplash' && imgslib.length ? <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', padding: 10 }}>
        <div onClick={() => {
          libraryPage > 1 && setLibraryPage(libraryPage - 1)
        }} style={{ display: 'inline', marginRight: 5, cursor:libraryPage>1?"pointer":"default", color: libraryPage>1 ? 'black' : 'grey' }}>&lt;</div>
        <div onClick={() => {
          setLibraryPage(libraryPage + 1)
        }} style={{ display: 'inline', marginLeft: 5, cursor:unsplashLibrary.totalImages > libraryPage * 9 ?"pointer":"default",  color: unsplashLibrary.totalImages > libraryPage * 9 ? 'black' : 'grey' }}>&gt;</div>
      </div> : null}
      <div className={styles.buttons}>
        <button className={styles.btn} onClick={handleClose}>
          cancel
        </button>
        {imgslib.length ? (
          <button
            className={`${styles.btn} ${selectedImg ? styles.btn_save : ""}`}
            onClick={(e) => {
              e.preventDefault()
              // if(!copyrightCompliance){
              //   showCopyrightWarning(true)
              //   return
              // }
              if (imgSourceCloud) {
              // if(scaleFactor && scaleFactor !== 1){
              //   // alert(scaleFactor)
              //   handleSaveFirebaseWithScale()
              // }else
                handleSaveFirebase()
              } else
                handleSave()
            }}
            disabled={selectedImg ? false : true}
          >
            ok
          </button>
        ) : null}
      </div>
      {!copyrightCompliance ? <FabricDialog 
      text={COPYRIGHT_WARNING.text.map(p=>t(p))} 
      title={t(COPYRIGHT_WARNING.title)}
      checkbox={t(COPYRIGHT_WARNING.checkbox)}
      visible={copyrightWarning}
        onNegative={() => {
          showCopyrightWarning(false)
        }}
        onPositive={() => {
          dispatch({type: ACTIONS_SET_IMAGES_COPYRIGHT_COMPLIANT})
          setCopyrightCompliance(true)
          showCopyrightWarning(false)
          if (imgSourceCloud) {
            handleSaveFirebase()
          } else
            handleSave()
        }} /> : null}
    </div>
  )
}

export default LIbrary
