import {useEffect, useState} from "react"

/**
 
 * ### `react custom hook`
 * in iframe check
 * @return {number} -1/1/0
 * @example useDecode('#sadf')
 */
const useIframe = () => {
  const [isIframe, setIsIframe] = useState(0)

  useEffect(() => {
    const inIframe = () => {
      try {
        setIsIframe(() => (window.self !== window.top ? 1 : -1))
      } catch (e) {
        setIsIframe(1)
      }
    }
    inIframe()
  }, [])

  return isIframe
}

export default useIframe
