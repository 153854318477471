export const INCREMENT_NO_OF_FABRIC_UNITS = "inc_fabric_units"
export const DECREMENT_NO_OF_FABRIC_UNITS = "dec_fabric_units"
export const RESET_NO_OF_FABRIC_UNITS = "reset_fabric_units"
export const SET_CONFIGURATION = "set_product_config"
export const UPDATE_DISPLAY_UNITS = 'update_display_units'
export const UPDATE_UNIT_TYPE = 'update_unit_type'
export const UPDATE_CONFIGURATION = "update_product_config"

export interface ISettings {
    unitType?: 'YARD' | 'METER',
    rulerUnits: number
    display?: {
        currentLength: number
    },
    configuration?: {
        stepSize: number,
        stepUnitType: 'YARD' | 'METER',
        split?: {
            rows: number, columns: number, fatQuarter: boolean
        }
    }
}

export const defaultSettingsState: ISettings = {
    rulerUnits: 1
}


export const settings = (state = defaultSettingsState, action: any): ISettings => {

    const unitsAvailable = action.payload || 0
    let existingConfig = state.configuration

    switch (action.type) {
        case UPDATE_UNIT_TYPE:
            return {...state, unitType:action.payload.unitType}
        case UPDATE_DISPLAY_UNITS:
            return {...state, display:action.payload}
        case RESET_NO_OF_FABRIC_UNITS:
            return { ...state, rulerUnits: 1 }   
        case INCREMENT_NO_OF_FABRIC_UNITS:
            return { ...state, rulerUnits: unitsAvailable || (state.rulerUnits + 1) }
        case DECREMENT_NO_OF_FABRIC_UNITS:
            return state.rulerUnits > 0 ? { ...state, rulerUnits: unitsAvailable || (state.rulerUnits - 1) } : state
        case UPDATE_CONFIGURATION:
            existingConfig = state.configuration
        case SET_CONFIGURATION:
            const config = {...existingConfig, ...action.payload }
            switch (config.stepUnitType) {
                case 'YARD':
                    // config.realFactor = (isSubUnit?: boolean) => isSubUnit ? 2.54 : 1 / 1.094
                    // config.displayFactor = (isSubUnit?: boolean) => isSubUnit ? 1 / 2.54 : 1.094
                    // break;
                default:
                    config.realFactor = (isSubUnit?: boolean) => 1
                    config.displayFactor = (isSubUnit?: boolean) => 1
                    break;
            }
            let initialFabricLength = {}
            if(!state.display?.currentLength){
                initialFabricLength = {display:{currentLength: config.stepSize}}
            }
            return { ...state, configuration: config, ...initialFabricLength }



    }


    return state

}