import * as THREE from "three"

export class ResizeControlsPlane extends THREE.Mesh {
    constructor() {
        super(
            new THREE.PlaneGeometry(100000, 100000, 2, 2),
            new THREE.MeshBasicMaterial({ color: 0xFFFF00, visible: false, wireframe: true, side: THREE.DoubleSide, transparent: true, opacity: 0.5 })
        );

        this.type = 'TransformControlsPlane';
    }
}
