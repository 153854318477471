import * as THREE from "three"
import ImageTexture from "./ImageTexture";
import glo from "./glo";
import Piece from "./Piece";

export default class Logo {
    public id: string = "";
    public hash: string = "";
    public piece: Piece | null;
    public cx: number = 0;
    public cy: number = 0;
    public bcx: number = 0;
    public bcy: number = 0;
    public cbx: number = 0;
    public cby: number = 0;
    public px: number = 0;
    public py: number = 0;
    public bpx: number = 0;
    public bpy: number = 0;
    public Npx: number = 0;
    public Npy: number = 0;
    public isText: boolean = false;
    public isRepeat: boolean = false;
    public textParams: any;
    public scaleX: number = 1;
    public scaleY: number = 1;
    public angle: number = 0;
    public rate: number = 100;
    public hotspot: string = "";
    public hotline: string = "";
    public locked: boolean = false;
    public deleted: boolean = false;
    public active: boolean = false;
    public autoscaled: boolean = false;

    public historyAction: string = "";

    //public uv: THREE.Vector3 | null = null;
    public loc2D: THREE.Vector3 | null = null;
    public loc3D: THREE.Vector3 | null = null;

    constructor(data: Piece | Logo | null) {
        if (data instanceof Logo) {
            this.piece = null;
            this.Copy(data);
        }
        else {
            this.id = Logo.generateLogoId();
            this.piece = data;
        }
    }
    public get Width(): number { return this.cx * Math.abs(this.scaleX); }
    public get WidthCM(): number { return Math.round((this.Width / glo.unit) * 1e2) / 1e2; }
    public set Width(v: number) { this.scaleX = Math.sign(this.scaleX) * v / this.cx; } //poorti only
    public get Height(): number { return this.cy * Math.abs(this.scaleY); }
    public get HeightCM(): number { return Math.round((this.Height / glo.unit) * 1e2) / 1e2; }
    public set Height(v: number) { this.scaleY = Math.sign(this.scaleY) * v / this.cy; } //poorti only 
    public get isFlipH(): boolean { return this.scaleX < 0; }
    public get isFlipV(): boolean { return this.scaleY < 0; }
    public get zOrder(): number | undefined { return this.piece?.logos.indexOf(this); }

    public Set(logo: any): void {
        this.cx = logo.iwidth;
        this.cy = logo.iheight;
        this.Width = logo.width;
        this.Height = logo.height;
        this.cbx = this.cx;
        this.cby = this.cy;
        this.cx = logo.width;
        this.cy = logo.height;
        this.rate = this.scaleX * 100;
        this.scaleY = this.scaleY / this.scaleX;
        this.scaleX = 1;
        this.angle = logo.angle;

        this.isText = logo.isText;
        this.isRepeat = logo.isRepeat;
        this.px = logo.UVPosition.x + (this.cx * (Math.abs(this.scaleX) - 1.0)) / 2;
        this.py = logo.UVPosition.y + (this.cy * (Math.abs(this.scaleY) - 1.0)) / 2;
        this.ResetPosition();
    }
    public Copy(src: Logo | null): void {
        if (!src) {
            this.id = "";
            return;
        }
        this.id = src.id;
        this.hash = src.hash;
        this.piece = src.piece;
        this.cx = src.cx;
        this.cy = src.cy;
        this.bcx = src.bcx;
        this.bcy = src.bcy;
        this.cbx = src.cbx;
        this.cby = src.cby;
        this.px = src.px;
        this.py = src.py;
        this.bpx = src.bpx;
        this.bpy = src.bpy;
        this.Npx = src.Npx;
        this.Npy = src.Npy;
        this.isText = src.isText;
        this.isRepeat = src.isRepeat;
        this.textParams = src.textParams ? JSON.parse(JSON.stringify(src.textParams)) : undefined;
        this.scaleX = src.scaleX;
        this.scaleY = src.scaleY;
        this.angle = src.angle;
        this.rate = src.rate;
        this.locked = src.locked;
        this.deleted = src.deleted;
        this.active = src.active;
        this.autoscaled = src.autoscaled;
        //if (src.uv) this.uv = src.uv.clone();
        if (src.loc2D) this.loc2D = src.loc2D.clone();
        if (src.loc3D) this.loc3D = src.loc3D.clone();
    }

    public Compare(src: Logo): boolean {
        const neq: boolean =
            this.id !== src.id ||
            this.hash !== src.hash ||
            this.piece !== src.piece ||
            this.cx !== src.cx ||
            this.cy !== src.cy ||
            this.bcx !== src.bcx ||
            this.bcy !== src.bcy ||
            this.cbx !== src.cbx ||
            this.cby !== src.cby ||
            this.px !== src.px ||
            this.py !== src.py ||
            this.bpx !== src.bpx ||
            this.bpy !== src.bpy ||
            this.Npx !== src.Npx ||
            this.Npy !== src.Npy ||
            this.isText !== src.isText ||
            this.isRepeat !== src.isRepeat ||
            this.scaleX !== src.scaleX ||
            this.scaleY !== src.scaleY ||
            this.angle !== src.angle ||
            this.rate !== src.rate;
        return !neq;
    }

    public getLoc(is2D: boolean): THREE.Vector3 | null { return is2D ? this.loc2D : this.loc3D; }
    public setLoc(is2D: boolean, val: THREE.Vector3 | null): void {
        if (is2D)
            this.loc2D = val;
        else
            this.loc3D = val;
    }
    public static generateLogoId = () =>
        String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now()

    public ResetPosition(): void {
        const x = this.px;
        const y = this.py;
        const w = this.cx;
        const h = this.cy;
        const o = (Math.PI * this.angle) / 180;
        const nw = Math.abs(w * Math.cos(o)) + Math.abs(h * Math.sin(o));
        const nh = Math.abs(w * Math.sin(o)) + Math.abs(h * Math.cos(o));
        const nx = x - (nw - w) / 2;
        const ny = y - (nh - h) / 2;

        this.bpx = nx;
        this.bpy = ny;
        this.bcx = nw;
        this.bcy = nh;
    }
    public static CreateLogo(url: string, piece: Piece, uv: THREE.Vector2, textParams: any, cbAfter: (logo: Logo) => void = (logo: Logo) => { }): void {
        ImageTexture.loadTexture(url, true, (hash: string, imageTex: ImageTexture) => {
            let logo = new Logo(piece);
            logo.createLogoImage(imageTex.orgHash!, imageTex.texImage!, piece, uv, textParams);
            cbAfter(logo);
        });
    }
    public get src(): HTMLImageElement { return ImageTexture.rtTextures[this.hash].logImage!; }
    public get org(): HTMLImageElement { return ImageTexture.rtTextures[this.hash].orgImage!; }

    public createLogoImage(hash: string, image: HTMLImageElement, piece: Piece, uv: THREE.Vector2, textParams: any): void {
        const aspect = image.height / image.width

        let resizeImage = image.width * image.height > 0.65 * (piece.duv.x * piece.duv.y);
        let cx = image.width;
        let cy = aspect * cx;

        this.hash = hash;
        this.scaleX = 1;
        this.scaleY = 1;
        this.cx = cx;
        this.cy = aspect * cx;

        if (resizeImage) {
            this.px = 0.5 * piece.duv.x - cx / 2;
            this.py = (1 - 0.5) * piece.duv.y - cy / 2;
        } else {
            this.px = uv.x * piece.duv.x - cx / 2;
            this.py =
                (1 - uv.y) * piece.duv.y - cy / 2;
        }

        this.cbx = cx;
        this.cby = cy;

        this.bpx = this.px;
        this.bpy = this.py;
        this.bcx = cx;
        this.bcy = cy;

        if (textParams) {
            this.isText = true
            this.textParams = { ...textParams }
        }

        this.angle = 0;
        this.rate = 100;
        this.locked = false;

        piece.logos.push(this);
    }
}
