import React from "react"

import styles from "./error.module.scss"
/**
 * Component for animation
 * @component
 * @param {object} props
 * @param {string} props.error - text message
 * @example

 * return (
 *  <Error test={'Error: 404'}/>
 * )
 */
const Error = props => {
  const {text = ""} = props
  return (
    <div className={styles.cover}>
      <div className={styles.content}>{text}</div>
    </div>
  )
}

export default Error
