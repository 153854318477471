import * as THREE from "three";
//import Logo from "./Logo";
import logo2D_vert from "./shaders/logo2D.vert";
import logo2D_frag from "./shaders/logo2D.frag";
import line2D_vert from "./shaders/line2D.vert";
import line2D_frag from "./shaders/line2D.frag";
import Piece from "./Piece";
//import glo from "./glo";
import ImageTexture from "./ImageTexture";
import Logo from "./Logo";
import glo from "./glo";

const idenMat = new THREE.Matrix3();
const uvMat = new THREE.Matrix3();

export default class OffScreen {
    private readonly is2D: boolean;
    private readonly props: any;
    private readonly scene: THREE.Scene;
    private readonly camera: THREE.OrthographicCamera;
    private readonly canvas2D: HTMLCanvasElement;
    private readonly context2D: CanvasRenderingContext2D | null;
    private readonly material: THREE.ShaderMaterial;
    private readonly lineMaterial: THREE.ShaderMaterial;
    private readonly plane: THREE.PlaneBufferGeometry;
    private readonly quad: THREE.Mesh;
    private readonly line: THREE.Mesh;

    constructor(is2D: boolean, props: any) {
        this.is2D = is2D;
        this.props = props;
        this.scene = new THREE.Scene()
        this.camera = new THREE.OrthographicCamera(-500, 500, 500, -500, -10000, 10000);
        this.camera.position.z = 100;
        this.canvas2D = document.createElement("canvas");
        this.context2D = this.canvas2D.getContext("2d");

        this.material = new THREE.ShaderMaterial({
            uniforms: {},
            transparent: true,
            side: THREE.DoubleSide,
            vertexShader: logo2D_vert,
            fragmentShader: logo2D_frag
        });
        this.lineMaterial = new THREE.ShaderMaterial({
            uniforms: {},
            transparent: true,
            side: THREE.DoubleSide,
            vertexShader: line2D_vert,
            fragmentShader: line2D_frag
        });

        this.plane = new THREE.PlaneBufferGeometry(1.0, 1.0);
        this.quad = new THREE.Mesh(this.plane, this.material);
        this.quad.position.z = - 100;
        this.scene.add(this.quad);

        this.line = new THREE.Mesh(this.plane.clone(), this.lineMaterial);
        this.line.position.z = - 100;
        this.scene.add(this.line);
    }
    public RenderPieceToTexture(renderer: THREE.WebGLRenderer, piece: Piece): void {
        const rf = piece.invResizeFactor;
        const renderTarget = piece.RenderTarget(this.is2D);
        if (!renderTarget) return;
        const cnvs = renderTarget?.texture.image;
        const W = piece.width;
        const H = piece.height;
        const clearColor = piece.color || piece.props.defaultPiecesColor;
        this.camera.left = -cnvs.width / 2; this.camera.right = cnvs.width / 2;
        this.camera.top = cnvs.height / 2; this.camera.bottom = -cnvs.height / 2;

        this.camera.scale.set(rf, rf, 1);
        this.camera.updateProjectionMatrix();

        renderer.autoClear = false;
        renderer.setClearColor(clearColor, 1);
        renderer.setRenderTarget(renderTarget);
        renderer.clear();

        piece.logos.forEach(value => { this.renderLogo(renderer, value, W, H); });
        if (piece.showHotlines)
            this.renderHotline(renderer, piece.hotlines, W, H);

        piece.Material(this.is2D).map = renderTarget?.texture!;
        piece.Material(this.is2D).needsUpdate = true;

        renderer.autoClear = true;
        renderer.setRenderTarget(null);
        renderer.setClearColor(0xf2f4f6, 1);
    }
    public renderText(textParams: any, fontSize: number = 240, padding: number = 90): string {
        const canvas = document.createElement("canvas")!;
        const ctx = canvas.getContext("2d")!;
        canvas.width = 10000;
        canvas.height = 300;

        ctx.font = `${textParams.weight ? textParams.weight : ""} ${fontSize}px ${textParams.font}`;
        let w = ctx.measureText(textParams.text).width + padding;
        let h = ctx.measureText(textParams.text).actualBoundingBoxAscent + ctx.measureText(textParams.text).actualBoundingBoxDescent + padding;

        ctx.fillText(textParams.text, canvas.width / 2, canvas.height / 2)

        canvas.width = w;
        canvas.height = h;
        ctx.font = `${textParams.weight ? textParams.weight : ""} ${fontSize}px ${textParams.font}`;
        ctx.fillStyle = textParams.color;
        ctx.textBaseline = "middle";
        ctx.textAlign = "left";
        ctx.fillText(textParams.text, padding / 2, h / 2);

        return canvas.toDataURL();
    }
    public renderLogo(renderer: THREE.WebGLRenderer, value: Logo, W: number, H: number): void {
        if (value.isRepeat) {
            value.px = value.px % W;
            value.py = value.py % H;
        }
        let px = value.px + value.cx / 2 - W / 2;
        let py = H / 2 - value.py - value.cy / 2;

        this.quad.visible = true;
        this.line.visible = false;
        const tex = ImageTexture.rtTextures[value.hash].texture;
        this.material.uniforms.map = { value: tex }
        this.material.uniforms.uvTransform = { value: idenMat }

        if (!value.isRepeat) {
            if (this.props.d2) {
                this.quad.scale.set(value.cx, value.cy, 1.0);
                this.quad.position.set(px, py, 0.0);
                this.quad.rotation.set(0.0, 0.0, -(Math.PI * value.angle) / 180.0);
            } else {
                this.quad.scale.set(value.cx * Math.abs(value.scaleX), value.cy * Math.abs(value.scaleY), 1.0);
                this.quad.position.set(px, py, 0.0);
                this.quad.rotation.set(0.0, 0.0, -(Math.PI * value.angle) / 180.0);

                uvMat.set(Math.sign(value.scaleX), 0, 0, 0, Math.sign(value.scaleY), 0, 0, 0, 1);
                this.material.uniforms.uvTransform = { value: uvMat }
            }
        }
        else {
            const S = Math.max(W, H) * 2;
            const SX = value.cx * Math.abs(value.scaleX);
            const SY = value.cy * Math.abs(value.scaleY);
            const IX = Math.floor(Math.ceil(S / SX) / 2) * 2 + 1;
            const IY = Math.floor(Math.ceil(S / SY) / 2) * 2 + 1;
            this.quad.scale.set(SX * IX, SY * IY, 1.0);
            this.quad.position.set(px, py, 0.0);
            this.quad.rotation.set(0.0, 0.0, -(Math.PI * value.angle) / 180.0);
            uvMat.set(IX, 0, 0, 0, IY, 0, 0, 0, 1);
            this.material.uniforms.uvTransform = { value: uvMat }
        }
        renderer.render(this.scene, this.camera);
    }
    public renderHotline(renderer: THREE.WebGLRenderer, hotlines: any, W: number, H: number): void {
        if (!hotlines)
            return;

        this.line.visible = true;
        this.quad.visible = false;
        const geom: THREE.BufferGeometry = this.line?.geometry as THREE.BufferGeometry;
        const position: any = geom.attributes.position;
        this.line.scale.set(1.0, 1.0, 1.0);
        this.line.position.set(-W * 0.5, -H * 0.5, 0.0);
        this.line.rotation.set(0.0, 0.0, 0.0);
        const color = new THREE.Color();

        for (let h in hotlines) {
            const data = hotlines[h];
            const line = data.lines;
            if (!line) continue;
            const w = data.width;
            color.set(data.color);
            this.lineMaterial.uniforms.color = { value: color };

            const len = line.length - 1;
            for (let i = 0; i < len; ++i) {

                const uv0 = line[i].uv;
                const uv1 = line[i + 1].uv;
                const dir = glo.vDir(uv0, uv1, true);
                const p0 = { x: uv0.x * W, y: uv0.y * H };
                const p1 = { x: uv1.x * W, y: uv1.y * H };
                const TL = { x: p0.x - dir.x * w, y: p0.y - dir.y * w };
                const TR = { x: p0.x + dir.x * w, y: p0.y + dir.y * w };
                const BL = { x: p1.x - dir.x * w, y: p1.y - dir.y * w };
                const BR = { x: p1.x + dir.x * w, y: p1.y + dir.y * w };

                position.setX(0, TL.x); geom.attributes.position.setY(0, TL.y);
                position.setX(1, TR.x); geom.attributes.position.setY(1, TR.y);
                position.setX(2, BL.x); geom.attributes.position.setY(2, BL.y);
                position.setX(3, BR.x); geom.attributes.position.setY(3, BR.y);
                position.needsUpdate = true

                renderer.render(this.scene, this.camera);
            }
        }
    }
}
