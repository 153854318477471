import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './uploadbutton.scss';

import alertify from "./../libs/alert"
import EXIF from 'exif-js';
import { useDispatch, useSelector } from 'react-redux';
import { addImagesToPart, clearImages, setRepeatType } from '../redux/actions/parts';
import { SEGMENT_SETTINGS_UPDATE } from '../redux/reducers/fabric/actions';
import { ACTIONS_CLEAR_ALL } from '../redux/reducers/images';
import useIframeMediaLibrary from "../hooks/useIframeMediaLibrary";

type ExifData = {
    src: string,
    width: number,
    height: number,
    partWidth: number,
    file: File,
    scaleFactor: number,
    curResolution: number,
}

export type UploadButtonProps = {
    interceptUploadEvent?: () => void
    repeatType?: 'NON_REPEAT' | 'REPEAT'
    totalCanvasImages?: number
    copyrightCompliance?: boolean
    imageUploadInProgress?: (progress: boolean) => void
    fabricSize?: { width: number, height: number }
    partId?: string
}

export const UploadButton = (props: UploadButtonProps) => {

    const { interceptUploadEvent, repeatType, totalCanvasImages = 0, copyrightCompliance = true, imageUploadInProgress = () => { }, fabricSize, partId } = props
    const { t } = useTranslation();
    const [readyToUploadMode, setreadyToUploadMode] = useState(true)
    const filesPendingUpload = useRef([] as any)
    const [copyrightWarning, showCopyrightWarning] = useState(false)
    const dispatch = useDispatch();
    const minResolution = useSelector((state: any) => state.fbdata?.minimalResolution);
    const selectedSegment = useSelector((state: any) => state.fabric.segmentInFocus);
    const currentImage = useSelector((state: any) => state.currentImg);

    const apikey = useSelector((state:any) => state.apikey)
    const mediaLibTitle = useSelector((state: any) => state.mediaLibTitle);

    const firebaseImages = useIframeMediaLibrary({ userId: apikey, auth: 1, mediaLibTitle: mediaLibTitle})

    useEffect(() => {
        if (mediaLibTitle && firebaseImages && firebaseImages.length && selectedSegment) {
            getAsFile(firebaseImages[0].url).then((imagefile)=>{
                uploadHandler({ target: { files: [imagefile] } }, firebaseImages[0].scaleFactor)
            })
        }
    }, [firebaseImages, mediaLibTitle, selectedSegment])

    useEffect(() => {
        if (currentImage && selectedSegment) {
            getAsFile(currentImage).then((imagefile)=>{
                uploadHandler({ target: { files: [imagefile] } })
            })
            
        }
    }, [currentImage, selectedSegment]);

    const uploaderRef = useRef(undefined as any);


    const toggleUploadMode = useCallback(() => {
        setreadyToUploadMode(!readyToUploadMode)
    }, [readyToUploadMode]);

    const uploadHandler = useCallback((e, scaleFactor?:number) => {
        const validity = true;
        if (validity) {
            const files = e.target.files
            if (files.length === 0) {
                setreadyToUploadMode(true)
                e.preventDefault()
                return
            }

            if (!copyrightCompliance) {
                e.preventDefault()
                filesPendingUpload.current = files
                showCopyrightWarning(true)
                return
            }
            imageUploadInProgress(true)
            handleFiles(files).then((imageExifData) => {
                const images = imageExifData.map((data) => ({
                    ...data, minResolution, fabricSize, repeatType: 'DEFAULT_REPEAT', scaleFactor
                }));
                dispatch({ type: ACTIONS_CLEAR_ALL })
                dispatch(clearImages(true))
                dispatch(addImagesToPart(partId, images, scaleFactor || 1, t, true))
                
                dispatch(setRepeatType(partId, 'DEFAULT_REPEAT', true))
                dispatch({
                    type: SEGMENT_SETTINGS_UPDATE, payload: {
                        parentCanvas: selectedSegment,
                        repeatType: 'DEFAULT_REPEAT'
                    }
                })

            }).catch((error) => {
                console.log(error);
                uploaderRef.current && (uploaderRef.current.value = null);
                alertify.alert(t("editor.alert_warning_title1"), t("editor.alert.warning_msg1"), t("i18n.ok"), () => { })
            }).finally(() => {
                imageUploadInProgress(false)
            })
        }
    }, [repeatType, copyrightCompliance, imageUploadInProgress, minResolution, fabricSize, partId, selectedSegment])


    return <div className="upload-button"
        onClick={interceptUploadEvent ? (e) => {
            if ((repeatType) !== 'NON_REPEAT' && totalCanvasImages >= 1) {
                e.preventDefault()
                alertify.alert(t("editor.alert_warning_title1"), t("editor.images_repeat_mode_add_image_msg"), t("i18n.ok"), () => { })
            } else if ((repeatType) === 'NON_REPEAT' && (totalCanvasImages) == 1) {
                e.preventDefault()
                alertify.alert(t("editor.alert_warning_title1"), t("You cannot upload more than one image."), t("i18n.ok"), () => { })
            } else {
                interceptUploadEvent()
            }
        } : undefined}>
        <div className='upload-button-display'>
            {/* <img src="/img/icons/AddIcon.svg" className='upload-icon' alt="upload" /> */}
            <span className='upload-icon'>UPLOAD NEW DESIGN</span>
        </div>
        {interceptUploadEvent ? null : <input
            id="uploadAddImageBtn"
            className="upload-input-concealer"
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={readyToUploadMode ? uploadHandler : toggleUploadMode}
            onClick={() => { }}
            ref={uploaderRef}
            multiple={false} />}
    </div>
}

const getAsFile = async (url: string): Promise<File> => {

    const blob = await (await fetch(url)).blob();

    const uri = decodeURIComponent(url);
    const name = uri?.split('#')?.shift()?.split('?')?.shift()?.split('/')?.pop()||'currentImage';
    return new File([blob], name, {
        type: blob.type
    })
}

const handleFiles = async (files: File[]): Promise<ExifData[]> => {

    let promises = []
    for (let i = 0; i < files.length; i++) {
        promises.push(fetchExifData(files[i]))
    }

    let images: ExifData[] = await Promise.all(promises)
    return images;
}

function fetchExifData(file: File): Promise<ExifData> {
    return new Promise((resolve, reject) => {

        var url = window.URL || window.webkitURL

        let img = new Image()
        img.onload = function () {

            const imageContext = this as any;
            EXIF.getData(imageContext, () => {
                let allMetaData = EXIF.getAllTags(imageContext)

                let curResolution = allMetaData.XResolution
                    ? allMetaData.XResolution.valueOf()
                    : 150

                curResolution = curResolution < 150 ? 150 : curResolution
                resolve({
                    src: imageContext.src,
                    width: imageContext.width,
                    height: imageContext.height,
                    partWidth: imageContext.width,
                    curResolution,
                    file,
                    scaleFactor: 1,
                })
            })
        }

        img.src = url.createObjectURL(file)

        img.onerror = function (err) {
            reject(err)
        }

    })
}
