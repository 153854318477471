import ACTIONS from "../../constatnts"

export const product = (state = null, actions) => {
  const {type, payload} = actions

  switch (type) {
    case ACTIONS.SET_PRODUCT: {
      if (payload) {
        return {
          ...payload,
          totalLength: parseFloat(payload.totalLength),
          width: parseFloat(payload.width),
          price: parseFloat(payload.price),
          rows: payload.rows,
          columns: payload.columns,
          fatQuarter: payload.fatQuarter
        }
      } else return null
    }
    default:
      return state
  }
}

export const products = (state = [], actions) => {
  const {type, payload} = actions

  switch (type) {
    case ACTIONS.SET_PRODUCTS:
      return payload
    default:
      return state
  }
}

export const currentImg = (state = null, actions) => {
  const {type, payload} = actions

  switch (type) {
    case ACTIONS.SET_CURRENT_IMG:
      return payload
    default:
      return state
  }
}

export const mediaLibTitle = (state = null, actions) => {
  const {type, payload} = actions

  switch (type) {
    case ACTIONS.SET_MEDIA_LIB_TITLE:
      return payload
    default:
      return state
  }
}
