import * as THREE from "three"

const DEFAULT_OPACITY = 0.5;
const DEG2RAD = Math.PI / 180.0;

export class ResizeControlsGizmo extends THREE.Object3D {

    public worldPosition: THREE.Vector3 | null = null;
    public cameraPosition: THREE.Vector3 | null = null;
    public handle: THREE.Object3D;
    public picker: THREE.Object3D;

    public camera: THREE.PerspectiveCamera;
    public enabled: boolean = true;
    public axis: string | null = null;
    public mode: string | null = null;
    public size: number = 0.6;
    public dragging: boolean = false;
    public totAngle: number = 0;
    public deltaAngle: number = 0;
    public initScale: number = 1;
    public totScale: number = 1;
    public initScaleX: number = 0;
    public initScaleY: number = 0;
    public totScaleX: number = 0;
    public totScaleY: number = 0;

    constructor(camera: THREE.PerspectiveCamera) {
        super();

        this.type = 'ResizeControlsGizmo';
        this.camera = camera;

        // shared materials
        const meshMat = new THREE.MeshPhongMaterial({
            color: 0x0000FF,
            depthTest: false,
            depthWrite: false,
            transparent: true,
            side: THREE.DoubleSide,
            fog: false
        });

        const meshInv = meshMat.clone();
        meshInv.color.set(0xFFFF00);
        meshInv.opacity = 0.0;

        const meshTMat = meshMat.clone();
        meshTMat.opacity = DEFAULT_OPACITY;

        const meshWMat = meshMat.clone();
        meshWMat.color.set(0x808080);
        meshWMat.opacity = DEFAULT_OPACITY;

        const meshTBlack = meshTMat.clone();
        meshTBlack.color.set(0x202020);

        const meshTCyl = meshTMat.clone();
        meshTCyl.color.set(0x202020);
        meshTCyl.opacity = 1.0;

        const CC = 0.06;
        const CR = 0.1;
        const DW = 0.04;
        const D = 1.4;
        const DH = 1.4 / 2 - CC * 2;
        const DY = DH / 2 + CC;
        const DM = (D - DW) / 2;
        const Z = 0.01;
        const HPI = Math.PI / 2;
        const CYH = 0.06;
        const BH = 0.25;
        const BW = 0.025;
        const CBH = BH - CYH * 2;
        const cylinder = new THREE.CylinderGeometry(0.04, 0, CYH, 4, 1, false);
        const circle = new THREE.CircleGeometry(BH, 32);
        const boxH = new THREE.BoxGeometry(BH, BW, 0.01);
        const boxV = new THREE.BoxGeometry(BW, BH, 0.01);
        const boxLineH = new THREE.BoxGeometry(DH, DW, 0.01);
        const boxLineV = new THREE.BoxGeometry(DW, DH, 0.01);
        const ring = new THREE.RingGeometry(CC - DW / 2, CC + DW / 2, 32, 1);
        const ringR = new THREE.RingGeometry(CR - DW / 3, CR + DW / 3, 32, 1, 0, HPI * 3);

        const torus = new THREE.RingGeometry(0.01, CC * 3, 24, 1);
        const boxLineH2 = new THREE.BoxGeometry(D, DW * 2, 0.01);
        const boxLineV2 = new THREE.BoxGeometry(DW * 2, D, 0.01);

        const gizmos = {
            Rotate: [
                [new THREE.Mesh(boxLineH.clone(), meshTMat.clone()), [-DY, DM, 0], [0, 0, 0], [1, 1, Z], 'circle'],
                [new THREE.Mesh(boxLineH.clone(), meshTMat.clone()), [DY, DM, 0], [0, 0, 0], [1, 1, Z], 'circle'],
                [new THREE.Mesh(boxLineH.clone(), meshTMat.clone()), [-DY, -DM, 0], [0, 0, 0], [1, 1, Z], 'circle'],
                [new THREE.Mesh(boxLineH.clone(), meshTMat.clone()), [DY, -DM, 0], [0, 0, 0], [1, 1, Z], 'circle'],
                [new THREE.Mesh(boxLineV.clone(), meshTMat.clone()), [DM, -DY, 0], [0, 0, 0], [1, 1, Z], 'circle'],
                [new THREE.Mesh(boxLineV.clone(), meshTMat.clone()), [DM, DY, 0], [0, 0, 0], [1, 1, Z], 'circle'],
                [new THREE.Mesh(boxLineV.clone(), meshTMat.clone()), [-DM, DY, 0], [0, 0, 0], [1, 1, Z], 'circle'],
                [new THREE.Mesh(boxLineV.clone(), meshTMat.clone()), [-DM, -DY, 0], [0, 0, 0], [1, 1, Z], 'circle'],

                [new THREE.Mesh(ring.clone(), meshTMat.clone()), [DM, 0, -Z], [0, 0, 0], [1, 1, Z], 'W', 0.75],
                [new THREE.Mesh(ring.clone(), meshTMat.clone()), [-DM, 0, Z], [0, 0, 0], [1, 1, Z], 'E', 0.75],
                [new THREE.Mesh(ring.clone(), meshTMat.clone()), [0, DM, Z], [0, 0, 0], [1, 1, Z], 'N', 0.75],
                [new THREE.Mesh(ring.clone(), meshTMat.clone()), [0, -DM, Z], [0, 0, 0], [1, 1, Z], 'S', 0.75],

                [new THREE.Mesh(ring.clone(), meshTMat.clone()), [DM, DM, Z], [0, 0, HPI * 1.5], [1, 1, Z], 'NW', 0.75],
                [new THREE.Mesh(ring.clone(), meshTMat.clone()), [DM, -DM, Z], [0, 0, HPI * 0.5], [1, 1, Z], 'SW', 0.75],
                [new THREE.Mesh(ring.clone(), meshTMat.clone()), [-DM, -DM, Z], [0, 0, HPI * 1.5], [1, 1, Z], 'SE', 0.75],
                [new THREE.Mesh(ring.clone(), meshTMat.clone()), [-DM, DM, Z], [0, 0, HPI * 2.5], [1, 1, Z], 'NE', 0.75],

                [new THREE.Mesh(circle.clone(), meshWMat.clone()), [0, 0, 0], [0, 0, 0], [1, 1, Z]],
                [new THREE.Mesh(cylinder.clone(), meshTMat.clone()), [0, -CBH, Z], [0, 0, 0], [1, 1, Z]],
                [new THREE.Mesh(boxH.clone(), meshTMat.clone()), [0, 0, Z], [0, 0, 0], [1, 1, Z]],
                [new THREE.Mesh(cylinder.clone(), meshTMat.clone()), [0, CBH, Z], [0, 0, HPI * 2.0], [1, 1, Z]],

                [new THREE.Mesh(cylinder.clone(), meshTMat.clone()), [CBH, 0, Z], [0, 0, HPI * 1.0], [1, 1, Z]],
                [new THREE.Mesh(boxV.clone(), meshTMat.clone()), [0, 0, Z], [0, 0, 0], [1, 1, Z]],
                [new THREE.Mesh(cylinder.clone(), meshTMat.clone()), [-CBH, 0, Z], [0, 0, HPI * 3.0], [1, 1, Z]],

                [new THREE.Mesh(circle.clone(), meshWMat.clone()), [0, 1, 0], [0, 0, 0], [0.75, 0.75, Z], 'circle'],
                [new THREE.Mesh(ringR.clone(), meshTMat.clone()), [0, 1, Z], [0, 0, 0], [1, 1, Z], 'circle', 0.75],
                [new THREE.Mesh(cylinder.clone(), meshTMat.clone()), [0.1, 1, Z], [0, 0, HPI * 3.0], [1, 1, Z]],
            ],


            RotatePicker: [
                [new THREE.Mesh(boxLineH2, meshInv), [0, DM, 0], [0, 0, 0], [1, 1, Z], 'circle1'],
                [new THREE.Mesh(boxLineH2, meshInv), [0, -DM, 0], [0, 0, 0], [1, 1, Z], 'circle1'],
                [new THREE.Mesh(boxLineV2, meshInv), [DM, 0, 0], [0, 0, 0], [1, 1, Z], 'circle1'],
                [new THREE.Mesh(boxLineV2, meshInv), [-DM, 0, 0], [0, 0, 0], [1, 1, Z], 'circle1'],

                [new THREE.Mesh(torus, meshInv), [0, 1, 0], null, null, 'circle'],
                [new THREE.Mesh(torus, meshInv), [0, DM, Z], null, null, 'N'],
                [new THREE.Mesh(torus, meshInv), [DM, DM, Z], null, null, 'NW'],
                [new THREE.Mesh(torus, meshInv), [DM, 0, Z], null, null, 'W'],
                [new THREE.Mesh(torus, meshInv), [DM, -DM, Z], null, null, 'SW'],
                [new THREE.Mesh(torus, meshInv), [0, -DM, Z], null, null, 'S'],
                [new THREE.Mesh(torus, meshInv), [-DM, -DM, Z], null, null, 'SE'],
                [new THREE.Mesh(torus, meshInv), [-DM, 0, Z], null, null, 'E'],
                [new THREE.Mesh(torus, meshInv), [-DM, DM, Z], null, null, 'NE']
            ]
        };

        this.handle = this.setupGizmo('handle', gizmos.Rotate);
        this.picker = this.setupGizmo('picker', gizmos.RotatePicker);
        this.add(this.handle);
        this.add(this.picker);
    }
    private setupGizmo(name: string, gizmos: any): THREE.Object3D {
        const gizmo = new THREE.Object3D();
        for (let i = gizmos.length; i--;) {

            const object = gizmos[i][0].clone();
            const position = gizmos[i]?.[1];
            const rotation = gizmos[i]?.[2];
            const scale = gizmos[i]?.[3];
            const tag = gizmos[i]?.[4];
            const opacity = gizmos[i]?.[5] ?? DEFAULT_OPACITY;

            // name and tag properties are essential for picking and updating logic.
            object.name = name;
            object.tag = tag;
            object.opacity = opacity;

            if (position)
                object.position.set(position[0], position[1], position[2]);

            if (rotation)
                object.rotation.set(rotation[0], rotation[1], rotation[2]);

            if (scale)
                object.scale.set(scale[0], scale[1], scale[2]);

            object.updateMatrix();

            //const tempGeometry = object.geometry.clone();
            //tempGeometry.applyMatrix(object.matrix);
            //object.geometry = tempGeometry;
            object.renderOrder = Infinity;

            //object.position.set(0, 0, 0);
            //object.rotation.set(0, 0, 0);
            //object.scale.set(1, 1, 1);

            gizmo.add(object);
        }

        return gizmo;
    }
    public updateMatrixWorld(force: boolean) {
        let eyeDistance = this.worldPosition!.distanceTo(this.cameraPosition!);
        let factor = eyeDistance * Math.min(1.9 * Math.tan(Math.PI * this.camera.fov / 360) / this.camera.zoom, 7);
        this.scale.set(1, 1, 1).multiplyScalar(factor * this.size / 7);
        this.rotation.set(0, 0, -this.totAngle * DEG2RAD);
        super.updateMatrixWorld(force);

        let handles: any[] = [];
        handles = handles.concat(this.handle.children);
        for (let i = handles.length; i--;) {
            let handle = handles[i];
            handle.material.opacity = handle.tag === this.axis ? 1.0 : (null === this.axis ? handle.opacity : handle.opacity);
        }
    }
}
