import ACTIONS from "../../constatnts"

export const setProduct = payload => ({
  type: ACTIONS.SET_PRODUCT,
  payload,
})

export const setProducts = payload => ({
  type: ACTIONS.SET_PRODUCTS,
  payload,
})

export const setCurrentImage = payload => ({
  type: ACTIONS.SET_CURRENT_IMG,
  payload,
})

export const setMediaLibTitle = payload => ({
  type: ACTIONS.SET_MEDIA_LIB_TITLE,
  payload,
})
