import ACTIONS from "../../constatnts"

export const spinner = (state = true, actions) => {
  const {type, payload} = actions

  switch (type) {
    case ACTIONS.SET_SPINNER:
      return payload
    default:
      return state
  }
}
