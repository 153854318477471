import i18n from "i18next"

export default {
  t: input => {
    let output = {found: false, res: input}

    if (input && typeof input == "string" && input.startsWith("i18n.")) {
      let translated = i18n.t(input)
      if (translated !== input) {
        output = {found: true, res: translated}
        // console.log('TRANSLATE', input, output);
      }
    }
    return output
  },
}
