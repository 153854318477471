import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addImagesToPart, toggleLibsMenu } from '../../redux/actions/parts'
import { setSpinner } from '../../redux/actions/spinner'
import styles from './imagepicker.module.scss'
import alertify from 'alertifyjs'
import EXIF from "exif-js"
import { ACTIONS_SET_IMAGES_COPYRIGHT_COMPLIANT } from '../../redux/reducers/images'
import { FabricDialog } from '../CopyrightDialog'
import { log } from "../../utilities/logger"

const COPYRIGHT_WARNING = {

    title: "i18n.intelWarn.copyrightTitle",
  
    text: ["i18n.intelWarn.p1",
  
      "i18n.intelWarn.p2",
  
      "i18n.intelWarn.p3"],
  
    checkbox: "i18n.intelWarn.check"
  }

interface IImagePicker {
    onClose: () => void
    part: any
    imageUploadStart: (started: boolean) => void
    fcSize: any
}


export const ImagePicker = (props: IImagePicker) => {

    const { part } = props

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const fbdata = useSelector((state: any) => state.fbdata)
    const product = useSelector((state: any) => state.product)
    const [uploadMode, setUploadMode] = useState(true)
    let compliant = useSelector((state: any) => state.canvasImages?.isCopyrightCompliant)
    let [copyrightWarning, showCopyrightWarning] = useState(false)
    let [copyrightCompliance, setCopyrightCompliance] = useState(false)
    const pendingFiles = useRef(undefined as FileList|undefined)
    const isSplit = useSelector((state:any) => state.settings?.configuration?.split)
    useEffect(() => { setCopyrightCompliance(compliant) }, [compliant])
    
    const uploadClickHandler = (e: any) => {
        let isNonRepeat = !part.image_repeat || (part.image_repeat === 'NON_REPEAT')
        let productMaxLength = product?.totalLength || 0
        let productNRBlanketMaxLength = (fbdata.fabricNonRepeatMaxLen || 20)

        if (part.image_repeat !== 'NON_REPEAT' && part.content_images.length >= 1) {
            e.preventDefault()
            setUploadMode(false)
            dispatch(setSpinner(false))
            alertify.alert(t("editor.alert_warning_title1"), t("editor.images_repeat_mode_add_image_msg"), t("i18n.ok"), () => { })
        } else if (part.content_images.length >= 1) {
            setUploadMode(true)
        }
    }

    const uploadHandler = (e: any) => {
        if (part.image_repeat === 'NON_REPEAT' || part.image_repeat === undefined) {
            if (!uploadMode) {
                setUploadMode(true)
                e.preventDefault()
                dispatch(setSpinner(false))
                return
            }
            dispatch(setSpinner(true))
            const files = e.target.files

            if (files.length === 0) {
                setUploadMode(true)
                e.preventDefault()
                dispatch(setSpinner(false))
                return
            }
            if (!copyrightCompliance) {
                dispatch(setSpinner(false))
                e.preventDefault()
                pendingFiles.current = files
                showCopyrightWarning(true)
                return
            }
            handleFiles(files)
        }
    }

    const handleFiles = async (files: FileList) => {

        dispatch(setSpinner(false))
        let promises = []
        for (let i = 0; i < files.length; i++) {
            promises.push(fetchExifData(files[i]))
        }
        let images: any[] = []
        try {
            images = await Promise.all(promises)
        } catch (error) {
            alertify.alert(t("editor.alert_warning_title1"), t("editor.alert.warning_msg1"), t("i18n.ok"), () => { })
        }
        dispatch(addImagesToPart(part._id, images, 1, t, isSplit))
        dispatch(setSpinner(false))
        props.onClose()
    }

    const fetchExifData = (file: File) => new Promise((resolve, reject) => {

        var url = window.URL || window.webkitURL

        let img = new Image()
        img.onload = function () {

            const it = this as HTMLImageElement

            EXIF.getData(it as any, function () {

                let allMetaData = EXIF.getAllTags(it)
                let curResolution = allMetaData.XResolution
                    ? allMetaData.XResolution.valueOf()
                    : 150
                curResolution = curResolution < 150 ? 150 : curResolution
                props.imageUploadStart(true)
                resolve({
                    src: it.src,
                    width: it.width,
                    height: it.height,
                    minResolution: fbdata.minimalResolution || 96,
                    partWidth: product.width,
                    curResolution,
                    fcSize: props.fcSize,
                    file,
                    scaleFactor: 1,
                })
            })
        }

        img.src = url.createObjectURL(file)
        log(url.createObjectURL(file))

        img.onerror = function (err) {
            reject(err)

        }

    })



    return <div className={styles.pickerParent}>
        <div className={styles.pickerParent_title}>
            <img src="/img/icons/ic_arrow_back.png" onClick={(e) => {
                e.preventDefault()
                props.onClose()
            }} />
            <span>Insert Tile</span>
        </div>
        <div className={styles.pickerParent_underline} />
        <div className={styles.pickerParent_title}>
            <img src="/img/icons/MobileCamera.svg" />
            <span>From Camera</span>
            <input
                id="uploadAddImageBtn"
                className={styles.pickerInput}
                type="file"
                onChange={uploadHandler}
                onClick={uploadClickHandler}
                capture
            />
        </div>
        <div className={styles.pickerParent_title}>
            <img src="/img/icons/MobileGallery.svg" />
            <span>From Gallery</span>
            <input
                id="uploadAddImageBtn"
                className={styles.pickerInput}
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={uploadHandler}
                onClick={uploadClickHandler}
                capture={false}
            />
        </div>
        {!copyrightCompliance ? <FabricDialog
            text={COPYRIGHT_WARNING.text.map((p:any) => t(p))}
            title={t(COPYRIGHT_WARNING.title)}
            checkbox={t(COPYRIGHT_WARNING.checkbox)}
            visible={copyrightWarning}
            onNegative={() => {
                showCopyrightWarning(false)
                pendingFiles.current = undefined
                //inpt.current.value = null
            }}
            onPositive={() => {
                dispatch({ type: ACTIONS_SET_IMAGES_COPYRIGHT_COMPLIANT })
                setCopyrightCompliance(true)
                showCopyrightWarning(false)
                pendingFiles.current && handleFiles(pendingFiles.current)
                pendingFiles.current = undefined
                //inpt.current.value = null
            }} /> : null}
    </div>
}