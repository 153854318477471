export const DEBUG = false
export const log = (data, prefix) => {

    if(!DEBUG){
        return
    }

    try {
        console.log(`%c${prefix} ${JSON.stringify(data)}`, 'color:blue; font-style:italic')
    } catch (error) {
        console.log(`%c${prefix} ${data}`, 'color:blue')
    }

}