// import store from "../redux/store"

// common define
export const PICK_COLOR_CURSOR = `url(${require("./View3D/assets/images/color-picker.svg")}) 0 32, default`

// store
export function isPickColorMode() {
  // const {pickColorMode, pickBaseColorMode} = store.getState()
  // return pickColorMode || pickBaseColorMode
  return false
}

// utils
function componentToHex(c) {
  const hex = c.toString(16)
  return hex.length === 1 ? "0" + hex : hex
}
export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export function considerDevExUI(e) {
  if (e.target === document.body || e.target.closest(".dx-popup-wrapper"))
    return false
  return true
}
