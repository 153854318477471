//import * as THREE from "three"
//import glo from "./glo";
import Logo from "./Logo";
import Piece from "./Piece";
import { log } from "../../../../../../../utilities/logger"

export default class Info {
    public props: any;
    public pieces: Piece[] = [];
    public viewers: any[] = [];
    public activePiece: Piece | null = null;
    public prevLogo: Logo = new Logo(null);
    public historyIndex: number = -1;
    public historyLogos: Logo[] = [];
    public selectedLogo: Logo | null = null;
    public selectedAction: string = "";

    constructor(props: any) {
        this.props = props;
        this.initHistory();
    }
    public refresh(forAllSides: boolean): void { this.viewers.forEach(viewer => { viewer.renderMaterial(forAllSides); }); }
    public refreshNextFrame(forAllSides: boolean): void { setTimeout(() => { this.refresh(forAllSides); }); }
    public selectLogo(logo: Logo | null): void { this.viewers.forEach(viewer => { viewer.selectLogo(logo); }); }
    public getAllLogos = () => { return this.pieces.reduce(function (a: Logo[], b: Piece): Logo[] { a.push(...b.logos); return a; }, []); }
    public refreshLogos(): void { if (this.props.onRefreshLogos) this.props.onRefreshLogos(this.getAllLogos()); }
    public ShowResizeControls(visible: boolean): void {
        this.viewers.forEach(viewer => {
            if (visible)
                viewer.refreshResizeControls();
            viewer.resizeControls.visible = visible;
        });
    }
    public Get(name: string): Piece | undefined {
        for (let p in this.pieces) {
            if (this.pieces[p].name === name)
                return this.pieces[p];
        }
        return undefined;
    }
    public Push(piece: Piece): void {
        if (!this.Get(piece.name))
            this.pieces.push(piece);
    }
    public get onlyColorPieces(): Piece[] {
        return this.pieces.filter(
            item =>
                !(
                    item.customizableRestrictions &&
                    item.customizableRestrictions.includes("color")
                ),
        )
    }
    protected initHistory(): void {
        this.historyLogos.length = 100;
    }
    public pushHistoryLogo(srcLogo: Logo, action: string): void {
        //if (false === this.prevLogo.Compare(srcLogo)) return;

        console.log("push", srcLogo, action);
        if (++this.historyIndex >= this.historyLogos.length)
            this.historyIndex = 0;
        let logo = this.historyLogos[this.historyIndex] || new Logo(null);
        logo.Copy(srcLogo);
        logo.historyAction = action;

        this.historyLogos[this.historyIndex] = logo;

        logo = this.historyLogos[(this.historyIndex + 1) % this.historyLogos.length];
        if (logo)
            logo.piece = null;
    }
    public undoHistory(): void {
        if (this.historyIndex < 0)
            return;
        const logo: Logo = this.historyLogos[this.historyIndex];
        if (undefined === logo)
            return;

        if (!logo.piece)
            return;

        const action = logo.historyAction;
        logo.historyAction = "";
        const dstLogo = logo.piece.SwapLogo(logo);
        if (dstLogo) {
            this.historyLogos[this.historyIndex] = dstLogo;
            dstLogo.historyAction = action;
        }
        if ("update" === action || "deleted" === action) {
            this.selectLogo(logo);
        }
        else if ("new" === action) {
            logo.piece.DeleteLogo(logo);
            if (dstLogo)
                dstLogo.deleted = true;
            this.selectLogo(null);
        }
        this.historyIndex--;
        if (this.historyIndex < 0)
            this.historyIndex = this.historyLogos.length - 1;

        this.refreshLogos();
    }
    public redoHistory(): void {
        if (this.historyIndex < 0)
            return;
        const nextIndex = (this.historyIndex + 1) % this.historyLogos.length;
        const logo: Logo = this.historyLogos[nextIndex];
        if (undefined === logo)
            return;

        if (!logo.piece)
            return;

        const action = logo.historyAction;
        logo.historyAction = "";
        const dstLogo = logo.piece.SwapLogo(logo);
        this.historyIndex = nextIndex;
        if (dstLogo)
            this.historyLogos[this.historyIndex] = dstLogo;

        if ("update" === action || "new" === action) {
            this.selectLogo(logo);
        }
        else if ("deleted" === action) {
            logo.piece.DeleteLogo(logo);
            if (dstLogo)
                dstLogo.deleted = true;
            this.selectLogo(null);
        }
        this.refreshLogos();
    }
}