import EXIF from "exif-js"
import JSZip from "jszip"
import * as CRYPTO_JS from "crypto-js"
import save from "save-file"
import { v4 as uuidv4 } from "uuid"
import { ContainerPart, FabricImage, Product } from "../../../libs/viewer/viewerHelp"
import { storage } from "../../../firebase"
import { DEBUG, log } from "../../../utilities/logger"
import { roundOff } from "../../../utilities"
import { PREVIEW_CANVAS_NAME, PREVIEW_FOLDER_PATH, PREVIEW_CANVAS_EXTENSION, PREVIEW_SEGMENT_PREFIX, SEGMENT_IMAGES_FOLDER_PATH, SEGMENT_IMAGE_FILE_NAME_REFIX, ADD_TO_CART_IFRAME_ACTION } from "./addtocart.constants"
import { convertToCm, convertToMeters, getResolution, getSubUnit, getSuperUnit } from "../../../libs/viewer/canvasPixelConvertor"


type SegmentZipInput = {
    zip: JSZip,
    segment: FabricSegmentOutput,
    canvasWindow: { width: number, height: number },
    sku: string,
    storeName: string,
    fabricName: string,
    segmentIndex: number,
}

type Logo = {
    _id: string,
    id?: string,
    src: string,
    repeatType: string,
    repeatSize: number
    width: number,
    height: number,
    angle?: number
}

//type CanvasMetadata = { logos: Logo[], plain: string, encoded: string }

type SegmentMetadata = {
    _id: string,
    number: number,
    length: number,
    width: number,
    position: {
        x: number,
        y: number
    },
    numberOfUnits: number,
    partIcon: string,
    logos: Logo[]
}

type CanvasMetaData = {
    metaData: {
        sku: string,
        width: number,
        totalLength: number,
        fatQuarterMode?: boolean
        parts: SegmentMetadata[],
    },
    serialized: {
        plain: string,
        encoded?: string
    }
}

export type CartDetailsForCustomer = {
    action: string,
    content: any,
    screenshot: {
        width: number,
        length: number,
        numberOfUnits: number,
        icon: string,
    }[],
    name: string,
    totalLength: number
    numberOfUnits: number
    productID: number | string
}

export type FabricSegmentOutput = {
    part: ContainerPart
    images: FabricImage[]
    fabricCanvas: fabric.Canvas
    columnIndex: number
    rowIndex: number
}


export const addToCart = async (
    productId: number | string,
    fabricSegments: FabricSegmentOutput[],
    product: Product,
    canvasWindow: { width: number, height: number },
    sku: string,
    storeName: string,
    fabricName: string,
    fabricDim: { width: number, length: number, rows: number, unit: 'YARD' | 'METER' },
    grid: { rows: number, columns: number },
    pcProgress?: (pc: number) => void,
    creatingCart?: () => void,
    isSplit?: boolean
): Promise<undefined | CartDetailsForCustomer> => {

    creatingCart && creatingCart()

    /*creating canvas to add the fabric preview*/
    const { previewCanvas, previewCanvasContext } = createPreviewCanvas(canvasWindow)

    /*make sure that fabric segments are in the correct wrap-order*/
    fabricSegments.sort((seg1, seg2) => {
        return (grid.columns * seg1.rowIndex + seg1.columnIndex) - (grid.columns * seg2.rowIndex + seg2.columnIndex)
    })

    const zip = new JSZip()
    let stepSize = fabricSegments.length && fabricSegments[0].part.partSize?.stepSize

    for (let segmentIndex = 0; segmentIndex < fabricSegments.length; segmentIndex++) {

        let columnIndex = segmentIndex % grid.columns
        let rowIndex = Math.floor(segmentIndex / grid.columns)

        const segment = fabricSegments[segmentIndex];

        const segmentDetails = await addSegmentArtifactsToZip({ zip, canvasWindow, fabricName, segment, segmentIndex, sku, storeName })

        /*draw segment on preview Canvas*/
        previewCanvasContext && await drawOnCanvas(previewCanvasContext, segmentDetails.previewData,
            columnIndex * (segment.part.width || 0), rowIndex * (segment.part.height || 0))

    }

    const dataAdded = addCanvasPreviewToZip(zip, previewCanvas)

    const jsonMetadataForFirebase = createMetadata(fabricSegments, product, sku, isSplit || false, grid, fabricDim, fabricDim.unit === 'YARD')

    const numberOfUnits = roundOff(fabricDim.length / (stepSize || 1), 3)

    if (!jsonMetadataForFirebase) {
        throw new Error(`could not create JSON metadata`)
    }

    let dataForBackUp = {
        fabric: fabricName,
        parts: [
            {
                width: fabricDim.width,
                length: fabricDim.length,
                numberOfUnits,
                icon: dataAdded,
            },
        ],
    }

    const { serialized: { encoded, plain } } = jsonMetadataForFirebase

    const dataForCustomer: CartDetailsForCustomer = {
        action: ADD_TO_CART_IFRAME_ACTION,
        screenshot: dataForBackUp.parts,
        name: dataForBackUp.fabric,
        totalLength: fabricDim.length,
        numberOfUnits,
        productID: productId,
        content: {}
    }

    if (DEBUG) {
        zip.file("info.json", plain)
        zip.file("info_backUpData.json", JSON.stringify(dataForCustomer))
        let plainTextContent = await zip.generateAsync({ type: 'blob' })
        let date = new Date()
        let result = await save(plainTextContent, `customer_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}.zip`)
        return { ...dataForCustomer, content: result && result.metadata || {} }
    }



    if (!encoded) {
        throw new Error(`could not encode JSON metadata`)
    }

    zip.file("info.json", encoded)
    let content = await zip.generateAsync({ type: 'blob' })
    var files = new File([content], "customizer.zip")

    let file_name = uuidv4().split("-").join("")

    let uploadTask = storage.ref().child(`${storeName}/fabric_storage/${file_name}.zip`).put(files)
    uploadTask.on('state_changed', (result) => {
        pcProgress && pcProgress(Math.round(result.bytesTransferred / result.totalBytes) * 100)
    })
    let result = await uploadTask

    return { ...dataForCustomer, content: result && result.metadata }

}

function addCanvasPreviewToZip(zip: JSZip, previewCanvas: HTMLCanvasElement) {
    const previewDataUrl = previewCanvas.toDataURL()
    const dataAdded = previewDataUrl.split(",")[1]
    const canvasPreviewFilePath = getCanvasPreviewFilePath()
    zip.file(canvasPreviewFilePath, dataAdded, { base64: true })
    return previewDataUrl
}

async function addSegmentArtifactsToZip(input: SegmentZipInput) {

    const { zip, segmentIndex, segment, canvasWindow, sku, storeName, fabricName } = input
    const segmentPreviewFilePath = getSegmentPreviewFilePath(segmentIndex)
    /*get segment preview*/
    const details = await getSegmentPreview(segment, canvasWindow, sku, storeName, fabricName)
    /*add segment preview to zip*/
    zip.file(segmentPreviewFilePath, details.previewData.split(",")[1], { base64: true })
    /*add images corresponding to the segment to zip*/
    addContentImagesZip(segmentIndex, segment.part, segment.images, zip)

    return details

}

function getSegmentPreviewFilePath(segmentIndex: number) {
    return `${PREVIEW_FOLDER_PATH}${PREVIEW_SEGMENT_PREFIX}${segmentIndex}${PREVIEW_CANVAS_EXTENSION}`
}
function createSegmentId(segmentIndex: number) {
    return `${SEGMENT_IMAGE_FILE_NAME_REFIX}${segmentIndex}`
}
function createImageId(segmentIndex: number, imageIndex: number) {
    const segmentId = createSegmentId(segmentIndex)
    return `${segmentId}_${imageIndex + 1}`
}
function getSegmentImageFilePath(segmentIndex: number, imageIndex: number, extension: string) {
    const fileName = createImageId(segmentIndex, imageIndex)
    extension = extension.startsWith(".") ? extension : `.${extension}`
    return `${SEGMENT_IMAGES_FOLDER_PATH}${fileName}${extension}`
}
function getCanvasPreviewFilePath() {
    return `${PREVIEW_FOLDER_PATH}${PREVIEW_CANVAS_NAME}${PREVIEW_CANVAS_EXTENSION}`
}

function createPreviewCanvas({ width, height }: { width: number, height: number }) {
    let canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    let context = canvas.getContext('2d')
    if (context)
        context.imageSmoothingEnabled = true
    return {
        previewCanvas: canvas,
        previewCanvasContext: context
    }
}

function createCanvasPreview(part: ContainerPart, images: FabricImage[], fabricCanvas: fabric.Canvas, isModeRepeat: boolean): string | undefined {
    if (part) {
        let anchorPoint
        if (isModeRepeat) {
            anchorPoint = { top: part.height, left: 0 }
        } else {
            let { top, left, width } = part
            top = top || 0
            left = left || 0
            width = width || 0
            anchorPoint = findImageAnchor(images, part.width || 0, { top, left, width }, false)
        }
        let previewOptions = {
            format: "jpeg",
            quality: 0.3,
            multiplier: 1,
            width: part.width,
            height: anchorPoint.top,
            left: part.left,
            top: part.top,
        }
        part.strokeWidth = 1
        let data = fabricCanvas.toDataURL(previewOptions)
        return data
    }
    return undefined
}

function findImageAnchor(images: FabricImage[], imgCanvasWidth: number, parentContainer: { top: number, left: number, width: number }, inCollage: boolean): { top: number, left: number } {

    let parentTop = parentContainer.top
    let parentLeft = parentContainer.left
    let parentWidth = parentContainer.width

    let rightCollageRectEdge = parentLeft
    let bottomCollageRectEdge = parentTop

    let inCollageTop = 0
    let inCollageLeft = 0

    images.forEach((item, index) => {

        let { top, left } = item
        let { height, scaleX, width, scaleY } = returnEffectiveDims(item)

        top = top || 0
        left = left || 0

        let widthOnCanvas = width * scaleX
        if (left + widthOnCanvas > rightCollageRectEdge) {
            rightCollageRectEdge = left + widthOnCanvas
        }
        let heightOnCanvas = height * scaleY
        if (top + heightOnCanvas > bottomCollageRectEdge) {
            bottomCollageRectEdge = top + heightOnCanvas
        }

        if ((index === (images.length - 1))) {
            if (inCollage) {
                let imgRightEdge = (left + widthOnCanvas)
                if ((imgRightEdge + imgCanvasWidth) < parentWidth) {
                    bottomCollageRectEdge = top
                    rightCollageRectEdge = imgRightEdge
                } else {
                    bottomCollageRectEdge = bottomCollageRectEdge //does not change
                    rightCollageRectEdge = 0
                }

            } else {
                if ((rightCollageRectEdge + imgCanvasWidth) > parentWidth) {
                    rightCollageRectEdge = 0
                } else {
                    bottomCollageRectEdge = 0
                }

            }
        }

    })

    return { top: bottomCollageRectEdge, left: rightCollageRectEdge }
}

const returnEffectiveDims = (image: fabric.Object) => {

    let { height = 0, scaleX = 0, width = 0, scaleY = 0, angle = 0 } = image
    let switchSides = (angle || 0) % 180

    return switchSides ? {

        height: width,
        width: height,
        scaleX: scaleY,
        scaleY: scaleX,
        sidesSwitched: true

    } : { height, scaleX, width, scaleY, sidesSwitched: false }


}

type SegmentDetails = {
    previewData: string
}

const addContentImagesZip = (segmentIndex: number, part: ContainerPart, images: FabricImage[], zipFile: JSZip) => {

    images.forEach((item, index) => {
        if (item.file) {
            const extension = item.file.type.split("/")[1]
            const filePath = getSegmentImageFilePath(segmentIndex, index, extension)
            zipFile.file(filePath, item.file)
        }
    })
}

function createMetadata(segments: FabricSegmentOutput[], product: Product, sku: string, isSplit: boolean,
    grid: { rows: number, columns: number }, fabricDim: { width: number, length: number, rows: number }, convertToSI: boolean): CanvasMetaData | undefined {

    const parts: SegmentMetadata[] = []
    const logosArray: Logo[] = []

    for (let segmentIndex = 0; segmentIndex < segments.length; segmentIndex++) {

        const fabricSegment = segments[segmentIndex];
        let { images, part, rowIndex, columnIndex } = fabricSegment

        columnIndex = segmentIndex % grid.columns
        rowIndex = Math.floor(segmentIndex / grid.columns)

        const partWidthSubunit = product.metadata?.widthSubUnit || 0
        let { pattern_type, width: partWidth, partSize, height } = part
        let pxToSubUnit = (partWidthSubunit) / (partWidth || 1)


        const partHeightSubunit = (height || 0) * pxToSubUnit//(partSize?.length || 0) * 100
        const column = segmentIndex % grid.columns
        const row = Math.floor(segmentIndex / grid.columns)
       

        let segmentTop = rowIndex * (part.height || 0)
        let segmentLeft = columnIndex * (part.width || 0)

        let isModeRepeat = pattern_type === 'DEFAULT_REPEAT' || part.pattern_type === 'HALF_BRICK_REPEAT' ||
            part.pattern_type === 'HALF_DROP_REPEAT' || part.pattern_type === 'MIRROR_REPEAT'

        let logos = images.filter(img => img.file).map((img, index) => {

            let { file, left, top, rotatedBy, optionalImageId } = img
            let { scaleX, scaleY, width: imgWidth, height: imgHeight } = returnEffectiveDims(img)

            log({ segmentLeft, segmentTop, left, top, rowIndex, columnIndex, grid }, 'cart create')

            left = segmentLeft + (left || 0)
            top = segmentTop + (top || 0)

            let x = left * pxToSubUnit
            let y = (top - (product.top || 0)) * pxToSubUnit
            let width = imgWidth * scaleX * pxToSubUnit
            let height = imgHeight * scaleY * pxToSubUnit

            width = convertToSI ? convertToCm(width) : width
            height = convertToSI ? convertToCm(height) : height
            x = convertToSI ? convertToCm(x) : x
            y = convertToSI ? convertToCm(y) : y

            let position = {
                x: roundOff(x, 3),
                y: roundOff(y, 3),
            }
            let idStr = createImageId(segmentIndex, index)
            const extension = file?.type.split("/")[1] || PREVIEW_CANVAS_EXTENSION


            let image = {
                _id: idStr,
                id: optionalImageId,
                src: getSegmentImageFilePath(segmentIndex, index, extension),
                repeatType: pattern_type || 'NON_REPEAT',
                repeatSize: isModeRepeat ? roundOff((imgWidth * scaleX) / (partWidth || 1), 3) : -1,
                width: parseFloat(width.toFixed(3)),
                height: parseFloat(height.toFixed(3)),
                angle: rotatedBy
            }

            return {
                ...image,
                position,
            }
        })
        logosArray.push(...logos)
        let idStr = `${part.id}`
        
        let length = getSuperUnit(partHeightSubunit)
        length = convertToSI? convertToMeters(length): length
       

        let width = getSuperUnit(partWidthSubunit)
        width = convertToSI? convertToMeters(width): width

        

        let part_x = column * partWidthSubunit
        part_x = convertToSI? convertToCm(part_x): part_x
        part_x = roundOff(part_x , 3)

        let part_y = row * partHeightSubunit
        part_y = convertToSI? convertToCm(part_y): part_y
        part_y = roundOff(part_y, 3)

        const partHeightSuperUnit = getSuperUnit(partHeightSubunit)
       

        parts.push({
            _id: createSegmentId(segmentIndex),
            number: segmentIndex + 1,
            length,
            width,
            position: {
                x: part_x,
                y: part_y
            },
            numberOfUnits: roundOff(partHeightSuperUnit / (partSize?.stepSize || 1), 3),
            partIcon: getSegmentPreviewFilePath(segmentIndex),
            logos
        })
    }

    let segmentsData = {
        sku,
        width: convertToSI? convertToMeters(fabricDim.width): fabricDim.width,
        totalLength: convertToSI? convertToMeters(fabricDim.length) : fabricDim.length,
        parts,
    }

    const KEY = process.env.REACT_APP_KEY
    const IV = process.env.REACT_APP_IV

    let plain = JSON.stringify(segmentsData)
    let encoded = KEY && IV ? CRYPTO_JS.AES.encrypt(
        plain,
        CRYPTO_JS.enc.Utf8.parse(KEY),
        {
            iv: CRYPTO_JS.enc.Utf8.parse(IV),
            padding: CRYPTO_JS.pad.Pkcs7,
            mode: CRYPTO_JS.mode.CBC,
        },
    ).toString() : undefined

    return { metaData: segmentsData, serialized: { plain, encoded } }

}


const getSegmentPreview = async (fabricSegment: FabricSegmentOutput, canvasWindow: { width: number, height: number }, sku: string, storeName: string, fabricName: string): Promise<SegmentDetails> => {

    let part = fabricSegment.part
    let isModeRepeat = part.pattern_type === 'DEFAULT_REPEAT' || part.pattern_type === 'HALF_BRICK_REPEAT' ||
        part.pattern_type === 'HALF_DROP_REPEAT' || part.pattern_type === 'MIRROR_REPEAT'

    let previewData = createCanvasPreview(part, fabricSegment.images, fabricSegment.fabricCanvas, isModeRepeat)
    if (!previewData) {
        throw new Error(`could not create Preview`)
    }
    return {
        previewData
    }

}

const loadImage = (imageDataUrl: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
    let image = new Image()
    image.setAttribute("crossorigin", "anonymous");
    image.src = imageDataUrl
    image.onload = function () {
        resolve(image)
    }
})

const drawOnCanvas = async (context: CanvasRenderingContext2D, imageDataUrl: string, top: number, left: number) => {

    const image = await loadImage(imageDataUrl)
    context.drawImage(image, top, left)
}


