import React from "react"

import styles from "./select.module.scss"

import OutsideClickHandler from "../../OutsideClickHandler/OutsideClickHandler";

/**
 * Custom select component
 * @component
 * @param {object} props
 * @param {boolean} props.active - component state
 * @param {function} props.handleToggle - open/close function
 * @param {function} props.handleSelect - select function
 * @param {string} props.label - select text
 * @param {string} props.name - name for function work
 * @param {Array} props.options - select options 
 * 
 * @example

 * return (
 * <Select 
 *  active ={active}
 *  handleToggle ={handleToggle}
 *  handleSelect ={handleSelect}
 *  label ={label}
 *  name ={name}
 *  options ={options}
 * />
 * )
 */
const Select = props => {
  const {
    active = false,
    handleToggle = () => {},
    handleSelect = () => {},
    onfocusout = () => {},
    label = "",
    name = "",
    options = [],
  } = props

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        onfocusout()
      }}
    >
      <div id="selectorParent" className={styles.container}>
      <div className={styles.select} data-name={name} onClick={handleToggle}>
        <span>{label}</span>
        <span hidden={props.options.length==1} className={active ? styles.active_arr : ""}>
          <img  src="/img/icons/chevron-down.svg" alt="arrow" />
        </span>
      </div>
        {active && (
          <div className={styles.option_cont}>
            {options.map(item => (
              <button
                className={styles.option_btn}
                key={item.name + Math.random() * 100}
                onClick={handleSelect}
                data-name={name}
                data-value={item.name}
                disabled={item.disabled}
              >
                {item.name}
              </button>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
    
  )
}

export default Select
