import * as THREE from "three"
import Piece from "./Piece";

export default class Hotspots extends THREE.Object3D {
    private readonly is2D: boolean;
    private spots: THREE.Mesh[] = [];
    private piecePosition: THREE.Vector3 = new THREE.Vector3();

    constructor(is2D: boolean) {
        super();

        this.is2D = is2D;
        const meshMat = new THREE.MeshPhongMaterial({
            color: 0xFF0000,
            transparent: true,
            opacity: 0.7,
            fog: false
        });

        const sphere = new THREE.SphereGeometry(1.0, 12, 12);
        for (let i: number = 0; i < 32; ++i) {
            const mesh = new THREE.Mesh(sphere, meshMat.clone());
            this.add(mesh);
            this.spots.push(mesh);
        }
    }

    public SetPiece(piece: Piece | null) {
        let i: number = 0;
        const len: number = this.spots.length;
        if (!piece || !piece.hotspots || !piece.Mesh(this.is2D)) {
            for (i = 0; i < len; ++i)
                this.spots[i].visible = false;

            return;
        }
        const mesh = piece.Mesh(this.is2D);
        mesh.getWorldPosition(this.piecePosition);



        for (let h in piece.hotspots) {
            if (i >= len) break;
            const spot = this.spots[i];
            if (!spot)
                break;
            spot.visible = true;
            const hspt = piece.hotspots[h];
            const hPos = this.is2D ? hspt.position2D : hspt.position3D;
            spot.position.copy(hPos);
            spot.scale.set(hspt.sphere, hspt.sphere, hspt.sphere);
            const mat: THREE.MeshPhongMaterial = spot?.material as THREE.MeshPhongMaterial;
            if (hspt?.color)
                mat.color.set(hspt.color);
            else
                mat.color.set(0xFF0000);

            spot.updateMatrix();
            ++i;
        }
    }
}
