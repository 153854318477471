/**
 * Connect to Firebase point
 * @file
 */
import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"

const config = {
  apiKey: process.env.REACT_APP_FB_APIKEY,
  authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECTID,
  storageBucket: process.env.REACT_APP_FB_STORBUCKET,
}

firebase.initializeApp(config)

const fireDir = firebase.firestore().collection("Tenants")
const storage = firebase.storage()
const db = firebase.firestore()

export {db, firebase, fireDir, storage}
