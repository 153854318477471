import React from "react"

import styles from "./colorsmenu.module.scss"
import colors from "../../../../data/colors"

const ColorsMenu = ({
  activeColor = "",
  target = "",
  handleSelect = () => {},
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Change text color</div>
      <div className={styles.body}>
        {colors.length ? (
          colors.map(item => (
            <div className={styles.btn_cont} key={item.color}>
              <button
                className={styles.btn}
                style={{
                  backgroundColor: item.color,
                  color:
                    item.color.startsWith("#F") || item.color.startsWith("#f")
                      ? "#000"
                      : "#fff",
                }}
                name={item.color}
                data-target={target}
                onClick={handleSelect}
              >
                {activeColor === item.color ? <span>&#10004;</span> : ""}
              </button>
            </div>
          ))
        ) : (
          <div>Colors not found</div>
        )}
      </div>
    </div>
  )
}

export default ColorsMenu
