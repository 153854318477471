import * as THREE from "three";
import translate from "../translate"

import glo from "./glo";
import Logo from "./Logo";

import meshFrag from "./shaders/mesh.frag"

export default class Piece {
    public props: any;
    public piece: any;
    public mesh2D: THREE.Mesh | null = null;
    public mesh3D: THREE.Mesh | null = null;
    public renderTarget2D: THREE.WebGLRenderTarget | null = null;
    public renderTarget3D: THREE.WebGLRenderTarget | null = null;

    public boundingBox2D: THREE.Box3 | null = null;
    public boundingBox3D: THREE.Box3 | null = null;
    public boundingSphere2D: THREE.Sphere | null = null;
    public boundingSphere3D: THREE.Sphere | null = null;

    public logos: Logo[] = [];
    public color: string = "";
    public pickColor: string = "";
    public name: string = "";
    public displayName: string = "";
    public width: number = 0;
    public height: number = 0;
    public uv: THREE.Vector2 = new THREE.Vector2();
    public duv: THREE.Vector2 = new THREE.Vector2();
    public bleedingTL: THREE.Vector2 = new THREE.Vector2();
    public bleedingBR: THREE.Vector2 = new THREE.Vector2();
    public hotspots: any = undefined;
    public hotlines: any = undefined;
    public selected: boolean = false;
    public showHotlines: boolean = false;

    public customizableRestrictions: any;

    public resizeFactor: number = 1;
    public invResizeFactor: number = 1;

    private tUV0 = new THREE.Vector2();
    private tUV1 = new THREE.Vector2();
    private tBox = new THREE.Vector3();

    constructor(props: any, piece: any) {
        this.props = props;
        this.piece = piece;
        this.name = piece.id;

        this.displayName = props.hasTranslations && translate.t("i18n." + piece.id).found ? translate.t("i18n." + piece.id).res : piece.displayName;
        this.color = piece.color || props.defaultPiecesColor || "#c2c2c2";

        const unit: number = glo.unit;

        this.uv.set(piece.uv.x, piece.uv.y);
        this.duv.set(piece.uv.x * unit, piece.uv.y * unit);
        this.customizableRestrictions = piece.customizableRestrictions

        if (piece.uv) {
            this.bleedingTL.set(piece.uv.bleedX0 * unit, piece.uv.bleedY0 * unit);
            this.bleedingBR.set(piece.uv.bleedX1 * unit, piece.uv.bleedY1 * unit);
        }

        this.hotspots = piece.hotspots;
        for (let h in this.hotspots) {
            const diag = this.duv.length();
            const spot = this.hotspots[h];
            spot.uv = new THREE.Vector3((spot.x * unit) / this.duv.x, (spot.y * unit) / this.duv.y, (spot.radius * unit) / diag);
        }

        this.hotlines = piece.hotlines;
        for (let h in this.hotlines) {
            const data = this.hotlines[h];
            const line = data.lines;
            for (let i in line) {
                let p = line[i];
                p.uv = new THREE.Vector2(p.u, p.v);
            }
        }

        let W = this.duv.x;
        let H = this.duv.y;
        let size = Math.max(W, H);
        let resizeFactor = 1;
        if (size > glo.MAX_TEXTURE_SIZE)
            resizeFactor = glo.MAX_TEXTURE_SIZE / size;

        this.resizeFactor = resizeFactor;
        this.invResizeFactor = 1.0 / resizeFactor;
        this.width = W;
        this.height = H;
    }
    public SetMesh(is2D: boolean, mesh: THREE.Mesh, fabricMaterialsList: any[]): void {
        if (is2D) {
            this.mesh2D = mesh;
            this.boundingBox2D = mesh.geometry.boundingBox;
            this.boundingSphere2D = mesh.geometry.boundingSphere;

        }
        else {
            this.mesh3D = mesh;
            this.boundingBox3D = mesh.geometry.boundingBox;
            this.boundingSphere3D = mesh.geometry.boundingSphere;
        }
        glo.Set(mesh, "piece", this);

        mesh.material = this.assignDefaultMaterial(mesh.material, mesh.material);
        const normalMap = glo.Get(mesh.material, "normalMap");
        if (!normalMap)
            fabricMaterialsList.forEach(material => { glo.Set(mesh.material, material.type, material.map); });

        this.generateTexture(is2D, mesh.material);

        this.Material(is2D).map = this.RenderTarget(is2D).texture!;
        this.Material(is2D).needsUpdate = true;

        if (is2D) {
            const bleed = glo.Get(mesh, "bleed");
            if (bleed) {
                bleed.material = mesh.material.clone();
                bleed.material.color.set(0x808080);
            }
        }


        for (let h in this.hotspots) {
            const spot = this.hotspots[h];
            const pos = this.uvToLocation(is2D, spot.uv);
            if (is2D)
                spot.position2D = new THREE.Vector3(pos?.x, pos?.y, pos?.z);
            else
                spot.position3D = new THREE.Vector3(pos?.x, pos?.y, pos?.z);
        }

        for (let h in this.hotlines) {
            const data = this.hotlines[h];
            const line = data.lines;
            for (let i in line) {
                const p = line[i];
                const pos = this.uvToLocation(is2D, p.uv);
                if (is2D)
                    p.position2D = new THREE.Vector3(pos?.x, pos?.y, pos?.z);
                else
                    p.position3D = new THREE.Vector3(pos?.x, pos?.y, pos?.z);
            }
        }
    }
    public Load(part: any, logos: { [id: string]: string | any }): Promise<Piece> {
        if (part.bleeing) {
            this.bleedingBR.set(part.bleeing.bottomRight.x, part.bleeing.bottomRight.y);
            this.bleedingTL.set(part.bleeing.topLeft.x, part.bleeing.topLeft.y);
        }
        if (part.color)
            this.color = part.color;

        let logoPromisses: any[] = [];
        const uv: THREE.Vector2 = new THREE.Vector2(0, 0);
        for (let l in part.logos) {
            const logo = part.logos[l];
            logoPromisses.push(new Promise((resolve: any, reject: any) => {
                const img = logos[logo.src || logo.imageKey]?.data ?? logos[logo.src || logo.imageKey];
                Logo.CreateLogo(img, this, uv, null, ((newLogo: Logo) => {
                    newLogo.Set(logo);
                    resolve(this);
                }));
            }));
        }

        return new Promise((resolve: any, reject: any) => {
            Promise.all(logoPromisses).then(logos => {
                resolve(this);
            })
        });
    }
    public DeleteLogo(logo: Logo): void {
        this.logos = this.logos.filter(val => { return val.id !== logo.id; });
        logo.deleted = true;
    }
    public ReplaceLogo(logo: Logo): Logo | null {
        for (let l in this.logos) {
            if (this.logos[l].id === logo.id) {
                this.logos[l].Copy(logo);
                return this.logos[l];
            }
        }
        return null;
    }
    public SwapLogo(logo: Logo): Logo | null {
        for (let l in this.logos) {
            if (this.logos[l].id === logo.id) {
                const dstLogo = this.logos[l];
                this.logos[l] = logo;
                return dstLogo;
            }
        }
        if (logo.deleted) {
            logo.deleted = false;
            this.logos.push(logo);
            return new Logo(logo);
        }
        return null;
    }

    public SnapToHotspots(is2D: boolean, logo: Logo): boolean {
        logo.hotspot = "";
        if (!this.hotspots)
            return false;

        const uv = this.logoUVCenter(is2D, logo);

        for (let h in this.hotspots) {
            const spot = this.hotspots[h];

            this.tUV0.set(uv.x, uv.y);
            this.tUV1.set(spot.uv.x, spot.uv.y);
            const dist = this.tUV0.distanceTo(this.tUV1);
            if (dist < spot.uv.z) {
                const W = this.width;
                const H = this.height;

                //x = (logo.px + logo.cx / 2) / W;
                logo.px = spot.uv.x * W - logo.cx / 2;

                //y = 1.0 - ((logo.py + logo.cy / 2) / H);
                logo.py = (1.0 - spot.uv.y) * H - logo.cy / 2;
                logo.hotspot = h;
                return true;
            }
        }
        return false;
    }

    public SnapToHotlines(is2D: boolean, logo: Logo): boolean {
        logo.hotline = "";
        if (!this.hotlines)
            return false;

        const uv = this.logoUVCenter(is2D, logo);

        for (let h in this.hotlines) {
            const data = this.hotlines[h];
            const line = data.lines;
            if (!line) continue;
            const len = line.length - 1;
            for (let i = 0; i < len; ++i) {
                const res = glo.project(uv, line[i].uv, line[i + 1].uv);
                if (res.dist < data.snap) {
                    const W = this.width;
                    const H = this.height;
                    logo.px = res.point.x * W - logo.cx / 2;
                    logo.py = (1.0 - res.point.y) * H - logo.cy / 2;
                    logo.hotline = h;
                    return true;
                }
            }
        }
        return false;
    }

    public logoUVCenter(is2D: boolean, logo: Logo): THREE.Vector3 {
        if (!logo)
            return new THREE.Vector3(0, 0, 1);
        const W = this.width;
        const H = this.height;

        const sphere = this.BoundingSphere(is2D);
        const l = glo.clamp(logo.px, 0, W);
        const r = glo.clamp(logo.px + logo.cx, 0, W);
        const t = glo.clamp(logo.py, 0, H);
        const b = glo.clamp(logo.py + logo.cy, 0, H);
        const x = (l + r) / (2 * W);
        const y = 1.0 - ((t + b) / (2 * H));
        const s = Math.max(logo.cx, logo.cy) / Math.min(W, H);
        const R = sphere ? sphere.radius : 1.0;

        return new THREE.Vector3(x, y, s * R);
    }

    public uvToLocation(is2D: boolean, p: any): THREE.Vector3 | null {
        const mesh = this.Mesh(is2D);
        if (null == mesh)
            return null;

        if (false === is2D) {
            const geometry = mesh.geometry as THREE.BufferGeometry;
            if (!geometry)
                return null;
            const position = geometry.attributes.position;
            const uv = geometry.attributes.uv;
            let I = -1;
            let minDistance = 100000, dist = 0;

            for (let i = uv.count - 1; i >= 0; --i) {
                this.tUV0.set(uv.getX(i), uv.getY(i));
                dist = this.tUV0.distanceToSquared(p);
                if (dist < minDistance) {
                    I = i;
                    minDistance = dist;
                }
            }
            if (I < 0)
                return null;

            const tPos = new THREE.Vector3(position.getX(I), position.getY(I), position.getZ(I));
            return mesh.localToWorld(tPos);
        }
        if (!this.boundingBox2D)
            return null;
        const minBox = this.boundingBox2D!.min;
        const maxBox = this.boundingBox2D!.max;
        const dBox = this.tBox;
        dBox.copy(maxBox);
        dBox.sub(minBox);

        const tPos = new THREE.Vector3(p.x * dBox.x + minBox.x, p.y * dBox.y + minBox.y, 0);
        return mesh.localToWorld(tPos);
    }

    public X(uv: THREE.Vector2): number { return uv.x * this.duv.x; }
    public Y(uv: THREE.Vector2): number { return (1 - uv.y) * this.duv.y; }
    public get bleeding(): any {
        return { topLeft: this.bleedingTL, bottomRight: this.bleedingBR };
    }

    private assignDefaultMaterial(material: THREE.Material | THREE.Material[], srcMaterial: THREE.Material | THREE.Material[]): THREE.Material {

        let newMaterial = new THREE.MeshStandardMaterial();
        const map = glo.Get(srcMaterial, "map");
        const aoMap = glo.Get(srcMaterial, "alphaMap");
        const normalMap = glo.GetSet(srcMaterial, newMaterial, "normalMap");
        newMaterial.normalScale.copy(glo.Get(srcMaterial, "normalScale"));
        newMaterial.roughness = 2;
        newMaterial.metalness = 0.1;
        const backMix: string = this.piece?.backMix || "0.0";
        newMaterial.onBeforeCompile = (shader: THREE.Shader, renderer: THREE.WebGLRenderer) => {
            shader.fragmentShader = `const float backMix = ${backMix};\n` + meshFrag;
            shader.uniforms.fmap = { value: map };
        }

        if (!normalMap) {
            newMaterial.normalScale.x = 0.05;
            newMaterial.normalScale.y = 0.05;
        }
        if (aoMap) {
            newMaterial.aoMap = aoMap;
            newMaterial.aoMapIntensity = 0.1;
        }

        newMaterial.side = THREE.DoubleSide;
        newMaterial.needsUpdate = true;
        return newMaterial;
    }

    public Mesh(is2D: boolean): THREE.Mesh { return is2D ? this.mesh2D! : this.mesh3D!; }
    public Material(is2D: boolean): THREE.MeshStandardMaterial { return this.Mesh(is2D)?.material as THREE.MeshStandardMaterial; }
    public RenderTarget(is2D: boolean): THREE.WebGLRenderTarget { return is2D ? this?.renderTarget2D! : this?.renderTarget3D!; }
    public BoundingBox(is2D: boolean): THREE.Box3 | null { return is2D ? this.boundingBox2D : this.boundingBox3D; }
    public BoundingSphere(is2D: boolean): THREE.Sphere | null { return is2D ? this.boundingSphere2D : this.boundingSphere3D; }

    public Highlight(selected: boolean, hotlines?: boolean): void {
        //const clr = highlight ? 0x0000FF : 0x000424;
        //this.Material(true)?.emissive.setHex(clr);
        //this.Material(false)?.emissive.setHex(clr);
        if (undefined !== selected)
            this.selected = selected;
        if (undefined !== hotlines)
            this.showHotlines = hotlines;
    }

    private generateTexture(is2D: boolean, material: THREE.Material | THREE.Material[]): void {
        let width = Math.min(glo.MAX_TEXTURE_SIZE, Math.round(this.duv.x * this.resizeFactor));
        let height = Math.min(glo.MAX_TEXTURE_SIZE, Math.round(this.duv.y * this.resizeFactor));

        if (is2D) {
            const bmesh: THREE.Mesh = glo.Get(this.mesh2D, "bleed");
            if (bmesh) {
                const rbox = new THREE.Vector3();
                const bbox = new THREE.Vector3();
                this.mesh2D?.geometry.boundingBox!.getSize(rbox);
                bmesh.geometry.boundingBox!.getSize(bbox);

                width *= bbox.x / rbox.x;
                height *= bbox.y / rbox.y;
            }
            const rt = new THREE.WebGLRenderTarget(width, height, { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, format: THREE.RGBAFormat });
            this.renderTarget2D = rt;
        }
        else {
            const rt = new THREE.WebGLRenderTarget(width, height, { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, format: THREE.RGBAFormat });
            this.renderTarget3D = rt;
        }
    }

    public ClearTexture(is2D: boolean, renderer: THREE.WebGLRenderer): void {
        renderer.setClearColor(this.props.defaultPiecesColor, 1);
        renderer.setRenderTarget(this.RenderTarget(is2D));
        renderer.clear();

        renderer.setRenderTarget(null);
        renderer.setClearColor(0xf2f4f6, 1);
    }
}
