import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeImageSizeCmsByHeight, changeImageSizeCmsByWidth } from '../../redux/actions/parts';
import { DebouncedInput } from '../DebouncedInput';
import './styles.scss';
import alertify from "../../libs/alert";
import { DimensionSlider } from '../DimensionSlider';
import { roundOff } from '../../utilities';

export type TileSizeProps = {
    part: any
}

export const TileSize = (props: TileSizeProps) => {

    const selectedSegment = useSelector((state: any) => state.fabric.segmentInFocus)
    const images = useSelector((state: any) => state.canvasImages && state.canvasImages.images)

    const [collageImages, setCollageImages] = useState([] as any[])
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [validationErrorMessage, setValidationErrorMessage] = useState('');

    const [dpi, setDpi] = useState(undefined as any)




    const fabricSize = useSelector((state: any) => state.fabric.settings?.fabricDimensionsPx);

    const { part } = props

    useEffect(() => {
        if (images && selectedSegment)
            setCollageImages(images.filter((image: any) => image.parentCanvas === selectedSegment))
        else {
            setCollageImages([]);
        }
    }, [images, selectedSegment])

    const { minResolution, tileOriginalWidth, widthSubUnit, heightSubUnit, heightLimits, widthLimits, url } = (collageImages && collageImages[0]) || {}

    useEffect(() => {
        widthSubUnit && tileOriginalWidth && setDpi(Math.round((tileOriginalWidth * 2.54) / widthSubUnit))
    }, [widthSubUnit, tileOriginalWidth])

    const displayError = useCallback(() => {
        validationErrorMessage && alertify.alert(t("editor.alert_warning_title2"), validationErrorMessage, t("i18n.ok"),
            () => { }).set('modal', true)
    }, [validationErrorMessage])

    const validateInput = useCallback((limits: { min: number, max: number }) => (value?: number) => {
        value = (value || 0) / 10;
        const isValueTooBig = !!(value && (value > limits.max));
        const isValueTooSmall = !!(value && (value < limits.min));
        if (isValueTooBig) {
            setValidationErrorMessage(`${t("editor.scale_too_high")} (${widthLimits.max * 10} x ${heightLimits.max * 10})`)
        } else if (isValueTooSmall) {
            setValidationErrorMessage(`${t("editor.scale_too_small")}`)
        }
        return !isValueTooBig && !isValueTooSmall
    }, [heightLimits, widthLimits])

    const validateResolution = useCallback((value) => {
        const maxResolution = Math.round((tileOriginalWidth*2.54)/2)
        const isTooLow = value < minResolution
        const isTooHigh = value > maxResolution
        if (isTooLow) {
            setValidationErrorMessage('The resolution is too low!')
        } else if(isTooHigh){
            setValidationErrorMessage('The resolution is too high!')
        }
        return !isTooLow && !isTooHigh;
    }, [minResolution, tileOriginalWidth])

    const onDpiChangeHandler = useCallback((value) => {
        const newDimension = (tileOriginalWidth / value) * 2.54
        part && dispatch(changeImageSizeCmsByWidth(part._id,
            url,
            'width',
            newDimension,
            heightSubUnit,
            part,
            true,
            fabricSize,
            t, true));
    }, [tileOriginalWidth, heightSubUnit, part, url, fabricSize]);

    const onWidthInputHandler = useCallback((value) => {
        const newValue = value / 10;
        part && dispatch(changeImageSizeCmsByWidth(part._id,
            url,
            'width',
            newValue,
            heightSubUnit,
            part,
            true,
            fabricSize,
            t, true));
    }, [heightSubUnit, part, url, fabricSize]);

    const onHeightInputHandler = useCallback((value) => {
        const newValue = value / 10;
        part && dispatch(changeImageSizeCmsByHeight(part._id,
            url,
            'height',
            widthSubUnit,
            newValue,
            part,
            true,
            fabricSize,
            t, true));
    }, [widthSubUnit, part, url, fabricSize]);

    return <div className="tileSizeContainer">
        <div className="patternSize">
            <div className="patternSizeLabel">Pattern Size</div>
            <div className="patternSizeDiv">
                <DebouncedInput displayError={displayError} validateInput={widthLimits && validateInput(widthLimits)}
                    onValueChanged={onWidthInputHandler}
                    currentValue={widthSubUnit && widthSubUnit * 10}
                    className="inputNumber" />
                x
                <DebouncedInput displayError={displayError} validateInput={heightLimits && validateInput(heightLimits)} onValueChanged={onHeightInputHandler}
                    currentValue={heightSubUnit && heightSubUnit * 10}
                    className="inputNumber" />
                mm
                {/* <input type="number" className={styles.inputNumber} /> */}
                <DebouncedInput displayError={displayError} validateInput={dpi && validateResolution}
                    onValueChanged={onDpiChangeHandler} currentValue={dpi && dpi} className="inputNumber" />
                dpi
            </div>
        </div>
        <DimensionSlider onSliderValueChanged={onHeightInputHandler} min={(heightLimits?.min || 0) * 10} max={(heightLimits?.max || 100) * 10} currentValue={heightSubUnit * 10} />
    </div>
}