import { Viewer2dApi } from "../../../libs/viewer"
import { log } from "../../../utilities/logger"
import { REMOVE_SEGMENT_SETTINGS } from "../fabric/actions"

export interface ICanvasImage {
    id: string
    tileWidth: number
    tileHeight: number
    tileOriginalWidth: number
    tileOriginalHeight: number
    url: string
    widthSubUnit: number
    heightSubUnit: number
    widthLimits: { min: number, max: number }
    heightLimits: { min: number, max: number }
    locked: boolean
    angle:number
    parentCanvas : Viewer2dApi
}

export interface ICanvasImages {

    isCopyrightCompliant?:boolean

    selectedImage?: {
        id: string
        url: string
        rotation?: number
        rotationFactor?: number
    }
    images?: ICanvasImage[]

}

export const canvasImages = (state: ICanvasImages = {}, action: { type: string, payload: any }) => {


    switch (action.type) {
        case ACTIONS_SELECT_IMAGE:
            return { ...state, selectedImage: { ...state.selectedImage, ...action.payload } }
        case ACTIONS_ROTATE_SELECTED_IMAGE:
            let rotationFactor = ((state.selectedImage?.rotationFactor || 0) + 1) % 4
            let rotation = rotationFactor * 90
            log({rotation},'effectiveRotation')
            return { ...state, selectedImage: { ...state.selectedImage, rotation, rotationFactor } }
        case ACTIONS_DESELECT_IMAGE:
            return { ...state, selectedImage: undefined }
        case ACTIONS_REMOVE_IMAGE: {
            let url = action.payload.url
            let { images, selectedImage } = state
            if (images) {
                let index = images.findIndex((item) => item.url === url);
                let isAlsoSelected = selectedImage && selectedImage.url === url
                if (index + 1) {
                    images.splice(index, 1)
                    return { ...state, images: [...images], ...isAlsoSelected ? { selectedImage: undefined }:{} }
                }
            }
            return state
        }
        case REMOVE_SEGMENT_IMAGES: {
            let canvas = action.payload.parentCanvas
            let { images, selectedImage } = state
            let isAnySelected = false
            if (images) {
                let updateImageArray:ICanvasImage[] = []
                images.forEach((image)=>{
                    if(image.parentCanvas !== canvas){
                        updateImageArray.push({...image})
                    }else if(selectedImage && selectedImage.url === image.url){
                        isAnySelected = true
                    }
                })
                return { ...state, images: [...updateImageArray], ...isAnySelected ? { selectedImage: undefined }:{} }
            }
            return state
        }
        case ACTIONS_CLEAR_ALL:
            return {isCopyrightCompliant: state.isCopyrightCompliant}
        case ACTIONS_UPSERT_IMAGE: {
            let id = action.payload.id
            let index = state.images?.findIndex((item) => {
                log({ id, existing: item.id, freshData: action.payload }, 'upserting')
                return item.id === id
            })
            let images = []
            if (state.images && index !== undefined && (index + 1)) {
                images = [...state.images]
                images[index] = { ...state.images[index], ...action.payload }
            } else {
                images = [...state.images || [], action.payload]
            }
            return { ...state, images }
        }
        case ACTIONS_SET_IMAGES_COPYRIGHT_COMPLIANT:{
            return {...state, isCopyrightCompliant: true}
        }
        default:
            return state
    }

}


export const ACTIONS_UPSERT_IMAGE = "UPSERT_IMAGE"
export const ACTIONS_REMOVE_IMAGE = "REMOVE_IMAGE"
export const ACTIONS_SELECT_IMAGE = "SELECT_IMAGE"
export const ACTIONS_DESELECT_IMAGE = "DESELECT_IMAGE"
export const REMOVE_SEGMENT_IMAGES = "REMOVE_SEGMENT_IMAGES"
export const ACTIONS_UPDATE_IMAGE = "UPDATE_IMAGE"
export const ACTIONS_CLEAR_ALL = "CLEAR_ALL"
export const ACTIONS_ROTATE_SELECTED_IMAGE = "ROTATE_SELECTED_IMAGE"
export const ACTIONS_SET_IMAGES_COPYRIGHT_COMPLIANT = "SET_IMAGES_COPYRIGHT_COMPLIANT"
