import React from "react"
import Anime from "../Anime/Anime"

import styles from "./spinner.module.scss"

/**
 * Preloader
 * @component
 * @param {object} props
 * @param {boolean} props.active - state
 * @param {string} props.container
 * @example
 * return (
 *  <Spinner
 *    active={false}
 * />
 * )
 */
const Spinner = props => {
  const {active = true, container = ''} = props
  const settings = {
    active,
    timeout: 200,
    enterStyle: {opacity: 1, position: "fixed", zIndex: 9999},
    inStyle: {opacity: 1, position: "fixed", zIndex: 9999},
    outStyle: {opacity: 0, position: "fixed", zIndex: 9999},
  }

  return (
    <Anime settings={settings}>
      <div className={`${styles.container} ${container}`}>
        <div className={styles.container_item}>
          <img src="/img/icons/hourglass.svg" alt="hourglass" />
        </div>
      </div>
    </Anime>
  )
}

export default Spinner
