import React from "react"
import {Switch, Route} from "react-router-dom"
// import Scen from "../components/Scen/Scen"
import Product from "../views/Product"
import Error from "../components/Error/Error"

/**
 * Aplication component render when auth in fb was true
 * @component
 * @example
 * return (
 *  <Application />
 * )
 */
const Application = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Product /> 
      </Route>
      <Route>
        <Error text={"ERROR: 404, Page not found"} />
      </Route>
    </Switch>
  )
}

export default Application
