export default {
  SET_APIKEY: "SET_APIKEY",

  SET_SPINNER: "SET_SPINNER",

  SET_PRODUCT: "SET_PRODUCT",
  SET_PRODUCTS: "SET_PRODUCTS",

  GET_FB_DATA: "GET_FB_DATA",

  ADD_PART: "ADD_PART",
  REMOVE_PART: "REMOVE_PART",
  SET_ACTIVE_PART: "SET_ACTIVE_PART",
  CLEAR_ACTIVE_PART: "CLEAR_ACTIVE_PART",
  CHANGE_PART_LENGTH: "CHANGE_PART_LENGTH",
  CLEAR_PARTS: "CLEAR_PARTS",
  CLEAR_IMAGES: "CLEAR_IMAGES",

  ADD_FABRIC_SEGMENT: "ADD_FABRIC_SEGMENT_FROM_ARRAY",
  REMOVE_FABRIC_SEGMENT: "REMOVE_FABRIC_SEGMENT_FROM_ARRAY",
  ADD_IMG_TO_PART: "ADD_IMG_TO_PART",
  ADD_IMAGES_TO_PART: "ADD_IMAGES_TO_PART",
  REMOVE_IMAGE_FROM_PART: "REMOVE_IMAGE_FROM_PART",
  // REMOVE_IMG_TO_PART: "REMOVE_IMG_TO_PART",
  TOGGLE_IMAGE_MENU: "TOGGLE_IMAGE_MENU",
  CHANGE_IMAGE_SIZE: "CHANGE_IMAGE_SIZE",
  CHANGE_IMAGE_SIZE_CMS_BY_WIDTH: "CHANGE_IMAGE_SIZE_CMS_BY_WIDTH",
  CHANGE_IMAGE_SIZE_CMS_BY_HEIGHT: "CHANGE_IMAGE_SIZE_CMS_BY_HEIGHT",
  SET_IMAGE_REPEAT_TYPE: "SET_IMAGE_REPEAT_TYPE",

  TOGGLE_LIBRARY_MENU: "TOGGLE_LIBRARY_MENU",
  GET_IMGS_LIB: "GET_IMGS_LIB",

  TOGGLE_TEXT_MENU: "TOGGLE_TEXT_MENU",
  ADD_TEXT_TO_PART: "ADD_TEXT_TO_PART",
  // REMOVE_TEXT_TO_PART: "REMOVE_TEXT_TO_PART",

  SET_ACTIVE_ELEMENT: "SET_ACTIVE_ELEMENT",
  SET_CURRENT_IMG: "SET_CURRENT_IMG",
  SET_MEDIA_LIB_TITLE: "SET_MEDIA_LIB_TITLE",

  ADD_IMAGE_LIBRARY: "ADD_IMAGE_LIBRARY",
}
