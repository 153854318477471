/**
 * Fonts constants
 * @file
 */
export default [
  {value: "Abril Fatface", label: "Abril Fatface"},
  {value: "Arbutus", label: "Arbutus"},
  {value: "Baloo", label: "Baloo"},
  {value: "Gloria Hallelujah", label: "Gloria Hallelujah"},
  {value: "Kaushan Script", label: "Kaushan Script"},
  {value: "Lobster", label: "Lobster"},
  {value: "Marcellus SC", label: "Marcellus SC"},
  {value: "Monoton", label: "Monoton"},
  {value: "Pacifico", label: "Pacifico"},
  {value: "Righteous", label: "Righteous"},
  {value: "Shrikhand", label: "Shrikhand"},
  {value: "Trocchi", label: "Trocchi"},
  {value: "Cinzel", label: "Cinzel"},
  {value: "Fjalla One", label: "Fjalla One"},
  {value: "Times New Roman", label: "Times New Roman"},
]
