import React, { useCallback, useEffect, useRef, useState } from 'react';
import './styles.scss';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { debounce } from 'throttle-debounce';

export type DimensionSliderProps = {
    min: number
    max: number
    currentValue: number
    onSliderValueChanged: (value: number) => void
}

export const DimensionSlider = (props: DimensionSliderProps) => {

    const { min, max, currentValue, onSliderValueChanged = () => { } } = props

    const [sliderValue, setSliderValue] = useState(min);
    const sliderValueListener = useRef(onSliderValueChanged);

    sliderValueListener.current = onSliderValueChanged;

    useEffect(()=>{
        setSliderValue(currentValue)
    },[currentValue])

    const debounceFunc = useCallback(debounce(100, (value: number) => {
        sliderValueListener.current(value);
    }), []);

    return <div className="sliderDiv">
        min
        <div className="slider">
            <Slider min={min} max={max} value={sliderValue} onChange={(value) => {
                if (!Array.isArray(value)) {
                    setSliderValue(value);
                    debounceFunc(value)
                }
            }}
            />
        </div>
        max
    </div>
}