import React from 'react'
import alertify from "alertifyjs"
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ACTIONS_CLEAR_ALL } from '../../redux/reducers/images'
import { setSpinner } from '../../redux/actions/spinner'
import { clearImages } from '../../redux/actions/parts'
import { setProduct } from '../../redux/actions/product'
import styles from "./clearall.module.scss"

interface IClearAll {
    clearSelections: () => void
}

export const ClearAllButton = (props: IClearAll) => {

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const product = useSelector((state:any) => state.product)
    const imgCount = useSelector((state:any) => state.canvasImages.images && state.canvasImages.images.length)
    const isSplit = useSelector((state:any) => state.settings?.configuration?.split)

    return <button disabled={!imgCount} onClick={() => {
        alertify.confirm(
            t("editor.alert_warning_title2"),
            t("editor.ask_clear"),
            () => {
                dispatch({ type: ACTIONS_CLEAR_ALL })
                dispatch(setSpinner(true))
                // dispatch(clearParts())
                dispatch(clearImages(isSplit))
                dispatch(setProduct({ ...product }))
                props.clearSelections()
                dispatch(setSpinner(false))
            },
            () => {
                return
            },
        ).set('labels', { ok: t("i18n.yes"), cancel: t("i18n.no") })
    }} className={styles.clearAllButton} style={{ opacity: imgCount ? 1 : 0.5 }}>
        <img src="/img/icons/trash_icn.svg" alt="Delete selected image" className="actionIcon" />
        <span>{t("editor.clear_all")}</span>
    </button>
}