/**
 * Application entry point
 */

// Load application styles
import * as THREE from "three"
import Viewer3D from "./View3D/Viewer3D";
import fitCameraToObject from "./lib/FitToObject.js"
import JSZip from "jszip"
import GPrint from "./lib/GPrint"
// import $ from "jquery"
import alertify from "alertifyjs"
import * as CRYPTO_JS from "crypto-js"
import { storage } from "../../../../../../firebase"
import anime from "animejs"
import translate from "./translate"
import glo from "./View3D/glo";
import { log } from "../../../../../../utilities/logger"


//-------------- Local variables ----------------------
var selected_logo = -1
// test checkin
export default class Viewer extends Viewer3D {
    constructor(is2D, props, node, info) {
        super(is2D, props, node, info);

        glo.alertify = alertify;

        // =====================================START INIT FOR Blinkinig and Teaser
        this.initState = localStorage.getItem("gBlink")
        if (!this.initState) {
            localStorage.setItem("gBlink", "iwasheare,1")
        } else {
            if (!!parseInt(this.initState.split(",")[1])) {
                localStorage.setItem(
                    "gBlink",
                    `iwasheare,${parseInt(this.initState.split(",")[1]) + 1}`,
                )
            } else {
                localStorage.setItem("gBlink", "iwasheare,1")
                this.initState = undefined
            }
        }
    }

    toggleFlatModel(active) {
        const { camera, controls } = this
        controls.reset();
        if (active) {
            this.mainModel.visible = false
            this.flatModel.visible = true
            controls.enableRotate = true
            fitCameraToObject({
                camera,
                controls,
                selection: [this.flatModel],
                fitRatio: window.d2mode ? 1 : 1.2,
            })

            /*controls.saveState()
            window.camera = camera
            window.controls = controls
            this.initialCameraPos = camera.position*/


        } else {
            this.mainModel.visible = true
            this.flatModel.visible = false
            controls.enableRotate = true
        }

        return this.flatModel.visible
    }

    getScreenShots(callback) {
        this.selectLogo(null);
        return new Promise((resolve, reject) => {
            this.controls.reset()
            let screenshotPromises = []
            let screenshotsFlatPromises = []

            //Guy skip in case there is only 2D
            if (global.api.viewer.props.product.modelFbx!=undefined)
            {
            this.camera.position.copy(this.initialCameraPos)
            Object.keys(this.screenshotsPositions).forEach(key => {
                screenshotPromises.push(
                    () =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                               
                                    if (key === "iso") {
                                        //this.camera.position.copy(this.initialCameraPos)
                                        var sphericalCoord = new THREE.Spherical()
                                        sphericalCoord.setFromVector3(camera.position)
                                        sphericalCoord.phi = THREE.Math.degToRad(45)
                                        sphericalCoord.theta = THREE.Math.degToRad(45)

                                        var vec = new THREE.Vector3().setFromSpherical(sphericalCoord)
                                        camera.position.set(vec.x - 20, vec.y + 30, vec.z)
                                    } else {
                                        this.camera.position.copy(this.initialCameraPos)
                                        this.mainModel.rotation.y -= Math.PI / 2
                                    }

                                    // //Guy
                                    //this.mainModel.rotation.y -= Math.PI / 2
                                    setTimeout(() => {
                                        let screen = {}
                                        screen[key] = this.renderer.domElement
                                            .toDataURL("image/jpeg", [0.0, 1.0])
                                            .split(",")[1]
                                        resolve(screen)
                                    }, 100)
                                
                            }, 100)
                        }),
                )
            })
        }

            const { renderer, camera, controls } = this

            let flatModelOpened = false

            if (this.flatModelExist) {
                Object.keys(this.screenshotsPositions).forEach(key => {
                    screenshotsFlatPromises.push(
                        () =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    this.flatModel.rotation.y += Math.PI / 2
                                    setTimeout(() => {
                                        let screen = {}
                                        screen["flat-" + key] = this.renderer.domElement
                                            .toDataURL("image/png", 1)
                                            .split(",")[1]
                                        resolve(screen)
                                    }, 100)
                                }, 100)
                            }),
                    )
                })
            }

            const serial = funcs =>
                funcs.reduce(
                    (promise, func) =>
                        promise.then(result =>
                            func().then(Array.prototype.concat.bind(result)),
                        ),
                    Promise.resolve([]),
                )
            //Guy om case only 2D exists
            if (global.api.viewer.props.product.modelFbx!=undefined)
            {
                renderer.setSize(1080, 1500)
                camera.aspect = 1080 / 1500
                camera.updateProjectionMatrix()
            
            

                fitCameraToObject({
                    camera,
                    controls,
                    selection: [this.mainModel],
                    fitRatio: 1.1,
                })
            }
            this.renderer.setClearColor(0xf2f4f6, 1) // the default
        

            serial(screenshotPromises).then(async arrayOfScreenshots => {
                this.renderer.setClearColor(0xf2f4f6, 0) // the default

                let flatScreenshots = []
                if (this.flatModelExist) {
                    flatModelOpened = this.flatModel.visible

                    renderer.setSize(1080 * 2, 1500 * 2)
                    camera.aspect = (1080 * 2) / (1500 * 2)
                    camera.updateProjectionMatrix()

                    this.toggleFlatModel(true)
                    fitCameraToObject({
                        camera,
                        controls,
                        selection: [this.flatModel],
                        fitRatio: 1.2,
                    })
                    flatScreenshots = await serial(screenshotsFlatPromises)

                    flatScreenshots.forEach(fScreen => {
                        arrayOfScreenshots.push(fScreen)
                    })
                }
                if (this.flatModelExist && !flatModelOpened) this.toggleFlatModel(false)
                this.updateRendererSize()

                this.controls.reset()
                
                const zip = new JSZip()

                let logosList = this.info.getAllLogos()
                let filteredLogos = []

                if (logosList.length) {
                    logosList.forEach(logo => {
                        let found = false
                        filteredLogos.forEach(fLogo => {
                            if (fLogo.org.src === logo.org.src) {
                                logo.imagePath = fLogo.imagePath
                                found = logo
                            }
                        })
                        if (!found) {
                            filteredLogos.push(logo)

                            logo.imagePath = `logos/image-${(
                                Math.random() * 100000
                            ).toFixed()}.${logo.org.src
                                .split(",")[0]
                                .split(";")[0]
                                .split("/")[1]
                                }`

                            zip.file(logo.imagePath, logo.org.src.split(",")[1], {
                                base64: true,
                            })
                        }
                    })
                }

                let objectInfo = {
                    garmentName: this.dataset.name,
                    garmentSize: this.dataset.size,
                    parts: [],
                }

                this.info.pieces.forEach(piece => {
                    let info = {
                        name: piece.name,
                        displayName: piece.displayName,
                        color: piece.color,
                        uvSize: piece.duv,
                        bleeding: piece.bleeding,
                        logos: [],
                        defaultUV: piece.uv,
                    }

                    piece.logos.forEach(logo => {
                        info.logos.push({
                            width: Math.abs(logo.cx * logo.scaleX),
                            height: Math.abs(logo.cy * logo.scaleY),
                            angle: logo.angle ? parseFloat(logo.angle) : 0,
                            scale: logo.rate ? parseFloat(logo.rate) : 0,
                            mirrorX: Math.sign(logo.scaleX),
                            mirrorY: Math.sign(logo.scaleY),
                            scaleX: logo.scaleX,
                            scaleY: logo.scaleY,
                            isText: logo.isText,
                            isRepeat: logo.isRepeat,
                            UVPosition: {
                                x: logo.px - (logo.cx * (Math.abs(logo.scaleX) - 1.0)) / 2,
                                y: logo.py - (logo.cy * (Math.abs(logo.scaleY) - 1.0)) / 2,
                            },
                            hotspotID: logo.hotspot,
                            src: logo.imagePath,
                        })
                    })
                    objectInfo.parts.push(info)
                })

                const KEY = "%D*G-KaPdSgVkYp2s5v8y/B?E(H+MbQe"
                const IV = "0000000000000000"

                let encJson = CRYPTO_JS.AES.encrypt(
                    JSON.stringify(objectInfo),
                    CRYPTO_JS.enc.Utf8.parse(KEY),
                    {
                        iv: CRYPTO_JS.enc.Utf8.parse(IV), // parse the IV
                        padding: CRYPTO_JS.pad.Pkcs7,
                        mode: CRYPTO_JS.mode.CBC,
                    },
                )

                zip.file("info.json", encJson.toString())
                //zip.file("info.json", JSON.stringify(objectInfo))

                let screenshot = {}

                arrayOfScreenshots.forEach(item => {
                    let key = Object.keys(item)[0]
                    //Guy
                    //if (key === "front" && !this.flatModelExist) {
                    if (!this.flatModelExist) {
                        screenshot[key] = item[key]
                    } else if (this.flatModelExist) {
                        if (key === "flat-front" || key === "flat-back" || key === "front")
                            screenshot[key] = item[key]
                    }
                    if (key.startsWith("flat-")) {
                        zip.file(`${key}-3d.png`, item[key], { base64: true })
                    } else {
                        zip.file(`${key}-3d.jpeg`, item[key], { base64: true })
                    }
                })

                zip.generateAsync({ type: "blob" }).then(content => {
                    // saveAs(content, 'test.zip');

                    var files = new File([content], "customizer.zip")
                    var form_data = new FormData()
                    form_data.append("file", files)

                    let file_name

                    storage
                        .ref()
                        .child(`${window.user_dir}/zip_storage/`)
                        .listAll()
                        .then(res => {
                            let namesNumberArr = res.items.map(item =>
                                parseInt(item.name.split(".")[0]),
                            )
                            let maxName = Math.max(...namesNumberArr)

                            file_name = res.items.length !== 0 ? maxName + 1 : 1
                            log("png file: ", file_name)
                        })
                        .then(() =>

                            //Instead of storage, HTTP POST the entire ZIP to function, i.e.: https://ev.firebase.com/SaveZip
                            storage
                                .ref()
                                .child(`${window.user_dir}/zip_storage/${file_name}.zip`)
                                .put(files)
                                .then(res => {
                                    let data = {
                                        screenshot,
                                        content: res.metadata,
                                    }

                                    resolve(data)
                                })
                                .catch(err => log(err)),
                        )
                        .catch(err => log(err))
                })
            })
        })
    }

    initMainModel() {
        const { initialModel, modelDataUrl, flatModelUrl } = this.props

        this.setMainModel(initialModel, modelDataUrl, flatModelUrl)
    }

    teaser = () => {
        let oldP = { ...this.camera.position }
        this.camera.position.set(3000, -1000, -1000)
        anime({
            targets: this.camera.position,
            x: oldP.x,
            y: oldP.y,
            z: oldP.z,
            easing: "easeInOutQuart",
            loop: false,
            direction: "alternate",
            duration: 2000,
        })
    }

    blinking = () => {
        this.animationArr = []

        setTimeout(() => {
            // this.sides.forEach(item => {
            //   this.animationArr.push(
            //     anime({
            //       targets: item.material.color,
            //       b: 5,
            //       easing: "easeInOutQuart",
            //       loop: true,
            //       direction: "alternate",
            //       duration: 1500,
            //     }),
            //   )
            // })
            this.props.showClickLabel(true)
        }, 2000)
    }

    blinkingClear = () => {
        this.animationArr.forEach(item => item.pause())

        // this.sides.forEach(item => {
        //   anime({
        //     targets: item.material.color,
        //     b: 1,
        //     easing: "easeInOutQuart",
        //     loop: false,
        //     direction: "alternate",
        //     duration: 1000,
        //   })
        // })

        this.props.showClickLabel(false)
    }

    resizeCallback = () => {
        if (this.selectedAction === "move" || this.selectedAction === "rotate") {
            return
        }

        let maxPercent = 150

        if (selected_logo.rate <= maxPercent) {
            selected_logo.scaleWarning = false
        }

        switch (this.props.scaleErrorHandler) {
            case "warning":
                if (selected_logo.rate > maxPercent) {
                    let alertWarning = alertify
                        .myConfirm(translate.t("i18n.scale_warning").res)
                        .set({
                            onok: () => {
                                GPrint.resizeLogo(selected_logo, selected_logo.rate)
                                selected_logo.scaleWarning = true
                                this.renderMaterial()
                            },
                            oncontinue: () => {
                                GPrint.resizeLogo(selected_logo, maxPercent)
                                selected_logo.scaleWarning = false
                                this.renderMaterial()
                            },
                            oncancel: () => {
                                GPrint.resizeLogo(selected_logo, 100)
                                selected_logo.scaleWarning = false
                                this.renderMaterial()
                            },
                            labels: {
                                ok: translate.t("i18n.yes").res,
                                cancel: translate.t("i18n.no").res,
                            },
                        })
                    alertWarning.setHeader(translate.t("i18n.warning").res)
                }

                break
            case "blocking":
                if (selected_logo.rate > maxPercent) {
                    alertify
                        .alert(
                            translate.t("i18n.warning").res,
                            translate.t("i18n.scale_blocking").res,
                            () => {
                                GPrint.resizeLogo(selected_logo, 100)
                                selected_logo.scaleWarning = false
                                this.renderMaterial()
                            },
                        )
                        .setting({
                            label: translate.t("i18n.close").res,
                        })
                }
                break

            default:
                return
        }


    }
    resizeCallbackrenderer = () => {
        this.selectedLogoMoved();
        this.renderMaterial();
    }
}
