import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CanvasGrid } from '../../App'
import { IFabric, IFabricSettings } from '../../redux/reducers/fabric'
import { setUpFabric } from '../../redux/reducers/fabric/actions'
import { ISettings } from '../../redux/reducers/settings'
import { log } from '../../utilities/logger'
import { Fabric } from "./Fabric"
import { IScen } from './ScenSegment'

export interface IFabricCanvas extends IScen{
    containerWidth: number
    productWidth: number
    unit: 'YARD'|'METER'|'DECIMETER'
}

export const FabricCanvas = (props:IFabricCanvas) => {

    const{containerWidth, productWidth, unit, ...scenProps} = props
    const dispatch = useDispatch()
    const settings: IFabricSettings = useSelector((state: any) => state.fabric.settings) 
    const productHeight = useSelector((state: any) => (state.settings as ISettings)?.display?.currentLength)
    const { grid, fabricDimensionsPx } = settings || {}
    const canvasGrid = useContext(CanvasGrid) || {}

    useEffect(()=>{

        if (containerWidth && productWidth && productHeight) {
        dispatch(setUpFabric({
            fabricCanvasWidthPx: containerWidth,
                grid: grid || {
                    columns: (canvasGrid.columns||1),
                    rows: (canvasGrid.rows || 1)
                },
            productWidth,
            productHeight,
            unit
        }))
        }

    }, [containerWidth, productWidth, productHeight, canvasGrid])

    log({settings}, 'FABRIC GRID')
    log({containerWidth, productWidth, productHeight, canvasGrid}, 'ADDING SCEN SEGMENT')

    const isDimensionValueValid = (settings?.fabricDimensionsPx?.heightPx > 0) && (settings?.fabricDimensionsPx?.widthPx > 0)

    return isDimensionValueValid ? <Fabric
        widthPx={fabricDimensionsPx.widthPx}
        heightPx={fabricDimensionsPx.heightPx}
        gridColumns={grid.columns} gridRows={grid.rows} {...scenProps} /> : null
}