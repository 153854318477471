import * as THREE from "three";
import Info from "./Info";
import Viewer3D from "./Viewer3D";
import SVGMesh from "./SVGMesh";


export default class Viewer2D extends Viewer3D {
    protected mainModel2D: SVGMesh;

    constructor(is2D: boolean, props: any, node: any, info: Info) {
        super(is2D, props, node, info);
        this.defaultColor = 0xf2f4f6;

        this.rotateEnabled = false;
        this.mainModel2D = new SVGMesh();
    }
    protected initLight(): void {
        let light = new THREE.DirectionalLight("#ffffff", 0.55);
        light.position.set(0.4, 0, 1);
        this.scene.add(light);

        light = new THREE.DirectionalLight("#ffffff", 0.55);
        light.position.set(-0.4, 0, -1);
        this.scene.add(light);

        let alight = new THREE.AmbientLight("#ffffff", 0.55);
        this.scene.add(alight);
    }
    protected onDataSetLoaded(modelUrl: string): void {
        modelUrl = this.props?.product?.d2;
        if (!modelUrl)
            return;
        super.onDataSetLoaded(modelUrl);
    }

    protected onObjectLoaded(_object: THREE.Group): void {
        this.mainModel2D.ProccessGroup(this.dataset, _object);
        super.onObjectLoaded(this.mainModel2D);
    }
}
