import { combineReducers } from "redux"

import { apikey } from "./apikey"
import { spinner } from "./spinner"
import { product, products, currentImg, mediaLibTitle } from "./product"
import { parts } from "./parts/index.ts"
import { imgslib } from "./imgslib"
import { fbdata } from "./fbdata"
import { activeelement } from "./activeelement"
import { canvasImages } from "./images"
import { settings } from './settings'
import {fabric} from './fabric'
import {viewer3DConfig} from './view3dsetting.reducer.ts'

export default combineReducers({
  apikey,
  spinner,
  product,
  parts,
  products,
  imgslib,
  fbdata,
  activeelement,
  currentImg,
  mediaLibTitle,
  canvasImages,
  settings,
  fabric,
  viewer3DConfig
})
