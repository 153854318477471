/**
 * init react app
 * @file
 */
import React from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import {BrowserRouter} from "react-router-dom"

import "./index.css"
import "alertifyjs/build/css/alertify.min.css"
import "alertifyjs/build/css/themes/default.min.css"

import App from "./App"
import { FabricEditor } from './AppCustom'

import store from "./redux/store"
import { Internationalization } from "./libs/translation"

export const USE_CUSTOM_APP = false //true

const noop = () => {}
if (process.env.NODE_ENV !== "development") {
  console.log = noop
  console.warn = noop
  console.groupCollapsed = noop
}

if (USE_CUSTOM_APP) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Internationalization>
            <FabricEditor />
          </Internationalization>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>, document.getElementById("root"))
} else {
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Internationalization>
          <App />
        </Internationalization>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
)
}
