import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { ResizeControls } from "./ResizeControls";

import fitCameraToObject from "../lib/FitToObject.js"
import { isPickColorMode, PICK_COLOR_CURSOR, rgbToHex } from "../common"
import GPrint from "../lib/GPrint"
//import translate from "../translate"

import glo from "./glo";
import Tab from "./Tab"
import Info from "./Info";
import Piece from "./Piece";
import Logo from "./Logo";
import Elapse from "./Elapse";
import OffScreen from "./OffScreen"
import Hotspots from "./Hotspots"
import ImageTexture from "./ImageTexture";
import JSZip, { JSZipObject } from "jszip";
import * as CRYPTO_JS from "crypto-js";
import { createTemplate, getJsonInfo } from "./createTemplate";
import { log } from "../../../../../../../utilities/logger"

export default class Viewer3D {
    //#region memebers
    protected readonly is2D: boolean;
    protected readonly props: any;
    protected readonly node: any;
    protected readonly canvas: any;
    protected readonly ngb: any;
    protected readonly T3: any = THREE;
    protected readonly renderer: THREE.WebGLRenderer;
    protected readonly loadManager: THREE.LoadingManager = new THREE.LoadingManager();
    protected readonly fileLoader = new THREE.FileLoader();
    protected readonly textureLoader: THREE.TextureLoader = new THREE.TextureLoader();
    protected readonly imageLoader: THREE.ImageLoader = new THREE.ImageLoader(this.loadManager);
    protected readonly fbxLoader: FBXLoader = new FBXLoader();
    protected readonly offscreen: OffScreen;
    protected readonly scene: THREE.Scene;
    protected readonly camera: THREE.PerspectiveCamera;
    protected readonly controls: OrbitControls;
    protected readonly raycaster: THREE.Raycaster;
    protected readonly resizeControls: ResizeControls;
    protected readonly hotspots: Hotspots;

    protected defaultColor: number;
    protected screenshotsPositions: { [id: string]: THREE.Vector3 };
    protected tabs: Tab[] = [];

    protected rotateEnabled: boolean = true;
    protected snapToHotspots: boolean = true;
    protected snapToHotlines: boolean = true;

    protected crect: any;
    protected dragX: number = NaN;
    protected dragY: number = NaN;
    protected ncs: THREE.Vector2 = new THREE.Vector2();
    protected prevMouse: THREE.Vector2 = new THREE.Vector2();
    protected currMouse: THREE.Vector2 = new THREE.Vector2();
    protected startClick: THREE.Vector2 = new THREE.Vector2();
    protected grabbingMode: boolean = false;
    protected initialCameraPos: THREE.Vector3 | null = null;

    protected dataset: any = null;
    protected relatios: any = null;
    protected mainModel: THREE.Group | null = null;
    protected info: Info;
    protected meshes: THREE.Mesh[] = [];
    protected fabricTexturesList: THREE.Texture[] = [];
    protected fabricMaterialsList: any[] = [];
    protected flatModelExist: boolean = false;

    protected mouseDownPoint: THREE.Vector2 = new THREE.Vector2();
    //#endregion

    constructor(is2D: boolean, props: any, node: any, info: Info) {
        let e = new Elapse("Construct ");
        this.is2D = is2D;
        this.props = props;
        this.node = node;
        this.node.viewer = this;
        this.info = info;
        this.screenshotsPositions = {
            left: new THREE.Vector3(5.0754376055703005, 945.9494682497618, 1761.3330299976237),
            back: new THREE.Vector3(-1758.754338772109, 945.9494682497618, -163.8971022583737),
            right: new THREE.Vector3(380.67837678306876, 945.9494682497618, -1739.559814890284),
            front: new THREE.Vector3(1770.760444534566, 945.9494682497618, -141.03431298406935),
            iso: new THREE.Vector3(5.0754376055703005, 945.9494682497618, 1761.3330299976237)
        };

        this.renderer = new THREE.WebGLRenderer({
            logarithmicDepthBuffer: true,
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true,
        });

        glo.imageLoader = this.imageLoader;
        glo.unit = props.minResolution / 2.54;
        glo.MAX_TEXTURE_SIZE = this.renderer.getContext().getParameter(this.renderer.getContext().MAX_TEXTURE_SIZE);
        this.renderer.setClearColor(0xf2f4f6, 1) // the default

        this.loadTabs();

        this.fbxLoader = new FBXLoader();
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(45, node.offsetWidth / node.offsetHeight, 0.1, 1000);
        this.camera.position.set(0, 0, 5);

        this.offscreen = new OffScreen(this.is2D, props);

        this.controls = new OrbitControls(this.camera, node);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.05;
        this.controls.rotateSpeed = 0.75;
        this.controls.enablePan = true;
        this.controls.screenSpacePanning = true;
        this.controls.panSpeed = 0.5;

        this.canvas = node.appendChild(this.renderer.domElement);
        this.canvas.viewer = this;
        this.canvas.setAttribute("data-name", "canvas");
        this.canvas.addEventListener("dragover",
            (event: any) => {
                this.dragX = event.offsetX;
                this.dragY = event.offsetY
            },
            false
        );

        this.crect = this.canvas.getBoundingClientRect();
        this.updateRendererSize();


        this.raycaster = new THREE.Raycaster();

        this.hotspots = new Hotspots(this.is2D);
        this.resizeControls = new ResizeControls(this.camera, node);
        this.resizeControls.addEventListener("change", this.onResizeChange);

        this.defaultColor = 0xf2f4f6;
        const { initialModel, modelDataUrl, flatModelUrl } = this.props;
        this.setMainModel(initialModel, modelDataUrl, flatModelUrl);

        this.render();

        window.addEventListener("resize", this.updateRendererSize);
        node.addEventListener("pointerdown", this.onMouseDown, false);
        node.addEventListener("pointermove", this.onMouseMove, false);
        node.addEventListener("pointerup", this.onMouseUp, false);
        window.addEventListener("keyup", this.onKeyUp.bind(this), false);

        e.End();
    }

    public updateCanvasView() {
        this.updateRendererSize();
    }

    public async getJsonInfo(model: any, image: string) {

        try {

            return await getJsonInfo(model, image)

        } catch (error) {
            throw error
        }

    }

    public async getDesignDictionary(model: any, image: string) {

        try {

            return await createTemplate(model, image, false)

        } catch (error) {
            throw error
        }

    }
    public async coverAll(model: any, image: string, imagePlacement: any, dictionary?: any) {
        //Guy Support Repeat in All over
        var inRepeatMode = false;
        if (this.selectedLogo) {
            if (this.selectedLogo.isRepeat) {
                inRepeatMode = true;
            }
        }
        //Guy Support Repeat in All over
        const designDictionary = dictionary || await createTemplate(model, image, inRepeatMode, imagePlacement);

        const info: any = designDictionary.infoJson;
        const logos: { [id: string]: string } = designDictionary.placements;
        this.CreateLogos(info, logos, false, false);
        return designDictionary;
    }

    public getPointer(event?: any) {
        return glo.getPointer(this.node, event);
    }

    // protected async onModelLoaded(): Promise<void> {
    protected onModelLoaded() {
        if (this.props.designDictionary) {
            const info: any = this.props.designDictionary?.infoJson;
            const logos: { [id: string]: string } = this.props.designDictionary?.placements;
            this.CreateLogos(info, logos, true, false);
            return;
        }

        if (this.props.logosZipUrl) {
            this.loadUrl(this.props.logosZipUrl)
            return;

        } else if (this.props.logosZipFile) {
            this.loadLogoZipFile(this.props.logosZipFile);
            return;
        }

        this.onReady();
    }
    public loadZip(zip: string) {
        this.loadLogoZipFile(zip);
    }
    protected onReady(): void {
        if (this.props.onReady) { setTimeout(() => { this.props.onReady(); }, 400) }
    }

    protected loadLogoZipFile(path: string): void {
        try {
            this.fileLoader.setResponseType("blob");
            this.fileLoader.load(path, (data: any) => {
                const jzip = new JSZip();
                jzip.loadAsync(data).then((zip) => {
                    const jfile = zip.file("info.json");
                    if (!jfile) {
                        this.onReady();
                        return;
                    }
                    zip.file("info.json")?.async("text").then((v: any) => {
                        this.SetLogos(v, zip, true);
                    });
                });
            });
        } catch (error) {
            this.onReady();
        }
    }

    protected loadUrl(url: string): void {
        try {
            let zipfile: JSZip | undefined = undefined
            fetch(url).then(function (response) {
                return response.blob();
            }).then((blob) => {
                const jzip = new JSZip();
                return jzip.loadAsync(blob)
            }).then((zip) => {
                zipfile = zip
                const jfile = zip.file("info.json");
                return jfile?.async('text')
            }).then((json) => {
                // decrypt json
                const KEY = "%D*G-KaPdSgVkYp2s5v8y/B?E(H+MbQe"
                const IV = "0000000000000000"
                if (!json) {
                    return;
                }
                var decrypted = CRYPTO_JS.AES.decrypt(json,
                    CRYPTO_JS.enc.Utf8.parse(KEY),
                    {
                        iv: CRYPTO_JS.enc.Utf8.parse(IV),
                        padding: CRYPTO_JS.pad.Pkcs7,
                        mode: CRYPTO_JS.mode.CBC
                    })

                log(decrypted.toString(CRYPTO_JS.enc.Utf8));

                zipfile?.file("info.json", decrypted.toString(CRYPTO_JS.enc.Utf8))

                zipfile?.file("info.json")?.async("text").then((v: any) => {
                    zipfile && this.SetLogos(v, zipfile, false);
                });
            })
        } catch (error) {
            this.onReady();
        }
    }

    protected SetLogos(js: string, zip: JSZip, processAddtoCart: boolean): void {
        const info = JSON.parse(js);
        let logoFiles: { [id: string]: JSZipObject } = {};
        for (let p in info.parts) {
            const part = info.parts[p];
            for (let l in part.logos) {
                const logo = part.logos[l];
                const src = logo.src;
                logoFiles[src] = (zip.file(src) || {}) as any;
            }
        }

        let logoPromisses: any[] = [];
        let logoIdx: { [id: string]: number } = {};
        for (let l in logoFiles) {
            const file = logoFiles[l];
            logoIdx[l] = logoPromisses.length;
            logoPromisses.push(file.async("base64"));
        }

        let logosData: { [id: string]: string } = {};
        Promise.all(logoPromisses).then(logos => {
            for (let l in logoIdx) {
                let ext = l.substr(l.lastIndexOf('.') + 1);
                if (!ext || ext.length <= 1)
                    ext = "blob";
                else
                    ext = "image/" + ext;

                logosData[l] = "data:" + ext + ";base64," + logos[logoIdx[l]];
            }

            this.CreateLogos(info, logosData, true, processAddtoCart);
        });
    }

    public loadJsonFile(jsonFile: any): void {
        const info: any = jsonFile?.infoJson;
        const logos: { [id: string]: string } = jsonFile?.placements;

        this.CreateLogos(info, logos, true, false);
    }
    protected refreshLogos(): void {
        if (this.props.onRefreshLogos)
            this.props.onRefreshLogos(this.info.getAllLogos())
    }
    protected CreateLogos(info: any, logos: { [id: string]: string }, includeZip: boolean, processAddtoCart: boolean): void {
        let piecePromisses: any[] = [];
        for (let p in info.parts) {
            log(p)
            const part = info.parts[p];
            const piece = this.info.Get(part.name);
            if (!piece)
                continue;
            piecePromisses.push(piece.Load(part, logos));
        }
        Promise.all(piecePromisses).then(pieces => {
            this.info.refresh(true);
            this.info.refreshLogos();
            this.onReady();

            if (includeZip) {
                this.props.logosZip(true);
                if (processAddtoCart) {
                    this.props.onLogosZipLoad();
                }
            }
        });
    }

    //#region Events
    protected onResizeChange = (event: any) => {
        if (!this.selectedLogo)
            return;

        switch (this.resizeControls.mode) {
            case "circle":
                this.updateLogo(this.selectedLogo, { angle: this.resizeControls.totAngle }, false, false);
                break;

            case "NW":
            case "NE":
            case "SW":
            case "SE":
                this.updateLogo(this.selectedLogo, { scale: this.resizeControls.totScale }, false, false);
                break;

            case "N":
            case "S":
                this.updateLogo(this.selectedLogo, { scaleY: this.resizeControls.totScaleY }, false, false);
                break;

            case "W":
            case "E":
                this.updateLogo(this.selectedLogo, { scaleX: this.resizeControls.totScaleX }, false, false);
                break;

            default:
                break;
        }

        this.info.refresh(false);
    }

    protected onLogoMoved(): void {
        if (!this.activeObject || !this.selectedLogo)
            return;

        if (this.snapToHotspots && this.activeObject.SnapToHotspots(this.is2D, this.selectedLogo))
            GPrint.reset_border(this.selectedLogo);
        if (this.snapToHotlines && this.activeObject.SnapToHotlines(this.is2D, this.selectedLogo))
            GPrint.reset_border(this.selectedLogo);
    }

    //#region Pointer Events
    protected onMouseDown = (event: any) => {
        if (1 !== event.which)
            return;
        const pointer = glo.getPointer(this.node, event);
        this.ncs.set(pointer.x, pointer.y);
        this.startClick.copy(this.ncs);
        this.grabbingMode = false;

        if (this.resizeControls.axis) {
            this.controls.enableRotate = false;
            this.prevLogo.Copy(this.selectedLogo);
            return;
        }
        if (isPickColorMode()) return // avoid to deselect current activeObject when pick-color mode

        if (!this.flatModelExist) {
            this.controls.enableRotate = this.rotateEnabled;
        }



        this.raycaster.setFromCamera(pointer, this.camera)
        const hits = this.raycaster.intersectObjects(this.meshes)

        if (this.props.onMouseDown) this.props.onMouseDown(event)

        if (hits.length) {
            const hit = hits[0]
            const piece = glo.Get(hit.object, "piece") as Piece;
            this.selectPart(piece, false);

            if (!this.activeObject)
                return

            let logos = this.activeObject.logos;

            const x = piece.X(hit.uv!);
            const y = piece.Y(hit.uv!);

            this.mouseDownPoint.set(x, y);
            this.prevLogo.Copy(this.selectedLogo);

            const logo = logos?.[GPrint.hitTopmostLogoIdx(logos, x, y)];
            if (logo) {
                this.controls.enableRotate = false;

                this.selectLogo(logo);

                this.activeObject.Highlight(false);
                this.prevLogo.Copy(this.selectedLogo);
                this.selectedAction = "move";

                if (this.props.onMouseUp)
                    this.props.onMouseUp(logo, event);

            } else {
                this.selectedAction = "";
                this.controls.enableRotate = this.rotateEnabled;
                this.selectLogo(null);
            }

            if (this.selectedAction) {
                switch (this.selectedAction) {
                    case "move":
                        this.setCursor("grabbing")
                        this.grabbingMode = true;
                        break;

                    case "delete":
                        //this.deleteLogo(this.selectedLogo)
                        break

                    default:
                        return
                }
            }

            this.info.refresh(false);

        } else {
            this.selectLogo(null);
            this.selectPart(null, false);
        }
    }
    protected onMouseMove = (event: any) => {
        const pointer = glo.getPointer(this.node, event);
        this.ncs.set(pointer.x, pointer.y);
        if (this.onSetCursor())
            return;

        this.prevMouse.copy(this.currMouse);
        this.currMouse.set(pointer.x, pointer.y);

        if (this.activeObject) {
            if (this.grabbingMode && this.selectedLogo)
                this.info.ShowResizeControls(false);
            this.raycaster.setFromCamera(pointer, this.camera)
            const hits = this.raycaster.intersectObjects(this.meshes)

            if (hits.length) {
                const hit = hits[0]
                const piece = glo.Get(hit.object, "piece") as Piece;
                const x = piece.X(hit.uv!);
                const y = piece.Y(hit.uv!);

                if (this.selectedAction) {
                    const deltaX = this.mouseDownPoint.x - x;
                    const deltaY = this.mouseDownPoint.y - y;

                    switch (this.selectedAction) {
                        case "move":
                            this.updateLogo(this.selectedLogo!, { px: this.prevLogo.px - deltaX, py: this.prevLogo.py - deltaY }, false, false);
                            break;

                        default:
                            return;
                    }
                    this.onLogoMoved();
                    this.info.refresh(false);
                }
            }
        }
    }
    protected onMouseUp = (event: any) => {
        const pointer = glo.getPointer(this.node, event);
        this.ncs.set(pointer.x, pointer.y);
        this.grabbingMode = false;
        if (this.activeObject && this.selectedLogo) {
            this.selectedLogoMoved();
            this.info.ShowResizeControls(true);

            if (false === this.prevLogo.Compare(this.selectedLogo))
                this.info.pushHistoryLogo(this.prevLogo, "update");
        }

        this.raycaster.setFromCamera(this.ncs, this.camera)
        const hits = this.raycaster.intersectObjects(this.meshes)

        if (!this.flatModelExist) {
            this.controls.enableRotate = this.rotateEnabled;
        }

        this.selectedAction = "";

        if (
            hits.length &&
            this.startClick.x === this.ncs.x &&
            this.startClick.y === this.ncs.y
        ) {
            this.info.refresh(false)
            if (this.props.onClick) {
                const hit = hits[0]
                const piece = glo.Get(hit.object, "piece") as Piece;

                if (isPickColorMode()) {
                    const rf = piece.resizeFactor;
                    const x = hit.uv!.x * piece.duv.x * rf;
                    const y = hit.uv!.y * piece.duv.y * rf;
                    let buff = new Uint8Array(1 * 1 * 4);
                    this.renderer.readRenderTargetPixels(piece.RenderTarget(this.is2D)!, x, y, 1, 1, buff);
                    piece.pickColor = rgbToHex(buff[0], buff[1], buff[2]);
                }
                this.props.onClick(piece, event)
            }
        }
        this.info.refresh(false);

        this.onSetCursor();
    }
    protected onKeyUp(event: any): void {
        if (true === event.ctrlKey) {
            switch (event.keyCode) {
                case 90:
                    this.info.undoHistory();
                    break;

                case 89:
                    this.info.redoHistory();
                    break;
            }
        }
    }
    //#endregion

    onSetCursor = () => {
        if (isPickColorMode()) {
            this.setCursor(PICK_COLOR_CURSOR)
            return true; // pick-color mode, so it is next returnable
        }
        if (this.resizeControls.axis) {
            switch (this.resizeControls.axis) {
                case 'circle':
                    let src = require("./assets/images/rotate.svg");
                    this.setCursor(`url('${src}') 12 12, auto`);
                    break;

                case 'N':
                case 'S':
                    this.setCursor("n-resize");
                    break;

                case 'E':
                case 'W':
                    this.setCursor("w-resize");
                    break;

                case 'NW':
                case 'SE':
                    this.setCursor("ne-resize");
                    break;

                case 'NE':
                case 'SW':
                    this.setCursor("nw-resize");
                    break;

                default:
                    break;
            }
            return true;
        }
        if (this.grabbingMode) {
            this.setCursor("grabbing") // while dragging(move)
            return false;
        }

        this.raycaster.setFromCamera(this.ncs, this.camera)
        const hits = this.raycaster.intersectObjects(this.meshes)

        if (hits.length) {
            const hit = hits[0];
            const piece = glo.Get(hit.object, "piece") as Piece;
            if (piece === this.activeObject) {
                this.setCursor("grab");
                return false;
            }
        }

        this.setCursor("default");
        return false;
    }

    protected setCursor(cursor: string): void {
        this.renderer.domElement.style.cursor = cursor
    }
    //#endregion

    //#region Model and pieces
    protected setMainModel(modelUrl: string, JSONUrl: string, flatModelUrl: string): void {
        const { scene } = this;

        for (let i = scene.children.length - 1; i >= 0; i--)
            scene.remove(scene.children[i]);

        for (let i = this.camera.children.length - 1; i >= 0; i--)
            this.camera.remove(this.camera.children[i]);
        this.initLight();

        this.meshes = [];
        this.mainModel = null;

        this.flatModelExist = !!flatModelUrl
        let texturePromisses = this.loadFabricTextures();
        Promise.all(texturePromisses).then(arrayOfMaterials => {
            this.fabricMaterialsList = arrayOfMaterials;
            fetch(JSONUrl).then(result => {
                result.json().then(dataset => {
                    //const dataset: any = JSONUrl;
                    this.dataset = dataset;
                    this.onDataSetLoaded(modelUrl);
                })
            })
        });
    }
    protected onDataSetLoaded(modelUrl: string): void {
        this.fbxLoader.load(modelUrl, async _object => { this.onObjectLoaded(_object); });
    }
    protected onObjectLoaded(_object: THREE.Group): void {
        const { scene, dataset } = this;
        this.mainModel = _object;
        scene.add(this.hotspots);
        scene.add(this.mainModel);
        scene.add(this.resizeControls);

        this.mainModel.rotation.set(this.props.defaultRotationX || 0, 0, 0)
        this.zoomCameraOnModel();
        this.centerCameraOnModel();

        this.mainModel.traverse(item => {
            let mesh = item as THREE.Mesh;
            if (mesh instanceof THREE.Mesh) {
                mesh.geometry.computeBoundingBox();
                mesh.geometry.computeBoundingSphere();
            }
        });

        let piecesData: any[] = dataset.pieces;
        piecesData.forEach(pieceData => {
            let mesh = this.scene.getObjectByName(pieceData.id) as THREE.Mesh;
            if (mesh) {
                let piece = this.info.Get(pieceData.id) || new Piece(this.props, pieceData);
                piece.SetMesh(this.is2D, mesh, this.fabricMaterialsList);
                piece.customizableRestrictions = pieceData.customizableRestrictions;
                piece.ClearTexture(this.is2D, this.renderer);
                this.info.Push(piece);
                this.meshes.push(mesh);
            }
        })

        this.relatios = dataset.Relatios
        this.onModelLoaded();


    }
    protected selectPart(part: Piece | null, dontHighlight: boolean, ngb: boolean = true): void {
        // unhighlight current selection
        if (this.activeObject)
            this.activeObject.Highlight(false, false);
        if (part !== this.activeObject)
            this.selectLogo(null);
        this.activeObject = part // set new selection
        // highlight new selection
        if (this.activeObject && !dontHighlight)
            this.activeObject.Highlight(true, true);

        this.hotspots.SetPiece(this.activeObject);
        if (ngb)
            this.ngb.selectPart(part, dontHighlight, false);
    }
    protected extractMeshes(is2D: boolean, pieces: Piece[]): THREE.Mesh[] {
        return pieces.reduce(function (a: THREE.Mesh[], b: Piece): THREE.Mesh[] {
            const mesh = b.Mesh(is2D);
            if (mesh)
                a.push(mesh);
            return a;
        }, []);
    }
    //#endregion    

    public isHit(offsetX: number, offsetY: number, event?: any) {
        const pointer = event ? glo.getPointer(this.node, event) : glo.scs2ncs(this.node, offsetX, offsetY, null, false);
        this.ncs.set(pointer.x, pointer.y);
        this.raycaster.setFromCamera(this.ncs, this.camera)
        const hits = this.raycaster.intersectObjects(this.meshes)

        return hits.length > 0
    }

    //#region Logos
    protected putLogo(imgUrl: string, offsetX: number, offsetY: number, cpyLogo: Logo | undefined, fileName?: string, pointerCaptured?: any): void {

        this.setCursor('progress')
        const pointer = pointerCaptured || glo.scs2ncs(this.node, offsetX, offsetY, null, false);
        this.ncs.set(pointer.x, pointer.y);

        this.raycaster.setFromCamera(this.ncs, this.camera);
        let meshes = this.extractMeshes(this.is2D, this.info.onlyColorPieces);
        const hits = this.raycaster.intersectObjects(meshes);

        if (hits.length) {
            const hit = hits[0];
            const piece = glo.Get(hit.object, "piece") as Piece;
            Logo.CreateLogo(imgUrl, piece, glo.Get(hit, "uv"), null, ((newlogo: Logo) => {
                this.selectPart(piece, true);
                //this.checkRelations();
                newlogo.src.dataset.fileName = fileName
                if (this.props.onNewLogo)
                    this.props.onNewLogo(this.info.getAllLogos(), newlogo)
                this.selectLogo(newlogo);
                this.info.refresh(false);
                this.info.pushHistoryLogo(newlogo, "new");

                if (cpyLogo) {
                    newlogo.isRepeat = cpyLogo.isRepeat
                    this.updateLogo(newlogo, cpyLogo);
                }
                this.setCursor('grabbing')
            }));
        }
    }

    protected replaceLogoImage(logo: Logo, imgUrl: string): void {
        const piece = logo.piece;
        if (!piece)
            return;
        ImageTexture.loadTexture(imgUrl, true, (hash: string, imageTex: ImageTexture) => {
            logo.hash = hash;
            this.selectPart(piece, true);
            //this.checkRelations();
            this.selectLogo(logo);
            this.info.refresh(false);
            this.info.pushHistoryLogo(logo, "update");
        });
    }

    // all size, rotate, mirror should some way go via this method
    // u pass the logo and the parameters you would like to change
    // if you want the snap not to work u add noSnap params set to true
    // angle: value in degrees
    // scale: the uniform scale of this logo, for example:
    //        logo is 800x600 and scale = 0.5 final logo size will be 400x300
    //        you cannot hurt the logo aspect ratio using this scale
    // px && py: position of logo in "pixels" of the piece. 0x0 means top left corner of the piece if it was a flat rectangle piece
    // scaleX && scaleY: these parameter hurt the aspect ratio of the original logo, aslo can cause a mirror effect
    //                   example: logo is 800x600 and scaleX = 0.5  final logo is 400x600
    //                            logo is 800x600 and scaleY = -0.5 final logo is 800x300 and fliped verticaly
    // width && height: not a good idea to use these, i write only for u (poorti)
    //                  will calclate the correct scaleX or scaleY according to the width or height


    protected updateLogo(logo: Logo, params: any, select: boolean = true, pushHisroty: boolean = true): void {
        if (pushHisroty)
            this.info.pushHistoryLogo(logo, "update");

        const snapRot = 2;
        const magentRot = 5;
        const magentAngles = [0, -90, 90, -180, 180, -270, 270, 360, -360];
        const snapScale = 10;
        const snapScaleS = 0.05;
        const magentS = 0.1;
        const magentScales = [-magentS, magentS, -1, 1, -1.5, 1.5, -2, 2];

        const snap = !(params?.noSnap);
        if (params?.angle)
            GPrint.rotateLogo(logo, snap ? glo.snap(glo.snapValues(params.angle, magentAngles, magentRot), snapRot) : params?.angle);

        if (params?.scale)
            GPrint.resizeLogo(logo, snap ? glo.snap(params.scale, snapScale, snapScale) : params.scale, this.props.scaleErrorHandler);

        if ((params?.px || params?.py)) {
            logo.px = params?.px * 1 || logo.px;
            logo.py = params?.py * 1 || logo.py;
        }

        if (params?.scaleY)
            logo.scaleY = snap ? glo.snap(glo.snapValues(params.scaleY, magentScales, magentS), snapScaleS) : params.scaleY;

        if (params?.scaleX)
            logo.scaleX = snap ? glo.snap(glo.snapValues(params.scaleX, magentScales, magentS), snapScaleS) : params.scaleX;

        if (params?.width) logo.Width = params.width;
        if (params?.height) logo.Height = params.height;

        GPrint.reset_border(logo);
        if (select)
            this.selectLogo(logo);

        if (this.props.onUpdateLogo)
            this.props.onUpdateLogo(logo);
    }
    protected deleteLogo(logo: Logo): void {
        this.info.pushHistoryLogo(logo, "deleted");
        this.info.pieces.forEach(piece => { piece.DeleteLogo(logo); });
        if (logo.id === this.selectedLogo?.id)
            this.selectLogo(null);

        this.info.refreshNextFrame(true);
    }
    protected selectLogo(logo: Logo | null): void {
        if (logo) {
            if (this.selectedLogo)
                this.selectedLogo.active = false;
            if (logo.piece !== this.activeObject)
                this.selectPart(logo.piece, false);
            this.selectedLogo = logo;
            this.selectedLogo.active = true;

            if (this.props.onSelectLogo)
                this.props.onSelectLogo(logo);

            this.selectedLogoMoved();
            this.info.ShowResizeControls(true);

        } else {
            this.info.ShowResizeControls(false);

            if (this.selectedLogo) {
                GPrint.reset_border(this.selectedLogo);
                this.selectedLogo.active = false
            }
            this.selectedLogo = null;
            if (this.props.onSelectLogo)
                this.props.onSelectLogo();
        }

        this.info.refresh(true);
    }
    protected reorderLogos(logos: Logo[]): void {
        this.info.pieces.forEach(piece => {
            let pieceLogos: Logo[] = [];

            logos.forEach(newLogo => {
                piece.logos.forEach(logo => {
                    if (logo.id === newLogo.id) {
                        pieceLogos.push(newLogo);
                    }
                })
            })
            piece.logos = pieceLogos;
        });
        this.info.refresh(true);
    }
    protected repeatLogo(logo: Logo): void {
        this.info.pushHistoryLogo(logo, "update");
        logo.isRepeat = !logo.isRepeat;
        this.info.refresh(true);
    }
    protected logoFlipH(logo: Logo, flip: boolean): void {
        this.info.pushHistoryLogo(logo, "update");
        logo.scaleX = Math.abs(logo.scaleX) * (flip ? -1.0 : 1.0);
        this.info.refresh(true);
    }
    protected logoFlipV(logo: Logo, flip: boolean): void {
        this.info.pushHistoryLogo(logo, "update");
        logo.scaleY = Math.abs(logo.scaleY) * (flip ? -1.0 : 1.0);
        this.info.refresh(true);
    }
    protected logoMoveZOrder(logo: Logo, steps: number): boolean {
        if (!logo.piece)
            return false;
        const logos: Logo[] = logo.piece!.logos;
        const currIndex: number = logos.indexOf(logo);
        if (currIndex < 0)
            return false;
        const len: number = logos.length;
        let newIndex: number = currIndex + steps;
        if (newIndex < 0) newIndex = 0;
        if (newIndex >= len) newIndex = len - 1;
        logos.splice(currIndex, 1);
        logos.splice(newIndex, 0, logo);
        this.info.refresh(true);
        return true;
    }
    protected selectedLogoMoved(): void {
        if (this.activeObject && this.selectedLogo) {
            this.selectedLogo.loc2D = this.activeObject.uvToLocation(true, this.activeObject.logoUVCenter(true, this.selectedLogo));
            this.selectedLogo.loc3D = this.activeObject.uvToLocation(false, this.activeObject.logoUVCenter(false, this.selectedLogo));
        }
    }
    protected scaleLogoToUV(logo: Logo): void {
        this.info.pushHistoryLogo(logo, "update");
        const activeObject: Piece = this.info.pieces.find(item => item.logos.find(i => i.id === logo.id))!;

        logo.autoscaled = true;
        let bleedingConst = 200;

        logo.Npy = (activeObject.duv.y + bleedingConst) / 2 - logo.cy / 2;
        logo.Npx = (activeObject.duv.x + bleedingConst) / 2 - logo.cx / 2;

        let percentTop = (logo.Npy * logo.rate) / logo.cy;
        let percentLeft = (logo.Npx * logo.rate) / logo.cx;

        let percentRight = ((activeObject.duv.x + bleedingConst - (logo.Npx + logo.cx)) * logo.rate) / logo.cx;
        let percentBottom = ((activeObject.duv.y + bleedingConst - (logo.Npy + logo.cy)) * logo.rate) / logo.cy;

        const maxPercent = Math.max(percentTop, percentBottom, percentLeft, percentRight);

        GPrint.resizeLogo(logo, logo.rate * 1.0 + maxPercent * 2, this.props.scaleErrorHandler, [() => { this.info.refresh(false); }, () => { this.info.refresh(false); }]);
        this.info.refresh(false);
    }
    public scaleSelectedLogoToUV(): void {
        let logo = this.selectedLogo;
        if (!logo) {
            return;
        }
        this.scaleLogoToUV(logo);
    }
    //#endregion

    //#region Color and Text
    protected setColor(hex: string, forAllSides: boolean): void {
        let pieces = forAllSides ? this.info.pieces : [this.activeObject];

        pieces.forEach(piece => { if (piece) piece.color = hex; });

        this.info.refresh(forAllSides)
    }
    protected putText(textParams = { text: "hello world", color: "red", font: "Comic Sans MS", weight: "bold" }, offsetX: number, offsetY: number, pointerCaptured?: any): void {
        const dataURL: string = this.offscreen.renderText(textParams);
        const pointer = pointerCaptured || glo.scs2ncs(this.node, offsetX, offsetY, null, false);
        this.ncs.set(pointer.x, pointer.y);
        this.raycaster.setFromCamera(this.ncs, this.camera);
        let meshes = this.extractMeshes(this.is2D, this.info.onlyColorPieces);
        const hits = this.raycaster.intersectObjects(meshes);

        if (hits.length) {
            const hit = hits[0];
            const piece = glo.Get(hit.object, "piece") as Piece;
            Logo.CreateLogo(dataURL, piece, glo.Get(hit, "uv"), textParams, ((logo: Logo) => {
                if (this.props.onNewLogo)
                    this.props.onNewLogo(this.info.getAllLogos(), logo)
                this.selectLogo(logo);
                this.info.refresh(false);
            }));
        }
    }
    protected updateText(logo: Logo, textParams: any): void {
        if (logo && logo.isText) {
            logo.textParams = textParams
            const dataURL = this.offscreen.renderText(textParams);
            ImageTexture.deleteTexture(logo.hash);
            ImageTexture.loadTexture(dataURL, false, ((hash: string, imageTex: ImageTexture) => {

                logo.hash = hash;
                logo.cx = imageTex.orgImage?.width!;
                logo.cy = imageTex.orgImage?.height!;
                logo.cbx = logo.cx;
                logo.cby = logo.cy;
                logo.bcx = logo.cx;
                logo.bcy = logo.cy;

                this.info.refresh(true);
            }));
        } else {
            log("SOMETHING WRONG WITH TEXT LOGO")
        }
    }
    //#endregion

    //#region general
    protected renderMaterial(forAllSides: boolean): void {
        let pieces = forAllSides ? this.info.pieces : [this.activeObject];
        pieces.forEach(piece => {
            if (piece)
                this.offscreen.RenderPieceToTexture(this.renderer, piece);
        });
    }
    protected loadFabricTextures(): any[] {
        this.fabricTexturesList.forEach(texture => { texture.dispose(); });
        this.fabricTexturesList = [];
        this.fabricMaterialsList = [];

        let textures = [
            {
                type: "normalMap",
                src: require("./assets/images/no3.jpg"),
            },
        ];
        let texturePromisses: any[] = [];

        textures.forEach(texture => {
            texturePromisses.push(
                new Promise((resolve, reject) => {
                    this.textureLoader.load(texture.src, map => {
                        map.wrapS = THREE.RepeatWrapping;
                        map.wrapT = THREE.RepeatWrapping;
                        this.fabricTexturesList.push(map);
                        resolve({
                            type: texture.type,
                            map: map,
                        })
                    })
                }),
            )
        });

        return texturePromisses;
    }
    protected zoomCameraOnModel(): void {
        let boundBox = new THREE.Box3().setFromObject(this.mainModel as THREE.Group);
        let center = new THREE.Vector3();
        boundBox.getCenter(center);
        let depthFactor = Math.abs(Math.abs(boundBox.max.z) + Math.abs(boundBox.min.z))
        this.controls.minDistance = depthFactor;

        this.resizeControls.position.set(center.x, center.y, center.z);
        this.resizeControls.updateMatrix();
    }
    protected centerCameraOnModel(fitRatio: number = 1): void {
        const { camera, controls } = this;
        fitCameraToObject({
            camera, controls, selection: [this.mainModel], fitRatio: fitRatio
        });
        glo.Call(controls, "saveState");
        glo.Set(window, "camera", camera);
        glo.Set(window, "controls", controls);
        this.initialCameraPos = camera.position;
    }
    protected initLight(): void {
        this.renderer.outputEncoding = THREE.LinearEncoding;
        (this.renderer as any).gammaFactor = 1.0;
        this.renderer.toneMappingExposure = 1.0;
        this.renderer.physicallyCorrectLights = true;
        this.renderer.setPixelRatio(window.devicePixelRatio);

        const light1 = new THREE.AmbientLight(0xFFFFFF, 1.0);
        light1.name = 'ambient_light';
        this.camera.add(light1);

        const light2 = new THREE.DirectionalLight(0xFFFFFF, 0.8 * Math.PI);
        light2.position.set(0.5, 0, 0.866); // ~60º
        light2.name = 'main_light';
        this.camera.add(light2);
        this.scene.add(this.camera);
    }
    protected refreshResizeControls() {
        if (!this.selectedLogo)
            return;
        this.resizeControls.totAngle = this.selectedLogo.angle;
        this.resizeControls.initScale = this.resizeControls.totScale = this.selectedLogo.rate;
        this.resizeControls.initScaleX = this.resizeControls.totScaleX = this.selectedLogo.scaleX;
        this.resizeControls.initScaleY = this.resizeControls.totScaleY = this.selectedLogo.scaleY;
        const loc = this.selectedLogo.getLoc && this.selectedLogo.getLoc(this.is2D);
        if (loc)
            this.resizeControls.position.copy(loc);
        this.resizeControls.updateMatrix();
    }
    protected loadTabs(): void {
        const that = this;
        this.imageLoader.load(require("./assets/images/rotate.png"), function (image: HTMLImageElement) { that.tabs.push(new Tab("rotate", image)) });
        this.imageLoader.load(require("./assets/images/del.png"), function (image: HTMLImageElement) { that.tabs.push(new Tab("delete", image)) });
    }
    protected render = () => {
        const { renderer, scene, camera } = this;

        if (glo.checkMobile())
            this.resizeControls.setMobile(glo.mobile);
        this.controls.update();
        this.renderer.setClearColor(this.defaultColor, 1) // the default
        renderer.render(scene, camera);

        requestAnimationFrame(this.render);
    }

    protected updateRendererSize = () => {
        const { renderer, camera } = this;
        const { offsetWidth, offsetHeight } = this.node;
        this.crect = this.canvas.getBoundingClientRect();

        renderer.setSize(offsetWidth, offsetHeight);
        camera.aspect = offsetWidth / offsetHeight;
        camera.updateProjectionMatrix();
    }
    //#endregion    

    protected get activeObject() { return this.info.activePiece; }
    protected set activeObject(v: Piece | null) { this.info.activePiece = v; }
    protected get selectedLogo() { return this.info.selectedLogo; }
    protected set selectedLogo(v: Logo | null) { this.info.selectedLogo = v; }
    protected get selectedAction() { return this.info.selectedAction; }
    protected set selectedAction(v: string) { this.info.selectedAction = v; }

    protected get prevLogo() { return this.info.prevLogo; }
    protected get historyLogos() { return this.info.historyLogos; }
    protected get historyIndex() { return this.info.historyIndex; }
    protected set historyIndex(v: number) { this.info.historyIndex = v; }
}
