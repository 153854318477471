import React, { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'throttle-debounce';

export interface DebouncedInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
{
    step?:number, 
    maximum?:number, 
    minimum?: number, 
    isDisabled?: boolean, 
    useCeiling?: boolean
    onValueChanged?: (value: number) => void, 
    currentValue?:number, 
    validateInput?: (value?: number) => boolean, 
    displayError?: () => void
}

export const DebouncedInput = (props: DebouncedInputProps) => {

    const { useCeiling, isDisabled, onValueChanged = () => { }, currentValue, validateInput = () => true, displayError = () => { } } = props
    const [inputValue, setInputValue] = useState(currentValue);
    const valueChangedCallback = useRef(onValueChanged);
    const validateInputCallback = useRef(validateInput);
    const displayErrorCallback = useRef(displayError);

    useEffect(() => {
        setInputValue(currentValue)
    }, [currentValue])


    valueChangedCallback.current = onValueChanged;
    validateInputCallback.current = validateInput;
    displayErrorCallback.current = displayError;

    const debounceFunc = useCallback(debounce(1000, (currentValue:number, value: number) => {
        value = useCeiling? Math.ceil(value*10)/10 : value
        if (validateInputCallback.current(value)) {
            setInputValue(value)
            valueChangedCallback.current(value);
        } else {
            setInputValue(currentValue)
            displayErrorCallback.current();
        }
    }), [useCeiling]);

    return <input
        data-is-valid={validateInput(inputValue)}
        value={inputValue}
        onKeyDown={(e) => {
            if (!e.nativeEvent.code.toLowerCase().startsWith('arrow')) {

                if (isDisabled) {
                    e.preventDefault()
                    return
                }
            }
        }}
        onChange={(inputEvent) => {
            inputEvent.preventDefault()

            let newValue = parseFloat(inputEvent.target.value)

            setInputValue(newValue)
            debounceFunc(props.currentValue, newValue)
        }}


        {...props} type='number'
    />
}