import alertify from 'alertifyjs'

export default {
    alert : (title:string, message: string, okLabel: string, onClick: () => void = ()=>{}) =>
    alertify.alert()
        .setting({
            'label':okLabel,
            'message':message,
            'title': title,
            'onok': onClick
        }).show()
}