import * as THREE from "three"
import { ResizeControlsGizmo } from "./ResizeControlsGizmo";
import { ResizeControlsPlane } from "./ResizeControlsPlane";

const _changeEvent = { type: 'change' };

const worldPosition = new THREE.Vector3();
const worldQuaternion = new THREE.Quaternion();
const worldScale = new THREE.Vector3();

const cameraPosition = new THREE.Vector3();
const cameraQuaternion = new THREE.Quaternion();
const cameraScale = new THREE.Vector3();

const pointStart = new THREE.Vector3(0, 0, 0);
const pointPrev = new THREE.Vector3(0, 0, 0);
const pointEnd = new THREE.Vector3(0, 0, 0);
const pointDelta = new THREE.Vector3(0, 0, 0);

const RAD2DEG = 180.0 / Math.PI;


export class ResizeControls extends THREE.Object3D {
    private mobile: boolean = false;
    private domElement: HTMLElement;
    private gizmo: ResizeControlsGizmo;
    private plane: ResizeControlsPlane;
    private raycaster = new THREE.Raycaster();

    private camera: THREE.PerspectiveCamera;
    private enabled: boolean = true;
    public axis: string | null = null;
    public mode: string | null = null;
    private size: number = 0.6;
    private dragging: boolean = false;
    public totAngle: number = 0;
    public deltaAngle: number = 0;
    public initScale: number = 1;
    public totScale: number = 1;
    public initScaleX: number = 0;
    public initScaleY: number = 0;
    public totScaleX: number = 0;
    public totScaleY: number = 0;

    private _getPointer: any;
    private _onPointerDown: any;
    private _onPointerHover: any;
    private _onPointerMove: any;
    private _onPointerUp: any;

    constructor(camera: THREE.PerspectiveCamera, domElement: HTMLElement) {
        super();

        this.visible = false;
        this.mobile = false;
        this.domElement = domElement;

        this.camera = camera;
        this.gizmo = new ResizeControlsGizmo(camera);
        this.plane = new ResizeControlsPlane();
        this.add(this.gizmo);
        this.add(this.plane);

        this.defineProperty("camera", camera);
        this.defineProperty("enabled", true);
        this.defineProperty("axis", null);
        this.defineProperty("mode", null);
        this.defineProperty("translationSnap", null);
        this.defineProperty("rotationSnap", null);
        this.defineProperty("size", 0.6);
        this.defineProperty("dragging", false);
        this.defineProperty("totAngle", 0);
        this.defineProperty("deltaAngle", 0);
        this.defineProperty("initScale", 1);
        this.defineProperty("totScale", 1);
        this.defineProperty("initScaleX", 0);
        this.defineProperty("initScaleY", 0);
        this.defineProperty("totScaleX", 0);
        this.defineProperty("totScaleY", 0);
        this.setMobile(this.mobile);

        this._getPointer = this.getPointer.bind(this);
        this._onPointerDown = this.onPointerDown.bind(this);
        this._onPointerHover = this.onPointerHover.bind(this);
        this._onPointerMove = this.onPointerMove.bind(this);
        this._onPointerUp = this.onPointerUp.bind(this);

        this.domElement.addEventListener('pointerdown', this._onPointerDown);
        this.domElement.addEventListener('pointermove', this._onPointerHover);
        this.domElement.ownerDocument.addEventListener('pointerup', this._onPointerUp);
    }
    private defineProperty(propName: any, defaultValue: any): void {
        const that: any = this;
        let propValue = defaultValue;

        Object.defineProperty(this, propName, {
            get: function () {
                return propValue !== undefined ? propValue : defaultValue;
            },
            set: function (value) {
                if (propValue !== value) {
                    propValue = value;
                    that.gizmo[propName] = value;
                    that.plane[propName] = value;

                    this.dispatchEvent({ type: propName + "-changed", value: value });
                }
            }
        });

        that[propName] = defaultValue;
        that.gizmo[propName] = defaultValue;
        that.plane[propName] = defaultValue;
    }
    public setMobile(value: boolean): void {
        this.mobile = value;
        if (value)
            this.size = 0.8;
        else
            this.size = 0.6;

        this.gizmo.picker.children.forEach(object => {
            const obj: any = object;
            let val = value;
            switch (obj.tag) {
                case 'circle':
                case 'N':
                case 'S':
                case 'W':
                case 'E':
                    val = false;
                    break;

                default:
                    break;
            }
            if (val)
                object.scale.set(2, 2, 1);
            else
                object.scale.set(1, 1, 1);
        });
    }
    private pointerHover(pointer: any): void {
        if (this.dragging === true)
            return;

        this.raycaster.setFromCamera(pointer, this.camera);

        const intersect: any = this.visible ? this.intersectObjectWithRay(this.gizmo.picker, false) : false;
        if (intersect)
            this.axis = intersect.object.tag;
        else
            this.axis = null;
    }
    private pointerDown(pointer: any): void {
        if (this.dragging === true || pointer.button !== 0)
            return;

        if (this.axis !== null) {
            this.mode = this.axis;
            this.raycaster.setFromCamera(pointer, this.camera);

            const intersect = this.intersectObjectWithRay(this.plane, true);
            if (intersect) {
                this.dragging = true;
                pointStart.copy(intersect.point);
                this.worldToLocal(pointStart);
                pointEnd.copy(pointStart);
            }
        }
    }
    private pointerMove(pointer: any): void {
        if (this.axis !== null) {
            this.raycaster.setFromCamera(pointer, this.camera);

            const intersect = this.intersectObjectWithRay(this.plane, true);
            if (intersect) {

                pointPrev.copy(pointEnd);
                pointEnd.copy(intersect.point);
                this.worldToLocal(pointEnd);
                pointDelta.copy(pointEnd).sub(pointPrev);

                switch (this.mode) {
                    case "circle": {
                        let angle0 = Math.atan2(pointPrev.y, pointPrev.x) * RAD2DEG;
                        let angle1 = Math.atan2(pointEnd.y, pointEnd.x) * RAD2DEG;
                        this.deltaAngle = (angle1 - angle0);
                        if (Math.abs(this.deltaAngle) > 90)
                            this.deltaAngle -= 360;
                        this.totAngle -= this.deltaAngle;
                        this.totAngle = this.totAngle % 360.0;
                    }
                        break;

                    case "NW":
                    case "NE":
                    case "SW":
                    case "SE":
                        this.totScale = this.initScale * pointEnd.length() / pointStart.length();
                        break;

                    case "N":
                    case "S":
                        this.totScaleY = this.initScaleY * pointEnd.y / pointStart.y;
                        break;

                    case "W":
                    case "E":
                        this.totScaleX = this.initScaleX * pointEnd.x / pointStart.x;
                        break;

                    default:
                        break;
                }
            }

            this.dispatchEvent(_changeEvent);
        }
    }
    private pointerUp(pointer: any): void {
        if (pointer.button !== 0)
            return;

        if (this.dragging && (this.axis !== null)) {

            this.initScale = this.totScale;
            this.initScaleX = this.totScaleX;
            this.initScaleY = this.totScaleY;
        }

        this.dragging = false;
        this.mode = null;
    }
    private dispose(): void {

        this.domElement.removeEventListener('pointerdown', this._onPointerDown);
        this.domElement.removeEventListener('pointermove', this._onPointerHover);
        this.domElement.ownerDocument.removeEventListener('pointermove', this._onPointerMove);
        this.domElement.ownerDocument.removeEventListener('pointerup', this._onPointerUp);

        this.traverse(function (child: any) {

            if (child.geometry) child.geometry.dispose();
            if (child.material) child.material.dispose();

        });
    }
    public updateMatrixWorld(force: boolean): void {
        this.matrixWorld.decompose(worldPosition, worldQuaternion, worldScale);

        this.camera.updateMatrixWorld();
        this.camera.matrixWorld.decompose(cameraPosition, cameraQuaternion, cameraScale);
        this.quaternion.copy(cameraQuaternion);

        this.gizmo.worldPosition = worldPosition;
        this.gizmo.cameraPosition = cameraPosition;

        super.updateMatrixWorld(force);
    }
    private getPointer(event: any): any {
        if (this.domElement.ownerDocument.pointerLockElement)
            return { x: 0, y: 0, button: event.button };
        else {
            const pointer = event.changedTouches ? event.changedTouches[0] : event;
            const rect = this.domElement.getBoundingClientRect();

            return { x: (pointer.clientX - rect.left) / rect.width * 2 - 1, y: - (pointer.clientY - rect.top) / rect.height * 2 + 1, button: event.button };
        }
    }
    private onPointerHover(event: any): void {
        if (!this.enabled)
            return;

        switch (event.pointerType) {
            case 'mouse':
            case 'pen':
                this.pointerHover(this._getPointer(event));
                break;
            default:
                break;
        }
    }
    private onPointerDown(event: any): void {

        if (!this.enabled)
            return;

        this.domElement.style.touchAction = 'none'; // disable touch scroll
        this.domElement.ownerDocument.addEventListener('pointermove', this._onPointerMove);

        this.pointerHover(this._getPointer(event));
        this.pointerDown(this._getPointer(event));

        if (null !== this.axis) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
    private onPointerMove(event: any): void {
        if (!this.enabled)
            return;

        this.pointerMove(this._getPointer(event));
        if (null !== this.axis)
            event.preventDefault();
    }
    private onPointerUp(event: any): void {
        if (!this.enabled)
            return;

        this.domElement.style.touchAction = '';
        this.domElement.ownerDocument.removeEventListener('pointermove', this._onPointerMove);

        this.pointerUp(this._getPointer(event));
        if (null !== this.axis) {
            event.preventDefault();
            this.axis = null;
        }
    }
    private intersectObjectWithRay(object: THREE.Object3D, includeInvisible: boolean) {

        const allIntersections = this.raycaster.intersectObject(object, true);

        for (let i = 0; i < allIntersections.length; i++) {

            if (allIntersections[i].object.visible || includeInvisible) {

                return allIntersections[i];
            }
        }

        return false;
    }
}