/**
 * Colors constants
 * @file
 */

export default [
  {color: "#FFFFFF", label: "White"},
  {color: "#F5F5F1", label: "Light Gray"},
  {color: "#8B8B8B", label: "Gray"},
  {color: "#000000", label: "Black"},
  {color: "#FD5255", label: "Coral"},
  {color: "#2C7F13", label: "Green"},
  {color: "#1CB2AC", label: "Turquoise"},
  {color: "#92FFEC", label: "No Name"},
  {color: "#98CBFC", label: "Light Blue"},
  {color: "#319CFC", label: "Blue"},
  {color: "#0D1F6C", label: "Darc Blue"},
  {color: "#B456EC", label: "Violet"},
  {color: "#7DE362", label: "Light Green"},
  {color: "#FCB12A", label: "Orange"},
  {color: "#FEE534", label: "Yellow"},
  {color: "#D92D66", label: "Fuchsia"},
]
