import ACTIONS from "../../constatnts"

export const fbdata = (state = null, actions) => {
  const {type, payload} = actions

  switch (type) {
    case ACTIONS.GET_FB_DATA:
      if (!payload.fabricUnitSizeCm) {
        payload.fabricUnitSizeCm = 1
      }
      return payload

    case ACTIONS.ADD_IMAGE_LIBRARY:
      return {...state, mediaLibrary: payload}

    default:
      return state
  }
}
