import React, { useState, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import styles from "./ruler.module.scss"

/**
 * Ruller, have 2 types(horizontal/vertical)
 * @component
 * @param {object} props
 * @param {number} props.fabricSize
 * @param {number} props.containerSize
 * @param {number} props.start
 * @param {string} props.type
 * @param {number} props.parts
 * @param {number} props.partLength
 * @param {number} props.partLengthChanged
 * @example
 *  const fabricSize=0
 *   const containerSize= 0
 *   const start= 0
 *   const type="HORIZONTAL"
 *
 * return (
 *  <Ruler
 *    fabricSize={fabricSize}
 *    containerSize={containerSize}
 *    start={start}
 *    type={type}
 * />
 * )
 */

interface IRuler {
  //pxPerUnit: number,
  fabricWidth: number,
  containerHeight: number,
  containerWidth: number,
  partsCount: number,
  start: number, //must be dm
  stepSize: number,
  partSize: number,
  type: "HORIZONTAL" | "VERTICAL",
  partLengthChanged: number,
  
}
interface IRulerSpecs {
  horizontalScaleMarkings: number[]
  verticalScaleMarkings: number[]
  parts: number[],
  sectionsPerFabricUnit: number
  pxPerUnit: number
}

const Ruler = (props: IRuler) => {
  let {
    //pxPerUnit = 0,
    fabricWidth = 0,
    containerWidth = 0,
    containerHeight = 0,
    partsCount = 1,
    start = 0, //must be dm
    stepSize = 0,
    type = "HORIZONTAL",
    partSize=1,
    partLengthChanged = 1,
  } = props

  stepSize = Math.ceil(stepSize)

  const [rulerSpecs, setRulerSpecs] = useState(undefined as undefined | IRulerSpecs)
  const fabricSizeUnit = useSelector((state:any) => state.settings.unitType)
  

  useEffect(() => {

    if (fabricWidth && containerWidth) {
      partsCount = partsCount||1
      let scaleSections = 0
      let sectionsPerFabricUnit = 0
     
      switch (fabricSizeUnit) {
        case 'YARD':
          scaleSections = fabricWidth * 36 //(1 yard = 36 inches)
          sectionsPerFabricUnit = 36*stepSize
          break;
        case 'METER':
          scaleSections = fabricWidth * 100 //(1 meter = 100 cm)
          sectionsPerFabricUnit = 100*stepSize
          break;
        case 'DECIMETER':
          scaleSections = fabricWidth * 10 //(1 decimeter = 10 cm)
          sectionsPerFabricUnit = 10*stepSize
          break;
        default:
          break;
      }

      scaleSections = Math.ceil(scaleSections);
      const pxPerUnit = containerWidth / scaleSections

      const currentRulerSpecs: IRulerSpecs = {
        horizontalScaleMarkings: new Array(scaleSections).fill(0),
        verticalScaleMarkings: new Array(sectionsPerFabricUnit).fill(0),
        pxPerUnit,
        parts: partSize && stepSize ? new Array(Math.ceil(partSize/stepSize)).fill(0):[],
        sectionsPerFabricUnit
      }

      setRulerSpecs(currentRulerSpecs)
    }
  }, [containerWidth, fabricWidth, start, partsCount,partSize, partLengthChanged, fabricSizeUnit])

  if (!rulerSpecs)
    return null

  const mediaQuery = window.matchMedia('(max-width: 600px)')
  let isMobMediaDetected = mediaQuery.matches

  return (type === 'HORIZONTAL') ?
    <div style={{
      borderLeft: '1px solid #404040', 
      width: '100%',
      display: 'flex', flexDirection: 'row', alignItems: 'flex-end', height: '100%'
    }}>
      {(rulerSpecs.horizontalScaleMarkings || []).map((item, index, array) => {
        index = index + 1
        return (
          <div key={`${index}`} style={{
            height: (index % 10) ? '40%' : '100%',
            alignSelf: 'bottom',
            borderRight: `${index % 2 ? 0 : 1}px solid black`,
            width: rulerSpecs.pxPerUnit,//`${100 / array.length}%`,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end'
          }}>
            {index % 10 ? null : <div style={{
              fontSize: `${isMobMediaDetected === true?'10px':'12px'}`,
              position: 'absolute',
              top: '5%',
              left: `${(100 / ((array.length))) * index}%`,
            }}>{(index)}</div>}
          </div>
        )
      })}
    </div> : <div style={{position:'absolute', maxWidth: '40px'}}>
      {(rulerSpecs.parts || []).map((item, i) => {

        const { pxPerUnit, sectionsPerFabricUnit } = rulerSpecs
        const partHeightPx = pxPerUnit * sectionsPerFabricUnit

        return <div key={`${i}`} style={{
          width: '40px',
          height: partHeightPx, position: 'absolute', top: (partHeightPx * i) - start,

        }}>
          {rulerSpecs.verticalScaleMarkings.map((item, index, array) => {
            index = index + 1
            const measurement = (index) + (i * sectionsPerFabricUnit)
            return <div key={`${measurement}_${index}`} style={{
              borderRight: '1px solid #404040',
              width: '100%', height: `${100/array.length}%`, display: 'flex',
              flexDirection: 'column', alignItems: 'flex-end',
            }}>
              <div style={{
                height: '100%',
                width: measurement % 10 ? '40%' : '100%',
                borderBottom: `${index % 2 ? 0 : 1}px solid black`,
              }}>
                {(measurement % 10) ? null: <div style={{ 
                  fontSize: `${isMobMediaDetected === true?'10px':'12px'}`, left: 0, position:'absolute',
                  top:`${(index*100 /(sectionsPerFabricUnit))}%` 
                  }}>
                  {measurement}</div>}
              </div>
            </div>
          })}

        </div>
      })}
    </div>

}


export default Ruler
