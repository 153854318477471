import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'

import alertify from "../../../libs/alert"
import { log } from '../../../utilities/logger'
import { useTranslation } from "react-i18next"
import { debounce as deb } from 'debounce'
import styles from "../../LeftWorkArea/leftworkarea.module.scss"

export const PartLengthInput = ({ inputValue, contextualMax, onInputChange, min, max, maxDimensions, debounce, active, isDisabled, ...props }) => {

    const [input, setInput] = useState(inputValue)

    let isNonRepeatCheck = contextualMax < max
    max = contextualMax

    const { t } = useTranslation()
    const [valueBelowMinimum, setValueBelowMinimum] = useState(false)
    const pendingInput = useRef(undefined)
    const [updateCounter, setUpdateCounter] = useState(0)
    //const [pendingInput, setPendingInput] = useState(undefined)
    const [counter, setCounter] = useState(0)
    const counterRef = useRef(counter)

    useEffect(() => {
        log({ inputValue, input }, 'value input debounce part length')
        if ((input !== inputValue) && (input >= min)) {
            pendingInput.current = input
            onInputChange(input)
        }
    }, [input])

    const debounced = useMemo(() => deb(() => {
        let val = pendingInput.current
        log({ debounce: val }, 'debounce hello test')
        if (val < min) {
            setValueBelowMinimum(true)
        } else {
            setValueBelowMinimum(false)
            if (val >= max) {
                // usunit
                alertify.alert(t("editor.alert_warning_title1"),
                `${isNonRepeatCheck ? (t(`The max length for No Repeat is ${max} metres.`)) : (`${t("editor.fabric_exceeds_max_length")} : ${max}m.`)}`,
                    t("i18n.ok"), () => { })
                val = max
            }
        }
        log({  val }, 'debounce hello test')
        setInput(val)
        if(val == max){
            pendingInput.current = `${max}`
            setCounter((counterRef.current++)%2)
        }
        // if (val >= min)
        //     onInputChange(val)
    }, debounce || 500), [min, max])

    useEffect(() => {
        if (inputValue >= min) {
            log({ min , inputValue }, 'debounce hello test')
            pendingInput.current = inputValue
            setInput(inputValue)
            setValueBelowMinimum(false)
        }
    }, [inputValue, counter])

    useEffect(() => {

        if (pendingInput.current !== undefined) {
            debounced(pendingInput.current)
        }
        return () => debounced.clear()
    }, [updateCounter])


    log({ input, min, pendingInput:pendingInput.current, counter }, 'debounce ??? initial')

    return <div style={{ position: 'relative', width: '100%', display:"flex", flexDirection:"row-reverse", marginLeft:"10px" }}>
        <button className={styles.lengthChangeBtn} style={{borderRadius: "0px 6px 6px 0px"}}
            onClick={()=>{
                let stepSize = (props.step)

                let inputData = parseFloat(inputValue)

                if ((inputValue % stepSize)) {

                    let prospectOne = Math.ceil(inputData)
                    let prospectTwo = Math.ceil(inputData) - stepSize

                    if ( inputData <= prospectTwo) {
                        inputData = prospectTwo
                    } else {
                        inputData = prospectOne
                    }

                }else{
                    inputData = parseFloat(inputValue) + stepSize
                log({ inputData, input: pendingInput.current, stepSize, props:input }, "debounce ???")
                }


                if (Math.abs(pendingInput.current - inputData) === (stepSize || 1)) {
                    if (inputValue < min) {
                        inputData = inputData<min?min:inputData
                        setValueBelowMinimum(true)
                    } else {
                        setValueBelowMinimum(false)
                        if (inputData >= max) {
                            //usunit
                            alertify.alert(t("editor.alert_warning_title1"), 
                                `${isNonRepeatCheck ? t(`The max length for No Repeat is ${max} metres.`) : `${t("editor.fabric_exceeds_max_length") }: ${max} m.`}`,
                             t("i18n.ok"), 
                             () => { }).set('modal', true)
                            inputData = max
                        }
                    }
                    pendingInput.current = inputData
                    onInputChange(inputData)
                } else {
                    log('debounce ??? ------->')
                    pendingInput.current = inputData
                    setUpdateCounter((updateCounter + 1) % 2)
                }
            }}>+</button>
        <input
            style={{ color: input >= min ? 'black' : 'grey' }}
            value={pendingInput.current || input}
            max={max+parseFloat(props.step)}
            min={min}
            isdisabled={`${isDisabled}`}
            onKeyDown={(e) => {
                if (!e.nativeEvent.code.toLowerCase().startsWith('arrow')) {

                    if (isDisabled) {
                        e.preventDefault()
                        return
                    }
                }
            }}
            onChange={(e) => {
                e.preventDefault()
                let inputData = (e.target.value)
                let stepSize = (props.step)
                log({ inputData, input: pendingInput.current, stepSize, props:input }, "debounce ???")

                if (Math.abs(pendingInput.current - inputData) === (stepSize || 1)) {
                    if (e.target.value < min) {
                        inputData = inputData<min?min:inputData
                        setValueBelowMinimum(true)
                    } else {
                        setValueBelowMinimum(false)
                        if (inputData >= max) {
                            //usunit
                            alertify.alert(t("editor.alert_warning_title1"), 
                                `${isNonRepeatCheck ? t(`The max length for No Repeat is ${max} metres.`) : `${t("editor.fabric_exceeds_max_length") }: ${max} m.`}`,
                             t("i18n.ok"), 
                             () => { }).set('modal', true)
                            inputData = max
                        }
                    }
                    pendingInput.current = inputData
                    onInputChange(inputData)
                } else {
                    log('debounce ??? ------->')
                    pendingInput.current = inputData
                    setUpdateCounter((updateCounter + 1) % 2)
                }
            }}
            
            {...props} type='number'
        />
        <button className={styles.lengthChangeBtn} style={{borderRadius: "6px 0px 0px 6px"}}
            onClick={()=>{
                let stepSize = (props.step)


                let inputData = parseFloat(inputValue)

                if ((inputValue % stepSize)) {

                    let prospectOne = Math.floor(inputData)
                    let prospectTwo = Math.floor(inputData) + stepSize

                    if ( inputData >= prospectTwo) {
                        inputData = prospectTwo
                    } else {
                        inputData = prospectOne
                    }

                }else{


                inputData = parseFloat(inputValue) - stepSize
                log({ inputData, input: pendingInput.current, stepSize, props:input }, "debounce ???")
                }
                if (Math.abs(pendingInput.current - inputData) === (stepSize || 1)) {
                    if (inputValue <= min) {
                        inputData = min
                        setValueBelowMinimum(true)
                    } else {
                        setValueBelowMinimum(false)
                        if (inputData >= max) {
                            //usunit
                            alertify.alert(t("editor.alert_warning_title1"), 
                                `${isNonRepeatCheck ? t(`The max length for No Repeat is ${max} metres.`) : `${t("editor.fabric_exceeds_max_length") }: ${max} m.`}`,
                             t("i18n.ok"), 
                             () => { }).set('modal', true)
                            inputData = max
                        }
                    }
                    pendingInput.current = inputData
                    onInputChange(inputData)
                } else {
                    log('debounce ??? ------->')
                    pendingInput.current = inputData
                    setUpdateCounter((updateCounter + 1) % 2)
                }
            }}>-</button>
        {valueBelowMinimum ? <span style={
            {
                position: 'absolute', top: '34px', left: 0, borderRadius: '5px', fontWeight: 'bold',
                backgroundColor: 'red', color: 'white', fontSize: '12px', padding: '2.5px', paddingLeft: '5px', paddingRight: '5px'
            }
        }>{`min: ${min}`}</span> : null}
    </div>
}