import React, { useEffect, useMemo, useState } from 'react'
import { useIframeMessageParser } from './hooks/useIframeMessageParser'
import './appcustom.scss'
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from './editor/editor';
import { useFirebaseData } from './hooks/useFirebaseData';
import { useProductsJson } from './hooks/useProductsJson';
import { setApiKey } from './redux/actions/apikey';
import { CanvasGrid } from './App';
import { ISettings, SET_CONFIGURATION, UPDATE_DISPLAY_UNITS, UPDATE_UNIT_TYPE } from './redux/reducers/settings';
import { FIREBASE_UNIT } from './libs/viewer/canvasPixelConvertor';
import { getImgs } from './redux/actions/imglib';
import { setCurrentImage, setMediaLibTitle, setProduct, setProducts } from './redux/actions/product';
import { ControlPanel } from './controlpanel';

export const API_KEY = "EV"//"5tDOls2BApP8lIXshTDzLH0kLfp1"

function App({ styleToApply }: { styleToApply: string }) {

    const product = useSelector((state: any) => state.product)
    const stepSize = useSelector((state: any) => state.fbdata?.fabricUnitSizeCm);

    const parts: any[] = useSelector((state: any) => state.parts)
    const activePart = useMemo(() => { return parts.find(part => part.active) }, [parts])

    const [partLength, setPartLength] = useState(stepSize);
    useEffect(() => { setPartLength(stepSize) }, [stepSize]);
    const rulerUnits = useSelector((state: any) => state.settings.rulerUnits);
    const fabricLength = useSelector((state: any) => (state.settings as ISettings)?.display?.currentLength)

    return product && stepSize ? <div className={cx(styleToApply||'default-style', 'app-container')}>
        <div className='control-panel'>
            <ControlPanel stepSize={stepSize} activePart={activePart} fabricMaxLength={product.totalLength} fabricWidth={product.width} fabricLength={fabricLength||stepSize} />
        </div>
        <div className='editor'>
            <Editor activePart={activePart} maxLength={product.totalLength} partLength={fabricLength||stepSize} partId={activePart?._id} fabricWidth={product.width} partSize={partLength || stepSize} stepSize={stepSize} noOfParts={rulerUnits} />
        </div>
    </div> : null

}

//export const CanvasGrid = React.createContext({ rows: 0, columns: 0 })

export const FabricEditor = () => {

    const [apiKey, setFbApikey] = useState(undefined as string | undefined);
    const iFrameData = useIframeMessageParser();
    const firebaseData = useFirebaseData(apiKey);
    const result = useProductsJson(iFrameData?.viewType === 'webpage', firebaseData.userAuthenticated || false);
    const [styleToApply, setStyleToApply] = useState('');
    const [display, setDisplay] = useState(false);
    const dispatch = useDispatch();
    const [canvasGridConfig, setCanvasGridConfig] = useState({ rows: 1, columns: 1 })

    useEffect(() => {
        if (iFrameData.viewType === 'webpage') {
            setDisplay(true);
        } else if (iFrameData.cssLink) {
            const link = document.createElement('link');
            link.rel = "stylesheet";
            link.type = "text/css";
            link.href = iFrameData.cssLink;
            document.head.appendChild(link);
            setStyleToApply("custom"); 
            link.onload = function () {
                setDisplay(true);
            }
        } else if(iFrameData.viewType === 'iframe' || iFrameData.viewType === 'embedded'){
            setDisplay(true);
        }
    }, [iFrameData.viewType, iFrameData.cssLink]);

    useEffect(() => {
        if (iFrameData.viewType === 'webpage') {
            setFbApikey(API_KEY)
            dispatch(setApiKey(API_KEY))
        } else if (iFrameData.apiKey) {
            setFbApikey(iFrameData.apiKey)
            dispatch(setApiKey(iFrameData.apiKey))
        }
    }, [iFrameData.viewType, iFrameData.apiKey]);

    useEffect(() => {
        let unitType = iFrameData.firebaseUnit || firebaseData.config?.unitType;
        if (unitType) {
            dispatch({
                type: UPDATE_UNIT_TYPE,
                payload: { unitType }
            });
            FIREBASE_UNIT.unit = unitType;
        }
    }, [iFrameData.firebaseUnit, firebaseData.config?.unitType])

    useEffect(() => {
        const { columns, rows } = iFrameData.gridConfig || {}
        if (rows && columns) {
            setCanvasGridConfig({ rows, columns })
        }
    }, [iFrameData.gridConfig])

    useEffect(() => {
        iFrameData.imgLib && dispatch(getImgs(iFrameData.imgLib))
    }, [iFrameData.imgLib]);

    useEffect(()=>{
        iFrameData.currentImage && dispatch(setCurrentImage(iFrameData.currentImage))
    },[iFrameData.currentImage])

    useEffect(()=>{
        iFrameData.mediaLibTitle && dispatch(setMediaLibTitle(iFrameData.mediaLibTitle))
    },[iFrameData.mediaLibTitle])

    useEffect(() => {
        if (iFrameData.fabricList?.length) {
            dispatch(setProducts(iFrameData.fabricList));
            dispatch(setProduct({
                ...iFrameData.fabricList[0],
                sku: iFrameData.sku ?? "notfound",
            }));
        }
    }, [iFrameData.fabricList]);

    const stepLength = firebaseData.config?.fabricUnitSizeCm;
    const { rows, columns } = canvasGridConfig;
    const fabricUnit = iFrameData.firebaseUnit || firebaseData.config?.unitType;

    useEffect(() => {
        if (stepLength && fabricUnit && rows && columns) {
            dispatch({
                type: SET_CONFIGURATION,
                display:{
                    currentLength: stepLength
                },
                payload: {
                    stepSize: stepLength,
                    stepUnitType: fabricUnit,
                    split: (rows || columns) ? {
                        rows,
                        columns
                    } : undefined
                }
            })
        }
    }, [stepLength, fabricUnit, rows, columns])

    return display ?
        <CanvasGrid.Provider value={canvasGridConfig}>
            <App styleToApply={styleToApply} />
        </CanvasGrid.Provider>
        : null

}

