import React from "react"
import {useSelector} from "react-redux"
import styles from "./blur.module.scss"

import Data from "../Header/Data"
import { useTranslation } from "react-i18next"

import { ReactComponent as ArrowBlur } from "../../images/arrow.svg"

/**
 * Blur for init app open & select product
 * @component
 * @example
 * return (
 *  <Blur/>
 * )
 */
const Blur = () => {
  const products = useSelector(state => state.products)
  const {t} = useTranslation()
  

  return products.length ? (
    <div className={styles.container}>
      <div className={styles.data}>
        <div className={styles.preview}>
          <div className={styles.preview_arrow}>
            <ArrowBlur className={styles.svgIcn} alt="arrow" />
          </div>
          <div className={styles.preview_title}>
            {t("editor.please_select")}
          </div>
        </div>
        <div className={styles.selectorPlaceholder}>
        <Data type="BLUR" />
        </div>
      </div>
    </div>
  ) : null
}

export default Blur
