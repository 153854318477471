import React, {useRef, useEffect, useCallback} from "react"
import { useDispatch, useSelector } from "react-redux"

import { ACTIONS_UPSERT_IMAGE, ACTIONS_REMOVE_IMAGE, ACTIONS_SELECT_IMAGE, ACTIONS_DESELECT_IMAGE } from "../../redux/reducers/images"
import { log } from "../../utilities/logger"
import { useTranslation } from "react-i18next"
import { deAllocSingletonViewerInstance, getSingletonViewerInstance } from "../../libs/viewer"


export type ProductUpsertInput = {
  productWidth: number,
  productLength: number,
  stepSizeMeters: number
} 

/**
 * Scene container
 * @component
 * @param {object} props
 * @param {object} props.containerSize
 * @param {string} props.activeUrl
 * @param {number} props.imageSelectionChange1
 * @param {number} props.imageSelectionChange2
 * @param {object} props.activePart
 * @param {number} props.partLengthChanged
 * @param {string} props.toastify
 * @example
 * return (
 *  <Scen
 *    containerSize = {{}}
 * />
 * )
 */
export interface IScen{
  containerSize?: {width:number, height: number}
  activeUrl:(inputObj:any, id:string)=>void
  imageSelectionChange1: (inputObj:any, val:any)=>void
  imageSelectionChange2: (inputObj:any, val:any)=>void
  partLengthChanged: (inputObj:any)=>void
  loadImage: (progress:any)=>void
  toastify:(inputObj:any)=>void
  fabricSizeUnit: 'YARD'|'METER'
}
const Scen = (props:IScen) => {
  const {containerSize = {width:0, height:0}} = props

  const node = useRef(null)
  const product = useSelector((state:any) => state.product)
  const apikey = useSelector((state:any) => state.apikey)
  const fbdata = useSelector((state:any) => state.fbdata)
  const stepSize = fbdata.fabricUnitSizeCm || 1
  // const fabricQtyByUnit = fbdata.fabricQtyByUnit || false
  const fabricQtyByUnit = fbdata.fabricQtyByUnit !== undefined?fbdata.fabricQtyByUnit:false
  const displayLength = useSelector((state:any) => state.settings?.display?.currentLength)||stepSize

  const {t} = useTranslation()

  const V = useRef(getSingletonViewerInstance()).current

  const selectedImage = useSelector((state:any) => state.canvasImages && state.canvasImages.selectedImage, (left, right) => {
    log({updateTest: (left?.id === right?.id)&&(left?.rotation === right?.rotation) },'rotateImage')
    return (left?.id === right?.id)&&(left?.rotation === right?.rotation)
  })

  // log({initiationAttempt:selectedImage?.rotation },'rotateImage')

  useEffect(() => {
    log(selectedImage, 'selection made to highlight rotateImage')
    if (V && selectedImage && selectedImage.url)
        V.selectImage(selectedImage.url)
  }, [selectedImage && selectedImage.url])

  useEffect(()=>{
    log({initiating:selectedImage?.rotation },'rotateImage')
    if(V && (selectedImage?.rotation !== undefined)){
      V.rotateImage(selectedImage.url, selectedImage.rotation)
    }
  },[selectedImage?.rotation])

  const dispatch = useDispatch()

   useEffect(()=>{

    return ()=>{
      V && V.dispose();
      deAllocSingletonViewerInstance()
    }

   },[])

  const productCreated = useRef(false)

  useEffect(() => {
    if (node.current && product) {
      V.init({
        node: node.current,
        // fabricWidth: product.width,
        // fabricLength: product.totalLength,
        // fabricName: product.name,
        stor: apikey,
        selTarget: (inputObj:any, id:string) => { props.activeUrl(inputObj, id) },
        onImageUpsert: async ({ id, minResolution, tileOriginalWidth, tileOriginalHeight, tileWidth, tileHeight, url, widthSubUnit, heightSubUnit, widthLimits, heightLimits, locked, angle }: any) => {
          setTimeout(() => {
            dispatch({ type: ACTIONS_UPSERT_IMAGE, payload: { id, minResolution, tileOriginalWidth, tileOriginalHeight, tileWidth, tileHeight, url, widthSubUnit, heightSubUnit, widthLimits, heightLimits, locked, angle } })
            dispatch({ type: ACTIONS_SELECT_IMAGE, payload: { id, url } })
          }, 1)
        },
        // onImageRemoved: (id) => {
        //   dispatch({ type: ACTIONS_REMOVE_IMAGE, payload: { id } })
        // },
        onImageSelected: ({ id, url }:any) => {
          if (url)
            dispatch({ type: ACTIONS_SELECT_IMAGE, payload: { id, url } })
          else
            dispatch({ type: ACTIONS_DESELECT_IMAGE })
        },
        selImageSzWidth: (inputObj:any,val:any)=>{props.imageSelectionChange1(inputObj, val)},
        selImageSzHeight: (inputObj:any,val:any)=>{props.imageSelectionChange2(inputObj, val)},
        curPartLength: (inputObj:any)=>{props.partLengthChanged(inputObj)},
        loadImage:(progress:any)=>props.loadImage(progress),
        toastify: (inputObj:any)=>{props.toastify(inputObj)}
      })

      V.setProps({
        fabricWidth: product.width,
        fabricLength: product.totalLength,
        fabricName: product.name,
        t:t,
      })
    }
  }, [apikey, product])

  useEffect(() => {
    if (V && node.current && product && containerSize.width) {
      let viewerInput:ProductUpsertInput = {productLength:0, productWidth:0,stepSizeMeters:0}
      log({product,stepSize},'product upsert')
      switch(props.fabricSizeUnit){
        case 'YARD':
          // viewerInput.productWidth = (product.width/1.094) //yard to meter
          // viewerInput.productLength = (displayLength/1.094) //yard to meter
          // viewerInput.stepSizeMeters = stepSize/1.094
          // break;
        case 'METER':
          viewerInput.productWidth = product.width
          viewerInput.productLength = displayLength
          viewerInput.stepSizeMeters = stepSize
          default:

      }
      if(!productCreated.current){
        V.createProduct(containerSize, {
          width: viewerInput.productWidth,
          length: viewerInput.productLength,
        }, viewerInput.stepSizeMeters, fabricQtyByUnit)
        productCreated.current = true
      }else{
        V.updateProduct(containerSize, {
          width: viewerInput.productWidth,
          length: viewerInput.productLength,
        }, viewerInput.stepSizeMeters)
      }
    }
  }, [product, containerSize])

  return <canvas ref={node}></canvas>
}

export default Scen
