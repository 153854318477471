import { Viewer2dApi } from "../../../libs/viewer"
import { settings } from "../settings"
import { REMOVE_SEGMENT_SETTINGS, SEGMENT_IN_FOCUS, SEGMENT_SETTINGS_UPDATE, SETUP_FABRIC, UPDATE_GRID_CONFIG } from "./actions"

export type IFabricSettings = {
    pxPerUnitFabric: number
    grid: {columns:number, rows: number}
    unitType: 'YARD'|'METER'
    fabricDimensionsPx:{
        widthPx: number
        heightPx: number
    }
}

export type ISegmentSettings = {
    columnIndex:number
    rowIndex:number
    parentCanvas: Viewer2dApi
    repeatType: "NON_REPEAT"|"DEFAULT_REPEAT"|"MIRROR_REPEAT"|"HALF_DROP_REPEAT"|"HALF_BRICK_REPEAT"
}

export interface IFabric{
    settings?: IFabricSettings
    segmentInFocus?:Viewer2dApi
    segmentSettings?: ISegmentSettings[]
}

export const fabric = (state:IFabric = {}, action:{type:string, payload:any}):IFabric=>{
    switch(action.type){
        case SETUP_FABRIC:
            return {...state, settings:{...state.settings, ...action.payload}}
        case UPDATE_GRID_CONFIG:
            return {...state, settings:{...state.settings as IFabricSettings, grid: action.payload}}    
        case SEGMENT_IN_FOCUS:
            return {...state, segmentInFocus: action.payload}  
        case REMOVE_SEGMENT_SETTINGS:{
            const canvas = action.payload.parentCanvas
            let segIndex = state.segmentSettings?.findIndex((segment: ISegmentSettings) => segment.parentCanvas === canvas)
            if (segIndex !== undefined && segIndex + 1) {
                const segment = state.segmentSettings?.splice(segIndex, 1)[0]
            } 
            return { ...state, segmentSettings: [...state.segmentSettings || []] }
        }
        case SEGMENT_SETTINGS_UPDATE:
            const canvas = action.payload.parentCanvas
            let segIndex = state.segmentSettings?.findIndex((segment:ISegmentSettings)=>segment.parentCanvas === canvas)
            let updatedSegment = undefined
            if(segIndex!==undefined && segIndex+1){
                const segment = state.segmentSettings?.splice(segIndex,1)[0]
                updatedSegment = {...segment, ...action.payload}
            }else{
                updatedSegment = {...action.payload}
            }
            return {...state, segmentSettings:[...state.segmentSettings||[], updatedSegment]}      
        default:
            return state    
    }
}