import ACTIONS from "../../constatnts"

import alertify from "../../../libs/alert"
import { getImageDim, getImageDim_max, getImageDim_min } from "../../../utilities"
import { log } from "../../../utilities/logger"
import { useTranslation } from "react-i18next"
import { getSingletonViewerInstance, Viewer2dApi } from "../../../libs/viewer"
import { getResolution, getSubUnit } from "../../../libs/viewer/canvasPixelConvertor"


interface IPart {
  number: number,
  title: string,
  _id: string,
  image_repeat?: string,
  length: number,
  //image: string | null,
  image_menu: boolean,
  active: boolean,
  libs_menu: boolean,
  text_menu: boolean,
  //file: File | null,
  content_images: any[],
  text: string[],
  canvasHandler: Viewer2dApi,
  part_width_subUnit: number,
  info: {
    //partWidth: number,
    minResolution: number
  }
}


const startLength = undefined
// const cmInI = 2.54

// const defState = [
//   {
//     number: 1,
//     title: "Part 1",
//     _id: "part_1",
//     length: startLength,
//     image: null,
//     image_menu: true,
//     active: true,
//     libs_menu: false,
//     text_menu: false,
//     file: null,
//     content_images: [],
//     text: [],
//   },
// ]
const defaultState: IPart[] = []

const _createId = () => "_" + Math.round(Math.random() * 36 ** 12).toString(36)

export const parts = (state = defaultState, action: any): IPart[] => {
  const {
    type,
    payload,
    part_id,
    img_url,
    part: canvasPart,
    part_length,
    length,
    totalLength,
    image,
    resizeImgType,
    size_wd,
    size_ht,
    lockedAR,
    fcSize,
    repeatType,
    textOpt,
    activity,
    file,
    info,
    imageInfoArray,
    scaleFactor,
    t,
  } = action

  const V = action.V || getSingletonViewerInstance()

  let fabricSegment = state.find(segment => segment.canvasHandler === V)

  log({ V }, 'CANVAS TRACK')

  switch (type) {
    // ===================================change part length
    case ACTIONS.CHANGE_PART_LENGTH: {

      let allPartsLength = state.reduce((acc, item) => {
        if (item !== fabricSegment) {
          return (acc += item.length)
        } else return acc
      }, 0)

      if (fabricSegment && parseFloat(length) > 0) {
        let freeLength = totalLength - allPartsLength
        let data = [...state]
        let updatedSegments = []
        let newLength= 0

        if (freeLength >= parseFloat(length)) {
          newLength = length
        } else {
          //usunit
          alertify.alert(t("editor.alert_warning_title1"), `${t("editor.fabric_exceeds_max_length")} ${freeLength} m.`, t("i18n.ok"), () => { })
          newLength = freeLength
        }

        // alert(newLength)

        log({newLength, total: allPartsLength}, "xxx in reducer")

        V.changePartLength(canvasPart._id, newLength)
        //updatedSegment = { ...fabricSegment, length: parseFloat(newLength) }
        data.forEach(seg=>{
          updatedSegments.push({...seg, length: newLength})
        })

        return [...data]
      } else return [...state]
    }
    // ===================================active part
    case ACTIONS.SET_ACTIVE_PART:
      V.scrollToPart(part_id)
      return [
        ...state.map(item =>
          item.canvasHandler === V
            ? { ...item, active: true }
            : { ...item, active: false, libs_menu: false, text_menu: false },
        ),
      ]

    // ===================================add/change part image
    case ACTIONS.ADD_IMAGES_TO_PART: {

      if (fabricSegment) {
        V.addImagesToPart(part_id, imageInfoArray)
        let data = [...state]
        let segmentIndex = state.findIndex(i => i.canvasHandler === V)
        let content_images: any[] = []

        let image_repeat = window.matchMedia('(max-width: 600px)').matches ? "DEFAULT_REPEAT" : "NON_REPEAT"
        let part_width_subUnit = 0;
        let partWidth = 0

        imageInfoArray.forEach((imageDetail: any, index: number) => {

          let { src: image, file, ...info } = imageDetail
          let lockedAR = true
          let image_size
          let image_size_ht
          let image_size_wd
          let max_image_size
          let min_image_size


          let minUnit = 1

          // let curentDPI = info.curResolution
          let currentResolution = getResolution(info.curResolution)
          // let minDPI = info.minResolution
          let minResolution = getResolution(info.minResolution)
          // let dpc = curentDPI / cmInI    // dpi to inch

          let maxImageSideSize = Math.max(info.width, info.height)

          if (maxImageSideSize > currentResolution * minUnit) {
            let partWidthSubUnit = getSubUnit(info.partWidth)
            let partHeightSubUnit = getSubUnit(part_length)
            let imgWidthSubUnit = info.width / currentResolution // image width recommended in current resolution(in cms)
            let imgHeightSubUnit = info.height / currentResolution  // image width recommended in current resolution(in cms)

            image_size = 3
            min_image_size = 10

            if (imgWidthSubUnit > partWidthSubUnit)
              imgWidthSubUnit = partWidthSubUnit

            image_size = partWidthSubUnit / imgWidthSubUnit

            let newImgSize = partWidthSubUnit / image_size
            let newResolution = (info.width) / newImgSize
            let maxImgSize = (info.width / minResolution)

            image_size_ht = Math.round(partHeightSubUnit / imgHeightSubUnit)

            max_image_size = (partWidthSubUnit / maxImgSize)

            if (newResolution <= minResolution) {
              image_size = (partWidthSubUnit / imgWidthSubUnit)
              image_size_ht = (partHeightSubUnit / imgHeightSubUnit)
              max_image_size = image_size
              min_image_size = image_size + 10
            }

            image_size_wd = image_size

            let { width, height } = info
            info.maxDimensions = getImageDim_max(width, height, minResolution, scaleFactor)
            info.minDimensions = getImageDim_min(width, height)

            let dims = getImageDim(width, height, partWidthSubUnit, minResolution, currentResolution, scaleFactor)

            //=============

            image_size_wd = dims.curSizeCm.width
            image_size_ht = dims.curSizeCm.height

            log({image_size_ht, image_size_wd}, 'IMAGE TRACK')
            //=============



            // let arrImages = [...part.content_images, image]
            let arrImages = {
              image,
              image_size,
              image_size_wd,
              image_size_ht,
              lockedAR,
              image_repeat,
              image_scale: newImgSize / imgWidthSubUnit,
              min_image_size,
              max_image_size,
              image_width_cm: imgWidthSubUnit,
              part_width_cm: partWidthSubUnit,
              file,
              info,
            }

            content_images.push(arrImages)

            // let arr = [...part.content_images, arrImages]
            // let newPart1 = {
            //   ...part,
            //   image,
            //   image_size,
            //   image_size_wd,
            //   image_size_ht,
            //   lockedAR,
            //   image_repeat,
            //   image_scale: newImgSizeSm / imgWidthCm,
            //   min_image_size,
            //   max_image_size,
            //   image_width_cm: imgWidthCm,
            //   part_width_cm: partWidthCm,
            //   content_images: arr,
            //   file,
            //   info,
            // }

            // let newPart = { ...newPart1 }


            // data[partIndex] = newPart

          } else {
            alertify.alert(t("editor.alert_warning_title2"), t("editor.image_too_small"), t("i18n.ok"), () => { })
          }
        })

        let updatedPart = { ...fabricSegment, content_images: [...fabricSegment.content_images, ...content_images], image_repeat, part_width_subUnit, info: { partWidth, minResolution: 1 } }
        data[segmentIndex] = updatedPart
        return [...data]
      } else {
        return state
      }
      //==============================================
    }
    // case ACTIONS.ADD_IMG_TO_PART: {
    //   if (fabricSegment && part_id && part_length && image) {
    //     let image_size
    //     let image_size_ht
    //     let image_size_wd
    //     let max_image_size
    //     let min_image_size
    //     let image_repeat = "NON_REPEAT"

    //     let minCM = 1

    //     let curentDPI = info.curResolution
    //     let minDPI = info.minResolution
    //     let dpc = curentDPI / cmInI    // dpi to inch

    //     let maxImageSideSize = Math.max(info.width, info.height)

    //     if (maxImageSideSize > dpc * minCM) {
    //       let partWidthCm = info.partWidth * 100
    //       let partHeightCm = part_length * 100
    //       let imgWidthCm = info.width / dpc  // image width recommended in current resolution(in cms)
    //       let imgHeightCm = info.height / dpc  // image width recommended in current resolution(in cms)

    //       let image_part_width = (info.partWidth * 10)  // blocks per 10cm
    //       let image_part_height = Math.ceil(part_length * 10)  // part length x 10 (m to cm) blocks per 10cm

    //       image_size = 3
    //       min_image_size = 10

    //       if (imgWidthCm > partWidthCm)
    //         imgWidthCm = partWidthCm

    //       image_size = partWidthCm / imgWidthCm

    //       let newImgSizeSm = partWidthCm / image_size
    //       let newDPI = (info.width * cmInI) / newImgSizeSm
    //       let maxImgSizeSm = (info.width / minDPI) * cmInI

    //       image_size_ht = Math.round(partHeightCm / imgHeightCm)

    //       max_image_size = (partWidthCm / maxImgSizeSm)

    //       if (newDPI <= minDPI) {
    //         image_size = (partWidthCm / imgWidthCm)
    //         image_size_ht = (partHeightCm / imgHeightCm)
    //         max_image_size = image_size
    //         min_image_size = image_size + 10
    //       }

    //       image_size_wd = image_size

    //       let ObjToPopulate: any = { width: info.width, height: info.height }
    //       let { width, height } = info
    //       let { minResolution } = info
    //       info.maxDimensions = getImageDim_max(width, height, info.fcSize, image_part_width * 10, image_part_height * 10, minResolution, scaleFactor)
    //       info.minDimensions = getImageDim_min(width, height)

    //       V.addImgToPart(part_id, image, image_size, image_size_ht, image_part_width, image_part_height, image_repeat, info, ObjToPopulate, info.curResolution)

    //       //=============
    //       let defaultImageWidth = Math.trunc(ObjToPopulate.part_width / ObjToPopulate.image_size)
    //       let defaultPartWidth = Math.abs(ObjToPopulate.part_width / ObjToPopulate.image_part_width)
    //       image_size_wd = Math.trunc((defaultImageWidth / defaultPartWidth) * 10)
    //       image_size_ht = Math.trunc((image_size_wd / ObjToPopulate.width) * ObjToPopulate.height)
    //       //=============

    //       let data = [...state]
    //       // let arrImages = [...part.content_images, image]
    //       let arrImages = {
    //         image,
    //         image_size,
    //         image_size_wd,
    //         image_size_ht,
    //         lockedAR,
    //         image_repeat,
    //         image_scale: newImgSizeSm / imgWidthCm,
    //         min_image_size,
    //         max_image_size,
    //         image_width_cm: imgWidthCm,
    //         part_width_cm: partWidthCm,
    //         file,
    //         info,
    //       }

    //       let arr = [...fabricSegment.content_images, arrImages]
    //       let newPart1 = {
    //         ...fabricSegment,
    //         image,
    //         image_size,
    //         image_size_wd,
    //         image_size_ht,
    //         lockedAR,
    //         image_repeat,
    //         image_scale: newImgSizeSm / imgWidthCm,
    //         min_image_size,
    //         max_image_size,
    //         image_width_cm: imgWidthCm,
    //         part_width_cm: partWidthCm,
    //         content_images: arr,
    //         file,
    //         info,
    //       }

    //       let newPart = { ...newPart1 }


    //       data[data.indexOf(fabricSegment)] = newPart

    //       return [...data]
    //     } else {
    //       alertify.alert(t("editor.alert_warning_title2"), t("editor.image_too_small"), t("i18n.ok"), () => { })
    //       return [...state]
    //     }
    //   } else return [...state]
    // }

    // ===================================toggle image menu
    case ACTIONS.TOGGLE_IMAGE_MENU: {
      if (part_id && fabricSegment) {

        let data = [...state]
        let newPart = { ...fabricSegment, image_menu: !fabricSegment.image_menu }

        data[data.indexOf(fabricSegment)] = newPart

        return [...data]
      } else return [...state]
    }

    // ===================================toggle library
    case ACTIONS.TOGGLE_LIBRARY_MENU: {
      
      if (part_id && fabricSegment) {
        let data = [...state]
        let index = data.indexOf(fabricSegment)
        data.forEach((segment,i)=>{
            segment.active = (i === index)
            segment.libs_menu = (i===index)?!segment.libs_menu : false
        })
        //let newPart = { ...fabricSegment, libs_menu: !fabricSegment.libs_menu 

        return data
      } else return [...state]
    }

    // ===================================toggle text
    case ACTIONS.TOGGLE_TEXT_MENU: {
      if (part_id && fabricSegment) {
        let data
        if (activity) {
          data = state.map(i => ({ ...i, text_menu: false }))
        } else data = [...state]

        let newPart = { ...fabricSegment, text_menu: activity }

        data[data.indexOf(fabricSegment)] = newPart

        return [...data]
      } else return [...state]
    }

    // ===================================change image size in cms by width
    case ACTIONS.CHANGE_IMAGE_SIZE_CMS_BY_WIDTH: {
      let isFieldEmpty = false
      if (size_wd === "" || size_ht === "") {
        isFieldEmpty = true
      }
      if (part_id && fabricSegment) {

        let data = [...state]

        let sz_wd = size_wd === "" ? 1 : size_wd
        let sz_ht = size_ht === "" ? 1 : size_ht//size_ht

        // let image_part_width = (fabricSegment.info.partWidth)  // blocks per 10cm
        // let image_part_height = (fabricSegment.length)  // part length x 10 (m to cm) blocks per 10cm

        let image = fabricSegment.content_images.find((item) => {
          log({ item }, 'adding Image : image search')
          return item.image === img_url
        })

        log({ img_url, image: image || 'NOT FOUND' }, 'adding Image : image found')
        if (image === undefined) {
          return [...state]
        }

        let particulars = image.info
        let actualResolution = particulars.curResolution
        actualResolution = getResolution(actualResolution)

        let AR = (particulars.height / particulars.width)
        let { width, height } = particulars
        let { minResolution } = fabricSegment.info
        minResolution = getResolution(minResolution)


        let { maxHeight, maxWidth } = getImageDim_max(width, height, minResolution, scaleFactor)
        let { minHeight, minWidth } = getImageDim_min(width, height)

        log({ sz_ht, sz_wd, maxHeight, maxWidth, minHeight, minWidth }, 'imgSizeHandler 2-init')

        sz_ht = parseFloat(sz_ht)
        sz_wd = parseFloat(sz_wd)

        if (sz_wd > maxWidth) {
          /*max-width exceeded*/
          alertify.alert(
            t("editor.alert_warning_title2"),
            `${t("editor.scale_too_high")} (${maxWidth} x ${maxHeight})`,
            t("i18n.ok")
          )
          sz_wd = maxWidth
          sz_ht = lockedAR ? (AR * sz_wd) : size_ht
        }
        if (sz_wd < minWidth) {
          log({ sz_wd, minWidth }, 'imgSizeHandler 2-fallen below')
          /*fallen below min-width*/
          alertify.alert(
            t("editor.alert_warning_title2"),
            t("editor.scale_too_small"),
            t("i18n.ok")
          )
          sz_wd = minWidth
          sz_ht = lockedAR ? (AR * sz_wd) : size_ht
        }

        sz_wd = (sz_wd / 1).toFixed(1)
        sz_ht = (sz_ht / 1).toFixed(1)

        let sz = (sz_wd / 10).toFixed(1)
        let newPart = {
          ...fabricSegment,
          // image_size: sz,
          // image_size_wd: sz_wd,
          // image_size_ht: sz_ht,
          // lockedAR,
          // image_scale: fabricSegment.part_width_cm / sz / part.image_width_cm,
        }

        log({ sz_ht, sz_wd }, 'imgSizeHandler 2-0')
        V.setImageSizeCmsByWidth(part_id, img_url, sz_wd, sz_ht, lockedAR, actualResolution, isFieldEmpty)
        data[data.indexOf(fabricSegment)] = newPart

        return [...data]
      }
      else return [...state]
    }

    // ===================================change image size in cms by height
    case ACTIONS.CHANGE_IMAGE_SIZE_CMS_BY_HEIGHT: {
      let isFieldEmpty = false
      if (size_ht === "" || size_wd === "") {
        isFieldEmpty = true
      }
      if (part_id && fabricSegment) {

        let data = [...state]

        let sz_wd = size_wd === "" ? 1 : size_wd
        let sz_ht = size_ht === "" ? 1 : size_ht

        // let image_part_width = (fabricSegment.info.partWidth)  // blocks per 10cm
        // let image_part_height = (fabricSegment.length)  // part length x 10 (m to cm) blocks per 10cm

        //============
        let image = fabricSegment.content_images.find((item) => item.image === img_url)
        if (image === undefined) {
          return [...state]
        }
        let particulars = image.info
        let actualResolution = particulars.curResolution

        let AR = (particulars.height / particulars.width)
        let { width, height } = particulars
        let { minResolution } = fabricSegment.info
        let { maxHeight, maxWidth } = getImageDim_max(width, height,  minResolution, scaleFactor)
        let { minHeight, minWidth } = getImageDim_min(width, height)

        sz_ht = parseFloat(sz_ht)
        sz_wd = parseFloat(sz_wd)

        if (sz_ht > maxHeight) {
          /*max-width exceeded*/
          alertify.alert(
            t("editor.alert_warning_title2"),
            `${t("editor.scale_too_high")} (${maxWidth} x ${maxHeight})`,
            t("i18n.ok")
          )
          sz_ht = maxHeight
          sz_wd = lockedAR ? ((1 / AR) * sz_ht) : size_wd
        }
        if (sz_ht < minHeight) {
          /*fallen below min-width*/
          alertify.alert(
            t("editor.alert_warning_title2"),
            t("editor.scale_too_small"),
            t("i18n.ok")
          )
          sz_ht = minHeight
          sz_wd = lockedAR ? ((1 / AR) * sz_ht) : size_wd
        }

        sz_wd = (sz_wd / 1).toFixed(1)
        sz_ht = (sz_ht / 1).toFixed(1)

        let sz = (sz_wd / 10).toFixed(1)
        let newPart = {
          ...fabricSegment,
          // image_size: sz,
          // image_size_wd: sz_wd,
          // image_size_ht: sz_ht,
          // lockedAR,
          // image_scale: part.part_width_cm / sz / part.image_width_cm,
        }

        V.setImageSizeCmsByHeight(part_id, img_url, sz_wd, sz_ht, lockedAR, actualResolution, isFieldEmpty)
        data[data.indexOf(fabricSegment)] = newPart

        return [...data]
      }
      else return [...state]
    }

    // ===================================change image size in cms
    case ACTIONS.REMOVE_IMAGE_FROM_PART: {
      if (part_id && img_url && fabricSegment) {


        let data = [...state]

        V.removeImageFromPart(part_id, img_url, fabricSegment)

        // let index = data.indexOf(part)
        // data.splice(index, 1)
        // data[data.indexOf(part)] = newPart

        return [...data]
      }
      else return [...state]
    }

    case ACTIONS.SET_IMAGE_REPEAT_TYPE: {
      if (part_id && repeatType && fabricSegment) {

        V.repeatImg(part_id, repeatType)
        let data = [...state]
        let newPart = { ...fabricSegment, image_repeat: repeatType }

        data[data.indexOf(fabricSegment)] = newPart

        return [...data]
      } else return [...state]
    }

    // ===================================add/update part text
    case ACTIONS.ADD_TEXT_TO_PART: {
      if (part_id && textOpt && fabricSegment) {
        let data = [...state]

        if (!textOpt.text_id) {
          // add text

          let newText = { ...textOpt, text_id: _createId() }

          let newPart = {
            ...fabricSegment,
            text: [...fabricSegment.text, newText],
          }

          V.addupdataText(part_id, newText)
          data[data.indexOf(fabricSegment)] = newPart
        } else {
          // update text

          let updatedText = { ...textOpt }
          let allText = [...fabricSegment.text]
          let oldText: any = allText.find((i: any) => i.text_id === updatedText.text_id)

          allText[allText.indexOf(oldText)] = updatedText

          let newPart = {
            ...fabricSegment,
            text: allText,
          }

          V.addupdataText(part_id, updatedText)
          data[data.indexOf(fabricSegment)] = newPart
        }
        return [...data]
      } else return [...state]
    }

    case ACTIONS.CLEAR_PARTS:
      V.claerParts()
      return defaultState
    case ACTIONS.REMOVE_FABRIC_SEGMENT:
      if(payload){
        let fabricSegmentIndex = state.findIndex(segment => segment.canvasHandler === payload.fabricCanvas);
        (fabricSegmentIndex+1) && state.splice(fabricSegmentIndex,1)
        state = [...state]
      } 
      return state 

    case ACTIONS.ADD_FABRIC_SEGMENT:
      log({payload}, 'ADDING SCEN SEGMENT')
      if (payload) {

        const { fabricCanvas, rowIndex, coulmnIndex, length, fabricWidth, repeatType } = payload
        let fabricSegmentIndex = state.findIndex(segment => segment.canvasHandler === fabricCanvas);
        (fabricSegmentIndex+1) && state.splice(fabricSegmentIndex,1)
        const index = fabricSegmentIndex + 1 ? fabricSegmentIndex : state.length
        let newFabricSegment:IPart = {
          number: index,
          title: "Part 1",
          _id: "part_1",
          length,
          image_menu: true,
          active: true,
          part_width_subUnit: getSubUnit(fabricWidth),
          image_repeat: repeatType,
          libs_menu: false,
          text_menu: false,
          content_images: [],
          text: [],
          canvasHandler: fabricCanvas,
          info:{
            //partWidth: fabricWidth,
            minResolution: 1
          }
        }
        state = [...state,newFabricSegment]

      }
      return state

    case ACTIONS.CLEAR_IMAGES:
      let data = [...state]
      let tempState = data.map((segment, index) => {
        return {
          ...segment,
          image_repeat: undefined,
          number: index,
          title: "Part 1",
          _id: "part_1",
          length: segment.length,
          image: null,
          image_menu: true,
          active: true,
          libs_menu: false,
          text_menu: false,
          file: null,
          content_images: [],
          text: [],

        }
      })

      V.clearImages()

      return tempState//defState

    default:
      return state
  }
}
