import React, {Fragment, useState, useEffect, useCallback} from "react"
import {useSelector, useDispatch} from "react-redux"

import Select from "./Select/Select"

import styles from "./header.module.scss"

import {setProduct} from "../../redux/actions/product"
import {clearParts} from "../../redux/actions/parts"

import {useDecode} from "../../hooks/useDecode"
import { useTranslation } from "react-i18next"
import { log } from "../../utilities/logger"

/**
 * Header data
 * @component
 * @param {object} props
 * @param {string} props.type - data type
 * @example

 * return (
 *  <Data typpe="BLUR"/>
 * )
 */
const Data = props => {
  const {type = ""} = props

  const products = useSelector(state => state.products)
  const product = useSelector(state => state.product)
  const parts = useSelector(state => state.parts)

  const currency = useDecode(
    product && product.currency ? product.currency : "",
  )

  const dispatch = useDispatch()

  const [active, setActive] = useState(false)
  const [data, setData] = useState({length: 0, price: 0})
  const {t} = useTranslation()
  /**
   * Open/close select with products
   * @param {object} e element
   * @method
   */
  const toggleSelect = e => {
    e.preventDefault()
    setActive(prev => !prev)
  }

  const focusOut = e => {
    setActive(prev => false)
  }

  /**
   * Select product
   * @param {object} e element
   * @method
   */
  const selectProd = e => {
    e.preventDefault()

    if (
      product &&
      e.target.dataset.value.split(" ").join("").trim() ===
        product.name.split(" ").join("").trim()
    )
      return

    // product && dispatch(clearParts())

    let p = products.find(i => i.name === e.target.dataset.value)
    requestAnimationFrame(() => dispatch(setProduct(p)))
    setActive(prev => !prev)
  }

  const fbdata = useSelector(state => state.fbdata)
  const stepLength = fbdata.fabricUnitSizeCm || 1
  const settings = useSelector(state => state.settings)||{}
  const isSplit = settings.configuration?.split

  const{rows} = useSelector((state)=>state.fabric.settings?.grid)||{}

  log({settings}, 'SETTINGS HELLO')

  useEffect(() => {
    if (settings.display && settings.configuration) {
      let {currentLength} = settings.display
     
      setData({length:currentLength, price: currentLength*product?.price||0})
    }
  }, [settings, product])

  return (
    <div className={styles.data}>
      <div className={styles.dataCol}
          style={type === "BLUR"?{border:'4px dashed rgba(131,36,253,1)'}:{}}>
        <div className={styles.content}>
          <Select
            label={product ? product.name : `${t("editor.select_fabric")}`}
            active={active}
            handleToggle={toggleSelect}
            handleSelect={selectProd}
            onfocusout={focusOut}
            options={products}
          />
        </div>
      </div>
      <div
        style={type === "BLUR" ? {opacity: 0} : {}}
        className={`${styles.dataCol} ${styles.dataCol_sm}`}
      >
        <div className={styles.content}>
          <span className={styles.content_title}>{t("editor.total_price")} </span>
          <span>
            <Fragment>{product && product.currency ? currency : ""}</Fragment>
            <Fragment>
              {product && product.price && !product.currency ? "$" : ""}{" "}
              {product && product.price ? data.price.toFixed(2) : ""}
            </Fragment>
          </span>
        </div>
      </div>
      <div
        style={type === "BLUR" ? {opacity: 0} : {}}
        className={`${styles.dataCol} ${styles.dataCol_sm}`}
      >
        <div className={styles.content}>
          <span className={styles.content_title}>{t("editor.total_length")}</span>
          {/* usunit */}
          {/* <span>{parts && parts.length ? `${data.length}m` : ""}</span> */}
          <span>{isSplit && isSplit.fatQuarter?(rows||1):data.length}</span>
        </div>
      </div>
    </div>
  )
}

export default Data
