import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from "react-redux"
import Slider from 'react-input-slider'
import { getSingletonViewerInstance } from '../../libs/viewer'

const SLIDER_YMAX = 100 //(default)
const SLIDER_STEP = 12.5
const THUMB_HEIGHT = 20


const Scrollbar = ({ trackerHeight, contentHeight }) => {

  const [scrollState, setScrollState] = useState({ progress: 0 })
  const [scrolledContent, setScrolledContent] = useState({ height: contentHeight, step: contentHeight / 25 })
  const scrolled = useRef(0)



  const parts = useSelector(state => state.parts)
  const product = useSelector(state => state.product)

  useEffect(() => {
    let sliderStep = contentHeight / 25
    setScrolledContent({ height: contentHeight, step: sliderStep })
  }, [contentHeight])

  useEffect(() => {
    if (parts && parts.length && product)
      V.connect({
        scrollbarProp: (event) => {
          let { length, position } = event
          setScrolledContent({ height: length, step: length / (25) });
          (position !== undefined) && setScrollState({ progress: position })
        }
      })
  }, [parts, product])

  const V = useRef(getSingletonViewerInstance()).current

  return <div>
    <Slider
      ymin={0}
      ymax={scrolledContent.height}
      ystep={scrolledContent.step}
      styles={{
        track: {
          backgroundColor: "#fff",
          width: 8,
          borderRadius: 0,
          height: trackerHeight-THUMB_HEIGHT,
        },
        active: {
          backgroundColor: "#fff",
        },
        thumb: {
          width: 5,
          height: THUMB_HEIGHT,
          borderRadius: 1,
          backgroundColor: "#DCDEE5",
        },
      }}
      axis='y' y={scrollState.progress} onChange={(e) => {
        let lastProgress = scrolled.current
        let scrollViewerBy = lastProgress - e.y

        if ((lastProgress !== e.y) || (e.y === 0))
          V.scrollHandler(scrollViewerBy || scrolledContent.step)
        else
          return

        scrolled.current = e.y
        setScrollState({ progress: e.y })
      }} />
  </div>

}
export default Scrollbar

