import Viewer from "./viewer"
import Info from "./View3D/Info"
import Viewer2D from "./View3D/Viewer2D"

class GViewer {

    init(options) {
            this.info = new Info(options);
            this.viewer = new Viewer(false, options, options.node3D, this.info);
            this.viewer2D = new Viewer2D(true, options, options.node2D, this.info);
            this.viewer.ngb = this.viewer2D;
            this.viewer2D.ngb = this.viewer;
            this.info.viewers.push(this.viewer, this.viewer2D);
    }

    get view() { return this.info?.activePiece?.clickEvent?.target?.viewer || this.viewer || this.viewer2D; }
    setCursor(cursor) {
        this.view.setCursor(cursor);
    }

    update() {
        this.viewer && this.viewer.updateRendererSize();
    }
    update2D() {
        this.viewer2D && this.viewer2D.updateRendererSize();
    }

    setColor(hex, all) {
        this.view.setColor(hex, all);
    }

    setLogo(imgUrl, offsetX, offsetY, logoParams, view, fileName, pointerCaptured) {
        const viewer = view || this.view;
        this.imageToAdd = null;
        viewer.renderer.domElement.style.cursor = "pointer";
        return viewer.putLogo(imgUrl, offsetX, offsetY, logoParams, fileName, pointerCaptured);
    }

    setText(options, offsetX, offsetY, view, pointerCaptured) {
        const viewer = view || this.view;
        viewer.putText(options, offsetX, offsetY, pointerCaptured)
        this.textToAdd = null
        viewer.renderer.domElement.style.cursor = "pointer"
    }

    setHotspotsVisible(value) {
        if (this.viewer) this.viewer.hotspots.visible = value;
        if (this.viewer2D) this.viewer2D.hotspots.visible = value;
    }

    setSnapToHotspots(value) {
        if (this.viewer) this.viewer.snapToHotspots = value;
        if (this.viewer2D) this.viewer2D.hotspots.visible = value;
    }

    defineItemToDrop(e, type, options) {
        switch (type) {
            case "text":
                e.dataTransfer.setData("textToDrop", JSON.stringify(options))
                break
            case "image":
                e.dataTransfer.setData("imageToDrop", options)
                break
            default:
                console.log("WRONG D&D ITEM")
        }
    }

    toggleFlatMode(active) {
        return this.viewer.toggleFlatModel(active)
    }

    getAllLogos() {
        return this.info.getAllLogos()
    }

    repeatLogo(logo) {
        this.view.repeatLogo(logo)
    }

    // flip = true means flip the image
    logoFlipH(logo, flip) {
        this.view.logoFlipH(logo, flip);
    }

    // flip = true means flip the image
    logoFlipV(logo, flip) {
        this.view.logoFlipV(logo, flip);
    }

    // to send back put steps = -1000
    // to bring front put steps = 1000
    // i think refreshLogos must be called to update the elements part
    logoMoveZOrder(logo, steps) {
        this.view.logoMoveZOrder(logo, steps);
    }

    getActivePart() {
        return this.info.activePiece;
    }

    resize = () => {
        this.viewer2D.updateRendererSize();
        return this.viewer.updateRendererSize();
    }

    syncWith2d = (imgArr, side) => {
        return this.viewer.syncWith2d(imgArr, side)
    }

    getPartsList() {
        return this.info.pieces
    }

    changeModel(json, model, flatModel) {
        this.viewer.setMainModel(model, json, flatModel)
        this.viewer2D.setMainModel(model, json, flatModel)
    }

    updateLogo(logo, params) {
        this.view.updateLogo(logo, params)
    }

    replaceLogoImage(logo, imgUrl){
        this.view.replaceLogoImage(logo, imgUrl);
    }

    deleteLogo(logo) {
        this.view.deleteLogo(logo)
    }

    updateText(logo, params) {
        this.view.updateText(logo, params)
    }

    selectLogo(logo) {
        this.view.selectLogo(logo)
    }

    getZip(callback) {
        this.view.getScreenShots().then(res => {
            if (callback) callback(res)
        })
    }

    selectPart(part) {
        this.view.selectPart(part)
    }

    getViewerInstanceInFocus(displayMode) {
        if (displayMode === '2D') return this.viewer2D;
        else if (displayMode === '3D') return this.viewer;
        else return undefined
    }

    isHit(x, y, event) {
        return this.viewer.isHit(x, y, event) || this.viewer2D.isHit(x, y, event)
    }

    getAdjustments() {
        let rect = this.viewer?.node?.getBoundingClientRect() || this.viewer2D?.node?.getBoundingClientRect()
        if (rect) {
            const urlBarHeight = (document.getElementById("icb-size-determiner")?.clientHeight||0) - window.innerHeight
            const { top, left } = rect;
            return {
                x: left,
                y: top + urlBarHeight,
            }
        }
        return undefined
    }

    isMobileViewModelTouched(x, y) {

        let rect = this.viewer?.node?.getBoundingClientRect() || this.viewer2D?.node?.getBoundingClientRect()
        if (rect) {
            const { top, left } = rect;
            return this.isHit(
                x - left,
                y - top,
            )
        }
        return undefined
    }

    GetHit(x, y, event) {
        if (this.viewer.isHit(x, y, event))
            return this.viewer.node;
        if (this.viewer2D.isHit(x, y, event))
            return this.viewer2D.node;
        return undefined;
    }

    GetContainerBounds(x) {

        let isHit = undefined
        let view = undefined

        if (this.viewer.node) {
            const rect = this.viewer.node.getBoundingClientRect()
            isHit = (x > rect.left && x < rect.right) ? { x: rect.left, y: rect.top } : undefined
            view = isHit ? this.viewer : undefined
        }
       
        if (!isHit && this.viewer2D.node) {
            const rect = this.viewer2D.node.getBoundingClientRect()
            isHit = (x < rect.right && x > rect.left )? { x: 0, y: rect.top } : undefined
            view = isHit ? this.viewer2d : undefined
        }
        
        return isHit ? { ...isHit, view } : undefined
    }

    getPointer(event){
        return this.viewer.getPointer(event) || this.viewer2D.getPointer(event)
    }

    scaleLogoToUV(logo) {
        this.view.scaleLogoToUV(logo)
    }


    scaleSelectedLogoToUV() {
        this.viewer.scaleSelectedLogoToUV()
    }

    async coverAll(model, imageData, imagePlacement, dict) {

        return await this.viewer.coverAll(model, imageData || this.currentOriginalImage, imagePlacement, dict)

    }

    async getDesignDictionary(model, image) {
        try {
            return await this.viewer.getDesignDictionary(model, image || this.currentOriginalImage)
        } catch (error) {
            throw error
        }

    }

    async getJsonInfo(model, image) {
        try {
            return await this.viewer.getJsonInfo(model, image || this.currentOriginalImage)
        } catch (error) {
            throw error
        }

    }

    sortLogos(logos) {
        this.view.reorderLogos(logos)
    }
    loadZipFile(zipFile) {
        this.view.loadZip(zipFile)
    }
}

let gv = new GViewer()

// gv.init({

//     node: document.getElementById('viewer'),
//     initialModel: './assets/models/sweatshirt/S/ForWeb/SWEATSHIRT_S.fbx',
//     modelDataUrl: './assets/models/sweatshirt/S/ForWeb/SWEATSHIRT_S.json',
//     defaultPiecesColor: "#ffffff",
//     defaultRotationX: 0,
//     debugMode: true,
//     canvasSize: {
//         x: 3000,
//         y: 4056
//     },

//     onReady: () => {
//         console.log('ready')
//     },
//     onMouseDown: () => {
//         console.log('onMouseDown');
//     },
//     onNewLogo: (logos, logo) => {

//     },
//     onClick: ((item, event) => {
//         console.log('onClick', item);
//         if(item){
//             gv.selectedElement = item;
//             document.getElementById('logoName').innerHTML = '';
//             document.getElementById('logoSize').value = '';
//             document.getElementById('logoAngle').value = '';
//             document.getElementById('logoX').value = '';
//             document.getElementById('logoY').value = '';
//             if(item.src){
//                 console.log('CLICKED LOGO', item, event);
//                 document.getElementById('logoName').innerHTML = item.src.currentSrc;
//                 document.getElementById('logoSize').value = item.rate;
//                 document.getElementById('logoAngle').value = item.angle;
//                 document.getElementById('logoX').value = item.px;
//                 document.getElementById('logoY').value = item.py;
//             } else {
//                 console.log('CLICKED PART', item, event);
//             }
//         } else {
//             gv.selectedElement = null;
//         }
//     })
// })

window.api = gv

export default gv

// window.api = new GViewer({
//     node: document.getElementById('viewer'),
//     initialModel: '/assets/models/shirt.fbx',
//     defaultPiecesColor: "#e618f0",
//     onMouseUp: (item => {
//         console.log('CLICKED', item);
//     })
// });
