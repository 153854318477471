import {useState, useEffect, useCallback} from "react"

/**
 * ### `react custom hook`
 * Get window sizes
 * @return {object} windowSize - object with window sizes
 * @example useWindowSize()
 */
const useWindowSize = () => {
  const isClient = typeof window === "object"

  const getSize = useCallback(
    () => ({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }),
    [isClient],
  )

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    const handleResize = () => {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    window.addEventListener("load", handleResize)
    window.addEventListener("orientationchange", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("load", handleResize)
      window.removeEventListener("orientationchange", handleResize)
    }
  }, [getSize, isClient])

  return windowSize

  // const [windowSize, setWindowSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // })

  // const handleResize = () => {
  //   setWindowSize({
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   })
  // }

  // useEffect(() => {
  //   handleResize()
  //   window.addEventListener("resize", handleResize)
  //   window.addEventListener("orientationchange", handleResize)
  //   return () => {
  //     window.removeEventListener("resize", handleResize)
  //     window.removeEventListener("orientationchange", handleResize)
  //   }
  // }, [])

  // return windowSize
}

export default useWindowSize
