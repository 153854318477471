import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Viewer2dApi } from '../../libs/viewer'
import { Viewer2d } from '../../libs/viewer/viewer'
import { addFabricSegment, removeFabricSegment } from '../../redux/actions/parts'
import { ISegmentSettings } from '../../redux/reducers/fabric'
import { REMOVE_SEGMENT_SETTINGS, SEGMENT_IN_FOCUS, SEGMENT_SETTINGS_UPDATE } from '../../redux/reducers/fabric/actions'
//import V from "../../libs/viewer"
import { ACTIONS_DESELECT_IMAGE, ACTIONS_SELECT_IMAGE, ACTIONS_UPSERT_IMAGE, REMOVE_SEGMENT_IMAGES } from '../../redux/reducers/images'
import { log } from '../../utilities/logger'
import { ProductUpsertInput } from './Scen'

export const SEGMENTS = 2;
export const COLUMNS_INCREMENT = 1;

export interface IScen {

  activeUrl: (inputObj: any, id: string) => void
  imageSelectionChange1: (inputObj: any, val: any) => void
  imageSelectionChange2: (inputObj: any, val: any) => void
  partLengthChanged: (inputObj: any, change?:number) => void
  loadImage: (progress: any) => void
  toastify: (inputObj: any) => void
  fabricSizeUnit: 'YARD' | 'METER'

}

export interface ISegment {
  widthPx: number
  heightPx: number
  totalColumns: number
  totalRows: number
  index: number
}

export interface ISegmentProps extends IScen, ISegment {

}

export const ScenSegment = (props: ISegmentProps) => {

  const { widthPx, heightPx, totalColumns = 1, totalRows = 1, index } = props

  const node = useRef(null)
  const product = useSelector((state: any) => state.product)
  const apikey = useSelector((state: any) => state.apikey)
  const fbdata = useSelector((state: any) => state.fbdata)
  const segmentInFocus = useSelector((state: any) => state.fabric.segmentInFocus)
  const stepSize = fbdata.fabricUnitSizeCm || 1
  const fabricQtyByUnit = fbdata.fabricQtyByUnit !== undefined ? fbdata.fabricQtyByUnit : false
  const isSplit = useSelector((state:any) => state.settings?.configuration?.split)
  const displayLength = useSelector((state:any) => state.settings?.display?.currentLength)||stepSize
  


  const V = useRef(new Viewer2dApi()).current
  const timerHandle = useRef(undefined as any)

  const isMobile = window.matchMedia('(max-width: 600px)').matches

  log({widthPx, heightPx}, 'track render 2')

  useEffect(() => {
    log({index}, 'ADDING SCEN SEGMENT')
    const columnIndex = index%totalColumns
    const rowIndex = Math.floor(index/totalColumns)
    const productWidth = product.width
    V && dispatch(addFabricSegment(V, rowIndex, columnIndex, displayLength,productWidth ))
    if (index === 0) {
      dispatch({
        type: SEGMENT_IN_FOCUS,
        payload: V
      })
    }
    V && dispatch({
      type: SEGMENT_SETTINGS_UPDATE, payload: {
      parentCanvas: V,
        repeatType: isMobile ? 'DEFAULT_REPEAT' : 'NON_REPEAT',
      columnIndex,
      rowIndex
      } as ISegmentSettings
    })

  }, [V])

  const { t } = useTranslation()

  useEffect(()=>{

    return ()=>{
      dispatch({
        type: REMOVE_SEGMENT_IMAGES, payload: {
        parentCanvas: V,
        }
      })
      dispatch(removeFabricSegment(V))
      dispatch({
        type: REMOVE_SEGMENT_SETTINGS, payload: {
        parentCanvas: V,
        }
      })
      V && V.dispose()
    }

   },[])

  const selectedImage = useSelector((state: any) => state.canvasImages && state.canvasImages.selectedImage, (left, right) => {
    return (left?.id === right?.id) && (left?.rotation === right?.rotation)
  })

  useEffect(() => {
    if (V && selectedImage && selectedImage.url && selectedImage.parentCanvas === V)
      V.selectImage(selectedImage.url)
  }, [V, selectedImage && selectedImage.url])

  useEffect(() => {
    if (V && (selectedImage?.rotation !== undefined) && selectedImage.parentCanvas === V) {
      V.rotateImage(selectedImage.url, selectedImage.rotation)
    }
  }, [V, selectedImage?.rotation])

  const dispatch = useDispatch()

  const productCreated = useRef(false)

  const effectiveProductWidth = product.width/(isSplit.fatQuarter?SEGMENTS:1)

  useEffect(() => {
    if (node.current && product) {
      V.init({
        isSplit,
        node: node.current,
        stor: apikey,
        selTarget: (inputObj: any, id: string) => { props.activeUrl(inputObj, id) },
        onImageUpsert: async ({ id, minResolution, tileOriginalWidth, tileOriginalHeight, tileWidth, tileHeight, url, widthSubUnit, heightSubUnit, widthLimits, heightLimits, locked, angle }: any) => {
          log({widthSubUnit}, 'width limits upsert')
          setTimeout(() => {
            dispatch({ type: ACTIONS_UPSERT_IMAGE, payload: { id,minResolution, tileOriginalWidth, tileOriginalHeight, tileWidth, tileHeight, url, widthSubUnit, heightSubUnit, widthLimits, heightLimits, locked, angle, parentCanvas: V } })
            dispatch({ type: ACTIONS_SELECT_IMAGE, payload: { id, url, parentCanvas: V } })
          }, 1)
        },
        // onImageRemoved: (id) => {
        //   dispatch({ type: ACTIONS_REMOVE_IMAGE, payload: { id } })
        // },
        onImageSelected: ({ id, url }: any) => {
          if (url)
            dispatch({ type: ACTIONS_SELECT_IMAGE, payload: { id, url, parentCanvas:V } })
          else
            dispatch({ type: ACTIONS_DESELECT_IMAGE })
        },
        canvasSegmentInFocus: () => {

        },
        selImageSzWidth: (inputObj: any, val: any) => { props.imageSelectionChange1(inputObj, val) },
        selImageSzHeight: (inputObj: any, val: any) => { props.imageSelectionChange2(inputObj, val) },
        curPartLength: (inputObj: any, change?:any) => { 
          log({inputObj, change, index},'TRACK xxx')
          props.partLengthChanged(inputObj, change)
         },
        loadImage: (progress: any) => props.loadImage(progress),
        toastify: (inputObj: any) => { props.toastify(inputObj) }
      })

      V.setProps({
        fabricWidth: effectiveProductWidth,
        fabricLength: product.totalLength,
        fabricName: product.name,
        t: t,
      })
    }
  }, [apikey, product, isSplit])

  useEffect(() => {
    if (V && node.current && product && widthPx) {
      let viewerInput: ProductUpsertInput = { productLength: 0, productWidth: 0, stepSizeMeters: 0 }
      
      switch (props.fabricSizeUnit) {
        case 'YARD':
          // viewerInput.productWidth = (effectiveProductWidth / 1.094) //yard to meter
          // viewerInput.productLength = (displayLength / 1.094) //yard to meter
          // viewerInput.stepSizeMeters = stepSize / 1.094
          // break;
        case 'METER':
          viewerInput.productWidth = effectiveProductWidth
          viewerInput.productLength = displayLength
          viewerInput.stepSizeMeters = stepSize
          break;
        default:
          break;

      }
      
      log({widthPx, heightPx}, 'track render 2')

      if (!productCreated.current) {
        log({ viewerInput, totalRows }, 'CREATE PRODUCT part size calculation')
        V.createProduct({ width: widthPx, height: heightPx }, {
          width: viewerInput.productWidth / totalColumns,
          length: viewerInput.productLength / totalRows,
        }, viewerInput.stepSizeMeters, fabricQtyByUnit)
        productCreated.current = true
      } else {
        log({ index , widthPx, heightPx }, 'UPDATE PRODUCT part size calculation')

        timerHandle.current && clearTimeout(timerHandle.current)
        timerHandle.current = setTimeout(()=>{
        V.updateProduct({ width: widthPx, height: heightPx }, {
          width: viewerInput.productWidth / totalColumns,
          length: viewerInput.productLength / totalRows,
        }, viewerInput.stepSizeMeters)
        }, 300)
       
      }
      }
    return ()=>{
      timerHandle.current && clearTimeout(timerHandle.current)
    }
  }, [product, widthPx, heightPx, totalColumns, totalRows, displayLength, effectiveProductWidth])

  log({ segmentInFocus }, 'SEGMENT IN FOCUS')

  log({ index , widthPx, heightPx }, 'create scen segment')

  return <div key={`${index}`} style={{
    border: '1px solid grey',
    overflow: 'hidden',
    display: 'block',
    height: props.heightPx, width: props.widthPx
  }} >
    <canvas style={{opacity: segmentInFocus === V? 1: 0.4}} ref={node} />
    {segmentInFocus === V ? null : <div style={
      {
        position: 'absolute', top: Math.floor(index/totalColumns)*props.heightPx, height: props.heightPx, width: props.widthPx,
        display: 'flex', backgroundColor: '#00000033', justifyContent: 'center', alignItems: 'center'
      }}>
      <button style={{backgroundColor:'transparent', border: '0px solid'}} onClick={() => dispatch({
        type: SEGMENT_IN_FOCUS,
        payload: V 
      })}>
        <img src={'/img/icons/Edit.svg'} style={{cursor:'pointer'}}/>
      </button>
    </div>}
  </div>

}