import React from "react"
import styles from "./repeatbtn.module.scss"

/**
 * Button for repeat image
 * @component
 * @param {object} props - all component props
 * @param {function} props.handler - click function
 * @param {string} props.name -  btn name
 * @param {string} props.img - btn img
 * @param {string} props.title - btn title
 * @param {boolean} props.active - btn state
 * @example
 *
 * const handler = () => {}
 * const name = ""
 * const img = ""
 * const title = ""
 * const active = false
 *
 * return (
 * <RepeatBtn
 *      handler={handler}
 *      name={name}
 *      img={img}
 *      title={title}
 *      active={active}
 * />
 * )
 */

const RepeatBtn = props => {
  const {
    handler = () => {},
    name = "",
    img = "",
    title = "",
    active = false,
  } = props

  return (
    <button
      className={`${styles.container} ${active ? styles.active : ""}`}
      disabled={active}
      name={name}
      onClick={handler}
    >
      <div className={styles.container_icon}>
        <img className={styles.pattern_icon} src={img} alt="pattern" />
      </div>

      <div className={styles.container_title}>{title}</div>
    </button>
  )
}

export default RepeatBtn
