import {storage} from "../../../../../../firebase"

const helpers = {
  getDirectLink: async (url, action) => {
    if (url && !url.startsWith("http")) {
      // download internal image
      if (action) {
        storage
          .refFromURL(url)
          .getDownloadURL()
          .then(file => {
            action(file)
          })
      } else {
        return await storage.refFromURL(url).getDownloadURL()
      }
    } else {
      return action ? action(url) : url
    }
  },
}

export default helpers
