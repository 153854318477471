/**
 * Root react component
 * @file
 */

import React, {useState, useEffect, useRef, Fragment} from "react"
import {useDispatch, useSelector} from "react-redux"

import {fireDir} from "./firebase"

import Spinner from "./components/Spinner/Spinner"
import Application from "./Application/Application"
import Error from "./components/Error/Error"

import useIframe from "./hooks/useIframe"

import {setSpinner} from "./redux/actions/spinner"
import {setApiKey} from "./redux/actions/apikey"
import {setProducts, setProduct, setCurrentImage} from "./redux/actions/product"
import {getImgs} from "./redux/actions/imglib"
import {getFbData} from "./redux/actions/fbdata"
import alertify from "./libs/alert"
import { DEBUG, log } from "./utilities/logger"
import { useTranslation } from "react-i18next"
import ACTIONS from "./redux/constatnts/index"
import { UPDATE_UNIT_TYPE } from "./redux/reducers/settings"
import { FIREBASE_UNIT } from "./libs/viewer/canvasPixelConvertor"

export const API_KEY = "ybPPY178g0SeiPxeKN4ARJzeVX02"

export const CanvasGrid = React.createContext({ rows: 0, columns: 0 })

function App() {
  const [auth, setAuth] = useState(0)
  const [canvasGridConfig, setCanvasGridConfig] = useState({ rows: 0, columns: 0 })

  const apikey = useSelector(state => state.apikey)
  const spinner = useSelector(state => state.spinner)
  const products = useSelector(state => state.products)
  const product = useSelector(state => state.product)
  const {t, i18n} = useTranslation()
  const dispatch = useDispatch()
  const isIframe = useIframe()
  const languageSettingConfigured = useRef(undefined)

  const defaultApiKey = API_KEY
  //usunit
  //const [language, setLanguage] = useState('en')

  useEffect(() => {
    if (apikey) {
      fireDir
        .doc(apikey)
        .get()
        .then(e => {
          let data = e.data()
          log(data, 'IFRAME')
          if (data) {
            (!languageSettingConfigured.current) && dispatch({
              type: UPDATE_UNIT_TYPE,
              payload: { unitType: data.lang === "en" ? 'METER' : 'YARD' }
            })
            FIREBASE_UNIT.unit = (data.lang === "en" ? 'METER' : 'YARD')
            dispatch(getFbData(data))
            setAuth(1)
          } else {
            setAuth(-1)
            dispatch(setSpinner(false))
          }
        })
    }
  }, [apikey, dispatch])

  useEffect(() => {
    const stopRequest = () => {
      let url =
        window.location !== window.parent.location
          ? document.referrer
          : document.location.origin

      window.parent.postMessage(
        JSON.stringify({
          action: "viewerReady",
        }),
        url,
      )
    }

    if (isIframe === 1) {
      window.addEventListener("message", ({data}) => {
        stopRequest()
        if (data.apiKey) {
          // setLanguage(data.lang||data.language)
          const {lang, language} = data
          const languageSetting = lang || language;
          if(languageSetting){
            (FIREBASE_UNIT.unit = languageSetting === "en" ? 'METER' : 'YARD');
            dispatch({
            type: UPDATE_UNIT_TYPE,
              payload: { unitType: languageSetting === "en" ? 'METER' : 'YARD' }
          })
            languageSettingConfigured.current = languageSetting
          }
          
          dispatch(setApiKey(data.apiKey))
          log("ALL DATA FROM WP", data)

          const { rows, columns } = data.selectedProd || {}

          if ((rows && columns)) {
            setCanvasGridConfig({ rows, columns })
          }

          if (data.selectedProd && data.selectedProd.imgLib) {
            dispatch(getImgs(data.selectedProd.imgLib))
          }

          if (
            data.selectedProd &&
            data.selectedProd.fabricList &&
            data.selectedProd.fabricList.length
          ) {
            dispatch(setProducts(data.selectedProd.fabricList))
          }

          if (
            data.selectedProd &&
            !data.selectedProd.currentImg &&
            data.selectedProd.currentFabric
          ) {
            dispatch(
              setProduct({
                ...data.selectedProd.currentFabric,
                sku: data.selectedProd.sku ?? "notfound",
              }),
            )
          }

          if (
            data.selectedProd &&
            data.selectedProd.currentImg &&
            data.selectedProd.fabricList &&
            data.selectedProd.fabricList.length
          ) {
            dispatch(
              setProduct({
                ...data.selectedProd.fabricList[0],
                sku: data.selectedProd.sku ?? "notfound",
               
              }),
            )
          }

          if (data.selectedProd && data.selectedProd.currentImg) {
            dispatch(setCurrentImage(data.selectedProd.currentImg))
          }

          dispatch(setSpinner(false))
        }
      })
    }

    if (isIframe === -1) {
      dispatch(setApiKey(defaultApiKey))
    }
  }, [dispatch, isIframe])

  useEffect(() => {
    if (isIframe === -1 && auth === 1) {
      fetch("/testdata/productsUS.json")
        .then(res => res.json())
        .then(res => {
          if (res.products && res.products.length) {
            if (process.env.NODE_ENV === "development") {
              dispatch(setProducts(res.products))
              dispatch(setProduct(res.products[0]))
            } else {
              dispatch(setProducts(res.products))
            }
            dispatch(setSpinner(false))
          }
        })
        .catch(err => {
          log(`Error: ${err}`)

          alertify.alert(t("editor.alert_warning_title2"), t("editor.alert.warning_msg1"), t("i18n.ok"), () => {
            dispatch(setSpinner(false))
          })
        })
    }
  }, [auth, dispatch, isIframe])

  return (
    <Fragment>
      <Spinner active={spinner} />
      {auth === 1 && (products.length || product) ?
        <CanvasGrid.Provider value={canvasGridConfig}>
          <Application />
        </CanvasGrid.Provider>
        : null}
      {auth === -1 ? <Error text={"ERROR: Api key is not valid"} /> : null}
      {!product && !products.length && auth === 1 ? (
        <Error text={"ERROR: product and products list is empty "} />
      ) : null}
      {DEBUG ?
        <div style={{
          left: '50%',
          top: 0,
          position: 'absolute', 
          color: 'white',
          fontSize: 20,
          backgroundColor:"#ff000099", 
          border:'1px solid white',
          borderRadius: 5, 
          padding: 10
        }}>
        DEBUG MODE
        </div>
        : null
      }
    </Fragment>
  )
}

export default App
