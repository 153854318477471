import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { toast, ToastContainer } from 'react-toastify'
import { log } from '../../utilities/logger'
import './dialog.scss'
import { useTranslation } from "react-i18next"

interface IFabricDialog {
    visible: boolean
    title: string
    text: string[]
    onPositive: () => void
    onNegative: () => void
    checkbox: string
}


export const FabricDialog = (props: IFabricDialog) => {

    const {t} = useTranslation()
    let { visible, title, text, onPositive, onNegative, checkbox } = props
    let [agreed, setAgreed] = useState(false)

    return <Dialog display={visible}>
        <div className='fDialogContainer'>
            <div className='fDialog'>
                <ToastContainer/>
                <button onClick={onNegative} className='closeIcon'>X</button>
                <section>
                    <header className='dialogTitle'>{title}</header>
                    <>{
                        text.map((paragraph, index) => {
                            return <p key={`${index}`} className='dialogText'>{paragraph}</p>
                        })
                    }</>
                </section>
                <span className='confirmation'>
                <input checked={agreed} className='confirmationInput' type='checkbox' onChange={(e)=>{
                    setAgreed(e.target.checked)
                }}/>
                <span onClick={()=>{setAgreed(!agreed)}}>{checkbox}</span>
                </span>
                <footer>
                    <button className='actionButtons' style={{backgroundColor:agreed?'green':'gray'}} onClick={()=>{
                        if(!agreed){
                            toast.error('Please confirm!')
                            return
                        }
                        onPositive()}}>{t("i18n.yes")}</button>
                    <button className='actionButtons' onClick={onNegative}>{t("i18n.no")}</button>
                </footer>
            </div>
        </div>
    </Dialog>
}

const Dialog = ({ display, children }: { display: boolean, children: any }) => {

    let rootNode = document.getElementById("root")
    const div = useMemo(() => {
        let div = document.createElement('div')
        rootNode?.appendChild(div)
        return div
    }, [rootNode])


    useEffect(() => {

        log(`display dialog ------- ${display}`)
        if (!display) {
            rootNode && rootNode.removeChild(div)
        } else {
            rootNode && rootNode.appendChild(div)
        }

    }, [display])

    return rootNode && display ? ReactDOM.createPortal(children, div) : null
} 