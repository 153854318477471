import React, { useMemo } from 'react' 
import { log } from '../../utilities/logger'
import styles from './scengrid.module.scss'
import { IScen, ISegment, ScenSegment } from './ScenSegment'

interface IFabric extends IScen{ 
    gridColumns: number
    gridRows:number
    widthPx:number
    heightPx:number
}

export const Fabric = React.memo((props:IFabric)=>{

    const{gridColumns = 1, gridRows = 1, widthPx, heightPx, ...scenProps} = props
    const segments:ISegment[] = useMemo(()=>{
        const cell: ISegment = {
            widthPx: widthPx/gridColumns,
            heightPx: heightPx/gridRows,
            totalColumns: gridColumns,
            totalRows: gridRows,
            index: 0
        }
        log({cell, cellWidth: gridColumns*(cell.widthPx), widthPx, heightPx, gridColumns, gridRows}, 'FABRIC GRID')
        return new Array(gridColumns*gridRows).fill(cell)

    },[gridColumns,gridRows, widthPx, heightPx])

    let isMediaQuery = window.matchMedia('(max-width: 600px)').matches

    log({widthPx, heightPx}, 'track render 1')
 
    return <div className={styles.scengrid} 
                style={{width: `${widthPx}px`, 
                        height: heightPx, 
                        maxWidth: `${widthPx}px`}}>
        {segments.map((item, index)=>{
            item.index = index
            return <ScenSegment key={`${index}`} {...item} {...scenProps}/>
        })}
    </div>

},(prevProps, nextProps)=>{
    const{partLengthChanged: method1_old, ...props} = prevProps
    const {partLengthChanged: method1_new, ...newProps} = nextProps
    return (JSON.stringify(props) === JSON.stringify(newProps))&&(method1_new === method1_old)
})





