import * as THREE from "three"

export default class glo {
    public static readonly HASHKEY = 0x1234567;
    public static MAX_TEXTURE_SIZE: number = 2048;
    public static imageLoader: THREE.ImageLoader;

    public static unit: number = 150 / 2.54;

    public static alertify: any;
    public static mobile: boolean = false;
    public static mobileTS: number = 0;

    public static Get(obj: any, name: any) {
        return obj[name];
    }
    public static Set(obj: any, name: any, value: any) {
        obj[name] = value;
    }
    public static GetSet(src: any, dst: any, name: any): any {
        const val = src[name];
        if (val)
            dst[name] = val;
        return val;
    }
    public static Call(obj: any, name: any, ...args: any[]): any {
        let fn = obj[name];
        return fn && fn.call(args);
    }
    public static get Ticks(): number {
        return (new Date()).getTime();
    }
    private static get isMobile(): boolean {
        const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
        const isAndroid = () => Boolean(userAgent.match(/Android/i));
        const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
        const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
        const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
        //const isSSR = () => Boolean(userAgent.match(/SSR/i));

        const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows());
        //const isDesktop = () => Boolean(!isMobile() && !isSSR());
        return isMobile();
    }
    public static checkMobile(): boolean {
        const ts = this.Ticks;
        if (ts - this.mobileTS < 1000)
            return false;
        this.mobileTS = ts;
        const oldMobile = this.mobile;
        this.mobile = this.isMobile;

        return oldMobile !== this.mobile;
    }
    public static getPointer(domElement: HTMLElement, event: MouseEvent | TouchEvent): any {
        const ev = event as MouseEvent;
        const tv = event as TouchEvent;

        if (domElement.ownerDocument.pointerLockElement)
            return { x: 0, y: 0, button: ev?.button };

        const rect = domElement.getBoundingClientRect();
        if (!tv?.changedTouches)
            return { x: (ev.clientX - rect.left) / rect.width * 2 - 1, y: - (ev.clientY - rect.top) / rect.height * 2 + 1, button: ev.button };

        const pv = tv.changedTouches[0];
        return { x: (pv.clientX - rect.left) / rect.width * 2 - 1, y: - (pv.clientY - rect.top) / rect.height * 2 + 1, button: null };
    }

    public static scs2ncs(domElement: HTMLElement, sx: number, sy: number, button: any, tl: boolean = false): any {
        const rect = domElement.getBoundingClientRect();
        return { x: (sx - (tl ? rect.left : 0)) / rect.width * 2 - 1, y: - (sy - (tl ? rect.top : 0)) / rect.height * 2 + 1, button: button };
    }
    public static snapValues(value: number, values: number[], res: number): number {
        for (let i = values.length - 1; i >= 0; --i) {
            if (Math.abs(value - values[i]) < res)
                return values[i];
        }
        return value;
    }
    public static clamp(value: number, L: number = -Infinity, H: number = Infinity): number {
        return Math.max(L, Math.min(H, value));
    }
    public static snap(value: number, res: number, L: number = -Infinity, H: number = Infinity) {

        return this.clamp(Math.round(value / res) * res, L, H);
    }

    public static sqr(x: number): number { return x * x; }
    public static dist2(v: any, w: any): number { return glo.sqr(v.x - w.x) + glo.sqr(v.y - w.y); }
    public static project(p: any, a: any, b: any): any {

        const atob = { x: b.x - a.x, y: b.y - a.y };
        const atop = { x: p.x - a.x, y: p.y - a.y };
        const len = atob.x * atob.x + atob.y * atob.y;
        var dot = atop.x * atob.x + atop.y * atob.y;
        const t = Math.min(1, Math.max(0, dot / len));
        dot = (b.x - a.x) * (p.y - a.y) - (b.y - a.y) * (p.x - a.x);
        const pp = {
            x: a.x + atob.x * t,
            y: a.y + atob.y * t
        };
        return {
            point: pp,
            left: dot < 1,
            dot: dot,
            t: t,
            dist: Math.sqrt(glo.dist2(p, pp))
        };
    }
    public static vLength(v: any): number { return Math.sqrt(glo.sqr(v.x) + glo.sqr(v.y)); }
    public static vAngle(u: any, v: any): number {
        const lenX = this.vLength(u);
        const lenY = this.vLength(v);
        const x = { x: u.x / lenX, y: u.y / lenX };
        const y = { x: v.x / lenY, y: v.y / lenY };

        return Math.acos(x.x * y.x + x.y * y.y);
    }
    public static vDir(u: any, v: any, swap: boolean = false): any {
        const d = { x: v.x - u.x, y: v.y - u.y };
        const len = this.vLength(d);
        d.x /= len;
        d.y /= len;
        if (swap) {
            const t = d.x;
            d.x = d.y;
            d.y = t;
        }
        return d;
    }


}
