import React, {useState, useEffect} from "react"
import {useSelector, useDispatch} from "react-redux"

import helpers from "../../libs/helpers"
import {setSpinner} from "../../redux/actions/spinner"
import { log } from "../../utilities/logger"

/**
 * Logo component
 * @component
 *
 * @example
 * const logoUrl = './src'
 * return (
 *  <Logo />
 * )
 */
const Logo = () => {
  const dispatch = useDispatch()
  const [src, setSrc] = useState("")
  let fbdata = useSelector(state => state.fbdata)

  useEffect(() => {
    if (fbdata && fbdata.logoUrl) {
      dispatch(setSpinner(true))
      helpers.getDirectLink(fbdata.logoUrl, url => {
        setSrc(url)
        dispatch(setSpinner(false))
      })
    } else {
      setSrc("/img/logo.png")
      dispatch(setSpinner(false))
    }
  }, [dispatch, fbdata])

  log({src},'setting Logo')

  return <img src={src} alt="logo" title="logo" />
}

export default Logo
