import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'

import alertify from "../../libs/alert"
import { log } from '../../utilities/logger'
import { useTranslation } from "react-i18next"
import { debounce as deb } from 'debounce'
import { useSelector } from 'react-redux'
import './imagesidebar.scss'


export const DimInput = ({ inputValue, onInputChange, min, max, maxDimensions, debounce, active, ...props }) => {

    max = parseFloat(max)
    min = parseFloat(min)
    maxDimensions.height = parseFloat((maxDimensions.height))
    maxDimensions.width = parseFloat((maxDimensions.width))


    const [input, setInput] = useState(inputValue)

    const { t } = useTranslation()

    const [valueBelowMinimum, setValueBelowMinimum] = useState(false)
    const pendingInput = useRef(undefined)
    const [updateCounter, setUpdateCounter] = useState(0)
    //const [pendingInput, setPendingInput] = useState(undefined)



    useEffect(() => {
        log({ inputValue, input }, 'value input debounce')
        if ((input !== inputValue) && (input >= min)) {
            pendingInput.current = input
            onInputChange(input)
        }
    }, [input])

    const debounced = useMemo(() => deb(() => {
        let val = pendingInput.current
        log({ debounce: val }, 'debounce')
        if (val < min) {
            setValueBelowMinimum(true)
        } else {
            setValueBelowMinimum(false)
            if (val > max) {
                alertify.alert(
                    t("editor.alert_warning_title2"),
                    `${t("editor.scale_too_high")} (${maxDimensions.width} x ${maxDimensions.height})`,
                    t("i18n.ok")
                )
                val = max
            }
        }
        setInput(val)
        // if (val >= min)
        //     onInputChange(val)
    }, 1000), [min, max])

    useEffect(() => {
        if (inputValue >= min) {
            pendingInput.current = inputValue
            setInput(inputValue)
            setValueBelowMinimum(false)
        }
    }, [inputValue])

    useEffect(() => {

        if (pendingInput.current !== undefined) {
            debounced(pendingInput.current)
        }
        return () => debounced.clear()
    }, [updateCounter])


    let realInputValue = pendingInput.current || input

    log({ input, min, realInputValue, pen: pendingInput.current }, 'debounce initial')

    return <div style={{ position: 'relative', alignItems:'center',
    display:"flex", flexDirection:"row", margin: '0 5px',...props.parentStyle}}>
        <button className='imageSideBar lengthChangeBtn' style={{borderRadius: "6px 0px 0px 6px"}}
            onClick={() => {
                let inputData = parseFloat(input) - 1
                log({ inputData, input: pendingInput.current, stepSize: props.stepSize }, "debounce ???")

                if (Math.abs(pendingInput.current - inputData) === (props.stepSize || 1)) {

                    if (inputData < min) {
                        setValueBelowMinimum(true)
                        alertify.alert(
                            t("editor.alert_warning_title2"),
                            `${t("editor.scale_too_small")}`,
                            t("i18n.ok")
                        ).set('modal', true)
                        inputData = min
                    }
                    pendingInput.current = inputData
                    onInputChange(inputData)
                } else {
                    if (inputData < min) {
                        alertify.alert(
                            t("editor.alert_warning_title2"),
                            `${t("editor.scale_too_small")}`,
                            t("i18n.ok")
                        ).set('modal', true)
                        inputData = min
                    }
                    pendingInput.current = inputData
                    setUpdateCounter((updateCounter - 1) % 2)
                }
            }}>-</button>
        <input
            style={{ color: input >= min ? 'black' : 'grey' }}
            value={realInputValue}
            disabled={active}
            min={0}
            onChange={(e) => {
                e.preventDefault()
                let inputData = parseFloat(e.target.value)
                log({ inputData, input: pendingInput.current, stepSize: props.stepSize, min , max }, "hello debounce ???")

                if (Math.abs(pendingInput.current - inputData) === (props.stepSize || 1)) {


                    if (inputData < min) {
                        setValueBelowMinimum(true)
                    } else {
                        setValueBelowMinimum(false)
                        if (inputData > max) {
                            alertify.alert(
                                t("editor.alert_warning_title2"),
                                `${t("editor.scale_too_high")} (${  (maxDimensions.width)} x ${  (maxDimensions.height)})`,
                                t("i18n.ok")
                            ).set('modal', true)
                            inputData = max
                        }
                    }
                    pendingInput.current = inputData
                    onInputChange(inputData)
                } else {
                    if (inputData > max) {

                        alertify.alert(
                            t("editor.alert_warning_title2"),
                            `${t("editor.scale_too_high")} (${  (maxDimensions.width)} x ${  (maxDimensions.height)})`,
                            t("i18n.ok")
                        ).set('modal', true)
                        inputData = max
                    }
                    pendingInput.current = inputData
                    setUpdateCounter((updateCounter + 1) % 2)
                }
            }}
            {...props}
        />
        <button className='imageSideBar lengthChangeBtn' style={{borderRadius: "0px 6px 6px 0px"}}
            onClick={() => {
                let inputData = parseFloat(input) + 1
                log({ inputData, input: pendingInput.current, stepSize: props.stepSize }, "debounce ???")

                if (Math.abs(pendingInput.current - inputData) === (props.stepSize || 1)) {

                    if (inputData < min) {
                        setValueBelowMinimum(true)
                    } else {
                        setValueBelowMinimum(false)

                        if (inputData > max) {

                            alertify.alert(
                                t("editor.alert_warning_title2"),
                                `${t("editor.scale_too_high")} (${  (maxDimensions.width)} x ${  (maxDimensions.height)})`,
                                t("i18n.ok")
                            ).set('modal', true)
                            inputData = max
                        }
                    }
                    pendingInput.current = inputData
                    onInputChange(inputData)
                } else {
                    if (inputData > max) {

                        alertify.alert(
                            t("editor.alert_warning_title2"),
                            `${t("editor.scale_too_high")} (${  (maxDimensions.width)} x ${  (maxDimensions.height)})`,
                            t("i18n.ok")
                        ).set('modal', true)
                        inputData = max
                    }
                    pendingInput.current = inputData
                    setUpdateCounter((updateCounter + 1) % 2)
                }
            }}>+</button>
        {valueBelowMinimum ? <span style={
            {
                position: 'absolute', top: '34px', left: 0, borderRadius: '5px', fontWeight: 'bold',
                backgroundColor: 'red', color: 'white', fontSize: '12px', padding: '2.5px', paddingLeft: '5px', paddingRight: '5px'
            }
        }>{`min: ${min}`}</span> : null}
    </div>
}