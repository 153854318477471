import glo from "./glo";
import { log } from "../../../../../../../utilities/logger"

export default class Elapse {
    private readonly msg: string;
    private readonly TS: number;

    constructor(msg: string) {
        this.msg = msg;
        this.TS = glo.Ticks;
    }

    public End = () => {
        let EL: number = glo.Ticks - this.TS;
        log(this.msg, EL);
    }
}
