import React, {useState, useEffect, useCallback} from "react"

import styles from "./fontsmenu.module.scss"

import fonts from "../../../../data/fonts"

const FontsMenu = ({
  activeFont = "",
  handleChange = () => {},
  handleToggle = () => {},
}) => {
  const [selectedValue, setSelectedValue] = useState("")

  useEffect(() => {
    if (activeFont) {
      setSelectedValue(activeFont)
    }
  }, [activeFont])

  const handleClose = e => {
    e.preventDefault()
    handleToggle()
  }

  const handleSelect = e => {
    e.preventDefault()
    if (e.target.name) {
      setSelectedValue(e.target.name)
    }
  }

  const handleApply = useCallback(
    e => {
      e.preventDefault()
      let ff = fonts.find(item => item.value === selectedValue)
      handleChange(ff)
      handleToggle()
    },
    [handleChange, handleToggle, selectedValue],
  )

  return (
    <div className={styles.container}>
      <div className={styles.title}>select font</div>
      <div className={styles.body}>
        {fonts.length ? (
          fonts.map(item => (
            <button
              onClick={handleSelect}
              className={`${styles.font} ${
                selectedValue === item.value ? styles.font_selected : ""
              }`}
              disabled={selectedValue === item.value}
              name={item.value}
              style={{fontFamily: item.value}}
              key={`_ ${item.value.toLocaleLowerCase().split(" ").join("")}`}
            >
              {item.label}
            </button>
          ))
        ) : (
          <div>Fonts not found</div>
        )}
      </div>
      <div className={styles.buttons}>
        <button className={styles.btn} onClick={handleClose}>
          cancel
        </button>
        <button
          className={`${styles.btn} ${
            activeFont !== selectedValue ? styles.btn_save : ""
          }`}
          disabled={activeFont === selectedValue}
          onClick={handleApply}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default FontsMenu
