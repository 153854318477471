import React from "react"
import {useSelector} from "react-redux"

import LeftWorkAreaPart from "../LeftWorkAreaPart/LeftWorkAreaPart"
import styles from "../leftworkarea.module.scss"

/**
 * Container for left work area
 * @component
 * @example
 *
 * return (
 *  <LeftWorkAreaContainer/>
 * )
 */
const LeftWorkAreaContainer = (props) => {
  const parts = useSelector(state => state.parts)
  const product = useSelector(state => state.product)

  return product ? (
    <div className={styles.container}>
      {parts.length && parts.find(i => i.active) && (
        <LeftWorkAreaPart 
              part={parts.find(i => i.active)} 
              parts={props.parts} 
              showLibrary={props.showLibrary}
              fabricSizeUnit={props.fabricSizeUnit}
              galleryHeight={props.galleryHeight}
              selCurImage={props.selCurImage} 
              selCurImageWidth={props.selCurImageWidth} 
              selCurImageHeight={props.selCurImageHeight}
              widthLimits={props.widthLimits}
              heightLimits={props.heightLimits}
              curPartLength={props.curPartLength}
              imageUploadStart={props.imageUploadStart}
              fcSize={props.fcSize}
              clearSelections={props.clearSelections}
              aspectRatioConstrained={props.aspectRatioConstrained}
              addToCartWidget={props.addToCartWidget}
              maxFabricLength={props.maxFabricLength}
              open3dView={props.open3dView}/>
      )}
    </div>
  ) : null
}

export default LeftWorkAreaContainer
