import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../../../../firebase";
import { ACTION_SETVIEWER3D_CONFIG } from "../../../../../redux/actions/view3dsetting.actions.ts";


export enum AuthState {
    AUTH_FAILED = -1,
    UNKNOWN,
    AUTH_SUCCESS,

}


export const useAuthentication = (userId?: string) => {


    const auth = useSelector((state: any) => state.viewer3DConfig.authentication)
    console.log('viewer3d', auth)
    const dispatch = useDispatch()
    const userAuthenticated = userId && auth && auth[userId] === AuthState.AUTH_SUCCESS

    const [authenticated, setAuthenticated] = useState(userAuthenticated ? AuthState.AUTH_SUCCESS : AuthState.UNKNOWN)
    

    useEffect(() => {
        if (userId && !userAuthenticated) {
            db.collection("Tenants")
                .doc(userId)
                .get()
                .then(e => {
                    const authenticated = e.data() ? AuthState.AUTH_SUCCESS : AuthState.AUTH_FAILED
                    dispatch({
                        type: ACTION_SETVIEWER3D_CONFIG,
                        payload: {
                            authentication: { [userId]: authenticated }
                        }
                    })
                    setAuthenticated(authenticated)
                }).catch((error)=>{
                    setAuthenticated(AuthState.AUTH_FAILED)
                })
        }
    }, [userId])

    return authenticated
}