import ACTIONS from "../../constatnts"

export const imgslib = (state = [], actions) => {
  const {type, payload} = actions

  switch (type) {
    case ACTIONS.GET_IMGS_LIB:
      return payload
    default:
      return state
  }
}
