import {storage} from "../firebase"

/**
 * help functions for geting data
 */
const helpers = {
  getDirectLink: async (url, action) => {
    // console.log('trying to get direct link');

    if (url && !url.startsWith("http")) {
      // console.log('DOWNLOAD INTERNAL IMAGE');

      if (action) {
        storage
          .refFromURL(url)
          .getDownloadURL()
          .then(file => {
            action(file)
          })
      } else {
        return await storage.refFromURL(url).getDownloadURL()
      }
    } else {
      return action ? action(url) : url
    }
  },
}

export default helpers
