import { fabric } from 'fabric'
import {  getImageDim, roundOff } from '../../utilities'
import alertify from "alertifyjs"
import JSZip from "jszip"
import * as CRYPTO_JS from "crypto-js"
import save from 'save-file'
import { v4 as uuidv4 } from "uuid"
import { DEBUG, log } from '../../utilities/logger'
import { storage } from "../../firebase"
import { getSubUnit, getSuperUnit } from './canvasPixelConvertor'

type RectArea = { width: number, height: number, top: number, left: number }

export type RepeatType = 'NON_REPEAT' | 'DEFAULT_REPEAT' | 'MIRROR_REPEAT' | 'HALF_DROP_REPEAT' | 'HALF_BRICK_REPEAT'

export class ContainerPart extends fabric.Object {
    id?: string
    pattern_type: RepeatType = 'NON_REPEAT'
    partSize?: {
        length: number,
        stepSize: number,
        lengthInUnitMultiples: boolean,
        width: number
    }

}
export class Product extends fabric.Object {
    metadata?: { widthSubUnit: number, perPartheightSubUnit: number }
}

export class FabricImage extends fabric.Image {
    id?: string
    uid?: string
    optionalImageId?:string
    maxDimensions?: { width: number, height: number }
    minDimensions?: { width: number, height: number }
    image_size_wd?: number
    image_size_ht?: number
    resolution?: { current: number, min: number }
    file?: File
    lockedAR?: boolean
    rotatedBy?: number
    allowScaleUpto?: number
    help?: {
        left: number,
        top: number,
        leftPer: number,
        topPer: number,
        width: number,
        height: number,
    }
}

export class Helper {
    fabricCanvas: fabric.Canvas
    t?: any
    patternSourceCanvas?: fabric.StaticCanvas
    originalImageClones: Map<string, fabric.Image> = new Map()

    constructor(canvas: fabric.Canvas) {
        this.fabricCanvas = canvas
    }

    private applyNonRepeat(originalImage: fabric.Image, part: ContainerPart) {
        part.set({
            fill: 'transparent',
            pattern_type: 'NON_REPEAT'
        })
        originalImage.visible = true
        this.fabricCanvas.renderAll()
    }
    private getCanvasPortion(originalImage: fabric.Image): string {

        let { width, height, scaleX, scaleY, top, left } = originalImage

        return this.fabricCanvas.toDataURL({
            width: (width || 0) * (scaleX || 1),
            height: (height || 0) * (scaleY || 1),
            top,
            left,
        })
    }
    private getAsImage(details: string, options: any, asHTMLImageElement?: boolean): Promise<fabric.Image | HTMLImageElement> {
        return new Promise((resolve, reject) => {
            fabric.util.loadImage(details, (imageString) => {
                if (asHTMLImageElement) {
                    resolve(imageString)
                    return
                }
                let copy = new fabric.Image(imageString)
                copy.set({
                    ...options
                })
                resolve(copy)
            })
        })
    }

    private getFromUrl(url: string): Promise<FabricImage> {
        return new Promise(resolve => {
            fabric.Image.fromURL(url, (image => {
                resolve(image)
            }))
        })
    }

    private async applyDefaultRepeat(originalImage: fabric.Image) {

        this.originalImageClones.clear()
        //let { width, height, scaleX, scaleY } = originalImage
        let { width, height, scaleX, scaleY, sidesSwitched } = this.returnEffectiveDims(originalImage)

        if (width && height && scaleX && scaleY && this.patternSourceCanvas) {

            this.originalImageClones.set('clone', await this.getClone(originalImage))
            this.configurePattern({ width, height }, { scaleX, scaleY }, 'DEFAULT_REPEAT')
            this.patternSourceCanvas.add(...Array.from(this.originalImageClones.values()))
            this.patternSourceCanvas.renderAll()

        }
    }

    private getClone(image: fabric.Image, options?: string[]): Promise<fabric.Image> {
        return new Promise(resolve => {
            image.clone((clonedImage: fabric.Image) => {
                resolve(clonedImage)
            }, options)
        })
    }

    private async applyMirrorRepeat(originalImage: fabric.Image) {

        this.originalImageClones.clear()
        let { width, height, scaleX, scaleY, sidesSwitched } = this.returnEffectiveDims(originalImage)


        if (width && height && scaleX && scaleY && this.patternSourceCanvas) {

            this.originalImageClones.set('clone', await this.getClone(originalImage))

            this.originalImageClones.set('cloneR', (await this.getClone(originalImage)).set(
                { scaleX: (sidesSwitched ? 1 : -1) * scaleX, scaleY: (sidesSwitched ? -1 : 1) * scaleY }) as fabric.Image)
            this.originalImageClones.set('cloneB', (await this.getClone(originalImage)).set(
                { scaleY: (sidesSwitched ? 1 : -1) * scaleY, scaleX: (sidesSwitched ? -1 : 1) * scaleX }) as fabric.Image)
            this.originalImageClones.set('cloneRB', (await this.getClone(originalImage)).set({ scaleX: -1 * scaleX, scaleY: -1 * scaleY }) as fabric.Image)

            this.configurePattern({ width, height }, { scaleX, scaleY }, 'MIRROR_REPEAT')
            this.patternSourceCanvas.add(...Array.from(this.originalImageClones.values()))
            this.patternSourceCanvas.renderAll()

        }
    }

    private async applyHalfDropRepeat(originalImage: fabric.Image) {

        this.originalImageClones.clear()
        //let { width, height, scaleX, scaleY } = originalImage
        let { width, height, scaleX, scaleY } = this.returnEffectiveDims(originalImage)

        if (width && height && scaleX && scaleY && this.patternSourceCanvas) {

            this.originalImageClones.set('clone', await this.getClone(originalImage))
            this.originalImageClones.set('cloneRT', await this.getClone(originalImage))
            this.originalImageClones.set('cloneRB', await this.getClone(originalImage))

            this.configurePattern({ width, height }, { scaleX, scaleY }, 'HALF_DROP_REPEAT')
            this.patternSourceCanvas.add(...Array.from(this.originalImageClones.values()))
            this.patternSourceCanvas.renderAll()

        }
    }

    private async applyHalfBrickRepeat(originalImage: fabric.Image) {

        this.originalImageClones.clear()
        //let { width, height, scaleX, scaleY } = originalImage
        let { width, height, scaleX, scaleY } = this.returnEffectiveDims(originalImage)

        if (width && height && scaleX && scaleY && this.patternSourceCanvas) {

            this.originalImageClones.set('clone', await this.getClone(originalImage))
            this.originalImageClones.set('cloneBL', await this.getClone(originalImage))
            this.originalImageClones.set('cloneBR', await this.getClone(originalImage))

            this.configurePattern({ width, height }, { scaleX, scaleY }, 'HALF_BRICK_REPEAT')
            this.patternSourceCanvas.add(...Array.from(this.originalImageClones.values()))
            this.patternSourceCanvas.renderAll()
        }

    }

    returnEffectiveDims = (image: fabric.Object) => {

        let { height = 0, scaleX = 0, width = 0, scaleY = 0, angle = 0 } = image
        let switchSides = (angle || 0) % 180

        return switchSides ? {

            height: width,
            width: height,
            scaleX: scaleY,
            scaleY: scaleX,
            sidesSwitched: true

        } : { height, scaleX, width, scaleY, sidesSwitched: false }


    }

    private findImageAnchor(imgCanvasWidth: number, parentContainer: { top: number, left: number, width: number }, inCollage: boolean): { top: number, left: number } {

        let images = this.getImages()
        let parentTop = parentContainer.top
        let parentLeft = parentContainer.left
        let parentWidth = parentContainer.width

        let rightCollageRectEdge = parentLeft
        let bottomCollageRectEdge = parentTop

        let inCollageTop = 0
        let inCollageLeft = 0

        images.forEach((item, index) => {

            let { top, left } = item
            let { height, scaleX, width, scaleY } = this.returnEffectiveDims(item)

            top = top || 0
            left = left || 0

            let widthOnCanvas = width * scaleX
            if (left + widthOnCanvas > rightCollageRectEdge) {
                rightCollageRectEdge = left + widthOnCanvas
            }
            let heightOnCanvas = height * scaleY
            if (top + heightOnCanvas > bottomCollageRectEdge) {
                bottomCollageRectEdge = top + heightOnCanvas
            }

            if ((index === (images.length - 1))) {
                if (inCollage) {
                    let imgRightEdge = (left + widthOnCanvas)
                    log({ imgRightEdge, imgCanvasWidth }, 'display')
                    if ((imgRightEdge + imgCanvasWidth) < parentWidth) {
                        bottomCollageRectEdge = top
                        rightCollageRectEdge = imgRightEdge
                    } else {
                        bottomCollageRectEdge = bottomCollageRectEdge //does not change
                        rightCollageRectEdge = 0
                    }

                } else {
                    if ((rightCollageRectEdge + imgCanvasWidth) > parentWidth) {
            rightCollageRectEdge = 0
        } else {
            bottomCollageRectEdge = 0
        }

                }
            }

        })

        return { top: bottomCollageRectEdge, left: rightCollageRectEdge }
    }

    private configurePattern(originalDims: { width: number, height: number }, updatedScale: { scaleX: number, scaleY: number },
        pattern: RepeatType) {

        if (this.patternSourceCanvas) {

            let { scaleX, scaleY } = updatedScale
            let { width, height } = originalDims
            let canvasWidth = Math.abs(width * scaleX)
            let canvasHeight = Math.abs(height * scaleY)


            switch (pattern) {
                case 'DEFAULT_REPEAT':
                    this.originalImageClones.get('clone')?.set({ scaleX, scaleY, top: 0, left: 0 })
                    this.patternSourceCanvas.setDimensions({ width: canvasWidth, height: canvasHeight })
                    break;
                case 'HALF_BRICK_REPEAT':
                    this.originalImageClones.get('clone')?.set({ top: 0, left: 0, scaleX, scaleY, })
                    this.originalImageClones.get('cloneBL')?.set({ top: canvasHeight, left: -canvasWidth / 2, scaleX, scaleY, })
                    this.originalImageClones.get('cloneBR')?.set({ top: canvasHeight, left: canvasWidth / 2, scaleX, scaleY, })
                    this.patternSourceCanvas.setDimensions({ width: canvasWidth, height: 2 * canvasHeight })
                    break;
                case 'HALF_DROP_REPEAT':
                    this.originalImageClones.get('clone')?.set({ top: 0, left: 0, scaleX, scaleY, })
                    this.originalImageClones.get('cloneRT')?.set({ top: -canvasHeight / 2, left: canvasWidth, scaleX, scaleY, })
                    this.originalImageClones.get('cloneRB')?.set({ top: canvasHeight / 2, left: canvasWidth, scaleX, scaleY, })
                    this.patternSourceCanvas.setDimensions({ width: 2 * canvasWidth, height: canvasHeight })
                    break;
                case 'MIRROR_REPEAT':
                    this.originalImageClones.get('clone')?.set({ top: 0, left: 0, scaleX, scaleY })
                    this.originalImageClones.get('cloneR')?.set({ top: 0, left: canvasWidth, scaleX, scaleY, })
                    this.originalImageClones.get('cloneB')?.set({ top: canvasHeight, left: 0, scaleX, scaleY, })
                    this.originalImageClones.get('cloneRB')?.set({ top: canvasHeight, left: canvasWidth, scaleX, scaleY, })
                    this.patternSourceCanvas.setDimensions({ width: 2 * canvasWidth, height: 2 * canvasHeight })
                    break;
            }
        }
    }



    resizePatternWidth(image: FabricImage,
        container: { widthPx: number, widthSubUnit: number },
        newImagewidthSubUnit: number, lockedAR: boolean, pattern: RepeatType) {

        let { width, height } = image

        if (width && this.patternSourceCanvas && height) {

            let dpc = container.widthPx / container.widthSubUnit
            let newCanvasWidth = dpc * newImagewidthSubUnit
            let widthScale = newCanvasWidth / width
            let aspectRatio = width / height
            image.set({
                image_size_wd: newImagewidthSubUnit,
                scaleX: widthScale, ...lockedAR && { scaleY: widthScale, image_size_ht: roundOff(newImagewidthSubUnit / aspectRatio, 1) }
            })
            image.setCoords()

            let { width: newW, height: newH, scaleY, scaleX } = this.returnEffectiveDims(image)
            if (scaleX && scaleY)
                this.configurePattern({ width: newW, height: newH }, { scaleX, scaleY }, pattern)
            this.patternSourceCanvas.renderAll()

        }

        this.fabricCanvas.requestRenderAll()


    }



    requestPartLengthIncrease = (part_id: string, image: fabric.Image, newImageHtCm: number,
        changePartLength: (id: string, newLength: number, increase:number) => number, onPartLengthIncreased: (newLength:number) => void): number => {

        let { top, partSize, width, height } = this.getParts()[0] as ContainerPart
        let { widthSubUnit } = (this.getProduct() as Product).metadata || {}
        let pxPerSubUnit = (width || 0) / (widthSubUnit || 1)
        let partPerStepHeightSubUnit = getSubUnit(partSize?.stepSize || 0)
        let perUnitLengthPx = partPerStepHeightSubUnit * pxPerSubUnit

        let imageBottom = (image.top || 0) + (newImageHtCm * pxPerSubUnit) + Math.abs(top || 0)
        log({ imageBottom }, 'resizing....')

        if (imageBottom > (height || 0)) {

            this.confirmation(this.t("editor.adjust_length"), this.t("editor.alert_warning_title2")).then((adjust) => {
                if (adjust) {
                    let newLength = 0
                    if (partSize?.lengthInUnitMultiples)
                        newLength = Math.ceil(((imageBottom + Math.abs(top || 0)) / perUnitLengthPx)) * getSuperUnit(partPerStepHeightSubUnit)
                    else
                        newLength = roundOff(((imageBottom + Math.abs(top || 0)) / perUnitLengthPx) * getSuperUnit(partPerStepHeightSubUnit), 2)
                    let increase = newLength - getSuperUnit((height||0)/pxPerSubUnit)
                    changePartLength(part_id, newLength, increase)
                    log({newLength, increase}, 'part length updatexxx')
                    onPartLengthIncreased(newLength)
                }else{
                    onPartLengthIncreased(0)
                }
            })
            return 1
        }
        return 0

    }

    resizePatternHeight(image: FabricImage,
        container: { widthPx: number, widthSubUnit: number },
        newImageHeightSubUnit: number, lockedAR: boolean, pattern: RepeatType) {

        let { width, height } = image

        if (width && this.patternSourceCanvas && height) {

            let dpc = container.widthPx / container.widthSubUnit
            let newCanvasHeight = dpc * newImageHeightSubUnit
            let heightScale = newCanvasHeight / height
            let aspectRatio = width / height
            image.set({
                image_size_ht: newImageHeightSubUnit,
                scaleY: heightScale, ...lockedAR && { scaleX: heightScale, image_size_wd: roundOff(newImageHeightSubUnit * aspectRatio, 1) }
            })
            image.setCoords()

            let { width: newW, height: newH, scaleY, scaleX } = this.returnEffectiveDims(image)
            if (scaleX && scaleY)
                this.configurePattern({ width: newW, height:newH }, { scaleX, scaleY }, pattern)
            this.patternSourceCanvas.renderAll()

        }

        this.fabricCanvas.requestRenderAll()


    }



    async repeatImg(originalImage: fabric.Image, part: ContainerPart, repeatType: RepeatType) {

        this.fabricCanvas.discardActiveObject()
        if (repeatType === 'NON_REPEAT') {
            this.applyNonRepeat(originalImage, part)
        } else {
            originalImage.visible = true
            let clipPath = originalImage.clipPath
            originalImage.clipPath = undefined
            this.patternSourceCanvas = new fabric.StaticCanvas(null)
            this.patternSourceCanvas.enableRetinaScaling = false
            switch (repeatType) {
                case 'DEFAULT_REPEAT':
                    await this.applyDefaultRepeat(originalImage)
                    break;
                case 'MIRROR_REPEAT':
                    await this.applyMirrorRepeat(originalImage)
                    break;
                case 'HALF_DROP_REPEAT':
                    await this.applyHalfDropRepeat(originalImage)
                    break;
                case 'HALF_BRICK_REPEAT':
                    await this.applyHalfBrickRepeat(originalImage)
                    break;
            }
            originalImage.visible = false
            originalImage.clipPath = clipPath
            part.set({
                fill: new fabric.Pattern({
                    source: this.patternSourceCanvas.getElement() as any,
                    repeat: "repeat",
                }),
                objectCaching: false,
                pattern_type: repeatType
            })
            this.renderObjectOnCanvasUtility(part)
            this.fabricCanvas.requestRenderAll()
        }
    }

    async insertImage(part_id: string,
        part: { width: number, height: number, top: number, left: number, lengthInUnitMultiples: boolean },
        product: { widthSubUnit: number, partPerStepHeightSubUnit: number },
        imageInfo: { url: string, file: File, pxWidth: number, pxHeight: number, repeatType: RepeatType, currentResolution: number, minResolution: number, scaleFactor: number, optionalImageId?:string },
        changePartLength: (id: string, newLength: number, change:number) => number
    ): Promise<FabricImage> {

        let { widthSubUnit, partPerStepHeightSubUnit } = product

        let dpc = part.width / widthSubUnit
        let perUnitLengthPx = partPerStepHeightSubUnit * dpc
        //let initialPartLength = 100 * dpc

        let { pxWidth, pxHeight, minResolution, currentResolution, url, file, optionalImageId } = imageInfo
        let dimensions = getImageDim(pxWidth, pxHeight, widthSubUnit, minResolution, currentResolution, imageInfo.scaleFactor)

        let { width, height, top, left, lengthInUnitMultiples } = part
        let widthOnCanvas = dimensions.curSizeCm.width * dpc
        let heightOnCanvas = dimensions.curSizeCm.height * dpc

        let maxDimensions = { width: dimensions.widthLimitsCm.max, height: dimensions.heightLimitsCm.max }
        let minDimensions = { width: dimensions.widthLimitsCm.min, height: dimensions.heightLimitsCm.min }

        let anchor = this.findImageAnchor(widthOnCanvas, { width, top, left }, true);

        if ((anchor.top + heightOnCanvas) > (height - Math.abs(top))) {

            let adjust = await this.confirmation(this.t("editor.adjust_length"), this.t("editor.alert_warning_title2"))
            if (adjust) {
                let newLength = 0
                if (lengthInUnitMultiples)
                    newLength = Math.ceil(((anchor.top + heightOnCanvas + Math.abs(top)) / perUnitLengthPx))* getSuperUnit(partPerStepHeightSubUnit)
                else
                    newLength = roundOff((Math.ceil(anchor.top + heightOnCanvas + Math.abs(top)) / perUnitLengthPx) * getSuperUnit(partPerStepHeightSubUnit), 2)
                let change = newLength - getSuperUnit(height/dpc)
                log({newLength,change }, 'PART LENGTH in meters xxxxx')
                height = changePartLength(part_id, newLength, change)
            }
        }


        let image = await this.getFromUrl(url)
        image.set({
            uid: uuidv4(),
            optionalImageId,
            id: part_id,
            left: anchor.left,
            top: anchor.top,
            width: pxWidth,
            height: pxHeight,
            hasControls: false,
            borderColor: "#4A69FF",
            scaleY: heightOnCanvas / pxHeight,
            scaleX: widthOnCanvas / pxWidth,
            maxDimensions,
            minDimensions,
            image_size_wd: dimensions.curSizeCm.width,
            image_size_ht: dimensions.curSizeCm.height,
            resolution: { current: currentResolution, min: minResolution },
            clipPath: new fabric.Rect({ width, height, top, left, absolutePositioned: true }),
            file,
            lockedAR: true,
            help: {
                left: anchor.left,
                top: anchor.top,
                leftPer: ((anchor.left - part.left) * 100) / width,
                topPer: ((anchor.top - part.top) * 100) / height,
                width: widthOnCanvas,
                height: heightOnCanvas,
            },
        })

        this.fabricCanvas.add(image)
        this.renderObjectOnCanvasUtility(image)

        return image
    }

    rotateImage = async (image: FabricImage, toAngle: number,
        part: ContainerPart,
        product: { widthSubUnit: number, partPerStepHeightSubUnit: number },
        changePartLength: (id: string, newLength: number, change:number) => number
    ) => {

        log({ toAngle, image: image?.uid }, 'rotateImage')

        let { scaleY, scaleX, width, height, top } = image
        scaleY = scaleY || 0
        scaleX = scaleX || 0
        width = width || 0
        height = height || 0
        let imageTop = top || 0

        let rotatedBy = 0
        switch (toAngle) {
            case 90:
                image.set({ originX: 'right', originY: 'top', angle: -90, scaleY: -1*scaleY, scaleX: -1*Math.abs(scaleX) })
                rotatedBy = 90
                break
            case 180:
                image.set({ originX: 'left', originY: 'top', angle: 0, scaleY: Math.abs(scaleY), scaleX: scaleX })
                rotatedBy = 180
                break
            case 270:
                image.set({ originX: 'right', originY: 'top', angle: -90, scaleY: -1*Math.abs(scaleY), scaleX: -1*Math.abs(scaleX) })
                rotatedBy = 270
                break
            default:
                image.set({ originX: 'left', originY: 'top', angle: 0, scaleY: Math.abs(scaleY), scaleX: Math.abs(scaleX) })
                rotatedBy = 0
                break

        }

        let { widthSubUnit, partPerStepHeightSubUnit } = product
        let dpc = (part.width||0) / widthSubUnit
        let perUnitLengthPx = partPerStepHeightSubUnit * dpc
        //let initialPartLength = 100 * dpc
        let heightOnCanvas = (rotatedBy % 180) ? (width * scaleX):(height * scaleY) 

        if ((imageTop + heightOnCanvas) > ((part.height||0) - Math.abs(part.top||0))) {

            let adjust = await this.confirmation(this.t("editor.adjust_length"), this.t("editor.alert_warning_title2"))
            if (adjust) {
                let newLength = 0
                if (part.partSize?.lengthInUnitMultiples)
                    newLength = Math.ceil((imageTop + heightOnCanvas + Math.abs(part.top || 0)) / perUnitLengthPx) * getSuperUnit(partPerStepHeightSubUnit)
                else
                    newLength = roundOff(((imageTop + heightOnCanvas + Math.abs(part.top || 0)) / perUnitLengthPx) * getSuperUnit(partPerStepHeightSubUnit), 2)
                let increase = newLength - getSuperUnit((part.height||0)/dpc)
                log({newLength, increase},'updatexxx during rotation')    
                changePartLength(part.id||'part_1', newLength, increase)
            }
        }
        
        image.set({rotatedBy})
        image.setCoords()
        this.fabricCanvas.renderAll()

        if (part.pattern_type && part.pattern_type !== 'NON_REPEAT') {
            this.repeatImg(image, part, part.pattern_type)
        }

    }



    private addScreenShots(zipFile: JSZip, canvasWindow: { width: number, height: number }):{id:string, icon:string}[] {

        let previewFolder = "screenshots/"
        let images = this.getImages()
        let part = this.getParts()[0] as ContainerPart

        let screenShots:{id:string, icon:string}[] = []

        images.forEach((image, index) => {

            let photo = this.fabricCanvas.toDataURL({
                format: "jpeg",
                quality: 0.3,
                multiplier: 1,
                width: canvasWindow.width,
                height: canvasWindow.height,
                left: 0,
                top: image.top,
            })

            let idStr = `${part.id}_${index + 1}`
            let info = { id: idStr, icon: photo }
            screenShots.push(info)
            zipFile.file(`${previewFolder}${idStr}.jpeg`, photo.split(",")[1], { base64: true })

        })
        return screenShots

    }



    /*assumes that the rect are already intersecting*/
    private _getOverlapArea(rect1: RectArea, rect2: RectArea) {
        let rect1RightEdge = rect1.left + rect1.width
        let rect1BottomEdge = rect1.top + rect1.height

        let rect2RightEdge = rect2.left + rect2.width
        let rect2BottomEdge = rect2.top + rect2.height

        if ((rect2.left < rect1RightEdge) && (rect2.top < rect1BottomEdge) && (rect2.top > rect1.top) && (rect2.left > rect1.left)) {
            let width = rect2RightEdge < rect1RightEdge ? rect2.width : (rect1RightEdge - rect2.left)
            let height = rect2BottomEdge < rect1BottomEdge ? rect2.height : (rect1BottomEdge - rect2.top)
            return width * height
        }

        if ((rect1.left < rect2RightEdge) && (rect2.top < rect1BottomEdge) && (rect2.top > rect1.top) && (rect2RightEdge < rect1RightEdge)) {
            let width = rect2.left > rect1.left ? rect2.width : (rect2RightEdge - rect1.left)
            let height = rect2BottomEdge < rect1BottomEdge ? rect2.height : (rect1BottomEdge - rect2.top)
            return width * height
        }

        if ((rect1.left < rect2RightEdge) && (rect2BottomEdge < rect1BottomEdge) && (rect2BottomEdge > rect1.top) && (rect2RightEdge < rect1RightEdge)) {
            let width = rect2RightEdge < rect1RightEdge ? rect2.width : (rect1RightEdge - rect2.left)
            let height = rect2.top > rect1.top ? rect2.height : (rect2BottomEdge - rect1.top)
            return width * height
        }

        if ((rect1.left < rect2RightEdge) && (rect2BottomEdge < rect1BottomEdge) && (rect2BottomEdge > rect1.top) && (rect2RightEdge < rect1RightEdge)) {
            let width = rect2.left > rect1.left ? rect2.width : (rect2RightEdge - rect1.left)
            let height = rect2.top > rect1.top ? rect2.height : (rect2BottomEdge - rect1.top)
            return width * height
        }
        return 0


    }

    private _getEmptyAreaNonRepeatMode() {

        let part = this.getParts()[0]
        let { height, width, top, left } = part
        top = top||0
        left = left||0
        width = width||0
        height = height||0
        const point = this.findImageAnchor(part.width || 0, { top, left, width }, false)
        
        return ((height - point.top)/height) * 100
    }

    private createCanvasPreview(isModeRepeat: boolean): string | undefined {

        let part = this.getParts()[0]
        if (part) {
            let anchorPoint
            if (isModeRepeat) {
                let top = 0.6 * (part.width || 0)
                let repeatedImage = this.getImages()[0]
                let canvasHeight = (repeatedImage.width || 0) * (repeatedImage.scaleX || 0)
                top = canvasHeight > top ? canvasHeight : top
                anchorPoint = { top, left: 0 }
            } else {
                let { top, left, width } = part
                top = top || 0
                left = left || 0
                width = width || 0
                anchorPoint = this.findImageAnchor(part.width || 0, { top, left, width }, false)
            }
            let previewOptions = {
                format: "jpeg",
                quality: 0.3,
                multiplier: 1,
                width: part.width,
                height: anchorPoint.top,
                left: part.left,
                top: part.top,
            }
            part.strokeWidth = 1
            let data = this.fabricCanvas.toDataURL(previewOptions)
            return data
        }
        return undefined
    }

    private addContentImagesZip(zipFile: JSZip) {

        let imageFolder = "images/"
        let partId = (this.getParts()[0] as ContainerPart).id

        let contentImages = this.getImages() as FabricImage[]

        contentImages.forEach((item, index) => {
            if (item.file) {
                let idStr = `${partId}_${index + 1}`
                zipFile.file(`${imageFolder}${idStr}.${item.file.type.split("/")[1]}`, item.file)
            }
        })
    }

    private createMetadata(sku: string, isRepeatMode: boolean): {logos:any[], plain: string, encoded?: string } | undefined {

        let imageFolder = "images/"
        let product = this.getProduct() as Product
        let part = this.getParts()[0] as ContainerPart

        let { pattern_type, width: partWidth, partSize } = part

        let images = this.getImages() as FabricImage[]
        let pxToCm = (product.metadata?.widthSubUnit || 0) / (partWidth || 1)

        let logos = images.map((img, index) => {

            let { file, left, top, angle, image_size_ht, image_size_wd, rotatedBy } = img

            let { scaleX, scaleY, width: imgWidth, height: imgHeight } = this.returnEffectiveDims(img)

            left = left || 0
            top = top || 0


            let x = left * pxToCm
            let y = (top - (product.top || 0)) * pxToCm
            let width = imgWidth * scaleX * pxToCm
            let height = imgHeight * scaleY * pxToCm

            let position = {
                x: roundOff(x, 3),
                y: roundOff(y, 3),
            }
            let idStr = `${part.id}_${index + 1}`
            let image = file ? {
                _id: idStr,
                src: `${imageFolder}${idStr}.${file.name ? file.name.split(".")[file.name.split(".").length - 1] : file.type.split("/")[1]}`,
                repeatType: pattern_type || 'NON_REPEAT',
                repeatSize: isRepeatMode ? roundOff((imgWidth * scaleX) / (partWidth || 1), 3) : -1,
                width: parseFloat(width.toFixed(3)),
                height: parseFloat(height.toFixed(3)),
                angle: rotatedBy
            } : undefined

            return {
                ...image,
                position,
            }
        })

        let idStr = `${part.id}`
        let parts = [{
            _id: idStr,
            number: 1,
            length: parseFloat(`${partSize?.length||1}`),
            numberOfUnits: roundOff((partSize?.length||1)/(partSize?.stepSize||1),3),
            partIcon: `screenshots/preview.jpeg`,
            logos
        }]

        let object = {
            sku,
            width: getSuperUnit(product.metadata?.widthSubUnit || 0),
            parts,
        }

        const KEY = process.env.REACT_APP_KEY
        const IV = process.env.REACT_APP_IV

        let plain = JSON.stringify(object)
        let encoded = KEY && IV ? CRYPTO_JS.AES.encrypt(
            plain,
            CRYPTO_JS.enc.Utf8.parse(KEY),
            {
                iv: CRYPTO_JS.enc.Utf8.parse(IV),
                padding: CRYPTO_JS.pad.Pkcs7,
                mode: CRYPTO_JS.mode.CBC,
            },
        ).toString() : undefined

        return {logos, plain, encoded }

    }


    addToCart = async (canvasWindow: { width: number, height: number }, sku: string, storeName: string,fabricName:string,
          fabricDim:{widthMeters:number, lengthMeters:number}
        , pcProgress?: (pc: number) => void, creatingCart?:()=>void) => {

        let part = this.getParts()[0] as ContainerPart
        let isModeRepeat = part.pattern_type === 'DEFAULT_REPEAT' || part.pattern_type === 'HALF_BRICK_REPEAT' ||
            part.pattern_type === 'HALF_DROP_REPEAT' || part.pattern_type === 'MIRROR_REPEAT'
        
        if (!isModeRepeat && (this._getEmptyAreaNonRepeatMode() > 50)) {
            const permitted = await this.confirmation(`More than 50% of the fabric length is empty, are you sure?`)
            if (!permitted) {
                return {abort: true}
            }
        }

        creatingCart && creatingCart()

        let previewFolder = "screenshots/"
        const zip = new JSZip()

        let previewData = this.createCanvasPreview(isModeRepeat)
        if (!previewData) {
            throw new Error(`could not create Preview`)
        }

        zip.file(`${previewFolder}preview.jpeg`, previewData.split(",")[1], { base64: true })

        let screenShots = this.addScreenShots(zip, canvasWindow)
        this.addContentImagesZip(zip)
        let jsonMetaData = this.createMetadata(sku, isModeRepeat)

        if (!jsonMetaData) {
            throw new Error(`could not create JSON metadata`)
        }

        // let dataForBackUp = {
        //     fabric: fabricName,
        //     parts: [
        //       ...jsonMetaData.logos.map(item => ({
        //         width: fabricDim.widthMeters,
        //         length: fabricDim.lengthMeters,
        //         icon: screenShots.find(i => i.id === item._id)?.icon,
        //       })),
        //     ],
        //   }
        
        let numberOfUnits = roundOff((part.partSize?.length||1)/(part.partSize?.stepSize||1),3)

        let dataForBackUp = {
            fabric: fabricName,
            parts: [
              {
                width: fabricDim.widthMeters,
                length: fabricDim.lengthMeters,
                numberOfUnits,
                icon: previewData,
              },
            ],
          }

        if (DEBUG) {
            zip.file("info.json", jsonMetaData.plain)
            zip.file("info_backUpData.json", JSON.stringify(dataForBackUp))
            let plainTextContent = await zip.generateAsync({ type: 'blob' })
            let date = new Date()
            let result = await save(plainTextContent, `customer_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}.zip`)
            return { data: dataForBackUp, metadata: result && result.metadata||{} }
        }

        if (!jsonMetaData.encoded) {
            throw new Error(`could not encode JSON metadata`)
        }

        zip.file("info.json", jsonMetaData.encoded)
        let content = await zip.generateAsync({ type: 'blob' })
        var files = new File([content], "customizer.zip")

        let file_name = uuidv4().split("-").join("")

        let uploadTask = storage.ref().child(`${storeName}/fabric_storage/${file_name}.zip`).put(files)

        uploadTask.on('state_changed', (result) => {
            pcProgress && pcProgress(getSubUnit(Math.round(result.bytesTransferred / result.totalBytes)))
        })
        let result = await uploadTask
        return { data: dataForBackUp, numberOfUnits, metadata: result && result.metadata }

    }


   

    public getPart = () => this.fabricCanvas.getObjects().filter((i: any) => i.id && i.name === "part")[0]

    public getCanvas = () => this.fabricCanvas
    public getProduct = () => this.fabricCanvas.getObjects().find(i => i.name === "product")
    public getParts = () => this.fabricCanvas.getObjects().filter((i: any) => i.id && i.name === "part")
    public getImages = () => this.fabricCanvas.getObjects().filter(i => i.type === "image")
    private getTexts = () => this.fabricCanvas.getObjects().filter(i => i.type === "text")
    private renderObjectOnCanvasUtility = (...objects: fabric.Object[]) => {
        objects.forEach(obj => obj.setCoords())
        this.fabricCanvas.renderAll()
    }
    private confirmation = (message: string, title?: string) => {
        return new Promise((resolve, reject) => {
            alertify.confirm(title || this.t("editor.alert_warning_title4"), message, () => {
                resolve(true)
            }, () => {
                resolve(false)
            }).set('labels', { ok: this.t("i18n.yes"), cancel: this.t("i18n.no") }).set('modal', true);
        })
    }


}


