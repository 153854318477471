import { debounce } from 'debounce'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Ruler from './ruler'
import './editor.scss'
import { HorizontalBorder } from './horizontalBorder'
import { FabricCanvas } from '../components/Scen/FabricCanvas'
import { useDispatch, useSelector } from 'react-redux'
import { UploadButton } from './UploadButton'
import { DECREMENT_NO_OF_FABRIC_UNITS, INCREMENT_NO_OF_FABRIC_UNITS, UPDATE_DISPLAY_UNITS } from '../redux/reducers/settings'
import { roundOff } from '../utilities'
import { changePartLength } from '../redux/actions/parts'
import { useTranslation } from 'react-i18next'

type EditorProps = {
    fabricWidth: number,
    noOfParts: number,
    partSize: number,
    stepSize: number,
    activePart: any,
    maxLength: number,
    partId: string,
    partLength: number,
    rows?: number

}

export const Editor = (props: EditorProps) => {
    const { activePart, maxLength, fabricWidth, noOfParts, partSize, stepSize, partId, partLength = 0, rows = 1 } = props

    const scenRef = useRef(undefined as any);
    const [scenDimensions, setScenDimensions] = useState({ width: 0, height: 0 });
    const [prodPosition, setProdPosition] = useState(0);
    const fabricUnit = useSelector((state: any) => state.settings.unitType);
    const [currentLength, setCurrentLength] = useState(stepSize);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        getFcSize();
        function getFcSize() {
            (debounce(() => {
                setScenDimensions({
                    width: scenRef.current?.offsetWidth,
                    height: scenRef.current?.offsetHeight,
                })
            }, 10))()
        };
        window.addEventListener("resize", getFcSize)
        window.addEventListener("orientationchange", getFcSize)
        return () => {
            window.removeEventListener("resize", getFcSize)
            window.removeEventListener("orientationchange", getFcSize)
        }
    }, []);

    const isScenWidthKnown = !!(scenDimensions.height && scenDimensions.width);

    return <div className='playground-container'><div className='editor-container'>
        <div className='horizontal-ruler-container'>
            {isScenWidthKnown ? <Ruler
                fabricWidth={fabricWidth}
                start={0}
                type={'HORIZONTAL'}
                partsCount={noOfParts}
                partSize={partSize}
                containerWidth={scenDimensions.width}
                containerHeight={scenDimensions.height}
            /> : null}
        </div>
        <div className='scrollable-container'>
        <div className='vertical-ruler-container ruler-markings'>
            {isScenWidthKnown ? <Ruler
                fabricWidth={fabricWidth}
                containerWidth={scenDimensions.width}
                containerHeight={scenDimensions.height}
                partsCount={noOfParts}
                start={prodPosition}
                stepSize={stepSize}
                    partSize={currentLength}
                type="VERTICAL"
            /> : null}
        </div>
        <div className="scen-container">
            <HorizontalBorder ref={scenRef} />
            {isScenWidthKnown ? <FabricCanvas containerWidth={scenDimensions.width}
                productWidth={fabricWidth}
                unit={fabricUnit}
                fabricSizeUnit={fabricUnit}
                activeUrl={(url) => {
                    //setCurFocusedImg(url) 
                }}
                loadImage={(isLoading) => {
                    //setLoading(isLoading)
                }}
                imageSelectionChange1={(wd, limits) => {
                    // setCurFocusedImgWidth(wd)
                    // limits && setCurFocusImgLimitsWidth(limits)
                }}
                imageSelectionChange2={(ht, limits) => {
                    // setCurFocusedImgHeight(ht)
                    // limits && setCurFocusImgLimitsHeight(limits)
                }}
                // activePart={parts}
                    partLengthChanged={(length = 0, change = 0) => {
                        //TODO: fix this:
                        //const currentLength = change ? roundOff(partLength + (change * rows), 3) : roundOff(length, 3)
                        const currentSections = Math.ceil((length) / stepSize)
                        setTimeout(() => {
                            setCurrentLength(roundOff(length, 3));
                            if (change > 0) {
                                dispatch({ type: INCREMENT_NO_OF_FABRIC_UNITS, payload: currentSections })
                            } else if (change < 0) {
                                dispatch({ type: DECREMENT_NO_OF_FABRIC_UNITS, payload: currentSections })
                            }
                            // dispatch(changePartLength(activePart, length, maxLength, t, true))
                            dispatch({
                                type: UPDATE_DISPLAY_UNITS,
                                payload: {
                                    currentLength: length
                                }
                            });
                        }, 10);
                }}
                toastify={(msg) => {
                    // notify(msg)
                }}
            /> : null}
            </div>
        </div>
    </div>
        <div className='upload-button-container'>
            <UploadButton fabricSize={scenDimensions} partId={partId} />
        </div>
    </div>
}