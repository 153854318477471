import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDecode } from '../../hooks/useDecode'
import { roundOff } from '../../utilities'
import styles from "./frameprice.module.scss"

export const FramePrice = () => {

   const{t} = useTranslation()
   const product = useSelector((state:any) => state.product)
   const currency = useDecode(
    product && product.currency ? product.currency : "",
  )
  const [data, setData] = useState({length: 0, price: 0})
  const settings = useSelector((state:any) => state.settings)||{}

  useEffect(() => {
    if (settings.display && settings.configuration) {
      let {currentLength} = settings.display
     
      setData({length:currentLength, price: roundOff(currentLength*product.price, 2)})
    }
  }, [settings, product])


    return <div>
        <div className={styles.content}>
            <span className={styles.content_title}>{t("editor.total_price")} </span>
            <div style={{fontWeight: "bold"}}>
                <span>{product && product.currency ? currency : ""}</span>
                <span>
                    {product && product.price && !product.currency ? "$" : ""}{" "}
                    {product && product.price ? data.price : ""}
                </span>
            </div>
        </div>
    </div>
}