
import ACTIONS from "../../constatnts"

// export const addPart = (payload, totalLength, isGrid) => isGrid ? (dispatch, getState) => dispatch({
//   type: ACTIONS.ADD_PART,
//   payload,
//   totalLength,
//   V: getState().fabric.segmentInFocus,
//   isGrid: true
// }) : ({
//   type: ACTIONS.ADD_PART,
//   payload,
//   totalLength,
// })

export const addFabricSegment = (fabricCanvas, rowIndex, coulmnIndex, length, fabricWidth, repeatType)=>{
  return ({
    type: ACTIONS.ADD_FABRIC_SEGMENT,
    payload:{
      fabricCanvas, rowIndex, coulmnIndex, length, fabricWidth, repeatType
    }
  })
}

export const removeFabricSegment = (fabricCanvas)=>{
  return{
    type: ACTIONS.REMOVE_FABRIC_SEGMENT,
    payload:{
      fabricCanvas
    }
  }
}

export const clearParts = (isGrid) => isGrid ? (dispatch, getState) => dispatch({
  type: ACTIONS.CLEAR_PARTS,
  V: getState().fabric.segmentInFocus
}):({
  type: ACTIONS.CLEAR_PARTS,
})

export const clearImages = (isGrid) => isGrid ? ((dispatch, getState) => {

  const state = getState();
  (state.fabric.segmentSettings||[]).forEach((seg)=>{
    dispatch({
  type: ACTIONS.CLEAR_IMAGES,
      V: seg.parentCanvas,
      isGrid: true
    })
  })
}):({
  type: ACTIONS.CLEAR_IMAGES,
})

// export const removePart = (part_id,isGrid) => isGrid ? (dispatch, getState) => dispatch({
//   type: ACTIONS.CLEAR_IMAGES,
//   V: getState().fabric.segmentInFocus,
//   isGrid: true
// }) : ({
//   type: ACTIONS.REMOVE_PART,
//   part_id,
// })

export const setActivePart = (part_id,isGrid) => isGrid ? (dispatch, getState) => dispatch({
  type: ACTIONS.SET_ACTIVE_PART,
  part_id,
  V: getState().fabric.segmentInFocus,
  isGrid: true
}): ({
  type: ACTIONS.SET_ACTIVE_PART,
  part_id,
})

export const clearActivePart = (isGrid) => isGrid ? (dispatch, getState) => dispatch({
  type: ACTIONS.CLEAR_ACTIVE_PART,
  V: getState().fabric.segmentInFocus,
  isGrid: true
}):({
  type: ACTIONS.CLEAR_ACTIVE_PART,
})

export const changePartLength = (part, length, totalLength, t, isGrid) => isGrid ? (dispatch, getState) => dispatch({
  type: ACTIONS.CHANGE_PART_LENGTH,
  part,
  length,
  totalLength,
  t,
  V: getState().fabric.segmentInFocus,
  isGrid: true
}):({
  type: ACTIONS.CHANGE_PART_LENGTH,
  part,
  length,
  totalLength,
  t,
})

// export const addImageToPart = (part_id, part_length, image, file, info, lockedAR, t, isGrid) => isGrid ?
// (dispatch, getState) => dispatch({
//   type: ACTIONS.ADD_IMG_TO_PART,
//   part_id,
//   part_length,
//   image,
//   file,
//   info,
//   lockedAR,
//   t,
//     V: getState().fabric.segmentInFocus,
//     isGrid: true
// }): ({
//   type: ACTIONS.ADD_IMG_TO_PART,
//   part_id,
//   part_length,
//   image,
//   file,
//   info,
//   lockedAR,
//   t,
// })

export const addImagesToPart = (part_id, imageInfoArray, scaleFactor, t, isGrid) => isGrid ? (dispatch, getState) => dispatch({
  type: ACTIONS.ADD_IMAGES_TO_PART,
  part_id,
  imageInfoArray,
  scaleFactor,
  t,
  V: getState().fabric.segmentInFocus,
  isGrid: true
}): ({
  type: ACTIONS.ADD_IMAGES_TO_PART,
  part_id,
  imageInfoArray,
  scaleFactor,
  t,
})

export const toggleImageMenu = (part_id,isGrid) => isGrid ? (dispatch, getState) => dispatch({
  type: ACTIONS.TOGGLE_IMAGE_MENU,
  part_id,
  V: getState().fabric.segmentInFocus,
  isGrid: true
}):({
  type: ACTIONS.TOGGLE_IMAGE_MENU,
  part_id,
})

export const toggleLibsMenu = (part_id,isGrid) => isGrid ? (dispatch, getState) => dispatch({
  type: ACTIONS.TOGGLE_LIBRARY_MENU,
  part_id,
  V: getState().fabric.segmentInFocus,
  isGrid: true
}):({
  type: ACTIONS.TOGGLE_LIBRARY_MENU,
  part_id,
})

export const toggleTextMenu = (part_id, activity, isGrid) => isGrid ? (dispatch, getState) => dispatch({
  type: ACTIONS.TOGGLE_TEXT_MENU,
  part_id,
  activity,
  V: getState().fabric.segmentInFocus,
  isGrid: true
}):({
  type: ACTIONS.TOGGLE_TEXT_MENU,
  part_id,
  activity,
})

export const changeImageSize = (part_id, img_url, resizeImgType, isGrid) => isGrid ? (dispatch, getState) => dispatch({
  type: ACTIONS.CHANGE_IMAGE_SIZE,
  part_id,
  img_url,
  resizeImgType,
  V: getState().fabric.segmentInFocus,
  isGrid: true
}): ({
  type: ACTIONS.CHANGE_IMAGE_SIZE,
  part_id,
  img_url,
  resizeImgType,
})

export const changeImageSizeCmsByWidth = (part_id, img_url, paramChanged, size_wd, size_ht, part, lockedAR, fcSize, t, isGrid) =>
isGrid?  (dispatch, getState) => dispatch({
  type: ACTIONS.CHANGE_IMAGE_SIZE_CMS_BY_WIDTH,
  part_id,
  img_url,
  paramChanged,
  size_wd,
  size_ht,
  part,
  lockedAR,
  fcSize,
  t,
    V: getState().fabric.segmentInFocus,
    isGrid: true
}):({
  type: ACTIONS.CHANGE_IMAGE_SIZE_CMS_BY_WIDTH,
  part_id,
  img_url,
  paramChanged,
  size_wd,
  size_ht,
  part,
  lockedAR,
  fcSize,
  t,
})

export const changeImageSizeCmsByHeight = (part_id, img_url, paramChanged, size_wd, size_ht, part, lockedAR, fcSize, t, isGrid) =>
isGrid? (dispatch, getState) => dispatch({
  type: ACTIONS.CHANGE_IMAGE_SIZE_CMS_BY_HEIGHT,
  part_id,
  img_url,
  paramChanged,
  size_wd,
  size_ht,
  part,
  lockedAR,
  fcSize,
  t,
    V: getState().fabric.segmentInFocus,
    isGrid: true
}): ({
  type: ACTIONS.CHANGE_IMAGE_SIZE_CMS_BY_HEIGHT,
  part_id,
  img_url,
  paramChanged,
  size_wd,
  size_ht,
  part,
  lockedAR,
  fcSize,
  t,
})

export const removeImageFromPart = (part_id, img_url, part, isGrid) => isGrid ? (dispatch, getState) => dispatch({
  type: ACTIONS.REMOVE_IMAGE_FROM_PART,
  part_id,
  img_url,
  part,
  V: getState().fabric.segmentInFocus,
  isGrid: true
}):({
  type: ACTIONS.REMOVE_IMAGE_FROM_PART,
  part_id,
  img_url,
  part,
})

export const setRepeatType = (part_id, repeatType, isGrid) => isGrid ? (dispatch, getState) => dispatch({
  type: ACTIONS.SET_IMAGE_REPEAT_TYPE,
  part_id,
  repeatType,
  V: getState().fabric.segmentInFocus,
  isGrid: true
}):({
  type: ACTIONS.SET_IMAGE_REPEAT_TYPE,
  part_id,
  repeatType,
})

export const addUpdateText = (part_id, textOpt, isGrid) => isGrid ?
(dispatch, getState)=> dispatch({
  type: ACTIONS.ADD_TEXT_TO_PART,
  part_id,
  textOpt,
    V: getState().fabric.segmentInFocus,
    isGrid: true
}):({
  type: ACTIONS.ADD_TEXT_TO_PART,
  part_id,
  textOpt,
})
