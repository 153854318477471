import { TripleDES } from 'crypto-js'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './imagesidebar.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ACTIONS_REMOVE_IMAGE, ACTIONS_ROTATE_SELECTED_IMAGE, ACTIONS_SELECT_IMAGE, ICanvasImage, ICanvasImages } from '../../redux/reducers/images'
import { log } from '../../utilities/logger'
import { DimInput } from './DimInput'

interface ITile {

    id: string
    url: string
    width: number
    height: number
    widthSubUnit: number
    heightSubUnit: number
    widthLimits: { max: number, min: number }
    heightLimits: { max: number, min: number }
    locked: boolean
    angle: number

}

interface IImageSideBar {

    height: number
    onToggleAR: (value: { url: string, widthSubUnit: number, heightSubUnit: number, lock: boolean }) => void
    onScale: (value: { url: string, targetName: string, widthSubUnit: number, heightSubUnit: number, lock: boolean }) => void
    onDelete: (value: { url: string }) => void
    PatternSettings: any

}


export const ImageSideBar = (props: IImageSideBar) => {

    let isMediaQuery = window.matchMedia('(max-width: 600px)').matches

    let { PatternSettings, height } = props
    let [containerWidth, setContainerWidth] = useState(300)

    const selectedSegment = useSelector((state: any) => state.fabric.segmentInFocus)
    const images = useSelector((state: any) => state.canvasImages && state.canvasImages.images) || []
    const [collageImages, setCollageImages] = useState([] as any[])
    const isSplit = useSelector((state:any) => state.settings?.configuration?.split)

    useEffect(() => {
        setCollageImages(images.filter((image: any) => (!isSplit) ||(image.parentCanvas === selectedSegment)))
    }, [images, selectedSegment])

    // let collageImages: ICanvasImage[] = useSelector((state: any) => state.canvasImages && state.canvasImages.images,
    //     (left, right) => JSON.stringify(left) === JSON.stringify(right))
    let tiles: ITile[] | undefined = useMemo(() => {
        let images = collageImages?.map((image) => {
            let { tileWidth, tileHeight, ...props } = image
            return {
                ...props,
                width: 0.25 * containerWidth,
                height: 0.2*containerWidth, 
            }
        })
        return [...images || []].reverse()
    }, [collageImages, containerWidth])

    let selectedImage = useSelector((state: any) => state.canvasImages && state.canvasImages.selectedImage, (left, right) => {
        return (left?.id === right?.id) && (left?.rotation === right?.rotation)
    })

    return <div className='imageSideBarParent'>
        {tiles ? tiles.map((tile, index) => {
            let isSelected = selectedImage && (selectedImage.url === tile.url)
            log({ widthLimits: tile.widthLimits, index }, 'WIDTH LIMITS')
            return <ImageRow
                onDelete={props.onDelete}
                onToggleAR={props.onToggleAR}
                onScale={props.onScale}
                isRowFirst={index === 0}
                isRowLast={index === (tiles?.length || 0) - 1}
                isRowOdd={index % 2 ? false : true} isSelected={isSelected} {...tile} />
        }) : null}
        {PatternSettings}
    </div>
}

interface IImageRow {
    isRowOdd: boolean,
    isRowFirst: boolean,
    isRowLast: boolean,
    locked: boolean
    width: number
    height: number
    url: string
    isSelected: boolean
    widthLimits: {
        min: number, max: number
    },
    heightLimits: {
        min: number, max: number
    }
    widthSubUnit: number
    heightSubUnit: number
    id: string
    onToggleAR: (value: { url: string, widthSubUnit: number, heightSubUnit: number, lock: boolean }) => void
    onScale: (value: { url: string, targetName: string, widthSubUnit: number, heightSubUnit: number, lock: boolean }) => void
    onDelete: (value: { url: string }) => void
    angle: number
   
}

export const ImageRow = (props: IImageRow) => {

    let isMediaQuery = window.matchMedia('(max-width: 600px)').matches



    let { width, height, url, isSelected, widthLimits, heightLimits, widthSubUnit, heightSubUnit, isRowOdd, isRowFirst,
        isRowLast, id, locked, onDelete, onToggleAR, onScale, angle } = props

    let [lockAspectRatio, setLockAspectRatio] = useState(locked)

    useEffect(() => {
        setLockAspectRatio(locked)
    }, [locked])

    const dispatch = useDispatch()

    let flipDimensions = angle % 180 ? true : false

    log({flipDimensions,angle},'flip')




    return <div className='imageRow'
        onClick={() => {
            dispatch({ type: ACTIONS_SELECT_IMAGE, payload: { id, url } })
            }}
        style={
            {
                ...isRowOdd && { backgroundColor: 'rgb(245,245,245)' },
                ...isRowFirst && { borderTopLeftRadius: 10, borderTopRightRadius: 10 },
                ...isRowLast && { borderBottomLeftRadius: 10, borderBottomRightRadius: 10 },
                ...isSelected && { border: '1px solid #4683b4', backgroundColor: '#4682b499' },
            }
        }>
        <div style={isMediaQuery ? undefined : {
            width: `${isMediaQuery ? 'fit-content' : width - 10}px`,
                    height: '100%',//`${isMediaQuery?100:height-10}px`, 
                    padding: 5, 
                    marginRight: `${isMediaQuery?10:0}px`,
            flexShrink: 0
        }}>
            <img className="individualTile" src={url}
                style={isMediaQuery ? undefined : {
                    width: `${isMediaQuery ? width - 30 : width - 10}px`,
                        height: `${isMediaQuery?height-15:height-10}px`,
                        objectFit: 'contain', 
                        borderRadius:2, 
                    backgroundColor: 'transparent'
                }} >
            </img>
        </div>
        <div className="imageSideBar imageControls">
        {isSelected ? <DimInput
            id={flipDimensions ? "imgSzHeight" : "imgSzWidth"}
            type="number"
            className="imageSideBar inputNumber"
            parentStyle={{ width: '25%', minWidth: '60px', overflow: 'hidden' }}
            name={flipDimensions ? "height" : "width"}
            debounce={1000}
            active={isSelected ? "" : "disabled"}
            min={flipDimensions ? heightLimits.min : widthLimits.min}
            max={flipDimensions ? heightLimits.max : widthLimits.max}
            maxDimensions={flipDimensions?{ height: widthLimits.max, width: heightLimits.max }:{ height: heightLimits.max, width: widthLimits.max }}
            inputValue={flipDimensions ? heightSubUnit : widthSubUnit}
            onInputChange={(value: number) => {
            if (flipDimensions)
                onScale({ url, targetName: 'height', widthSubUnit, heightSubUnit: value, lock: lockAspectRatio })
            else
                onScale({ url, targetName: 'width', widthSubUnit: value, heightSubUnit, lock: lockAspectRatio })
            }}
        /> : null}
        {isSelected ? 'x' : ''}
        {isSelected ? <DimInput
            id={flipDimensions ? "imgSzWidth" : "imgSzHeight"}
            type="number"
            className="imageSideBar inputNumber"
            parentStyle={{ width: '25%', minWidth: '60px', overflow: 'hidden' }}
            name={flipDimensions ? "width" : "height"}
            debounce={1000}
            active={isSelected ? "" : "disabled"}
            min={flipDimensions ? widthLimits.min : heightLimits.min}
            max={flipDimensions ? widthLimits.max : heightLimits.max}
            maxDimensions={flipDimensions?{ height: widthLimits.max, width: heightLimits.max }:{ height: heightLimits.max, width: widthLimits.max }}
            inputValue={flipDimensions ? widthSubUnit : heightSubUnit}
            onInputChange={(value: number) => {
            if (flipDimensions)
                onScale({ url, targetName: 'width', widthSubUnit: value, heightSubUnit, lock: lockAspectRatio })
            else
                onScale({ url, targetName: 'height', widthSubUnit, heightSubUnit: value, lock: lockAspectRatio })
            }}
        /> : null}
        {isSelected ? <button onClick={() => {
            let lock = !lockAspectRatio
            setLockAspectRatio(lock)
            onToggleAR({ widthSubUnit, heightSubUnit, url, lock })
        }} className="actionIconButton" disabled={!isSelected}>
            <img src={lockAspectRatio ? "/img/icons/link.svg" : "/img/icons/broken-link.svg"}
                alt={lockAspectRatio ? "Aspect Ratio Off" : "Aspect Ratio On"}
                className="actionIcon" style={{ opacity: isSelected ? 1 : 0.4 }} />
        </button> : null}
        {isSelected ? <button onClick={() => {
                log('dispatch','rotateImage')
                dispatch({type: ACTIONS_ROTATE_SELECTED_IMAGE})
        }} className="actionIconButton">
            <img src="/img/icons/RotateCW.svg" alt="Rotate clockwise" className="actionIcon"/>
        </button> : null}
        {isSelected ? null : <span className="imageSideBar dimensions">{flipDimensions ?
        `${(heightSubUnit)} X ${(widthSubUnit)}`:`${(widthSubUnit)} X ${(heightSubUnit)}`}</span>}
        <button onClick={() => {
            dispatch({ type: ACTIONS_REMOVE_IMAGE, payload: { url, id } })
            onDelete({ url })
        }} className="actionIconButton">
            <img src="/img/icons/trash_icn.svg" alt="Delete selected image" className="actionIcon" />
        </button>
        </div>
        
    </div>

}