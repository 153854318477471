import React, {useCallback, useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import { useHistory } from "react-router-dom";

import AddImage from "../AddImage/AddImage"
import LIbrary from "../LIbrary/LIbrary"
import Text from "../Text/Text"

import styles from "../leftworkarea.module.scss"

import {
  changePartLength
  // раскоментить для работы текста
  // toggleTextMenu,
} from "../../../redux/actions/parts"
import alertify from "alertifyjs"
import { useTranslation } from "react-i18next"
import { PartLengthInput } from "./PartLengthInput"
import useMediaLibrary from "../../../hooks/useMediaLibrary"
import { API_KEY } from "../../../App"
import { DECREMENT_NO_OF_FABRIC_UNITS, INCREMENT_NO_OF_FABRIC_UNITS, RESET_NO_OF_FABRIC_UNITS, UPDATE_CONFIGURATION, UPDATE_DISPLAY_UNITS } from "../../../redux/reducers/settings"
import { ClearAllButton } from "../../clearAll"
import { ImagePicker } from "../../ImageUpload/ImagePicker"
import { FramePrice } from "../../framePrice"
import { UPDATE_GRID_CONFIG } from "../../../redux/reducers/fabric/actions";
import { setSpinner } from "../../../redux/actions/spinner";
import { ACTIONS_CLEAR_ALL } from "../../../redux/reducers/images";
import { setProduct } from "../../../redux/actions/product";
import { log } from "../../../utilities/logger";
// import { FabricLengthField } from "../../../fabricKit/components/FabricLengthField/FabricLengthField";

const startLength = 1

/**
 * Fabric part component
 * @component
 * @param {object} props
 * @param {object} props.part - active fabric part
 * @example
 *
 * const part = null
 * return (
 *  <LeftWorkAreaPart part={part}/>
 * )
 */
const LeftWorkAreaPart = props => {
  const {part = null} = props
  const dispatch = useDispatch()
  const product = useSelector(state => state.product)
  const imgCount = useSelector(state=> state.canvasImages.images && state.canvasImages.images.length)

  const fbdata = useSelector(state => state.fbdata)
  const apikey = useSelector(state => state.apikey)
  const settings = useSelector(state => state.settings)
  const { configuration, rulerUnits } = settings
  const { stepSize} = configuration || {}
  //const convertor = useCallback((inputValue) => displayFactor && (inputValue * displayFactor()).toFixed(2), [displayFactor])
  const stepLength = fbdata.fabricUnitSizeCm !== undefined?fbdata.fabricUnitSizeCm:1
  const fabricQtyByUnit = fbdata.fabricQtyByUnit !== undefined?fbdata.fabricQtyByUnit:false
  // fbdata.fabricQtyByUnit || false

  const isSplit = settings.configuration?.split

  const [showPicker, setShowPicker] = useState(false)

  const isMediaQuery = window.matchMedia('(max-width: 600px)').matches

  const normalizeInput = useCallback((input)=>{

    if(fabricQtyByUnit){
      input = Math.ceil(input/stepLength)*stepLength
    }
    return input

  },[fabricQtyByUnit])

  

  const {t} = useTranslation()


  const {firebaseImages, inProgress} = useMediaLibrary({ userId: apikey, auth: 1 })
  const [currentLength, setCurrentLength] = useState(stepSize)


  useEffect(() => {

    if (window.matchMedia('(max-width: 600px)').matches){
      if (part.libs_menu) {
        if (firebaseImages && firebaseImages.length) {
          props.showLibrary({ firebaseImages, partId: part._id })
        }else{
          alertify.alert(
            t("editor.alert_warning_title2"),
            `The Library has no images to display`,
            t("i18n.ok"))
        }
      } else {
        props.showLibrary(undefined)
      }
    }

  }, [part.libs_menu, firebaseImages])

  useEffect(()=>{
    if (stepLength && (props.curPartLength > stepLength)) {
      dispatch(changePartLength(part, props.curPartLength, product.totalLength, t, isSplit))
    }
  },[props.curPartLength])

  /**
   * dispatch action for change selected part length
   * @param {object} e - element
   * @method
   */
  const yardApplied = useRef(false)
  const changeLengthHandler = (e) => {
    if (part.image_repeat === 'NON_REPEAT' && e.target.value > props.maxFabricLength) {
      alertify.alert(
        t("editor.alert_warning_title2"), 
        `The max length for No Repeat is ${props.maxFabricLength} metres.` , 
        t("i18n.ok"))
      
      // Add to Cart blocked in Body.js
    }

    yardApplied.current = true
    props.parts(e.target.value)
    const newPartLength = e.target.value
    const length = newPartLength //* realFactor()
    const totalLength = product.totalLength //* realFactor()
    log(`${length},${e.target.value},${product.totalLength},${totalLength}`, 'partLengthIncreasexxx trace')
    dispatch(changePartLength(part, length, totalLength, t, isSplit))
  }
//
  /**
   * dispatch action for open close text menu
   * @param {object} e - element
   * @method
   */
  const textMenuHandler = e => {
    e.preventDefault()
    // раскоментить для работы текста
    // dispatch(toggleTextMenu(part._id, true))
    alertify.alert(t("editor.alert_warning_title3"), t("editor.alert.warning_msg2"), t("i18n.ok"))
  }

  let { rows } = useSelector((state) => state.fabric.settings?.grid) || {}
  rows = isSplit ? rows : undefined
  log({ rulerUnits }, ' RULER UNITS understanding_units inside')
  useEffect(()=>{
    let length = ((rulerUnits === 1) || !rulerUnits) ? stepSize : props.curPartLength;//convertor(props.curPartLength)
    log({ rulerUnits }, ' RULER UNITS outside')
    log({ stepSize, rulerUnits, rows, length, partLength: props.curPartLength, currentLength }, 'partlengthincrease')
    setCurrentLength(length)
    dispatch({
      type: UPDATE_DISPLAY_UNITS,
      payload:{
        currentLength: calibrateInUnits ? stepSize * (rows || 1) : length
      }
    })
  }, [rulerUnits, props.curPartLength, rows])


  const calibrateInUnits = isSplit && isSplit.fatQuarter

  const partLengthInput = calibrateInUnits ? rows : currentLength
   log({partLengthInput, rows, currentLength}, 'updateDisplayUnits debounce hello test')
  const [showGrid, setShowGrid] = useState(isSplit && !isSplit.fatQuarter && isSplit.rows && (isSplit.rows>1 || isSplit.columns >1))
   
  const onFabricLengthInputHandler = useCallback((value) => {
                  if (value > (calibrateInUnits ? rows : props.curPartLength)) {
                dispatch({
                  type: INCREMENT_NO_OF_FABRIC_UNITS
                })
                    if (calibrateInUnits) {
                dispatch({
                  type: UPDATE_GRID_CONFIG,
                  payload: {
                    columns:1,
                    rows: (rows||0)+1
                  }
                })
                    }
              }else{
                dispatch({
                  type: DECREMENT_NO_OF_FABRIC_UNITS
                })
                    if (calibrateInUnits) {
                dispatch({
                  type: UPDATE_GRID_CONFIG,
                  payload: {
                    columns:1,
                    rows: (rows||0)-1
                  }
                })

                    }
                  }
                    log({ value }, 'understanding partLengthIncrease')
                  if (!calibrateInUnits)
                    changeLengthHandler({ target: { value: normalizeInput(value) } })

  },[calibrateInUnits, rows, props.curPartLength]);

  const styleAdjustmentLength = showGrid? {height: '40%'}:{}
  const styleAdjustmentImage = showGrid? {height: '60%'}:{}

  return part && stepSize && configuration ? (
    <div style={{height: '100%'}}>
      <div className={styles.part}>
        {part.libs_menu ? <div className={styles.libContainer}><LIbrary progress={inProgress} firebaseImages={firebaseImages} id={part._id} /></div> : null}
        {part.text_menu ? <Text id={part._id} /> : null}
       
        <div className={styles.part_length} style={styleAdjustmentLength}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
            {/* <div style={{ width: '50%', maxWidth: '50%' }}> */}
              <div className={styles.part_lengthparent}>
                <span className={styles.strTitle}>{calibrateInUnits ? 'Units' : (isMediaQuery ? "Total Length" : t("editor.length"))}</span>

                <PartLengthInput
                  className={styles.lengthInp}
                  type="number"
                  step={calibrateInUnits ? 1 : stepLength}
                  min={calibrateInUnits ? 1 : stepLength}
                  max={product.totalLength}
                  contextualMax={props.maxFabricLength}
                  inputValue={partLengthInput}
                  debounce={300}
                  isDisabled={fabricQtyByUnit}
                  onInputChange={onFabricLengthInputHandler}
              />
            </div>

            {/* </div> */}
            {!isMediaQuery?<div style={{ flexGrow: 1, marginLeft: 10 }}>
              {!isSplit?.fatQuarter ? <button style={{ backgroundColor: 'transparent', borderWidth: 0 }} onClick={() => {
                setShowGrid(!showGrid)
                if (!isSplit) {
                  const fatQuarter = isSplit.fatQuarter
                        dispatch({
                          type: UPDATE_CONFIGURATION,
                          payload: {
                            split: {
                        fatQuarter,
                        rows: fatQuarter ? 0 : (fbdata.rows || product.rows || 1),
                        columns: fatQuarter ? 0 : (fbdata.columns || product.columns || 1)
                            }
                          }
                        })
                  dispatch({
                    type: UPDATE_GRID_CONFIG,
                    payload: {
                      rows: fatQuarter ? 1 : (fbdata.rows || product.rows || 1),
                      columns: fatQuarter ? 1 : (fbdata.columns || product.columns || 1)
                      }
                  })
              }
              }}>
                <img src={showGrid ? '/img/icons/chevron-up.svg' : '/img/icons/chevron-down.svg'} />
              </button>:<span className={styles.segmentTypeSelect}>Fat Quarter</span>}
        </div>:<div style={{ flexGrow: 1, marginLeft: 10 }}></div>}
          <div className={styles.leftPanelFabricPrice}>
            <FramePrice/>
          </div>
        <div className={styles.leftPanelClearAll}>
          <ClearAllButton clearSelections={props.clearSelections} />
        </div>
        <div className={styles.leftPanelAddToCart}>
          {props.addToCartWidget}
        </div>
      </div>
          <div style={{ display: 'flex', alignSelf: 'flex-start', flexDirection: 'column', width: '50%' }}>
            {showGrid ? <div className={styles.part_lengthparent}>
              <span className={styles.strTitle} style={{ flexGrow: 1 }}>Rows</span>
              <input className={styles.lengthInp} min={1} value={isSplit.rows}
              type="number"
                step={1} onInput={(event) => {
                  if (!event.target.value || (parseInt(event.target.value) < 1)) {
                    return
                  }
                  const newRowValue = parseInt(event.target.value)
                  if (calibrateInUnits) {
                    if (newRowValue > isSplit.rows) {
                      dispatch({
                        type: INCREMENT_NO_OF_FABRIC_UNITS
                      })
                    } else if (newRowValue < isSplit.rows) {
                      dispatch({
                        type: DECREMENT_NO_OF_FABRIC_UNITS
                      })
                    }
                  }
                  dispatch({
                    type: UPDATE_CONFIGURATION,
                    payload: {
                      split: {
                        fatQuarter: false,
                        rows: parseInt(event.target.value),
                        columns: isSplit.columns
                      }
                    }
                  })
                  dispatch({
                    type: UPDATE_GRID_CONFIG,
                    payload: {
                      rows: parseInt(event.target.value),
                      columns: isSplit.columns
                    }
                  })
                }} />
            </div> : null}
            {showGrid ? <div className={styles.part_lengthparent}>
              <span className={styles.strTitle} style={{ flexGrow: 1 }}>Columns</span>
              <input className={styles.lengthInp} min={1} value={isSplit.columns}
              type="number"
                step={1} onInput={(event) => {
                  if (!event.target.value || (parseInt(event.target.value) < 1)) {
                    return
                  }
                  dispatch({
                    type: UPDATE_CONFIGURATION,
                    payload: {
                      split: {
                        fatQuarter: false,
                        rows: isSplit.rows,
                        columns: parseInt(event.target.value)
                      }
                    }
                  })
                  dispatch({
                    type: UPDATE_GRID_CONFIG,
                    payload: {
                      columns: parseInt(event.target.value),
                      rows: isSplit.rows
                    }
                  })
                }} />
            </div> : null}
          </div>
        </div>

      {
        // ==============part image
      }
        <div className={styles.part_addImg} style={styleAdjustmentImage}>
        <AddImage 
              part={part} 
            interceptUploadEvent={ window.matchMedia('(max-width: 600px)').matches?()=>{
              setShowPicker(true)
            }:undefined}
              galleryHeight={props.galleryHeight}
              imageUploadStart={props.imageUploadStart}
              selCurImage={props.selCurImage} 
              selCurImageWidth={props.selCurImageWidth} 
              selCurImageHeight={props.selCurImageHeight}
              selCurImageConstrained={props.aspectRatioConstrained}
              widthLimits={props.widthLimits}
              heightLimits={props.heightLimits}
              clearSelections={props.clearSelections}
              fcSize={props.fcSize}
              maxFabricLength={props.maxFabricLength}
              open3dView={props.open3dView}/>
      </div>
      {
        // ==============part text
      }
      <div className={styles.part_addText}>
        <button
          name="ADD_IMG_TOGGLE"
          onClick={textMenuHandler}
          className={styles.toggleBtn}
        >
          {/* <span>Add Text</span>
          <img
            src={"/img/icons/chevron-down.svg"}
            alt={part.image_menu ? "close" : "open"}
          /> */}
        </button>
      </div>
    </div>
      {
        showPicker ? <div className={styles.mobilePicker}>
        <ImagePicker part={part}  
        fcSize={props.fcSize}
        onClose={()=>{
          setShowPicker(false)
        }}
        imageUploadStart={props.imageUploadStart}/> 
        </div> : null
      }
    </div> 
  ) : null
}

export default LeftWorkAreaPart
