import React from "react"
import styles from "./buttons.module.scss"

/**
 * Add to Cart button
 * @component
 * @param {object} props - all component props
 * @param {function} props.handler - click function
 * @param {string} props.title - btn title
 * @param {object} props.enterStyle - btn style
 * @param {object} props.name - btn name
 * @example
 *
 * const handler = () => {}
 * const title = ""
 * const enterStyle = {}
 * const name = ""
 *
 * return (
 * <ATCbutton
 *      handler={handler}
 *      title={title}
 *      enterStyle={enterStyle}
 *      name={name}
 * />
 * )
 */
const ATCbutton = props => {
  const {handler = () => {}, title = "", enterStyle = {}, name = "", active = {}} = props
  return (
    <button
      onClick={handler}
      className={styles.btn}
      style={{...enterStyle}}
      name={name}
      disabled={active}
    >
      {title}
    </button>
  )
}

export default ATCbutton
