import React from 'react'
import { useTranslation } from 'react-i18next';
import styles from "./addtocart.module.scss"

interface IATCButton {
    handler: ()=>void
    name: string
    title: string
    active: boolean
    enterStyle:any
}

export const AddToCartBtn = (props: IATCButton) => {


    // return <button disabled={!imgCount} onClick={() => {
    // }} className={styles.addToCartButton} style={{ opacity: imgCount ? 1 : 0.5 }}>
    //     <img src="/img/icons/ic_add_shopping_cart.png" alt="Add To Cart" className="actionIcon" />
    //     <span>{t("editor.clear_all")}</span>
    // </button>
    return <button
        onClick={props.handler}
        className={styles.addToCartButton}
        name={props.name}
        disabled={props.active}
        style={{...props.enterStyle}}
    >
        <img src="/img/icons/Add2Cart.svg" alt="Add To Cart" className="actionIcon" />
        <span>{props.title}</span>
    </button>
}