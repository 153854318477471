import React from "react"
import {Link} from "react-router-dom"
import { ClearAllButton } from "../clearAll"

import Logo from "../Logo/Logo"
import Data from "./Data"

import styles from "./header.module.scss"

/**
 * App header
 * @component
 * @example

 * return (
 *  <Header/>
 * )
 */
const Header = (props) => {
  return (
    <header className={styles.container}>
      <div className={styles.logoContainer}>
        <div className="logo-link">
          <Logo />
        </div>
        <div className={styles.clearallBtn}>
          <ClearAllButton clearSelections={()=>{
            props.forceClear &&  props.forceClear()
          }} />
        </div>
        {/* <Link to="/" className="logo-link">
          <Logo />
        </Link> */}
      </div>
      <Data />
    </header>
  )
}

export default Header
