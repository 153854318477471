import * as THREE from "three"
export default ({camera, selection = [], fitRatio = 1.2, controls}) => {
  const box = new THREE.Box3()

  // console.log("qweqwe", camera)
  // camera.position.set(0, 0, 5)

  selection.forEach(item => box.expandByObject(item))
  const {x: sx, y: sy, z: sz} = box.getSize(new THREE.Vector3())
  const center = box.getCenter(new THREE.Vector3())
  const maxSize = Math.max(sx, sy, sz)
  const fitHeightDistance =
    maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360))
  const fitWidthDistance = fitHeightDistance / camera.aspect
  const distance = fitRatio * Math.max(fitHeightDistance, fitWidthDistance)
  const direction = controls.target
    .clone()
    .sub(camera.position)
    .normalize()
    .multiplyScalar(distance)

  controls.maxDistance = distance * 10
  controls.target.copy(center)
  camera.near = distance / 100
  camera.far = distance * 100
  camera.updateProjectionMatrix()
  camera.position.copy(controls.target).sub(direction)
  controls.update()
}
