import * as THREE from "three"
import MurmurHash3 from "../lib/MurmurHash"
import glo from "./glo";

export default class ImageTexture {
    public static rtTextures: { [id: string]: ImageTexture } = {};

    public texture: THREE.Texture | undefined;
    public urlHash: string | undefined;
    public texHash: string | undefined;
    public texImage: HTMLImageElement | undefined;
    public orgHash: string | undefined;
    public orgImage: HTMLImageElement | undefined;
    public logHash: string | undefined;
    public logImage: HTMLImageElement | undefined;

    public static createTexture(imageTex: ImageTexture): void {
        imageTex.texture = new THREE.Texture(imageTex.texImage);
        imageTex.texture.minFilter = THREE.LinearFilter;
        imageTex.texture.magFilter = THREE.LinearFilter;
        imageTex.texture.wrapS = THREE.RepeatWrapping;
        imageTex.texture.wrapT = THREE.RepeatWrapping;
        imageTex.texture.needsUpdate = true;
        this.rtTextures[imageTex.urlHash!] = imageTex;
        this.rtTextures[imageTex.orgHash!] = imageTex;
        this.rtTextures[imageTex.texHash!] = imageTex;
        this.rtTextures[imageTex.logHash!] = imageTex;
    }
    public static deleteTexture(hash: string): void {
        let tex = this.rtTextures[hash];
        delete this.rtTextures[tex.urlHash!];
        delete this.rtTextures[tex.orgHash!];
        delete this.rtTextures[tex.texHash!];
        delete this.rtTextures[tex.logHash!];
        tex.texture?.dispose();
    }
    public static loadTexture(url: string, scale: boolean, cbAfter: (hash: string, imageTex: ImageTexture) => void): void {
        let urlHash = MurmurHash3.hashBytes(url, url.length, glo.HASHKEY);
        let imageTex: ImageTexture = ImageTexture.rtTextures[urlHash];
        if (undefined === imageTex) {
            imageTex = new ImageTexture();
            imageTex.urlHash = urlHash;
            glo.imageLoader.load(url, srcImage => {
                imageTex.orgImage = srcImage;
                let canvas = document.createElement("canvas")
                let canvCTX = canvas.getContext("2d")
                if (0 !== srcImage.src.indexOf("data")) {
                    canvas.width = srcImage.width;
                    canvas.height = srcImage.height;
                    canvCTX!.drawImage(srcImage, 0, 0, srcImage.width, srcImage.height);
                    imageTex.orgImage = new Image();
                    imageTex.orgImage.src = canvas.toDataURL();
                }
                imageTex.orgHash = MurmurHash3.hashBytes(imageTex.orgImage.src, imageTex.orgImage.src.length, glo.HASHKEY);
                if (!scale) {
                    imageTex.logHash = imageTex.texHash = imageTex.orgHash;
                    imageTex.logImage = imageTex.texImage = imageTex.orgImage;
                    ImageTexture.createTexture(imageTex);
                    cbAfter(urlHash, imageTex);
                }
                else {
                    let maxImage = Math.max(srcImage.width, srcImage.height);;
                    let FTex = 1000.0 / maxImage;
                    let FLogo = 64.0 / maxImage;
                    canvas.width = srcImage.width * FLogo;
                    canvas.height = srcImage.height * FLogo;
                    canvCTX!.drawImage(srcImage, 0, 0, srcImage.width * FLogo, srcImage.height * FLogo);
                    imageTex.logImage = new Image();
                    imageTex.logImage.src = canvas.toDataURL();
                    imageTex.logImage.onload = () => {
                        imageTex.logHash = MurmurHash3.hashBytes(imageTex.logImage!.src, imageTex.logImage!.src.length, glo.HASHKEY);
                        imageTex.logImage!.width = srcImage.width;
                        imageTex.logImage!.height = srcImage.height;

                        if (FTex < 0.9) {
                            canvas.width = srcImage.width * FTex;
                            canvas.height = srcImage.height * FTex;
                            canvCTX!.drawImage(srcImage, 0, 0, srcImage.width * FTex, srcImage.height * FTex);
                            imageTex.texImage = new Image();
                            imageTex.texImage.src = canvas.toDataURL();
                            imageTex.texImage.onload = () => {
                                imageTex.texHash = MurmurHash3.hashBytes(imageTex.texImage!.src, imageTex.texImage!.src.length, glo.HASHKEY);
                                imageTex.texImage!.width = srcImage.width;
                                imageTex.texImage!.height = srcImage.height;
                                ImageTexture.createTexture(imageTex);
                                cbAfter(urlHash, imageTex);
                            }
                        } else {
                            imageTex.texImage = imageTex.orgImage;
                            imageTex.texHash = imageTex.orgHash;
                            ImageTexture.createTexture(imageTex);
                            cbAfter(urlHash, imageTex);
                        }
                    }
                }
            });
        }
        else {
            cbAfter(urlHash, imageTex);
        }
    }
}