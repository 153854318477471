//import { TEST_MEASUREMENT } from "../../components/Body/Body"
//import store from "../../redux/store/index"
// export const TEST_MEASUREMENT = 'YARD'
// export const TEST_LANGUAGE = 'us'



export var FIREBASE_UNIT = {
    unit: 'METER'
}

export const unitToPixel = (fabricWidthPx: number, fabricWidth: number, fabricLength: number) => {


    let fabricWidthSubUnits = fabricWidth * (FIREBASE_UNIT.unit === 'YARD' ? 36 : 100)
    let fabricHeightSubUnits = fabricLength * (FIREBASE_UNIT.unit === 'YARD' ? 36 : 100)

    let pxPerSubUnit = fabricWidthPx / fabricWidthSubUnits

    let fabricHeightPx = pxPerSubUnit * fabricHeightSubUnits

    let size = {
        width: Math.round(fabricWidthPx),
        height: Math.round(fabricHeightPx),
        pxPerSubUnit
    }

    return size


}

export const getResolution = (dotsPerInch: number) => {

    return FIREBASE_UNIT.unit === 'YARD' ? dotsPerInch : (dotsPerInch / 2.54)
}

export const convertToCm = (inches: number) => inches * 2.54
export const convertToMeters = (yards: number) => yards * 0.9144


export const getSubUnit = (value: number) => {

    return value * (FIREBASE_UNIT.unit === 'YARD' ? 36 : 100)
}

export const getSuperUnit = (value: number) => {

    return (FIREBASE_UNIT.unit === 'YARD' ? value / 36 : value / 100)
}