import alertify from "alertifyjs"
import translate from "../translate"
//import glo from "../View3D/glo";
// ===============================================CUSTOM ALERT
alertify.dialog(
    "myConfirm",
    function () {
        // let settings = null
        return {
            setup: function () {
                let settings = alertify.confirm().settings
                for (var prop in settings) this.settings[prop] = settings[prop]
                let setup = alertify.confirm().setup()

                setup.buttons.push({
                    text: translate.t("i18n.resize_to_max").res,
                    key: 67 /*c*/,
                    scope: "button",
                    className: "ajs-continue",
                })
                return setup
            },
            settings: {
                oncontinue: null,
            },
            callback: function (closeEvent) {
                if (closeEvent.index === 2) {
                    if (typeof this.get("oncontinue") === "function") {
                        let returnValue = this.get("oncontinue").call(this, closeEvent)
                        if (typeof returnValue !== "undefined") {
                            closeEvent.cancel = !returnValue
                        }
                    }
                } else {
                    alertify.confirm().callback.call(this, closeEvent)
                }
            },
        }
    },
    false,
    "confirm",
)
// ===============================================CUSTOM ALERT

const GPrint = {
    rotateLogo: (item, angle) => {
        item.angle = angle
        GPrint.reset_border(item)
    },

    resizeLogo: (item, value, props = "", callbacks = []) => {
        GPrint.reset_size(item, value, props, callbacks)
        GPrint.reset_border(item)
    },

    hitTopmostLogoIdx: (list, x, y) => {
        let i = -1
        let foundRepeated = false
        list.forEach((logo, key) => {
            if (
                x >= logo.bpx &&
                x <= logo.bpx + logo.bcx &&
                y >= logo.bpy &&
                y <= logo.bpy + logo.bcy
            ) {
                i = key
            }

            if (logo.isRepeat) {
                foundRepeated = key
            }
        })

        if (foundRepeated !== false && i === -1) {
            /*let repeatedLogo = list[foundRepeated]

            let difference = {
                y: Math.trunc((repeatedLogo.py - y) / repeatedLogo.cy),
                x: Math.trunc((repeatedLogo.px - x) / repeatedLogo.cx),
            }
            if (!Object.is(difference.y, -0) && difference.y >= 0) {
                difference.y += 1
            }
            if (!Object.is(difference.x, -0) && difference.x >= 0) {
                difference.x += 1
            }
            repeatedLogo.py -= difference.y * repeatedLogo.cy
            repeatedLogo.px -= difference.x * repeatedLogo.cx*/
            return foundRepeated
        }
        return i
    },

    reset_border: item => {
        var x = item.px
        var y = item.py
        var w = item.cx
        var h = item.cy
        var o = (Math.PI * item.angle) / 180
        var nw = Math.abs(w * Math.cos(o)) + Math.abs(h * Math.sin(o))
        var nh = Math.abs(w * Math.sin(o)) + Math.abs(h * Math.cos(o))
        var nx = x - (nw - w) / 2
        var ny = y - (nh - h) / 2

        item.bpx = nx
        item.bpy = ny
        item.bcx = nw
        item.bcy = nh
    },

    reset_size: (item, rate, props, callbacks) => {
        let resize = rate => {
            let delta = {
                x: item.cx,
                y: item.cy,
            }
            item.cx = parseInt((item.cbx * rate) / 100)
            item.cy = parseInt((item.cby * rate) / 100)

            delta.x -= item.cx
            delta.y -= item.cy

            item.px += delta.x / 2
            item.py += delta.y / 2

            item.rate = rate;

            // const pxSize = Math.min(item.cbx, item.cbx);
            // const cmSize = Math.min(item.cx, item.cx) / glo.unit;
            // const dpi = pxSize / cmSize;
            //console.log(pxSize, cmSize, dpi);
        }

        if (item.autoscaled) {
            let maxPercent = 150

            switch (props) {
                case "warning":
                    if (rate > maxPercent) {
                        let alertWarning = alertify
                            .myConfirm(translate.t("i18n.scale_warning").res)
                            .set({
                                onok: () => {
                                    if (item.Npx && item.Npy) {
                                        item.px = item.Npx
                                        item.py = item.Npy
                                    }

                                    item.Npx = false
                                    item.Npy = false

                                    resize(rate)
                                    item.scaleWarning = true
                                    item.autoscaled = false
                                    GPrint.reset_border(item)

                                    callbacks[1]()
                                },
                                oncontinue: () => {
                                    if (item.Npx && item.Npy) {
                                        item.px = item.Npx
                                        item.py = item.Npy
                                    }

                                    item.Npx = false
                                    item.Npy = false

                                    resize(maxPercent)
                                    item.scaleWarning = false
                                    item.autoscaled = false
                                    GPrint.reset_border(item)

                                    callbacks[1]()
                                },
                                oncancel: () => {
                                    item.scaleWarning = false
                                    item.autoscaled = false
                                    callbacks[1]()
                                },
                                labels: {
                                    ok: translate.t("i18n.yes").res,
                                    cancel: translate.t("i18n.no").res,
                                },
                            })
                        alertWarning.setHeader(translate.t("i18n.warning").res)
                    } else {
                        item.autoscaled = false
                        resize(rate)
                    }
                    break
                case "blocking":
                    if (rate > maxPercent) {
                        alertify
                            .alert(
                                translate.t("i18n.warning").res,
                                translate.t("i18n.scale_blocking").res,
                                () => {
                                    item.scaleWarning = false
                                    item.autoscaled = false
                                    callbacks[1]()
                                },
                            )
                            .setting({
                                label: translate.t("i18n.close").res,
                            })
                    } else {
                        item.autoscaled = false
                        resize(rate)
                    }
                    break

                default:
                    if (item.Npx && item.Npy) {
                        item.px = item.Npx
                        item.py = item.Npy
                    }

                    item.Npx = false
                    item.Npy = false
                    resize(rate)
                    callbacks.forEach(it => it())
                    return
            }
        } else {
            resize(rate)
            callbacks.forEach(it => it())
        }
    },

    checkBleeding: (logo, side) => {
        let LogoCorners = [
            {
                type: "tl",
                x: logo.px,
                y: logo.py,
            },
            {
                type: "tr",
                x: logo.px + logo.cx,
                y: logo.py,
            },
            {
                type: "br",
                x: logo.px + logo.cx,
                y: logo.py + logo.cy,
            },
            {
                type: "bl",
                x: logo.px,
                y: logo.py + logo.cy,
            },
        ]

        let bleedCorners = {
            tl: {
                x: side.bleeding.topLeft.x,
                y: side.bleeding.topLeft.y,
            },
            tr: {
                x: side.bleeding.bottomRight.x + side.duv.x,
                y: side.bleeding.topLeft.y,
            },
            br: {
                x: side.bleeding.bottomRight.x + side.duv.x,
                y: side.bleeding.bottomRight.y + side.duv.y,
            },
            bl: {
                x: side.bleeding.topLeft.x,
                y: side.bleeding.bottomRight.y + side.duv.y,
            },
        }

        let found = null
        LogoCorners.forEach(c => {
            if (!found) {
                if (c.x > bleedCorners.tl.x && c.x < 0)
                    found = {
                        type: "left",
                        diff: Math.abs(bleedCorners.tl.x - c.x),
                        corn: c,
                    }
                if (c.y > bleedCorners.tl.y && c.y < 0)
                    found = {
                        type: "top",
                        diff: Math.abs(bleedCorners.tl.y - c.y),
                        corn: c,
                    }
                if (c.x > side.duv.x && c.x < bleedCorners.tr.x)
                    found = {
                        type: "right",
                        diff: bleedCorners.tr.x - c.x,
                        corn: c,
                    }
                if (c.y > side.duv.y && c.y < bleedCorners.bl.y)
                    found = {
                        type: "bottom",
                        diff: bleedCorners.bl.y - c.y,
                        corn: c,
                    }
            }
        })
        return found
    },
}

export default GPrint
