
import gv from "../../js/viewer-api"
import Logo from "../../js/View3D/Logo";
import { storage } from "../../../../../../../firebase";
import { log } from "../../../../../../../utilities/logger"


export const fetchImageDimensions = (imageData: string): Promise<{ width: number, height: number }> => new Promise((resolve, reject) => {
    {
        let image = new Image()
        image.src = imageData
        image.onload = function () {
            resolve({
                width: image.width,
                height: image.height
            })
        }
    }
})

export const getJsonInfo = async (model: any, imageData: string, imagePlacement?: any): Promise<jsonInfo> => {
    try {
        log("getJsonInfo 1")
        const imageDimensions = await fetchImageDimensions(imageData)
        log("getJsonInfo 2")
        let url = await storage.refFromURL(model).getDownloadURL().catch((error) => { log("get download url error: ", error); throw error });
        log(url)
        const jResponse = await fetch(url).catch((error) => { log("fetch error: ", error); throw error });
        log(jResponse)
        const UVConfig = await jResponse.json().catch((error) => { log("jResponse error: ", error); throw error });
        log(UVConfig)

        const DEFAULT_WIDTH = 150
        const DEFAULT_HEIGHT = 150

        let deltaWidth;
        let deltaHeight;

        if (UVConfig.ispos === undefined) {
            return { width: 0, height: 0 };
        }

        var posWidth = UVConfig.ispos.imageSize.width * 150 / 2.54;
        var posHeight = UVConfig.ispos.imageSize.height * 150 / 2.54;

        var scaleWidth = imageDimensions.width / posWidth;
        var scaleHeight = imageDimensions.height / posHeight;
        var effScaleFactor = Math.min(scaleWidth, scaleHeight);

        var theWidth = 0;
        var theHeight = 0;
        theWidth = (imageDimensions.width) / effScaleFactor;
        theHeight = (imageDimensions.height) / effScaleFactor;

        return ({ width: theWidth, height: theHeight })

        // else
        // {
        //     throw new Error('Item does not have all over spread option, sorry...');
        // }

    } catch (error) {
        log("getJsonInfo 3", error)
        throw error
    }
}

export type UVConfig = {
    name: any,
    size: any,
    ispos?: {
        imageSize:{
            width: number,
            height:number
        },
        piecepos: any[]
    }
}

export const addImageToTemplate = async (imageData: string, isRepeatMode: boolean, uvConfig: UVConfig): Promise<ITemplate> => {

    const imageObj = { data: imageData };
    const imageDimensions = await fetchImageDimensions(imageData)

    let deltaWidth;
    let deltaHeight;

    if (isRepeatMode) {
        let objectInfo: IGarmentInfo = {
            garmentName: uvConfig.name,
            garmentSize: uvConfig.size,
            parts: [],
        }
        let placements: IGarmentPieces = {}

        //Get all the pieces
        var thePieces = gv.getPartsList();
        var activePiece = gv.getActivePart();

        var activeLogo = null;

        //GTodo - Get this in the constructor or similar, why loop
        if (activePiece) {
            for (var k = 0; k < activePiece.logos.length; k++) {

                if (activePiece.logos[k].active) {
                    activeLogo = activePiece.logos[k];
                    break;
                }
            }
        }

        for (var k = 0; k < thePieces.length; k++) {
            log(thePieces[k].name);

            placements[thePieces[k].name] = imageObj;

            let info: IPieceInfo = {
                name: thePieces[k].name,
                displayName: thePieces[k].name,
                color: thePieces[k].color,// "#ffffff",
                logos: [],
            }
            if (activeLogo) {
                info.logos.push({
                    iwidth: imageDimensions.width,
                    iheight: imageDimensions.height,
                    width: activeLogo.Width,
                    height: activeLogo.Height,
                    angle: activeLogo.angle,
                    scale: 1,
                    mirrorX: (activeLogo.isFlipH) ? 1 : 0,
                    mirrorY: (activeLogo.isFlipV) ? 1 : 0,
                    scaleX: activeLogo.scaleX,
                    scaleY: activeLogo.scaleY,
                    isText: activeLogo.isText,
                    isRepeat: activeLogo.isRepeat,
                    UVPosition: {
                        x: 0,
                        y: 0,
                    },
                    imageKey: thePieces[k].name,
                })
                objectInfo.parts.push(info);
            }

        }

        return {
            placements,
            infoJson: objectInfo,
        }
    }
    else if (uvConfig.ispos !== undefined) {
        var posWidth = uvConfig.ispos.imageSize.width * 150 / 2.54;
        var posHeight = uvConfig.ispos.imageSize.height * 150 / 2.54;

        var scaleWidth = imageDimensions.width / posWidth;
        var scaleHeight = imageDimensions.height / posHeight;
        var effScaleFactor = Math.min(scaleWidth, scaleHeight);

        var theWidth = 0;
        var theHeight = 0;
        theWidth = (imageDimensions.width) / effScaleFactor;
        theHeight = (imageDimensions.height) / effScaleFactor;
        deltaWidth = (theWidth - posWidth) / 2;
        deltaHeight = (theHeight - posHeight) / 2;

        let objectInfo: IGarmentInfo = {
            garmentName: uvConfig.name,
            garmentSize: uvConfig.size,
            parts: [],
        }
        let placements: IGarmentPieces = {}


        for (var k = 0; k < uvConfig.ispos.piecepos.length; k++) {
            var thePiece = uvConfig.ispos.piecepos[k];
            log("working on " + thePiece.id);
            var pieceName = thePiece.id;
            placements[pieceName] = imageObj;

            let info: IPieceInfo = {
                name: thePiece.id,
                displayName: thePiece.id,
                color: "#ffffff",
                logos: [],
            }

            var theX = uvConfig.ispos.piecepos[k].uv.x * 150 / 2.54;
            var theY = uvConfig.ispos.piecepos[k].uv.y * 150 / 2.54;


            var doMirror = uvConfig.ispos.piecepos[k].uv.mirror;
            var theAngle = uvConfig.ispos.piecepos[k].uv.angle;

            info.logos.push({
                iwidth: imageDimensions.width,
                iheight: imageDimensions.height,
                width: theWidth,
                height: theHeight,
                angle: 0,
                scale: 1,
                mirrorX: (doMirror) ? -1 : 1,
                mirrorY: 1,
                scaleX: (doMirror) ? -1 : 1,
                scaleY: 1,
                isText: false,
                isRepeat: false,
                UVPosition: {
                    x: theX - deltaWidth,
                    y: theY - deltaHeight,
                },
                imageKey: pieceName,
            })

            objectInfo.parts.push(info);
        }
        return {
            placements,
            infoJson: objectInfo,
        }
    }
    else {

        throw new Error('Item does not have all over spread option, sorry...');
    }


}

export const fetchUVConfig = async (model: string): Promise<UVConfig> => {
    try {

        let url = await storage.refFromURL(model).getDownloadURL().catch((error) => { log("get download url error: ", error); throw error });
        const jResponse = await fetch(url).catch((error) => { log("fetch error: ", error); throw error });
        return await jResponse.json().catch((error) => { log("jResponse error: ", error); throw error });


    } catch (error) {
        log("createTemplate 3", error)
        throw error
    }
}


export const createTemplate = async (model: any, imageData: string, isRepeatMode: boolean, imagePlacement?: any): Promise<ITemplate> => {
    try {
        const imageObj = { data: imageData };
        const imageDimensions = await fetchImageDimensions(imageData)
        let url = await storage.refFromURL(model).getDownloadURL().catch((error) => { log("get download url error: ", error); throw error });
        const jResponse = await fetch(url).catch((error) => { log("fetch error: ", error); throw error });
        const UVConfig = await jResponse.json().catch((error) => { log("jResponse error: ", error); throw error });

        let deltaWidth;
        let deltaHeight;

        if (isRepeatMode) {
            let objectInfo: IGarmentInfo = {
                garmentName: UVConfig.name,
                garmentSize: UVConfig.size,
                parts: [],
            }
            let placements: IGarmentPieces = {}

            //Get all the pieces
            var thePieces = gv.getPartsList();
            var activePiece = gv.getActivePart();

            var activeLogo = null;

            //GTodo - Get this in the constructor or similar, why loop
            if (activePiece) {
                for (var k = 0; k < activePiece.logos.length; k++) {

                    if (activePiece.logos[k].active) {
                        activeLogo = activePiece.logos[k];
                        break;
                    }
                }
            }

            for (var k = 0; k < thePieces.length; k++) {
                log(thePieces[k].name);

                placements[thePieces[k].name] = imageObj;

                let info: IPieceInfo = {
                    name: thePieces[k].name,
                    displayName: thePieces[k].name,
                    color: thePieces[k].color,// "#ffffff",
                    logos: [],
                }
                if (activeLogo) {
                    info.logos.push({
                        iwidth: imageDimensions.width,
                        iheight: imageDimensions.height,
                        width: activeLogo.Width,
                        height: activeLogo.Height,
                        angle: activeLogo.angle,
                        scale: 1,
                        mirrorX: (activeLogo.isFlipH) ? 1 : 0,
                        mirrorY: (activeLogo.isFlipV) ? 1 : 0,
                        scaleX: activeLogo.scaleX,
                        scaleY: activeLogo.scaleY,
                        isText: activeLogo.isText,
                        isRepeat: activeLogo.isRepeat,
                        UVPosition: {
                            x: 0,
                            y: 0,
                        },
                        imageKey: thePieces[k].name,
                    })
                    objectInfo.parts.push(info);
                }

            }

            return {
                placements,
                infoJson: objectInfo,
            }
        }
        else if (UVConfig.ispos !== undefined) {
            var posWidth = UVConfig.ispos.imageSize.width * 150 / 2.54;
            var posHeight = UVConfig.ispos.imageSize.height * 150 / 2.54;

            var scaleWidth = imageDimensions.width / posWidth;
            var scaleHeight = imageDimensions.height / posHeight;
            var effScaleFactor = Math.min(scaleWidth, scaleHeight);

            var theWidth = 0;
            var theHeight = 0;
            theWidth = (imageDimensions.width) / effScaleFactor;
            theHeight = (imageDimensions.height) / effScaleFactor;
            deltaWidth = (theWidth - posWidth) / 2;
            deltaHeight = (theHeight - posHeight) / 2;

            let objectInfo: IGarmentInfo = {
                garmentName: UVConfig.name,
                garmentSize: UVConfig.size,
                parts: [],
            }
            let placements: IGarmentPieces = {}


            for (var k = 0; k < UVConfig.ispos.piecepos.length; k++) {
                var thePiece = UVConfig.ispos.piecepos[k];
                log("working on " + thePiece.id);
                var pieceName = thePiece.id;
                placements[pieceName] = imageObj;

                let info: IPieceInfo = {
                    name: thePiece.id,
                    displayName: thePiece.id,
                    color: "#ffffff",
                    logos: [],
                }

                var theX = UVConfig.ispos.piecepos[k].uv.x * 150 / 2.54;
                var theY = UVConfig.ispos.piecepos[k].uv.y * 150 / 2.54;


                var doMirror = UVConfig.ispos.piecepos[k].uv.mirror;
                var theAngle = UVConfig.ispos.piecepos[k].uv.angle;

                info.logos.push({
                    iwidth: imageDimensions.width,
                    iheight: imageDimensions.height,
                    width: theWidth,
                    height: theHeight,
                    angle: 0,
                    scale: 1,
                    mirrorX: (doMirror) ? -1 : 1,
                    mirrorY: 1,
                    scaleX: (doMirror) ? -1 : 1,
                    scaleY: 1,
                    isText: false,
                    isRepeat: false,
                    UVPosition: {
                        x: theX - deltaWidth,
                        y: theY - deltaHeight,
                    },
                    imageKey: pieceName,
                })

                objectInfo.parts.push(info);
            }
            return {
                placements,
                infoJson: objectInfo,
            }
        }
        else {

            throw new Error('Item does not have all over spread option, sorry...');
        }



    } catch (error) {
        log("createTemplate 3", error)
        throw error
    }
}

interface jsonInfo {
    width: number
    height: number
}

export interface ITemplate {

    placements: IGarmentPieces
    infoJson: IGarmentInfo

}

export interface ITemplateForImage {

    placements: IGarmentPieces
    infoJson: IGarmentInfo

}

interface IGarmentPieces {
    [key: string]: any
}

interface IGarmentInfo {
    garmentName: string,
    garmentSize: string,
    parts: IPieceInfo[],
}

interface IPieceInfo {
    name: string,
    displayName: string,
    color: string,
    logos: ILogo[],
}

interface ILogo {
    iwidth: number,
    iheight: number,
    width: number,
    height: number,
    angle: number,
    scale: number,
    mirrorX: number,
    mirrorY: number,
    scaleX: number,
    scaleY: number,
    isText: boolean,
    isRepeat: boolean,
    UVPosition: {
        x: number,
        y: number,
    },
    imageKey: string,
}