import { ACTION_CLEARVIEWER3D_CONFIG, ACTION_SETVIEWER3D_CONFIG } from "../../actions/view3dsetting.actions.ts";

export const viewer3DConfig = (state={}, action:any)=>{

     switch(action.type){
         case ACTION_SETVIEWER3D_CONFIG:
             return {...state, ...action.payload}
         case ACTION_CLEARVIEWER3D_CONFIG:
             return state;    
     }
     return state

}