/**
 * Viewer Canvas
 * @file
 */
import {fabric} from "fabric"

import JSZip from "jszip"
import {storage} from "../../firebase"
import {v4 as uuidv4} from "uuid"
import * as CRYPTO_JS from "crypto-js"
import save from 'save-file'
import alertify from "alertifyjs"
import { getImageDim } from '../../utilities'
import { Helper } from './viewerHelp'
import { log } from "../../utilities/logger"
import { useTranslation } from "react-i18next"
import { getResolution, getSubUnit, getSuperUnit, unitToPixel } from "./canvasPixelConvertor"

/**
 * Class for work with canvas
 * @class
 */
class Viewer2d {
  constructor(props) {
    this.props = props

    this._init()
  }

  startLength = 1
  selectedImgCache = null
  selectedImageUrl = '';
  // curDelta = 0

  relativeMaxHeight = 0
  patternSourceCanvas = undefined

  _disabledObject = {
    centeredRotation: false,
    centeredScaling: false,
    evented: false,
    hasBorders: false,
    hasControls: false,
    hasRotatingPoint: false,
    lockRotation: true,
    lockMovementY: true,
    lockMovementX: true,
    selectable: false,
    hoverCursor: "default",
  }

  help = {
    prodPosition: () => {},
    scrollbarProp: () => {},
    setActivePart: () => {},
    getActiveObj: () => {},
  }

  /**
   * create id for objects
   * @method
   */
  _crtId = () =>
    String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now()

  /**
   * initial function
   * @method
   */
  _init = () => {
    const { node, selTarget, selImageSzWidth, selImageSzHeight, curPartLength, loadImage, toastify,
      onImageAdded, onImageRemoved, onImageUpsert, onImageSelected, canvasSegmentInFocus, isSplit } = this.props
    this.captureFocus = selTarget
    this.canvasSegmentInFocus = canvasSegmentInFocus
    // this.captureFocusSz = selImageSz
    this.captureFocusSzWidth = selImageSzWidth
    this.captureFocusSzHeight = selImageSzHeight
    this.onImageAdded = onImageAdded
    //this.onImageRemoved = onImageRemoved
    this.onImageSelected = onImageSelected
    this.notifyImageLoad = loadImage||function (progress){return progress }
    this.onImageUpsert = onImageUpsert
    this.curPartLength = curPartLength
    this.toastify = toastify
    this.c = new fabric.Canvas(node)
    this.helper = new Helper(this.c)
    this.c.selection = false; //Guy

    this.c.on("mouse:wheel", opt => {
      let delta = opt.e.deltaY / 8

      if (!isSplit) {
      this._setProductPosition(-delta)

      this.c.requestRenderAll()
      }
    })

    this.c.observe("object:moving", opt => {
      if (opt.target) {
        this._blockMoving(opt.target)
      }
    })

    this.c.on("mouse:up", this._upHandler)
  }

  /**
   * handler for mouse up event
   * @param {object} opt - options
   * @method
   */
  _upHandler = opt => {
    // let activeObj = this.c.getActiveObjects()

    let part = this._getParts().find(i => i.id === "part_1")
    this.canvasSegmentInFocus(this)
    this.help.getActiveObj(opt.target)
    if (opt.target) {

      if (opt.target.isSticking) {
        log({ intersecting: opt.target.intersectsWithObject(opt.target.isSticking) }, 'intersection')
        opt.target.intersects = (opt.target.intersectsWithObject(opt.target.isSticking)) ? opt.target.isSticking : undefined
        if (!opt.target.intersects) {
          opt.target.isSticking = undefined
        }
      }

      let limitsWidth = { max: opt.target.maxDimensions.width, min: opt.target.minDimensions.width }
      let limitsHeight = { max: opt.target.maxDimensions.height, min: opt.target.minDimensions.height }
      this.captureFocus(opt.target._element.currentSrc,)
      this.captureFocusSzWidth(opt.target.image_size_wd, limitsWidth)
      this.captureFocusSzHeight(opt.target.image_size_ht, limitsHeight)
      this.selectedImageUrl = opt.target._element.currentSrc
      this.onImageSelected({ url: opt.target._element.currentSrc, id: opt.target.uid })
    } else if (part.pattern_type && part.pattern_type !== "NON_REPEAT") {
      this.captureFocus(this.selectedImageUrl)
      this.onImageSelected({ url: this.selectedImageUrl, id: this._getImages()[0]?.uid })
    } else {
      this.selectedImageUrl = ""
      this.captureFocus("")
      this.onImageSelected({})
      // this.captureFocusSz(0)
      this.captureFocusSzWidth(0)
      this.captureFocusSzHeight(0)
    }
  }

  /**
   * change units size
   * @param {object} windowSize
   * @param {object} partSize
   * @method
   */
  _smToPx = (windowSize, partSize) => {
    return unitToPixel(windowSize.width,partSize.width, partSize.length )
  }

  /**
   * Change product position
   * @method
   */
  _setProductPosition = (delta, isNewPart) => {

    if (isNewPart)
      return

    let product = this._getProduct()
    let parts = this._getParts()

    let allPartsLength = parts.reduce((acc, item) => (acc += item.height), 0)

    if (product) {
      let newDelta

      if (product.top + delta > 0) {
        newDelta = 0
      } else if (
        product.top + delta -
          this.windowSize.height 
           +
           this.windowSize.height / 2 
          <=
        -allPartsLength
      ) {
        newDelta = 0
      } else {
        newDelta = delta
      }

      product.top = product.top + newDelta
      if (parts.length) {
        parts.forEach(item => {
          item.top = item.top + newDelta
          item.setCoords()
        })
      }

      let scrollbarLength = (allPartsLength + this.windowSize.height / 2) - this.windowSize.height
      let scrollbarPosition = isNewPart ? undefined : Math.round(Math.abs(product.top))

      !isNewPart && this.help.prodPosition(Math.abs(product.top))
      parts.forEach(item => {
        if (item.top > -50 && item.top < 50) {
          this.help.setActivePart(item.id)
        }
      })
      log(`-------scrolling ${isNewPart}----${product.top}------(${delta}`,'scrolling')

      if (scrollbarLength > 0)
        this.help.scrollbarProp({
          length: scrollbarLength,
          position: scrollbarPosition,
        })
    }

    product.setCoords()

    this._calcObjPosition()
    this.c.renderAll()
  }

  /**
   * discard active object
   * @method
   */
  discardActiveObj = () => {
    this.c.discardActiveObject()
  }

  /**
   * select active object
   * @method
   */
  selectActiveObj = (e) => {
    var id = this.c.getObjects().indexOf(e)
    this.c.setActiveObject(this.c.item(id))
  }

  /**
   * remove active object
   * @method
   */
  removeActiveObj = (e) => {
    var id = this.c.getObjects().indexOf(e)
    this.c.remove(this.c.item(id))
  }

  /**
   * help functions
   * @method
   */
  _getProduct = () => this.c.getObjects().find(i => i.name === "product")
  _getParts = () => this.c.getObjects().filter(i => i.id && i.name === "part")
  _getImages = () => this.c.getObjects().filter(i => i.type === "image")
  _getTexts = () => this.c.getObjects().filter(i => i.type === "text")
  _renderObjectOnCanvasUtility = (...objects) => {
    objects.forEach(obj => obj.setCoords())
    this.c.renderAll()
  }


  getProduct = () => {
    return this.helper.getProduct()
  }
  getPart = () => {
    return this.helper.getPart()
  }
  getImages = () => {
    return this.helper.getImages()
  }
  getCanvas = () => {
    return this.helper.getCanvas()
  }



  /**
   * Set props from react
   * @method
   */
  setProps = props => {
    this.info = props
    this.helper.t = props.t
    // console.log(this.info)
  }

  roundOff = (value, dp) => {
    return Math.round(value * Math.pow(10, dp)) / Math.pow(10, dp)
  }

  /**
   * Create product
   * @param {object} windowSize
   * @param {object} partSize
   */
  createProduct = async (windowSize, partSize, partStepSize, fabricQtyByUnit) => {
    if (windowSize && partSize && partStepSize) {

     partSize.length = partSize.length || partStepSize//in meters

      let product = this._getProduct()

      let size = this._smToPx(windowSize, partSize)
      this.pxPerSubUnit = size.pxPerSubUnit

      log({size}, 'PART SIZE CALCULATION')

      this.windowSize = windowSize

  

      log({ width: this.c.width, height: this.c.height }, ' UPDATE PRODUCT -0')
      if (product && ((this.c.height !== windowSize.height) || (this.c.width !== windowSize.width))) {
        this.updateProduct(windowSize, partSize, partStepSize)
        return
      } else {
        // console.log("CREATE PRODUCT")
        this.c.setWidth(windowSize.width)
        this.c.setHeight(windowSize.height)

        product = new fabric.Rect({
          left: 0,
          top: 0,
          fill: "white",
          name: "product",

          // height: size.height,

          width: size.width,
          height: size.height,
          ...this._disabledObject,
        })

        this.c.add(product)
      }

      product['metadata'] = {
        widthSubUnit: getSubUnit(partSize.width),
      }

      product.setCoords()
      this.c.renderAll()
      // =======================add first part

      let parts = this._getParts()
      if (!parts.length) {
        this.createPart(windowSize, 1, "part_1", partSize, partStepSize, fabricQtyByUnit)
      }


    } else log("ERROR: create product params not valid")
  }

  updateProduct = async (windowSize, partSize, partStepSize) => {

    let product = this._getProduct()

    this.windowSize = windowSize

    this.c.setWidth(windowSize.width)
    this.c.setHeight(windowSize.height)


    partSize.length = partSize.length || partStepSize //meters
    
    let part = this._getParts()[0]
    let newPartLength = partSize.length//part.partSize.length

    let isProductNew = (product.metadata.widthSubUnit !== getSubUnit(partSize.width))

    let size = this._smToPx(windowSize, partSize)
    this.pxPerSubUnit = size.pxPerSubUnit

    let adjustmentFactor = isProductNew ? await this._determineScaleDownFactor(product.metadata.widthSubUnit, getSubUnit(partSize.width), windowSize) :
      windowSize.width / this._getParts()[0].width

    product.set({
      width: windowSize.width, height: this.pxPerSubUnit * getSubUnit(newPartLength),
      metadata: {
        widthSubUnit: getSubUnit(partSize.width), //perPartHeightSubUnit: partSize.length * 100
       }, ...isProductNew && { top: 0, left: 0 }
    })
    product.setCoords()
    this.c.renderAll()
    await this._onProductUpdated(adjustmentFactor, product, isProductNew, newPartLength)
    
    return { width: part.width, height: part.height }

  }

  rotateImage = async (url, toAngle) => {
    let part = this._getParts()[0]
    let product = this._getProduct()
    let image = this._getImages().find(image=>image._element.currentSrc === url)

    let { partSize } = part
    let { widthSubUnit } = product.metadata

    await this.helper.rotateImage(image, toAngle, part,
        { widthSubUnit, partPerStepHeightSubUnit: getSubUnit(partSize.stepSize) },
        this.changePartLength)
    this._imageUpsert(image)
  }

  dispose = ()=>{
    this.c.clear()
    this.c.dispose()
  }

  async _onProductUpdated(scaleDownFactor, product, isProductNew, newPartLength) {

    let part = this._getParts()[0]
    let lastScroll = 0
    if (part) {
      lastScroll = part.top
      let newHeight = (getSubUnit(newPartLength||part.partSize.length)) * (product.width / product.metadata.widthSubUnit)
      part.set({ width: product.width, height: newHeight, ...isProductNew && { top: 0, left: 0, partSize: { ...part.partSize, width: getSuperUnit(product.metadata.widthSubUnit), length: newPartLength } } })
    }
    part.setCoords()
    this.c.renderAll()
    if (isProductNew) {
    this.help.prodPosition(Math.abs(product.top))
    this.help.scrollbarProp({
      length: part.height,
      position: 0,
    })
    }
    await this._imageAdjustments(scaleDownFactor, part, lastScroll, isProductNew)

  }

  _determineScaleDownFactor = async (productWidthSubUnit, newProductWidthSubUnit) => {

    let part = this._getParts()[0]
    let images = this._getImages()
    // calculate left Edge of Rect occupied in Canvas
    let leftEdge = 0
    images.forEach((image) => {
      let { left, width, scaleX } = image
      let imageLeftSide = (left + width * scaleX)
      if (leftEdge < imageLeftSide) {
        leftEdge = imageLeftSide
      }
    })
    let pxToSubUnit = productWidthSubUnit / part.width
    let pxToSubUnitNew = newProductWidthSubUnit / part.width
    let scaleDownFactor = 1
    let collageRectOverflows = (leftEdge * pxToSubUnit) > (part.width * pxToSubUnitNew)

    if(part.pattern_type && part.pattern_type !== 'NON_REPEAT'){
      if (collageRectOverflows) {
        let scaleDown = await this._confirmation(this.info.t("editor.tile_scale_down"))
        if (!scaleDown) {
          scaleDownFactor = productWidthSubUnit/newProductWidthSubUnit
        } else {
          scaleDownFactor = part.width / leftEdge
        }
      }else{
        scaleDownFactor = productWidthSubUnit/newProductWidthSubUnit
      }
    }else{
      if (collageRectOverflows) {
        this.toastify(this.info.t("editor.images_scaled"))
        scaleDownFactor = (part.width) / (leftEdge)
      }else{
        scaleDownFactor = productWidthSubUnit / newProductWidthSubUnit
      }
    }

    return scaleDownFactor
  }

  async _imageAdjustments(scaleDownFactor, part, lastScroll, isProductNew) {
    let images = this._getImages()
    let left = 0
    let top = 0
    let rowHeight = 0

    let pxInSubUnit = 1/this.pxPerSubUnit;//getSubUnit(part.partSize.width) / part.width

    images.forEach((image, index) => {

      let { top, left, width, height, scaleX, scaleY, _element: { currentSrc } } = image
      scaleX = scaleX * scaleDownFactor
      scaleY = scaleY * scaleDownFactor

      let imageCanvasWidth = width * scaleX
      let imageCanvasHeight = height * scaleY
      left = left * scaleDownFactor
      top = isProductNew ? (top - lastScroll) * scaleDownFactor: (top-lastScroll)*scaleDownFactor + lastScroll

      log(`----adjust--->${part.left} ${part.top} ${lastScroll} ${top} ${left} ${imageCanvasHeight}`)


      // if ((left + imageCanvasWidth) > part.width) {
      //   top = top + rowHeight
      //   rowHeight = 0
      // }

      let help = {
        left: left - part.left,
        top: top - part.top,
        leftPer: ((left - part.left) * 100) / part.width,
        topPer: ((top - part.top) * 100) / part.height,
        width: imageCanvasWidth,
        height: imageCanvasHeight,
      }
      let clipPath = new fabric.Rect({ width: part.width , height: part.height, top:0, left:0, absolutePositioned: true })

      let image_size_ht = this.roundOff(imageCanvasHeight * pxInSubUnit, 1)
      let image_size_wd = this.roundOff(imageCanvasWidth * pxInSubUnit, 1)


      image.set({ help, image_size_wd, image_size_ht, left, top, scaleX, scaleY, clipPath })
      //this.c.add(new fabric.Rect({ left, top, strokeWidth: 10, fill: 'transparent', stroke: index % 2 ? 'yellow' : 'red', width: imageCanvasWidth, height: imageCanvasHeight }))
      image.setCoords()
      // this.c.renderAll()

      // if (rowHeight < imageCanvasHeight) {
      //   rowHeight = imageCanvasHeight
      // }

      // left = left + imageCanvasWidth

      if (isProductNew && (currentSrc === this.selectedImageUrl)) {
        let { maxDimensions, minDimensions } = image
        let limitsWidth = { max: maxDimensions.width, min: minDimensions.width }
        let limitsHeight = { max: maxDimensions.height, min: minDimensions.height }
        this.captureFocusSzHeight(image_size_ht, limitsHeight)
        this.captureFocusSzWidth(image_size_wd, limitsWidth)
      }

      (part.pattern_type && (part.pattern_type === 'DEFAULT_REPEAT')) && 
      this.helper.resizePatternWidth(image,{widthPx: part.width, widthSubUnit: getSubUnit(part.partSize.width)},image_size_wd,true,part.pattern_type)
      this._imageUpsert(image)
    })
    this.c.renderAll()
    // this.c.requestRenderAll()
  }

  _adjustmentsAfterPartLengthChange = (topShift) => {

    let images = this._getImages()

    let part = this._getParts()[0]

    images.forEach((image, index) => {

      let { top, left } = image

      top = top + topShift


      let help = {
        ...image.help,
        left: left - part.left,
        top: top - part.top,
        leftPer: ((left - part.left) * 100) / part.width,
        topPer: ((top - part.top) * 100) / part.height,
       
      }
      let clipPath = new fabric.Rect({ width: part.width, height: part.height, top: part.top, left: part.left, absolutePositioned: true })

      image.set({ help,  left, top,clipPath  })
      image.setCoords()

    })
    this.c.renderAll()

  }

  _createPattern = (img, part) => new Promise((resolve, reject)=>{

    this.c.discardActiveObject()
    this.selectedImgCache = {
      repeat: true,
      left: img.left,
      top: img.top,
      visible: img.visible,
      help: img.help,
      pattern_type: img.pattern_type,
    }
    let clipCache = img.clipPath

    this.c.getObjects().forEach(item => (item.visible = false))

    img.visible = true
    img.clipPath = null

    let newImg = this.c.toDataURL({
      width: img.width * img.scaleX,
      height: img.height * img.scaleY,
      left: img.left,
      top: img.top,
      quality: 1,
    })
    fabric.util.loadImage(newImg, image => {
      part.set({
        fill: new fabric.Pattern({
          source: image,
          repeat: "repeat",
        }),
        pattern_type: 'DEFAULT_REPEAT',
      })

      this.c.getObjects().forEach(item => (item.visible = true))

      img.visible = false
      img.clipPath = clipCache
      img.help = {
        left: 0,
        top: 0,
        leftPer: 0,
        topPer: 0,
      }

      img.pattern_type ='DEFAULT_REPEAT'
      img.setCoords()
      this.c.requestRenderAll()
      resolve()
    })

  })

  _confirmation = (message, title) => {
    return new Promise((resolve, reject) => {
      alertify.confirm(title || this.info.t("editor.alert_warning_title4"), message, () => {
        resolve(true)
      }, () => {
        resolve(false)
      }).set('labels', { ok: this.info.t("i18n.yes"), cancel: this.info.t("i18n.no") });
    })
  }

  /**
   * Resize Parts
   * @method
   * @param {object} windowSize
   */
  _resizeParts = windowSize => {
    let parts = this._getParts()

    if (parts.length) {
      parts.forEach(item => {
        let size = this._smToPx(windowSize, item.partSize)

        item.set({...size})

        item.setCoords()
      })
      this.c.renderAll()
    }
  }

  /**
   * Calc parts position when scrolling and change window size
   * @method
   */
  _calcPartsPosition = () => {
    let parts = this._getParts()
    let product = this._getProduct()
    let topPosition = 0

    if (parts.length) {
      parts.forEach(item => {
        let position = this._smToPx(this.windowSize, {
          width: this.info.fabricWidth,
          length: topPosition,
        })

        item.set({
          top: product.top + position.height,
          left: product.left,
        })

        item.setCoords()
        topPosition += item.partSize.length
      })

      this.c.renderAll()
    }
  }

  /**
   * Create part
   * @param {number} number -part number
   * @param {string} id part id
   * @param {number} length -part length
   * @method
   */
  createPart = (windowSize, number, id, partSize, partStepSize, fabricQtyByUnit) => {
    if (number && id && partSize && partStepSize) {
      let product = this._getProduct()
      this._getParts().forEach(i => (i.active_part = false))

      let size = this._smToPx(windowSize, partSize)
      const length = partSize.length

      let part = new fabric.Rect({
        ...size,

        id,
        name: "part",
        fill: `transparent`,
        strokeWidth: 1,
        stroke: "#4A69FF",
        active_part: true,
        partSize: {
          length,
          stepSize: partStepSize,
          lengthInUnitMultiples: fabricQtyByUnit,
          width: partSize.width//this.info.fabricWidth,
        },
        ...this._disabledObject,
      })

      part.set({top: product.top, left: product.left})

      this.c.add(part)

      this._calcPartsPosition()
      this.help.prodPosition(Math.abs(product.top))
      log(`-----notifying scroll----(${part.height})`)
      this.help.scrollbarProp({
        length: part.height,
        position: 0,
      })
    } else log("ERROR: create part params not valid")
  }

  /**
   * Remove part
   * @method
   */
  removePart = part_id => {
    let parts = this._getParts()
    let part = parts.find(i => i.id === part_id)
    let objs = this.c.getObjects().filter(i => i.id === part_id)

    if (objs.length) {
      this.c.remove(...objs)

      if (parts.indexOf(part) !== parts.length - 1) {
        this._calcPartsPosition(part.height)
      }
    } else log("ERROR: remove objects not found")
  }

  /**
   * Change part length
   * @method
   */
  changePartLength = (part_id, length, change) => {
    if (part_id && length) {
      log({length, change: change||'NA'},"changePartLength TRACK xxx")
      this.curPartLength(length, change)
      let part = this._getParts()[0]
      let product = this._getProduct()
      //alert(`${part}`)
      if (part) {
        let currentPartTop = part.top
  
        let newHeight = getSubUnit(length) * this.pxPerSubUnit
        let stepHeight = getSubUnit(part.partSize.stepSize) * this.pxPerSubUnit

        let scrollUpBy =  currentPartTop+stepHeight

        log({scrollUpBy}, 'scroll up')

        let newTop = Math.abs(currentPartTop) >= newHeight ? scrollUpBy : currentPartTop

        //alert(`${newTop},${newHeight}`)


        product.set({ top: newTop, height: newHeight })
        part.set({ top: newTop, height: newHeight, partSize: { ...part.partSize, length } })
        part.setCoords()
        this.c.renderAll()

        this._adjustmentsAfterPartLengthChange(Math.abs(currentPartTop - newTop))



        //this._getImages().forEach(image => this._blockMoving(image))
        this.help.prodPosition(Math.abs(product.top))
        this.help.scrollbarProp({ length: part.height })

        //this._calcObjPosition()

        return newHeight

      }

      // this.curPartLength(length)
      // let parts = this._getParts()
      // let part = parts.find(i => i.id === part_id)

      // let size = this._smToPx(this.windowSize, {
      //   width: this.info.fabricWidth,
      //   length: parseFloat(length),
      // })

      // let oldLength = part.partSize.length

      // part.set({
      //   ...size,
      //   partSize: {
      //     length: parseFloat(length),
      //     width: this.info.fabricWidth,
      //   },
      // })

      // part.setCoords()
      // this.c.renderAll()

      // this._calcPartsPosition()

      // this._getImages().forEach(item => this._blockMoving(item))
      // this._getTexts().forEach(item => this._blockMoving(item))
      // this._calcObjPosition()

      // let flag = true
      // if (oldLength > length)
      //   flag = false

      // this._setProductPosition(
      //   -(part.top + (part.height - part.height / length)), flag
      // )

      

    } else log("ERROR: change part length params not valid")
    return 0
  }

  /**
   * Scroll to part
   * @method
   */
  scrollToPart = part_id => {
    if (part_id) {
      let parts = this._getParts()
      parts.forEach(i => (i.active_part = false))
      let part = parts.find(i => i.id === part_id)
      if (part) {
        let c
        if (part.id === "part_1") {
          c = -part.top
        } else {
          c = -(part.top - 20)
        }

        part.active_part = true

        this._setProductPosition(c)
      } else {
        log("ERROR: part for scroll not found")
        return
      }
    } else log("ERROR: scroll to part params is not valid")
  }



  selectImage = (url) => {
    if (url !== this.selectedImageUrl) {
      let image = this._getImages().find((image) => image._element.currentSrc === url)
      log(image, 'found selected Image displayGallery')
      if (image) {
        this._notifyUI(image, true)
      }
    } else {
      log('already selected', 'displayGallert')
    }

  }
      
  addImagesToPart = async (part_id, imageArray) => {

    let part = this._getParts()[0]
    let product = this._getProduct()

    for (let i = 0; i < imageArray.length; i++) {

      let { top, left, width, height, partSize } = part
      let { widthSubUnit} = product.metadata
      let {src:img, ...info} = imageArray[i]

      let mediaQuery = window.matchMedia('(max-width: 600px)')
      
      log({ info, stepSize: partSize.stepSize }, 'adding image')

      let image = await this.helper.insertImage(part_id,
        { top, left, width, height, lengthInUnitMultiples:partSize.lengthInUnitMultiples },
        { widthSubUnit, partPerStepHeightSubUnit: getSubUnit(partSize.stepSize) },
        {
          url: img,
          pxWidth: info.width,
          pxHeight: info.height,
          repeatType: info.repeatType || (mediaQuery.matches ? 'DEFAULT_REPEAT' : 'NON_REPEAT'),
          file: info.file,
          currentResolution: getResolution(info.curResolution),
          minResolution: getResolution(info.minResolution),
          scaleFactor: info.scaleFactor,
          optionalImageId: info.image_id
        }, this.changePartLength)
      this.c.requestRenderAll()
      this._notifyUI(image, true)
      this._imageUpsert(image)
      if(info.repeatType && (info.repeatType !== 'NON_REPEAT')){
        this.repeatImg(part_id, info.repeatType)
      }else if (mediaQuery.matches)
        this.repeatImg(part_id, 'DEFAULT_REPEAT')
    }



    this.notifyImageLoad(false)
    
    

  }


  addImgToPart = async (part_id, img, image_size, image_size_ht, image_part_width, image_part_height, repeatType = "", info, objToPopulate, actualResolution) => {

    objToPopulate['part_width'] = this._getParts()[0].width
    objToPopulate['image_size'] = image_size
    objToPopulate['image_part_width'] = image_part_width

    let part = this._getParts()[0]
    let product = this._getProduct()

    let { top, left, width, height, partSize } = part
    let { widthSubUnit } = product.metadata

    log(`------> file------>>>>>` + info.file, 'addToCart')

    let image = await this.helper.insertImage(part_id,
      { top, left, width, height },
      { widthSubUnit, partPerStepHeightSubUnit: partSize.stepSize*100 },
      {
        url: img,
        mimeType: info.mimeType,
        pxWidth: info.width,
        pxHeight: info.height,
        repeatType,
        file: info.file,
        currentResolution: info.curResolution,
        minResolution: info.minResolution,
        scaleFactor: info.scaleFactor,
      }, this.changePartLength)


    this.c.requestRenderAll()
    this._notifyUI(image, true)
    if (repeatType && repeatType !== 'NON_REPEAT')
      this.repeatImg(part_id, repeatType)
    this.notifyImageLoad(false)
    log(`... adding image .... ${image._element.currentSrc}`, 'displayGallery')
    this._imageUpsert(image)

  }

  _notifyUI = (image, selectAsActive) => {

    this.selectedImageUrl = image._element.currentSrc
    this.captureFocus(this.selectedImageUrl)
    let { maxDimensions, minDimensions, image_size_ht, image_size_wd } = image
    let limitsWidth = { max: maxDimensions.width, min: minDimensions.width }
    let limitsHeight = { max: maxDimensions.height, min: minDimensions.height }
    this.captureFocusSzHeight(image_size_ht, limitsHeight)
    this.captureFocusSzWidth(image_size_wd, limitsWidth)
    selectAsActive && this.selectActiveObj(image)
    selectAsActive && this.c.requestRenderAll()

  }
  _imageUpsert = (image) => {
    log({ angle: image.rotatedBy || 'not found' }, 'flip')
    this.onImageUpsert({
      id: image.uid,
      minResolution: image.resolution.min,
      tileOriginalWidth: image.width,
      tileOriginalHeight: image.height,
      tileWidth: image.width * image.scaleX,
      tileHeight: image.height * image.scaleY,
      url: image._element.currentSrc,
      widthSubUnit: image.image_size_wd,
      heightSubUnit: image.image_size_ht,
      widthLimits: { max: image.maxDimensions.width, min: image.minDimensions.width },
      heightLimits: { max: image.maxDimensions.height, min: image.minDimensions.height },
      locked: image.lockedAR,
      angle: image.rotatedBy || 0
    })
  }

  /**
   * set image size in SubUnits
   * @param {string} img_id - part id
   * @param {object} img_url - image
   * @param {object} size image
   * @method
   */
  setImageSizeCmsByWidth = (img_id, img_url, sz_wd, sz_ht, lockAspectRatio, actualResolution, isFieldEmpty) => {
    //correcting ....
    let product = this._getProduct()
    let part = this._getParts()[0]
    let image_part_width = product.metadata.widthSubUnit
    let image_part_height = getSubUnit(part.partSize.length)

    log({ sz_ht, sz_wd }, 'imgSizeHandler-width 1')

    if (img_id && img_url && image_part_width && image_part_height && sz_wd && sz_ht) {

      let part = this._getParts().find(i => i.id === img_id)
      let img = this._getImages().find(i => i._element.currentSrc === img_url)


      let adjustWidth = ()=> {
      log({ sz_ht, sz_wd }, 'imgSizeHandler 2')


      if (part.pattern_type && part.pattern_type !== 'NON_REPEAT') {
        this.helper.resizePatternWidth(img,
          { widthPx: part.width, widthSubUnit: image_part_width }, sz_wd, lockAspectRatio, part.pattern_type)
            this._notifyUI(img)
        this._imageUpsert(img)

        return
      }

      // img.image_size = size
      //const cmInPx = 37.79
      if (img) {
        this._resizeImageCmsByWidth(part, img, image_part_width, image_part_height, sz_wd, sz_ht, lockAspectRatio, actualResolution, isFieldEmpty)
        this._blockMoving(img)
        this._imageUpsert(img)
      }

      if (part.pattern_type) {
        this.repeatImg(img_id, part.pattern_type)
      }
    }

      let effectiveHeight = ((img.rotatedBy||0) % 180) ? sz_wd : (img.height/img.width)*sz_wd
      log({ effectiveHeight, sz_wd }, 'imgSizeHandler 2')
      
      let requesting = lockAspectRatio?this.helper.requestPartLengthIncrease(part.id, img, effectiveHeight, this.changePartLength, () => {
        adjustWidth()
      }):false
      if (!requesting) {
        adjustWidth()
      }
    }
  }

  /**
   * set image size in cms
   * @param {string} img_id - part id
   * @param {object} img_url - image
   * @param {object} size image
   * @method
   */
  setImageSizeCmsByHeight = (img_id, img_url, sz_wd, sz_ht, lockAspectRatio, actualResolution, isFieldEmpty) => {
    
    //correcting
    let product = this._getProduct()
    let part = this._getParts()[0]
    let image_part_width = product.metadata.widthSubUnit
    let image_part_height = getSubUnit(part.partSize.length)

    log({ sz_ht, sz_wd }, 'imgSizeHandler-height 1')

    if (img_id && img_url && image_part_width && image_part_height && sz_wd && sz_ht) {
      let part = this._getParts().find(i => i.id === img_id)
      let img = this._getImages().find(i => i._element.currentSrc === img_url)

      let adjustHeight = ()=> {
      if (part.pattern_type && part.pattern_type !== 'NON_REPEAT') {
        this.helper.resizePatternHeight(img,
          { widthPx: part.width, widthSubUnit: image_part_width }, sz_ht, lockAspectRatio, part.pattern_type)
            this._notifyUI(img)
        log({ imgHeight: img.image_size_ht, imgWidth: img.image_size_wd }, 'imgSizeHandler-height')
        this._imageUpsert(img)
        return
      }

      if (img) {
        this._resizeImageCmsByHeight(part, img, image_part_width, image_part_height, sz_wd, sz_ht, lockAspectRatio, actualResolution, isFieldEmpty)
        this._blockMoving(img)
        this._imageUpsert(img)
      }

      if (part.pattern_type) {
        this.repeatImg(img_id, part.pattern_type)
      }
    }

      let effectiveHeight = ((img.rotatedBy||0) % 180) ? sz_wd : sz_ht
      let requesting = this.helper.requestPartLengthIncrease(part.id, img, effectiveHeight, this.changePartLength, () => {
        adjustHeight()
      })
      if (!requesting) {
        adjustHeight()
      }
    }
  }

  /**
   * remove image from part
   * @param {string} img_id - part id
   * @param {object} img_url - image
   * @param {object} image_part - part
   * @method
   */
  removeImageFromPart = (img_id, img_url, image_part) => {
    if (img_id && img_url && image_part) {
      let part = this._getProduct()
      let img = this._getImages().find(i => i._element.currentSrc === img_url)
      let images = this._getImages()
      
      if (img) {
        this.removeActiveObj(img)
        this.selectedImageUrl = ""
        this.captureFocus("")
        this.captureFocusSzWidth(0)
        this.captureFocusSzHeight(0)

        // remove image from the image objects and content_images array
        let index1 = this._getImages().map((e) => { return e._element.currentSrc; }).indexOf(img_url);
        let index2 = image_part.content_images.map((e) => { return e.image; }).indexOf(img_url);

        this._getImages().splice(index1, 1)
        image_part.content_images.splice(index2, 1)
        images = this._getImages()
      }
    }
  }

  /**
   * block moving
   * @param {object} obj - fabric js object
   * @method
   */
   _blockMoving = (obj, disableImageOverlap) => {
    let part = this._getParts().find(i => i.id === obj.id)

    if (part) {

      //log({intersect:obj.intersection||'undefined'}, 'intersection')

      this._getImages().forEach((image) => {
        if (obj !== image) {

          let {height, width, scaleX, scaleY} = this.helper.returnEffectiveDims(obj)
          let {height:imageHeight, width:imageWidth, scaleX:imageScaleX, scaleY:imageScaleY} = this.helper.returnEffectiveDims(image)

          if (obj.intersects !== image) {
            let objCanvasWidth = (width * scaleX)
            let objCanvasHeight = (height * scaleY)
            let objRight = obj.left + objCanvasWidth
            let objLeft = obj.left
            let objTop = obj.top
            let objBottom = obj.top + objCanvasHeight

            let imageRight = image.left + (imageWidth * imageScaleX)
            let imageLeft = image.left
            let imageTop = image.top
            let imageBottom = image.top + (imageHeight * imageScaleY)

            let isSameRowRight = (imageRight > objRight) && (image.top <= objBottom) && (obj.top <= imageBottom)
            let isSameRowLeft = (imageLeft < objLeft) && (image.top <= objBottom) && (obj.top <= imageBottom)
            let isSameColumnTop = (objTop > imageTop) && (image.left <= objRight) && (obj.left <= imageRight)
            let isSameColumnBottom = (objBottom < imageBottom) && (image.left <= objRight) && (obj.left <= imageRight)

            // Sticky for Obj right and left edges
            if (isSameRowRight) {
              if ((objRight >= image.left) && (Math.abs(objRight - image.left) < 60)) {
                obj.left = Math.floor(image.left - objCanvasWidth)
                obj.isSticking = image
              }
            } else if (isSameRowLeft) {
              if ((objLeft <= imageRight) && (Math.abs(objLeft - imageRight) < 60)) {
                obj.left = Math.floor(imageRight)
                obj.isSticking = image
              }
            }

            // Sticky for Obj top and Bottom edges
            if (isSameColumnTop) {
              log({ obj: objTop, image: imageTop }, 'intersection')
              if ((objTop <= imageBottom) && (Math.abs(objTop - imageBottom) < 60)) {
                obj.top = Math.floor(imageBottom)
                obj.isSticking = image
              }
            } else if (isSameColumnBottom) {
              if ((objBottom >= imageTop) && (Math.abs(objBottom - imageTop) < 60)) {
                obj.top = Math.floor(imageTop - objCanvasHeight)
                obj.isSticking = image
              }
            }

          }
        }
      })


      let {height:objHeight, width:objWidth, scaleX:objScaleX, scaleY:objScaleY} = this.helper.returnEffectiveDims(obj)

      if (obj.top < part.top) {
        //Top boundary
        obj.top = part.top
      }
      part.bottom = part.top + part.height
      if (obj.top + objHeight * objScaleY > part.top + part.height) {
        //Bottom boundary
        // obj.top = part.bottom - obj.height * obj.scaleY
      }

      if (obj.left < part.left) {
        //Left boundary
        obj.left = part.left
      }

      part.right = part.left + part.width
      if (obj.left + (objWidth * objScaleX) > (part.left + part.width)) {
        //Right boundary
        obj.left = part.right - (objWidth * objScaleX)
      }

      // =============set help coords

      obj.help = {
        ...obj.help,
        left: obj.left - part.left,
        top: obj.top - part.top,
        leftPer: ((obj.left - part.left) * 100) / part.width,
        topPer: ((obj.top - part.top) * 100) / part.height,
        width: objWidth * objScaleX,
        height: objHeight * objScaleY,
      }
    }
  }

  /**
   * calc object position
   * @method
   */
  _calcObjPosition = () => {
    return new Promise(resolve => {
      let images = [...this._getImages(), ...this._getTexts()]
      // let texts = this._getTexts()
      let parts = this._getParts()
      images.forEach((item, index) => {
        let part = parts.find(i => i.id === item.id)
        let newCoordinates = {
          left: (part.left + ((part.width * item.help.leftPer) / 100)),
          top: (part.top + ((part.height * item.help.topPer) / 100))
        }

        item.set(newCoordinates)
        item.setCoords()

        let newPath = {
          top: part.top,
          left: part.left,
          height: part.height,
          absolutePositioned: true,
        }
        item.clipPath &&
          item.clipPath.set(newPath)
        
        // if (item.type !== "text") {
        //   this._resizeImage(part, item, item.image_size)
        // } else {
        //   this._resizeText(part, item)
        // }

      })
      this.c.renderAll()
      resolve()
    })
  }

  /**
   * resize image
   * @method
   */
  // _resizeImage = (part, img, image_part, image_size) => {
    _resizeImage = (part, img, image_part_width, image_part_height, image_size, actualResolution = 150) => {
    
      let defaultImageWidth = (part.width / image_size)
  
      actualResolution = actualResolution || getResolution(150)
      // let pxPerSubUnit = part.width / image_part_width /*expecting image_part_width to be in decimeters*/
      let imageWidthInSubUnit = img.width / (actualResolution)
      let imageHeightInSubUnit = img.height / (actualResolution)

      let imageWidthInPx
      let imageHeightInPx
      let AR = img.width / img.height

      if (imageWidthInSubUnit > image_part_width) {
        imageWidthInSubUnit = image_part_width
        imageWidthInSubUnit = imageWidthInSubUnit * this.pxPerSubUnit
        imageHeightInSubUnit = imageWidthInPx / AR
        imageHeightInSubUnit = imageHeightInPx / this.pxPerSubUnit
      }
  
      imageWidthInPx = imageWidthInSubUnit * this.pxPerSubUnit
      imageHeightInPx = imageHeightInSubUnit * this.pxPerSubUnit
  
      let delta = imageWidthInPx/img.width
  
      let newWd = imageWidthInSubUnit.toFixed(1)
      let newHt = imageHeightInSubUnit.toFixed(1)
      
    let limitsWidth = { max: img.maxDimensions.width, min: img.minDimensions.width }
    let limitsHeight = { max: img.maxDimensions.height, min: img.minDimensions.height }
      this.captureFocusSzWidth(newWd, limitsWidth)
      this.captureFocusSzHeight(newHt, limitsHeight)

    img.scale(delta)
    img.clipPath &&
      img.clipPath.set({
        width: part.width,
        height: part.height,
        absolutePositioned: true,
      })

    img.set({
      image_size_wd: newWd,
      image_size_ht: newHt,
      help: {
        topAdjustment: img.help.topAdjustment,
        top: img.help.top,
        left:img.help.left,
        width: imageWidthInPx,
        height: imageHeightInPx,
        leftPer: img.help.leftPer,
        topPer: img.help.topPer
      }
    })

    img.setCoords()

    this.c.requestRenderAll()
  }

  /**
   * resize image in cms by width
   * @method
   */
  _resizeImageCmsByWidth = (part, img, image_part_width, image_part_height, sz_wd, sz_ht, lockedAR, actualResolution, isFieldEmpty) => {

    if (img) {

      let imgWidth = img.width
      let imgHeight = img.height

      let imageWidthInSubUnit = parseFloat(sz_wd)
      let imageHeightInSubUnit = parseFloat(sz_ht)

  
      if (lockedAR) {
        imageHeightInSubUnit = (imageWidthInSubUnit/ imgWidth) * imgHeight
        imageHeightInSubUnit = this.roundOff(imageHeightInSubUnit,1)
      }
  
      let imageWidthInPx = imageWidthInSubUnit * this.pxPerSubUnit
      let imageHeightInPx = imageHeightInSubUnit * this.pxPerSubUnit
  
      let delta = imageWidthInPx/imgWidth

      if (lockedAR)
        img.scale(delta)
      else
        img.scaleX = delta

      img.clipPath &&
        img.clipPath.set({
          width: part.width,
          height: part.height,
          absolutePositioned: true,
        })

      img.set({
        image_size_wd: imageWidthInSubUnit,
        image_size_ht: imageHeightInSubUnit,
        lockedAR,
        left: img.left,
        top: img.top,
        visible: true,
        help: {
          width: imageWidthInPx,
          height: lockedAR ? imageWidthInPx : imageHeightInPx,
          topAdjustment: img.help.topAdjustment,
          top: img.help.top,
          left:img.help.left,
          leftPer: img.help.leftPer,
          topPer: img.help.topPer
        }
      })

      img.setCoords()

      if (!isFieldEmpty) {
        this.captureFocusSzWidth(imageWidthInSubUnit)
      } else {
        this.captureFocusSzWidth("")
      }

      this.captureFocusSzHeight(imageHeightInSubUnit)
      // this._adjustImagesOnCanvas()

      this.c.requestRenderAll()
    }
  }

  /**
   * resize image in cms by width
   * @method
   */
  _resizeImageCmsByHeight = (part, img, image_part_width, image_part_height, sz_wd, sz_ht, lockedAR, actualResolution, isFieldEmpty) => {

    if (img) {
      let imgWidth = img.width
      let imgHeight = img.height

      let newHt = sz_ht
      let newWd// = lockedAR ? Math.trunc((newHt / imgHeight) * imgWidth) : sz_wd

      if (lockedAR) {
        let wd = (newHt / imgHeight) * imgWidth
        newWd = (wd).toFixed(1)
      } else {
        newWd = sz_wd
      }

      newWd = (newWd / 1).toFixed(1)
      newHt = (newHt / 1).toFixed(1)

      let imageWidthInSubUnit = newWd
      let imageHeightInSubUnit = newHt

     
      if (imageWidthInSubUnit > image_part_width) {
        imageWidthInSubUnit = image_part_width
        imgWidth = (image_part_width) * getResolution(actualResolution)
      }

  
  
      let imageWidthInPx = imageWidthInSubUnit * this.pxPerSubUnit
      let imageHeightInPx = imageHeightInSubUnit * this.pxPerSubUnit
  
      let delta = imageHeightInPx/imgHeight

      if (lockedAR)
        img.scale(delta)
      else
        img.scaleY = delta

      img.clipPath &&
        img.clipPath.set({
          width: part.width,
          height: part.height,
          absolutePositioned: true,
        })

      img.set({
        image_size_wd: newWd,
        image_size_ht: newHt,
        lockedAR,
        help: {
          width: lockedAR ? imageWidthInPx : imageHeightInPx,
          height: imageHeightInPx,
          topAdjustment: img.help.topAdjustment,
          top: img.help.top,
          left:img.help.left,
          leftPer: img.help.leftPer,
          topPer: img.help.topPer
        }
      })

      img.setCoords()

      if (!isFieldEmpty) {
        this.captureFocusSzHeight(newHt)
      }
      else {
        this.captureFocusSzHeight("")
      }

      this.captureFocusSzWidth(newWd)
      // this._adjustImagesOnCanvas()

      this.c.requestRenderAll()
    }
  }

  /**
   * resize text
   * @method
   */
  _resizeText = (part, item) => {
    
  }

  _repeatImagesResize = () => {
    let images = this._getImages()
    let parts = this._getParts()

    images.forEach(item => {
      let part = parts.find(i => i.id === item.id)

      if (item.pattern_type) {
        this.repeatImg(part.id, item.pattern_type)
      }
    })
  }

  /**
   * repeat image, create pattern
   * @param {string} part_id
   * @param {string} repeatType
   * @method
   */
  _repeatImg = (part_id, repeatType) => {
    const NON_REPEAT = "NON_REPEAT"
    const DEFAULT_REPEAT = "DEFAULT_REPEAT"
    this.c.discardActiveObject()

    if (part_id && repeatType) {
      let part = this._getParts().find(i => i.id === part_id)

      let imgs = this._getImages()
      let img = imgs.find(i => (i._element.currentSrc === this.selectedImageUrl))

      if (img == null)
        return

      if (repeatType === NON_REPEAT) {
        part.set({fill: "transparent", pattern_type: repeatType})
        if(this.selectedImgCache && this.selectedImgCache.repeat) {
          img.set({
            left: this.selectedImgCache.left,
            top: this.selectedImgCache.top,
            visible: true,//this.selectedImgCache.visible,
            help: this.selectedImgCache.help,
            pattern_type: repeatType,
          })
          // this.scrollToPart(part_id)
        }
      } else {
        this.selectedImgCache = {
          repeat: true,
          left: img.left,
          top: img.top,
          visible: img.visible,
          help: img.help,
          pattern_type: img.pattern_type,
        }
        let clipCach = img.clipPath

        let getImg = img =>
          new Promise(resolve => {
            this.c.getObjects().forEach(item => (item.visible = false))

            img.visible = true
            img.clipPath = null

            let newImg = this.c.toDataURL({
              width: img.width * img.scaleX,
              height: img.height * img.scaleY,
              left: img.left,
              top: img.top,

              quality: 1,
            })

            resolve(newImg)
          })

        getImg(img).then(url => {
          fabric.util.loadImage(url, image => {
            part.set({
              fill: new fabric.Pattern({
                source: image,
                repeat: "repeat",
              }),
              pattern_type: repeatType,
            })

            this.c.getObjects().forEach(item => (item.visible = true))

            img.visible = false
            img.clipPath = clipCach
            img.help = {
              left: 0,
              top: 0,
              leftPer: 0,
              topPer: 0,
            }

            img.pattern_type = repeatType
            this.c.requestRenderAll()
          })
        })
      }

      img.setCoords()
      this.c.requestRenderAll()
    } else log("ERROR: repeat image params is not valid")
  }

  repeatImg = (part_id, repeatType) => {
    let imgs = this._getImages()
    let img = imgs.find(i => (i._element.currentSrc === this.selectedImageUrl))
    let part = this._getParts()[0]
    log(`------repeat--${repeatType}---${img}--`)
    if (img == null)
      return

    this.helper.repeatImg(img, part, repeatType)
  }

  /**
   * add/update text
   * @param {string} part_id
   * @param {object} opt - text options
   * @method
   */
  addupdataText = (part_id, opt) => {
    if (part_id && opt) {
      let part = this._getParts().find(i => i.id === part_id)
      let currentText = this._getTexts().find(i => i.text_id === opt.text_id)

      let textObj = {
        fontFamily: opt.font.value,
        fontSize: 50,
        scaleX: parseInt(opt.scale) * 0.01,
        scaleY: parseInt(opt.scale) * 0.01,
        fill: opt.color,
        id: part_id,
        text_id: opt.text_id,
        stroke: opt.outline.color,
        strokeWidth: opt.outline.weight,
        angle: opt.rotate,
        textAlign: opt.alignment,
        charSpacing: opt.spacing * 100,
      }
      if (!currentText) {
        let text = new fabric.Text(opt.value, {
          ...textObj,
          left: part.left + part.strokeWidth / 2,
          top: part.top + part.strokeWidth / 2,
          help: {
            left: 0,
            top: 0,
            leftPer: 0,
            topPer: 0,
          },
        })

        this.c.add(text)
      } else {
        currentText.set({
          text: opt.value,
          ...textObj,
        })
      }

      this.c.renderAll()
    }
  }

  /**
   * clear parts
   * @method
   */
  claerParts = () => {
    this.c.remove(...this.c.getObjects())
    this.curPartLength(1)
  }

  /**
   * clear images
   * @method
   */
  clearImages = () => {
    let part = this._getParts().find(i => i.id === "part_1")
    if (part) {
    let pLen = part.partSize.length
    let product = this._getProduct()
    this._getParts().forEach((part) => {
      product.top = 0
      part.set({
        top: 0,
        left: 0,
        fill: "transparent",
        pattern_type: null,
        partSize: {
          ...part.partSize,
          length: parseFloat(pLen),
          width: this.info.fabricWidth,
        },
      })
      part.setCoords()
    })
    setTimeout(() => {
      let allPartsLength = this._getParts().reduce((acc, item) => (acc += item.height), 0)
      let scrollbarLength = allPartsLength - this.windowSize.height
      let scrollbarPosition = 0
      this.help.scrollbarProp({
        length: scrollbarLength,
        position: scrollbarPosition,
      })
      this.help.prodPosition(0)
    }, 300)

    this._calcPartsPosition()
    this.c.remove(...this._getImages())
    this.c.renderAll()
  }
  }


  _calculateImageAnchor = (imgCanvasWidth, parentContainer) => {
    let scrollAdjustment = 0;
    let images = this._getImages()
    let parentTop = parentContainer.top || 0
    let parentLeft = parentContainer.left || 0
    let parentWidth = parentContainer.width || 0
    let curRowHeight = 0
    scrollAdjustment = Math.abs(parentContainer.top)

    let anchorTop = (parentTop + scrollAdjustment)

    images.forEach((item) => {

      let { top, height, scaleY } = item
      if (anchorTop !== (top + scrollAdjustment)) {
        anchorTop = top + scrollAdjustment
        curRowHeight = 0
      }
      let canvasHeight = height * scaleY
      if (curRowHeight < canvasHeight) {
        curRowHeight = canvasHeight
      }
    })
    return { top: anchorTop + curRowHeight, left: 0 }
  }

  _addContentImagesZip = async (zipFile, part, contentImages) => {
    let imageFolder = "images/"
    contentImages.forEach((item, index) => {
      if (item.image) {
        let idStr = `${part.id}_${index + 1}`
        if (item.file) {
          zipFile.file(`${imageFolder}${idStr}.${item.file.type.split("/")[1]}`, item.file)
        } else {
          zipFile.file(`${imageFolder}${idStr}.${item.image.split("/")[1].split(";")[0]}`,
            item.image.split(",")[1], { base64: true })
        }
      }
    })
  }

  _addScreenShots = async (zipFile) => {

    let previewFolder = "screenshots/"
    let images = this._getImages()
    let part = this._getParts()[0]

    images.forEach((image, index) => {

      let photo = this.c.toDataURL({
        format: "jpeg",
        quality: 0.3,
        multiplier: 1,
        width: this.windowSize.width,
        height: this.windowSize.height,
        left: 0,
        top: image.top,
      })

      let idStr = `${part.id}_${index + 1}`
      zipFile.file(`${previewFolder}${idStr}.jpeg`, photo.split(",")[1], { base64: true })

    })

  }

  _createCanvasPreview = async (isModeRepeat) => {

    let top = 0
    let part = this._getParts()[0]

    if (isModeRepeat) {
      top = 0.6 * part.width
      let repeatedImage = this._getImages()[0]
      let canvasHeight = repeatedImage.width * repeatedImage.scaleX
      top = canvasHeight > top ? canvasHeight : top
    }


    if (part && part.width) {
      let anchorPoint = isModeRepeat ? { top, left: 0 } : this._calculateImageAnchor(part.width, part);
      let previewOptions = {
        format: "jpeg",
        quality: 0.3,
        multiplier: 1,
        width: part.width,
        height: anchorPoint.top,
        left: part.left,
        top: part.top,
      }
      part.strokeWidth = 1
      let data = this.c.toDataURL(previewOptions)
      return data
    }
  }

  _createMetadata = (data, part, images, indata) => {
    let imageFolder = "images/"
    let product = this._getProduct()
    let logos = images.map((img, index) => {

      let file = img.help.file
      let isRepeat = data[0].image_repeat === 'DEFAULT_REPEAT'
      let pxToSubUnit = product.metadata.widthSubUnit / part.width

      let x = img.help.left * pxToSubUnit
      let y = img.help.top * pxToSubUnit
      let width = img.width * img.scaleX * pxToSubUnit
      let height = img.height * img.scaleY * pxToSubUnit

      // height = ((img.height * img.scaleY) + img.help.top) > part.height ? (part.height - img.help.top)*pxToSubUnit : height

      let position = {
        x: parseFloat(x.toFixed(3)),
        y: parseFloat(y.toFixed(3)),
      }
      let idStr = `${part.id}_${index + 1}`
      let image = {
        src: `${imageFolder}${idStr}.${file.name ? file.name.split(".")[file.name.split(".").length - 1] : file.type.split("/")[1]}`,
        repeatType: data[0].image_repeat,
        repeatSize: isRepeat ? parseFloat(((img.width * img.scaleX) / part.width).toFixed(3)) : -1,
        width: parseFloat(width.toFixed(3)),
        height: parseFloat(height.toFixed(3))
        //scale: parseFloat((1 * item.image_scale).toFixed(3)),
      }

      return {
        ...image,
        position,
      }
    })

    let idStr = `${part.id}`
    let parts = [{
      _id: idStr,
      number: data[0].number,
      length: data[0].length,
      partIcon: `screenshots/preview.jpeg`,
      logos
    }]

    let object = {
      sku: indata.sku,
      width: indata.width,
      parts,
    }

    const KEY = process.env.REACT_APP_KEY
    const IV = process.env.REACT_APP_IV

    let plain = JSON.stringify(object)
    let encoded = CRYPTO_JS.AES.encrypt(
      plain,
      CRYPTO_JS.enc.Utf8.parse(KEY),
      {
        iv: CRYPTO_JS.enc.Utf8.parse(IV),
        padding: CRYPTO_JS.pad.Pkcs7,
        mode: CRYPTO_JS.mode.CBC,
      },
    ).toString()

    return { plain, encoded }

  }

  /**
   * add to cart
   * @method
   */
  addToCart = async (data, callback, indata, pcProgress) => {
    let widthMeters = getSuperUnit(this._getProduct().metadata?.widthSubUnit || 0)
    let part = this._getParts()[0]
    let lengthMeters = part?.partSize.length

    let result = await this.helper.addToCart({ width: this.c.getWidth(), height: this.c.getHeight() }, indata.sku, this.props.stor, this.props.fabricName,
      { widthMeters, lengthMeters }, undefined, indata.creatingCart)
    callback(result)

  }



}

export {Viewer2d}
