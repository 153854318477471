import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FIREBASE_UNIT } from '../libs/viewer/canvasPixelConvertor';
import { getFbData } from '../redux/actions/fbdata';
import { UPDATE_UNIT_TYPE } from '../redux/reducers/settings';
import { fireDir } from "./../firebase"

export type FirebaseData = {
  fetchInProgress: boolean,
  userAuthenticated?: boolean,
  config?: any
}

export const useFirebaseData = (apikey?: string) => {
  const dispatch = useDispatch();
  const [firebaseData, setFirebaseData] = useState({ fetchInProgress: false } as FirebaseData)
  useEffect(() => {
    if (apikey) {
      setFirebaseData({ fetchInProgress: true });
      fireDir
        .doc(apikey)
        .get()
        .then(entry => {
          let data = entry.data()
          if (data) {
            const unitType = data.lang === "en" ? 'METER' : 'YARD';
            dispatch(getFbData({ ...data, unitType }))
            setFirebaseData({ userAuthenticated: true, fetchInProgress: false, config: { ...data, unitType } })
          } else {
            setFirebaseData({ userAuthenticated: true, fetchInProgress: false })
          }
        })
    }
  }, [apikey, dispatch]);
  return firebaseData;
}