import React, { Suspense, useContext, useEffect } from 'react'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { fbdata } from '../../redux/reducers/fbdata';
import { useSelector } from 'react-redux';


i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en",
    backend: {
      loadPath: "https://firebasestorage.googleapis.com/v0/b/evcustomizer.appspot.com/o/{{ns}}%2FFabric%2F{{lng}}.json?alt=media",
      crossDomain: true,
    },
    fallbackLng: "en",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      useSuspense: true,
    },
  })




export const Internationalization = ({ children }: any) => {

  return <Suspense fallback={<h1>Loading ...</h1>}>
    {children}
  </Suspense>
}

