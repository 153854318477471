import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRepeatType } from '../../redux/actions/parts';
import { SEGMENT_SETTINGS_UPDATE } from '../../redux/reducers/fabric/actions';
import "./styles.scss"
import { useTranslation } from "react-i18next"

export type RepeatPanelProps = {
    partId: string
}

export const RepeatPanel = (props: RepeatPanelProps) => {

    const dispatch = useDispatch();
    const selectedSegment = useSelector((state: any) => state.fabric.segmentInFocus);
    const fabric = useSelector((state: any) => state.fabric)
    const segmentSettings = fabric.segmentSettings
    const repeatType = segmentSettings?.find((segment: any) => segment.parentCanvas === selectedSegment)?.repeatType

    const { partId } = props;

    const {t} = useTranslation()

    const repeatPatternSettings = [
        //{ title: t("editor.repeat_basic"), class: `repeatBtnBasic`, name: "DEFAULT_REPEAT" },
        { title: "Standard", class: `repeatBtnStandard`, name: "DEFAULT_REPEAT" },
        { title: t("editor.repeat_halfdrop"), class: `repeatBtnHalfDrop`, name: "HALF_DROP_REPEAT" },
        { title: t("editor.repeat_halfbrick"), class: `repeatBtnHalfBrick`, name: "HALF_BRICK_REPEAT" },
        // { title: "Full Mirror", class: `repeatBtnFullMirror`, name: "MIRROR_REPEAT" },
        { title: "Basic Mirror", class: `repeatBtnBasicMirror`, name: "MIRROR_REPEAT" },
        { title: "No Repeat", class: `repeatBtnPlacement`, name: "NON_REPEAT" },
    ]

    const patterns = useMemo(() => {
        return repeatPatternSettings.map(patternSetting =>
            <div className="repeatBtnDiv" key={patternSetting.name + "_key"}>
                <button data-is-selected={repeatType === patternSetting.name} className={`repeatBtn ${patternSetting.class}`} onClick={() => {
                    dispatch(setRepeatType(partId, patternSetting.name, true))
                    dispatch({
                        type: SEGMENT_SETTINGS_UPDATE, payload: {
                            parentCanvas: selectedSegment,
                            repeatType: patternSetting.name
                        }
                    })
                }}/>
                <div className="container_title">{patternSetting.title}</div>
            </div>
            )
    }, [repeatPatternSettings, selectedSegment, partId, repeatType])


    return <div className="repeatSection">
        <div className="repeatLabel">Repeat Style</div>
        <div className="repeatOptions">
            {patterns}
        </div>
    </div>
}