import React, {useEffect, useState} from "react"

/**
 * Component for animation
 * @component
 * @param {object} props
 * @param {object} props.chidren - react component children
 * @param {object} props.settings - animation settings
 * @param {number} props.settings.timeout
 * @param {object} props.settings.enterStyle
 * @param {object} props.settings.inStyle
 * @param {object} props.settings.outStyle
 * @example
 * const settings = {
 *    timeout: 400,
 *    enterStyle: {opacity: 0},
 *    inStyle: {opacity: 1},
 *    outStyle: {opacity: 0},
 *  }
 *
 * return (
 *  <Anime settings={settings}>
 *     <Children/>
 *     <Children/>
 *  </Anime>
 * )
 */
const Anime = props => {
  const {
    children,
    settings = {
      timeout: 400,
      enterStyle: {opacity: 0},
      inStyle: {opacity: 1},
      outStyle: {opacity: 0},
    },
    classNames = "",
  } = props

  const [state, setState] = useState(false)
  const [styles, setStyles] = useState({})
  const {active, timeout, enterStyle, inStyle, outStyle} = settings

  const [e, setE] = useState(false)

  useEffect(() => {
    if (!e && active && !state) {
      let p = new Promise(resolve => {
        setStyles(enterStyle)
        setState(true)
        resolve()
      })
      p.then(
        () =>
          new Promise(resolve => {
            setTimeout(() => {
              setStyles(inStyle)
              resolve()
            }, timeout)
          }),
      ).then(() => setE(true))
    }
  }, [active, e, enterStyle, inStyle, state, timeout])

  useEffect(() => {
    if (e && !active && state) {
      let p = new Promise(resolve => {
        setStyles(outStyle)
        resolve()
      })

      p.then(
        () =>
          new Promise(resolve => {
            setTimeout(() => {
              setState(false)
              resolve()
            }, timeout)
          }),
      ).then(() => setE(false))
    }
  }, [active, e, outStyle, state, timeout])

  const defaultStyle = {
    ...enterStyle,
    position: "relative",
    zIndex: "99",
    transition: `opacity ${timeout / 1000}s ease`,
  }

  return state ? (
    <div
      style={{...defaultStyle, ...styles}}
      className={`transition ${classNames}`}
    >
      {children}
    </div>
  ) : null
}

export default Anime
