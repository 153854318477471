import { log } from "./logger"

//const CM_IN_INCH = 2.54

/**Returns max ImageDimensions, within permissible Quality. this uses the Assumption that the maxCm Length of the Image would be when the Resolution in minimum.
MoreTheResolution => MoreThePixelsIn A UnitOfCm => lesserIsTheSpaceInWhichTheImageFits**/
export const getImageDim_max = (widthInPx, heightInPx, minResolution, scaleFactor) => {
    let maxWidth = parseFloat((( widthInPx * (scaleFactor||1)) / minResolution).toFixed(1))
    let maxHeight = parseFloat((( heightInPx * (scaleFactor||1)) / minResolution).toFixed(1))

    maxWidth = parseFloat((maxWidth / 1).toFixed(1))
    maxHeight = parseFloat((maxHeight / 1).toFixed(1))

    return { maxWidth, maxHeight }
}

/**For all Images returns Dimensions in which the lesser dimension = 2cm**/
export const getImageDim_min = (widthInPx, heightInPx) => {
    let minWidth = widthInPx > heightInPx ? parseFloat(1 * (widthInPx / heightInPx)).toFixed(1) : 1
    let minHeight = widthInPx > heightInPx ? 1 : parseFloat(1 * (heightInPx / widthInPx)).toFixed(1)
    return { minWidth, minHeight }
}




/**Returns max ImageDimensions, within permissible Quality. this uses the Assumption that the maxCm Length of the Image would be when the Resolution in minimum.
MoreTheResolution => MoreThePixelsIn A UnitOfCm => lesserIsTheSpaceInWhichTheImageFits**/
export const getImageDim = (
    imgPxWidth,
    imgPxHeight,
    containerWidthSubUnit,
    minResolution,
    curResolution,
    scaleFactor=1,
) => {

    let maxWidthSubUnit = (imgPxWidth * scaleFactor) / minResolution
    let maxHeightSubUnit = (imgPxHeight * scaleFactor) / minResolution

    let curWidthSubUnit = (imgPxWidth * scaleFactor) / curResolution
    let curHeightSubUnit = (imgPxHeight * scaleFactor) / curResolution

    

    if (containerWidthSubUnit < curWidthSubUnit) {
        curHeightSubUnit = curHeightSubUnit * (containerWidthSubUnit / curWidthSubUnit)
        curWidthSubUnit = containerWidthSubUnit
    }


    let minWidthSubUnit = imgPxWidth > imgPxHeight ? 2 * (imgPxWidth / imgPxHeight) : 2
    let minHeightSubUnit = imgPxWidth > imgPxHeight ? 2 : 2 * (imgPxHeight / imgPxWidth)

    return {
        widthLimitsCm: { max: roundOff(maxWidthSubUnit), min: roundOff(minWidthSubUnit) },
        heightLimitsCm: { max: roundOff(maxHeightSubUnit), min: roundOff(minHeightSubUnit) },
        curSizeCm: { width: roundOff(curWidthSubUnit), height: roundOff(curHeightSubUnit) }
    }
}

export const roundOff = (value, dp) => {
    dp = dp || 1
    return Math.round(value * Math.pow(10, dp)) / Math.pow(10, dp)

}



export const toNumber = (value) => {

    try {
        return parseFloat(value)
    } catch (error) {
        return 0
    }

}


