import React, {useState, useCallback, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import FontsMenu from "./FontsMenu/FontsMenu"
import ColorsMenu from "./ColorsMenu/ColorsMenu"
import OutlineMenu from "./OutlineMenu/OutlineMenu"
import Input from "./Input/Input"

import {toggleTextMenu, addUpdateText} from "../../../redux/actions/parts"

import styles from "./text.module.scss"
/**
 * Window for work with text /disabled now
 * @component
 * @param {object} props - all component props
 * @param {string} props.id - part id

 * @example
 *
 * return (
 * <Text id={'sdfsdfsdf'} />
 * )
 */
const Text = props => {
  const {id = ""} = props

  const [text, setText] = useState(defaultState)
  const [fontMenu, setFontMenu] = useState(false)
  const [colorMenu, setColorMenu] = useState(false)
  const [outlineMenu, setOutlineMenu] = useState(false)
  const parts = useSelector(state => state.parts)
  const activeelement = useSelector(state => state.activeelement)

  const dispatch = useDispatch()

  useEffect(() => {
    if (activeelement && activeelement.type === "text") {
      let part = parts.find(i => i._id === id)
      if (part) {
        setText({...part.text.find(i => i.text_id === activeelement.text_id)})
      }
    }
  }, [activeelement, id, parts])
  const isSplit = useSelector((state) => state.settings?.configuration?.split)

  const handleClose = useCallback(
    e => {
      e.preventDefault()
      id && dispatch(toggleTextMenu(id, false, isSplit))
    },
    [dispatch, id],
  )
  const handleMenu = useCallback(e => {
    e.preventDefault()
    if (e.target.name) {
      switch (e.target.name) {
        case "SELECT_FONT":
          setFontMenu(prev => !prev)
          setColorMenu(false)
          setOutlineMenu(false)
          break

        case "SELECT_COLOR":
          setColorMenu(prev => !prev)
          setFontMenu(false)
          setOutlineMenu(false)
          break

        case "SELECT_OUTLINE":
          setOutlineMenu(prev => !prev)
          setColorMenu(false)
          setFontMenu(false)

          break

        default:
          break
      }
    }
  }, [])

  const handleSave = useCallback(
    e => {
      e.preventDefault()
      dispatch(addUpdateText(id, text, isSplit))
      id && dispatch(toggleTextMenu(id, false,isSplit))
    },
    [dispatch, id, text],
  )

  const handleChange = useCallback(({target}) => {
    let value
    if (target.value) {
      value = target.value
    } else {
      value = target.name
    }
    setText(prev => ({...prev, [target.dataset.target]: value}))
  }, [])
  const handleFontChange = useCallback(opt => {
    setText(prev => ({...prev, font: opt}))
  }, [])
  const handleOutlineChange = useCallback(opt => {
    setText(prev => ({...prev, outline: opt}))
  }, [])

  const handleSettings = ({target}) => {
    setText(prev => ({
      ...prev,
      [target.name]: target.value.replace(/[^+\d]/g, ""),
    }))
  }
  const hadleSettingsBtn = e => {
    e.preventDefault()
    e.persist()

    if (e.target.name) {
      switch (e.target.name) {
        case "plus": {
          setText(prev => ({
            ...prev,
            [e.target.dataset.target]:
              parseInt(text[e.target.dataset.target] || 0) + 1,
          }))

          break
        }

        case "minus": {
          let n
          if (parseInt(text[e.target.dataset.target] || 0) - 1 <= 0) {
            n = 0
          } else {
            n = parseInt(text[e.target.dataset.target] || 0) - 1
          }

          setText(prev => ({
            ...prev,
            [e.target.dataset.target]: n,
          }))
          break
        }

        default:
          return
      }
    }
  }

  return id ? (
    <div className={styles.container}>
      {fontMenu ? (
        <FontsMenu
          activeFont={text.font.value}
          handleToggle={() => setFontMenu(false)}
          handleChange={handleFontChange}
        />
      ) : null}
      <div className={styles.title}>Add Text</div>
      <div className={styles.body}>
        {
          //text
        }
        <div className={styles.body_text}>
          <textarea
            data-target="value"
            className={styles.text}
            // name="ENTER_TEXT"
            placeholder="Enter text"
            value={text.value}
            onChange={handleChange}
            style={{
              fontFamily: text.font.value,
              color: text.color,
              backgroundColor:
                text.color.startsWith("#F") || text.color.startsWith("#f")
                  ? "#000"
                  : "#fff",
              textAlign: text.alignment,
              // WebkitTextStroke: text.outline.color,
              // WebkitTextStrokeWidth: text.outline.weight,
            }}
          ></textarea>
        </div>
        {
          //font
        }
        <button
          className={styles.body_cont}
          title={`selected font ${text.font.label}`}
          name="SELECT_FONT"
          onClick={handleMenu}
        >
          <div className={styles.contTitle}>Font</div>
          <div className={styles.contInfo}>
            <div className={styles.font} style={{fontFamily: text.font.value}}>
              {text.font.label}
            </div>
            <img src="/img/icons/chevron-down.svg" alt="open" />
          </div>
        </button>

        {
          //color
        }
        <div className={styles.cont}>
          <button
            className={styles.body_cont}
            title={`selected color ${text.color}`}
            name="SELECT_COLOR"
            onClick={handleMenu}
          >
            <div className={styles.contTitle}>Text color</div>
            <div className={styles.contInfo}>
              <div
                className={styles.color}
                style={{background: text.color}}
              ></div>
              <img
                src={
                  colorMenu
                    ? "/img/icons/chevron-up.svg"
                    : "/img/icons/chevron-down.svg"
                }
                alt="open"
              />
            </div>
          </button>
          {colorMenu ? (
            <ColorsMenu
              activeColor={text.color}
              target="color"
              handleSelect={handleChange}
            />
          ) : null}
        </div>
        {
          //outlines
        }
        <div className={styles.cont}>
          <button
            className={styles.body_cont}
            title={`selected color ${text.color}`}
            name="SELECT_OUTLINE"
            onClick={handleMenu}
          >
            <div className={styles.contTitle}>Outline</div>
            <div className={styles.contInfo}>
              {text.outline.color ? (
                <div
                  className={styles.color}
                  style={{background: text.outline.color}}
                ></div>
              ) : (
                <div className={styles.font} style={{fontWeight: 500}}>
                  None
                </div>
              )}
              <img
                src={
                  outlineMenu
                    ? "/img/icons/chevron-up.svg"
                    : "/img/icons/chevron-down.svg"
                }
                alt="open"
              />
            </div>
          </button>
          {outlineMenu ? (
            <OutlineMenu
              data={text.outline}
              handleSelect={handleOutlineChange}
            />
          ) : null}
        </div>
        {
          //alignment
        }
        <div
          className={styles.body_cont}
          title={`selected alignment ${text.alignment}`}
        >
          <div className={styles.contTitle}>Text Alignment</div>
          <div className={styles.contInfo}>
            {alignment.map(item => (
              <button
                key={`_${item.name}$`}
                className={`${styles.alignment} ${
                  item.name === text.alignment ? styles.alignment_active : ""
                }`}
                data-target="alignment"
                name={item.name}
                title={`align text ${item.name}`}
                onClick={handleChange}
              >
                {item.icon}
              </button>
            ))}
          </div>
        </div>
        {
          //form
        }
        <div className={styles.form_cont}>
          {settings.map(item => (
            <div className={styles.form} key={`_${item.name}$`}>
              <div className={styles.contTitle}>{item.title}</div>
              <Input
                value={text[item.name]}
                name={item.name}
                handler={hadleSettingsBtn}
                changes={handleSettings}
              />
            </div>
          ))}
        </div>
      </div>
      {
        //btns
      }
      <div className={styles.buttons}>
        <button className={styles.btn} onClick={handleClose}>
          cancel
        </button>
        <button
          className={`${styles.btn} ${text.value ? styles.btn_save : ""}`}
          disabled={text.value ? false : true}
          name="APPLY_TEXT"
          onClick={handleSave}
        >
          save changes
        </button>
      </div>
    </div>
  ) : null
}

export default Text

const alignment = [
  {name: "left", icon: "l"},
  {name: "center", icon: "c"},
  {name: "right", icon: "r"},
]

const settings = [
  {name: "spacing", title: "Spacing"},
  {name: "scale", title: "Scale"},
  {name: "rotate", title: "Rotate"},
]

const defaultState = {
  value: "",
  font: {
    value: "Abril Fatface",
    label: "Abril Fatface",
  },
  outline: {
    color: null,
    weight: 0,
  },
  color: "#000000",
  scale: 100,
  rotate: 0,
  spacing: 0,
  alignment: "left",
}
