import React from "react"

import styles from "./input.module.scss"

const Input = ({
  value = "",
  name = "",
  handler = () => {},
  changes = () => {},
}) => {
  return (
    <div className={styles.input}>
      <button
        name="minus"
        data-target={name}
        className={styles.input_btn}
        onClick={handler}
      >
        -
      </button>
      <input
        className={styles.input_inpt}
        name={name}
        type="text"
        value={value}
        onChange={changes}
      />
      <button
        name="plus"
        data-target={name}
        className={styles.input_btn}
        onClick={handler}
      >
        +
      </button>
    </div>
  )
}

export default Input
