import React, { useRef, useState, useEffect } from "react"
import {useDispatch, useSelector} from "react-redux"
import {EXIF} from "exif-js"
import RepeatBtn from "../RepeatBtn/RepeatBtn"

import {
  //addImageToPart,
  toggleImageMenu,
  changeImageSize,
  changeImageSizeCmsByWidth,
  changeImageSizeCmsByHeight,
  removeImageFromPart,
  clearImages,
  setRepeatType,
  toggleLibsMenu,
  addImagesToPart,
} from "../../../redux/actions/parts"

import {setProduct} from "../../../redux/actions/product"

import styles from "../leftworkarea.module.scss"
import {setSpinner} from "../../../redux/actions/spinner"
import alertify from "../../../libs/alert"
import { FabricDialog } from "../../CopyrightDialog"
import { log } from "../../../utilities/logger"
import { ImageSideBar } from "../../ImageSidebar"
import { useTranslation } from "react-i18next"
import { ACTIONS_SET_IMAGES_COPYRIGHT_COMPLIANT } from "../../../redux/reducers/images"
import { SEGMENT_SETTINGS_UPDATE } from "../../../redux/reducers/fabric/actions"

const COPYRIGHT_WARNING = {

  title: "i18n.intelWarn.copyrightTitle",

  text: ["i18n.intelWarn.p1",

  "i18n.intelWarn.p2",

  "i18n.intelWarn.p3"],

  checkbox: "i18n.intelWarn.check"
}


const AddImage = props => {
  const {part = null} = props

  const {t} = useTranslation()
  const dispatch = useDispatch()
  const inpt = useRef(null)
  const product = useSelector(state => state.product)
  const fbdata = useSelector(state => state.fbdata)
 
 // const imgCount = useSelector(state => state.canvasImages && state.canvasImages.images && state.canvasImages.images.length)
  const selectedSegment = useSelector(state => state.fabric.segmentInFocus)
  const fabric = useSelector(state => state.fabric)
  const segmentSettings = fabric.segmentSettings
  const images = useSelector(state => state.canvasImages && state.canvasImages.images)||[]
  const isSplit = useSelector((state) => state.settings?.configuration?.split)
  const[imgCount, setImgCount] = useState(0)
  const[repeatType, setRepeatMode] = useState('NON_REPEAT')

  log({settings: segmentSettings?.map(segment=>segment.repeatType)}, 'LOG SEGMENTS')

  useEffect(()=>{
    const repeatType = segmentSettings?.find(segment=>segment.parentCanvas === selectedSegment)?.repeatType
    log({repeatType},'LOG SEGMENTS - repeat effect')
    repeatType && setRepeatMode(repeatType)
  },[segmentSettings, selectedSegment])

  useEffect(()=>{
    setImgCount(images.filter((image)=>image.parentCanvas === selectedSegment).length)
  },[images, selectedSegment])

  useEffect(()=>{

    if((repeatType === 'NON_REPEAT') && (imgCount === 1) && window.matchMedia('(max-width: 600px)').matches){
      setRepeatMode('DEFAULT_REPEAT')
    }else if(imgCount === 0){
      setRepeatMode('NON_REPEAT')
    }

  },[imgCount])


  log({ imgCount }, 'images added')
  const currentImg = useSelector(state => state.currentImg)

  const [uploadMode, setUploadMode] = useState(true)
  // const [imgDimensions1, setImgDimensions1] = useState('')
  const [aspectRatioImgPath, setAspectRationImgPath] = useState("/img/icons/link.svg")
  let timeout =  0

  const isMediaQuery = window.matchMedia('(max-width: 600px)').matches

  useEffect(() => {
    if (currentImg) {
      // dispatch(setSpinner(true))

      const url =
        window.location !== window.parent.location
          ? document.referrer
          : document.location.origin

      const urlForRequest = url.split("/")[2]
      // const urlForRequest = `myalloverprint.com`
      // console.log("URL", urlForRequest)

      const getIMG = () =>
        new Promise((resolve, reject) => {
          fetch(`//${urlForRequest}/wp-json/ev-admin/v1/get-image-base64`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({image: currentImg}),
          })
            .then(res => res.json())
            .then(res => {
              resolve(res)
            })
            .catch(err => {
              reject(err)
              // dispatch(setSpinner(false))
            })
        })

      props.imageUploadStart(true)
      getIMG()
        .then(img => {
          fetch(img)
            .then(res => res.blob())
            .then(blob => {
              let url = window.URL || window.webkitURL
              let image = new Image()
              image.src = url.createObjectURL(blob)
              let curResolution = 150

              image.onload = function () {
                EXIF.getData(this, function () {
                  let allMetaData = EXIF.getAllTags(this)
                  log({allMetaData},'allMetaData')
                  let curResolution = allMetaData.XResolution
                    ? allMetaData.XResolution.valueOf()
                    : 150
                  curResolution = curResolution < 150 ? 150 : curResolution
                  // props.imageUploadStart(true)
                
                  dispatch(addImagesToPart("part_1", [{
                    src: this.src,
                    width: this.width,
                    height: this.height,
                    minResolution: fbdata.minimalResolution || 96,
                    partWidth: product.width,
                    curResolution,
                    fcSize: props.fcSize,
                    file: blob,
                    scaleFactor: 1,
                  }], 1, t, isSplit))
                  // dispatch(setSpinner(false))
                })
              }

              // props.imageUploadStart(true)
              // image.onload = function () {
              //   dispatch(
              //     addImageToPart("part_1", image.src, blob, {
              //       width: this.width,
              //       height: this.height,
              //       minResolution: fbdata.minimalResolution || 96,
              //       partWidth: product.width,
              //       curResolution,
              //       fcSize: props.fcSize,
              //     }, true, t),
              //   )
              //   dispatch(setSpinner(false))
              // }
            })
            .catch(err => {
              log(`ERROR:${err}`)
              alertify.alert(t("editor.alert_warning_title2"), t("editor.alert.warning_msg1"), t("i18n.ok"), () => {
                // dispatch(setSpinner(false))
                return
              })
            })
        })
        .catch(err => {
          log(`ERROR:${err}`)
          alertify.alert(t("editor.alert_warning_title2"), t("editor.alert.warning_msg1"), t("i18n.ok"), () => {
            // dispatch(setSpinner(false))
            return
          })
        })
    }
  }, [currentImg])

  const toggleHandler = e => {
    e.preventDefault()
    dispatch(toggleImageMenu(part._id, isSplit))
  }

  const uploadClickHandler = e => {


    let isNonRepeat = repeatType? (repeatType === 'NON_REPEAT'): !part.image_repeat || (part.image_repeat === 'NON_REPEAT')
    let productMaxLength = product?.totalLength || 0
    let productNRBlanketMaxLength = (fbdata.fabricNonRepeatMaxLen || 20)
    const maxFabricLength = (isNonRepeat ? (productMaxLength < productNRBlanketMaxLength ? productMaxLength : productNRBlanketMaxLength) : productMaxLength)
   
    log({maxFabricLength, curLength: part.length},'VALUES VALUES')
    
    const invalidMode = isSplit ? (repeatType !== 'NON_REPEAT' && imgCount >= 1) :
    (part.image_repeat !== 'NON_REPEAT' && part.content_images.length >= 1)

    if (invalidMode) {
      e.preventDefault()
      setUploadMode(false)
      // dispatch(setSpinner(false))
      alertify.alert(t("editor.alert_warning_title1"), t("editor.images_repeat_mode_add_image_msg"), t("i18n.ok"), () => {})
    } else if( (imgCount || part.content_images.length) >= 1) {
      setUploadMode(true)
    }
  }

  const open3dViewHandler = () => {
    props.open3dView()
  }

  const fetchExifData = (file) => new Promise((resolve, reject) => {

        var url = window.URL || window.webkitURL

        let img = new Image()
        img.onload = function () {

          EXIF.getData(this, function () {
            let allMetaData = EXIF.getAllTags(this)

            let curResolution = allMetaData.XResolution
              ? allMetaData.XResolution.valueOf()
              : 150

            curResolution = curResolution < 150?150:curResolution
            props.imageUploadStart(true)
        resolve({
          src: this.src,
                width: this.width,
                height: this.height,
                minResolution: fbdata.minimalResolution || 96,
                partWidth: product.width,
                curResolution,
                fcSize: props.fcSize,
          file,
          scaleFactor: 1,
        })
          })
        }

    img.src = url.createObjectURL(file)
    log(url.createObjectURL(file))

        img.onerror = function (err) {
      reject(err)

    }

  })


  const handleFiles = async (files) => {

          // dispatch(setSpinner(false))

    let promises = []
    for (let i = 0; i < files.length; i++) {
      promises.push(fetchExifData(files[i]))
    }

    let images = []

    try {

      images = await Promise.all(promises)
      log({ images }, 'adding image')
      

    } catch (error) {
      log({ error }, 'image pick error')
          alertify.alert(t("editor.alert_warning_title1"), t("editor.alert.warning_msg1"), t("i18n.ok"), () => {})
        }

    dispatch(addImagesToPart(part._id, images, 1, t, isSplit))

    if (inpt.current)
      inpt.current.value = null
    // dispatch(setSpinner(false))

    // var url = window.URL || window.webkitURL

    // let img = new Image()
    // img.onload = function () {

    //   EXIF.getData(this, function () {
    //     let allMetaData = EXIF.getAllTags(this)

    //     let curResolution = allMetaData.XResolution
    //       ? allMetaData.XResolution.valueOf()
    //       : 150

    //     curResolution = curResolution < 150 ? 150 : curResolution
    //     props.imageUploadStart(true)
    //     dispatch(
    //       addImageToPart(part._id, part.length, img.src, selectedFile, {
    //         width: this.width,
    //         height: this.height,
    //         minResolution: fbdata.minimalResolution || 96,
    //         partWidth: product.width,
    //         curResolution,
    //         fcSize: props.fcSize,
    //         file: selectedFile,
    //       }, true),
    //     )

    //     inpt.current.value = null
    //     dispatch(setSpinner(false))
    //   })
    // }

    // img.src = url.createObjectURL(files[0])
    // console.log(url.createObjectURL(files[0]))

    // img.onerror = function (err) {
    //   dispatch(setSpinner(false))
    //   alertify.alert("Oops", "Something went wrong :(", () => { })
    // }

      }
      
  const uploadHandler = e => {
    const validity = isSplit ? (repeatType === 'NON_REPEAT' || repeatType === undefined) :
    (part.image_repeat === 'NON_REPEAT' || part.image_repeat === undefined)
    if (validity) {
      if (!uploadMode) {
        setUploadMode(true)
        e.preventDefault()
        // dispatch(setSpinner(false))
        return
      }
      // if (!part.file){return}



      // dispatch(setSpinner(true))
      const files = e.target.files

      if (files.length === 0) {
        setUploadMode(true)
        e.preventDefault()
        // dispatch(setSpinner(false))
        return
      }

      if (!copyrightCompliance) {
        // dispatch(setSpinner(false))
        e.preventDefault()
        pendingFiles.current = files
        //inpt.current.value = null
        log(files, `------------->>>>>> showCopyrightwarning`)
        showCopyrightWarning(true)
        return
      }

      handleFiles(files)
    }
  }

  const openLibHandler = e => {
    e.preventDefault()

    if((repeatType||part.image_repeat) !== 'NON_REPEAT' && (imgCount||part.content_images.length) >= 1){
      alertify.alert(t("editor.alert_warning_title1"), t("editor.images_repeat_mode_add_image_msg"), t("i18n.ok"), () => {})
      return
    }
    dispatch(toggleLibsMenu(part._id, isSplit))
  }

  const imgSizeHandler = e => {
    e.preventDefault()
    if (e.target.name) {
      switch (e.target.name) {
        case "IMG_BIGGER":
          dispatch(changeImageSize(part._id, props.selCurImage, e.target.name.split("_")[1], isSplit))
          break
        case "IMG_SMALLER":
          dispatch(changeImageSize(part._id, props.selCurImage, e.target.name.split("_")[1], isSplit))
          break
        default:
          break
      }
    }
  }

  const imgSizeCmsHandler = val => {
    let e = { target: { url:val.url ,width: val.widthSubUnit, height: val.heightSubUnit, name: val.targetName, lockAspectRatio: val.lock }, preventDefault: () => { } }
    log(e,'imgSizeHandler')
    e.preventDefault()
    let wd = 2
    let ht = 2
    if (e.target.name) {
      switch (e.target.name) {
        case "width":
          wd = e.target.width
          ht = e.target.height
          dispatch(changeImageSizeCmsByWidth(part._id, 
              e.target.url,
              e.target.name,
              wd,
              ht,
              part,
              e.target.lockAspectRatio,
              props.fcSize,
            t, isSplit))
          break
        case "height":
          wd = e.target.width
          ht = e.target.height
          dispatch(changeImageSizeCmsByHeight(part._id, 
	            e.target.url,
              e.target.name,
              wd, 
              ht,
              part,
	            e.target.lockAspectRatio,
              props.fcSize,
            t, isSplit))
          break
        default:
          break
      }
    }
  }

  const toggleAspectRatio = ({ url, widthSubUnit, heightSubUnit, lock }) => {

      dispatch(changeImageSizeCmsByWidth(part._id,
        url,
        "width",
        widthSubUnit,
        heightSubUnit,
        part,
        lock,
      props.fcSize, isSplit))
      dispatch(changeImageSizeCmsByHeight(part._id,
	      url,
          "height",
	      widthSubUnit,
    	  heightSubUnit,
          part,
	      lock,
      props.fcSize, isSplit))
    
	    if (lock) {
	      if (widthSubUnit < heightSubUnit) {
          dispatch(changeImageSizeCmsByWidth(part._id,
            url,
            "width",
            widthSubUnit,
            heightSubUnit,
            part,
            lock,
          props.fcSize, isSplit))
        } else {
          dispatch(changeImageSizeCmsByHeight(part._id,
            url,
            "height",
            widthSubUnit,
            heightSubUnit,
            part,
            lock,
          props.fcSize, isSplit))
        }
      }
      
  }


  const deleteImageFromPart = ({ url }) => {

    if ((repeatType || part.image_repeat) === "NON_REPEAT") {
      dispatch(removeImageFromPart(part._id, 
        url,
        part, isSplit))
    } else {
      dispatch(setSpinner(true))
      dispatch(clearImages(isSplit))
      dispatch(setProduct({...product}))
      props.clearSelections()
      dispatch(setSpinner(false))
      const canvas = fabric.segmentInFocus
      log({V: canvas}, 'LOG SEGMENTS - update')
      dispatch({
        type: SEGMENT_SETTINGS_UPDATE, payload: {
          parentCanvas: canvas,
          repeatType: 'NON_REPEAT',
        }
      })
    }
  }

  const repeatPatternSettings = [
    {title: t("editor.no_repeat"), name: "NON_REPEAT", icon: "/img/icons/NoRepeat.svg"},
    {title: t("editor.repeat_basic"), name: "DEFAULT_REPEAT", icon: "/img/icons/Repeat.svg"},
    {title: t("editor.repeat_mirror"), name: "MIRROR_REPEAT", icon: "/img/icons/Mirror.svg"},
    {title: t("editor.repeat_halfdrop"), name: "HALF_DROP_REPEAT", icon: "/img/icons/HalfDrop.svg"},
    {title: t("editor.repeat_halfbrick"), name: "HALF_BRICK_REPEAT", icon: "/img/icons/HalfBrick.svg"},
  ]

  const repeatHandler = e => {
    e.preventDefault()
    if ((imgCount||part.content_images.length) > 1 && e.target.name !== 'NON_REPEAT') {
      alertify.alert(t("editor.alert_warning_title1"), t("editor.images_repeat_with_multiple_images"), t("i18n.ok"), () => {})
    }
    else {

      let isNonRepeat = e.target.name === 'NON_REPEAT'
      let productMaxLength = product?.totalLength || 0
      let productNRBlanketMaxLength = (fbdata.fabricNonRepeatMaxLen || 20)
      const maxFabricLength = (isNonRepeat ? (productMaxLength < productNRBlanketMaxLength ? productMaxLength : productNRBlanketMaxLength) : productMaxLength)

      log({ length: part.length, maxLength: maxFabricLength }, 'LENGTH MEASURE')
      if (e.target.name === 'NON_REPEAT' && part.length > maxFabricLength) {
        alertify.alert(
          t("editor.alert_warning_title2"), 
          `The max length for No Repeat is ${maxFabricLength} metres.`,
          t("i18n.ok"))
        
        // Add to Cart blocked in Body.js
      }
      if (e.target.name) {
        dispatch(setRepeatType(part._id, e.target.name, isSplit))
        if (isSplit) {
          dispatch({
            type: SEGMENT_SETTINGS_UPDATE, payload: {
            parentCanvas: selectedSegment,
            repeatType: e.target.name
            }
          })
        }
      }
    }
  }

  let compliant = useSelector((state)=>state.canvasImages?.isCopyrightCompliant)

  let [copyrightWarning, showCopyrightWarning] = useState(false)
  let [copyrightCompliance, setCopyrightCompliance] = useState(false)
  
  const pendingFiles = useRef(undefined)
  let [headerHeight, setHeaderHeight] = useState(0)

  useEffect(()=>{setCopyrightCompliance(compliant)},[compliant])
  


  return part ? (
    <div className={styles.addImage}>
      {/* <button
        name="ADD_IMG_TOGGLE"
        className={styles.toggleBtn}>
          <span>Add Image</span>
      </button> */}
      <div style={{height:'100%'}}>
        <div className={styles.toggleUpload}>
          <div className={styles.upload} 
               onClick={props.interceptUploadEvent ? (e) => {
                  if ((repeatType||part.image_repeat) !== 'NON_REPEAT' && (imgCount||part.content_images.length) >= 1) {
                    e.preventDefault()
                    alertify.alert(t("editor.alert_warning_title1"), t("editor.images_repeat_mode_add_image_msg"), t("i18n.ok"), () => { })
                  } else if ((repeatType || part.image_repeat) === 'NON_REPEAT' && (imgCount || part.content_images.length) == 1) {
                    e.preventDefault()
                    alertify.alert(t("editor.alert_warning_title1"), t("You cannot upload more than one image."), t("i18n.ok"), () => { })
                  } else {
                    props.interceptUploadEvent()
                  }
                } : undefined}>
            <img src="/img/icons/upload_icn.svg" alt="upload" />
            <span>{t("editor.upload")}</span>
            {props.interceptUploadEvent ? null : <input
                id="uploadAddImageBtn"
                className={styles.upload_inpt}
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={uploadHandler}
                onClick={uploadClickHandler}
                ref={inpt}
                multiple/>}
          </div>
          <button
            name={"OPEN_LIBRARY"}
            className={styles.upload}
            onClick={openLibHandler}>
            <img src="/img/icons/image_icn.svg" alt="upload" />
            <span>{t("editor.select_lib")}</span>
          </button>
          {/* <button
            name={"VIEW3D"}
            // className={styles.view3d}
            className={`${styles.view3d} ${(imgCount && (repeatType === "DEFAULT_REPEAT"))? "": styles.disabled}`}
            onClick={open3dViewHandler}>
            <img src='/img/icons/360.svg' alt="View in 3D"/>
          </button> */}
        </div>
        <div className={styles.imageConfig}>{imgCount ? <ImageSideBar onDelete={deleteImageFromPart}
                      onToggleAR={toggleAspectRatio} 
                      onScale={(val) => imgSizeCmsHandler(val)}
          PatternSettings={imgCount === 1 ? <div className={styles.imgCont}>
            <div className={styles.repeat}>
                <div className={styles.repeat_title}>
                  <span className={styles.strTitle} style={{paddingLeft:10}}>{t("editor.repeat_option")}</span>
                </div>
                <div className={styles.repeat_btns}>
                  {repeatPatternSettings.map(item => (
                    <div className={styles.repeatBtn} key={item.name + "_key"}>
                      <RepeatBtn
                        name={item.name}
                        img={item.icon}
                        handler={props.selCurImage ? repeatHandler:undefined}
                        title={item.title}
                        active={props.selCurImage ? (item.name === (repeatType||part.image_repeat)?true:false) : false}
                      />
                    </div>
                  ))}
                </div>
            </div>
              </div> : null}
        /> : null}</div>
        </div>
      {!copyrightCompliance ? <FabricDialog 
      text={COPYRIGHT_WARNING.text.map(p=>t(p))} 
      title={t(COPYRIGHT_WARNING.title)}
      checkbox={t(COPYRIGHT_WARNING.checkbox)}
      visible={copyrightWarning}
        onNegative={() => {
          showCopyrightWarning(false)
          pendingFiles.current = undefined
          inpt.current.value = null
        }}
        onPositive={() => {
          dispatch({type: ACTIONS_SET_IMAGES_COPYRIGHT_COMPLIANT})
          setCopyrightCompliance(true)
          showCopyrightWarning(false)
          handleFiles(pendingFiles.current)
          pendingFiles.current = undefined
          inpt.current.value = null
        }} /> : null}
    </div>
  ) : null
}

export default AddImage

const settings = {
  timeout: 200,
  enterStyle: {opacity: 0},
  inStyle: {opacity: 1},
  outStyle: {opacity: 0},
}
