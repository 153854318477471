import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useIframe from './useIframe';

import { setCurrentImage, setProduct, setProducts } from '../redux/actions/product';
export type ViewType = 'embedded' | 'iframe' | 'webpage' | 'unknown'

type IFrameData = {
    viewType: ViewType,
    apiKey?: string,
    gridConfig?: { rows: number, columns: number },
    firebaseUnit?: 'METER' | 'YARD',
    languageSetting?: string,
    imgLib?: any,
    fabricList?: any[],
    isParsing: boolean,
    currentImage?: any,
    mediaLibTitle?: string,
    cssLink?: string,
    sku?: string
}

export function useIframeMessageParser() {

    const dispatch = useDispatch();								   
    const iframeState = useIframe()
    const isIframe = iframeState === 1;
    const isEmbedded = useMemo(() => {
        const params = new URLSearchParams(window.location.search)
        const isEmbedded = params.get('displayType') === 'embedded';
        return isEmbedded;
    }, [window])

    const [result, setResult] = useState({ isParsing: false, viewType: 'unknown' } as IFrameData);

    useEffect(()=>{
        let viewType: ViewType = 'unknown'
        switch (iframeState) {
            case 1:
                viewType = isEmbedded ? 'embedded' : 'iframe'
                break;
            case -1:
                viewType = 'webpage'
                break;
            default:
                break;
        }
        setResult({ ...result, isParsing: !isEmbedded, viewType });
    }, [iframeState, isEmbedded]);

    const sendToHost = useCallback((action) => {
        let url =
            window.location !== window.parent.location
                ? document.referrer
                : document.location.origin

        window.parent.postMessage(
            JSON.stringify({
                action
            }),
            url,
        )
    }, [window])

    

    const messageHandler = useCallback(({ data }, isEmbedded) => {

        const iframeData: IFrameData = { isParsing: false, viewType: isEmbedded ? 'embedded' : 'iframe' };

        if (data) {
            
            const { apiKey , action} = data
            if(action === 'LOAD_EMBEDDED_IFRAME'){
                sendToHost('EMBEDDED_IFRAME_LOADED');
            }
            if (apiKey) {
                iframeData.apiKey = apiKey;
            }
            const { rows, columns, imgLib, fabricList, currentImg, mediaLibTitle, currentFabric, sku = "notfound", cssLink } = data.selectedProd || {}
            rows && columns && (iframeData.gridConfig = { rows, columns })
            if (imgLib) {
                iframeData.imgLib = imgLib;
            }
            iframeData.fabricList = fabricList;
            if(currentFabric)
            {
                dispatch(setProduct({ ...currentFabric, sku }))
            }            
            iframeData.cssLink = cssLink;
            iframeData.currentImage = currentImg;
            iframeData.mediaLibTitle = mediaLibTitle;
            iframeData.sku = sku;
            const { language, lang } = data
            const languageSetting = language || lang;
            if (languageSetting) {
                iframeData.languageSetting = languageSetting;
                iframeData.firebaseUnit = (languageSetting === 'en' ? 'METER' : 'YARD');
            }
            setResult(iframeData);
        }
       
    }, [sendToHost]);

    useEffect(() => {
        function messageListener(message: any) {
            !isEmbedded && sendToHost('viewerReady');
            messageHandler(message, isEmbedded);
        }
        isIframe && window.addEventListener('message', messageListener);
        return () => window.removeEventListener("message", messageListener);
    }, [isEmbedded, sendToHost, messageHandler,isIframe]);

    return result;

}