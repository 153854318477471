import {useEffect, useState} from "react"

/**
 * ### `react custom hook`
 * Create html cpecial symbol from string
 * @property {string} str - string for decode
 * @return {string} new string
 * @example useDecode('#sadf')
 */
export const useDecode = (str = "") => {
  const [parcedCurrency, setParcedCurrency] = useState("$")

  useEffect(() => {
    if (str) {
      const parser = new DOMParser()
      const decodedString = parser.parseFromString(
        `<!doctype html><body>${str}`,
        "text/html",
      ).body.textContent

      setParcedCurrency(decodedString)
    }
  }, [str])

  return parcedCurrency
}
