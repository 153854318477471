import React from "react"

import Input from "../Input/Input"

import styles from "./outlinemenu.module.scss"
import colors from "../../../../data/colors"

const OutlineMenu = ({
  data = {color: null, weight: 0},
  handleSelect = () => {},
}) => {
  const handleSelectColor = e => {
    e.preventDefault()
    let obj = {...data, color: e.target.name}
    handleSelect(obj)
  }

  const hadleWeightBtn = e => {
    e.preventDefault()

    if (e.target.name) {
      switch (e.target.name) {
        case "plus": {
          let n = parseInt(data.weight || 0) + 1
          if (n > 20) n = 20
          let obj = {...data, weight: n}
          handleSelect(obj)
          break
        }

        case "minus": {
          let n = parseInt(data.weight || 0) - 1
          if (n < 0) n = 0
          let obj = {...data, weight: n}
          handleSelect(obj)
          break
        }

        default:
          return
      }
    }
  }

  const handleChange = e => {
    let n
    if (parseInt(e.target.value.replace(/[^+\d]/g, "")) < 0) {
      n = 0
    } else if (parseInt(e.target.value.replace(/[^+\d]/g, "")) >= 20) {
      n = 20
    } else n = e.target.value.replace(/[^+\d]/g, "")

    let obj = {...data, weight: n}
    handleSelect(obj)
  }

  return (
    <div className={styles.container}>
      <div className={styles.weight}>
        <span className={styles.title}>Weight:</span>
        <Input
          name="OUTLINE"
          value={data.weight}
          handler={hadleWeightBtn}
          changes={handleChange}
        />
      </div>
      <div className={styles.color}>
        <span className={styles.title}>Outline color:</span>
        <div
          className={`${styles.color_cont} ${
            !data.color ? styles.color_cont_dis : ""
          }`}
          style={{backgroundColor: data.color || "#fff"}}
        ></div>
      </div>
      <div className={styles.body}>
        {colors.length ? (
          colors.map(item => (
            <div className={styles.btn_cont} key={item.color}>
              <button
                className={styles.btn}
                style={{
                  backgroundColor: item.color,
                  color:
                    item.color.startsWith("#F") || item.color.startsWith("#f")
                      ? "#000"
                      : "#fff",
                }}
                name={item.color}
                onClick={handleSelectColor}
              >
                {data.color === item.color ? <span>&#10004;</span> : ""}
              </button>
            </div>
          ))
        ) : (
          <div>Colors not found</div>
        )}
      </div>
    </div>
  )
}

export default OutlineMenu
