import React, { useState } from "react"
import {useSelector} from "react-redux"

import useWindowSize from "../hooks/useWindowSize"

import Header from "../components/Header/Header"
import Body from "../components/Body/Body"
import Blur from "../components/Blur/Blur"
import LIbrary from "../components/LeftWorkArea/LIbrary/LIbrary"
import useIframe from "../hooks/useIframe"

/**
 * Product Page
 * @component
 * @example
 * return (
 *  <Product />
 * )
 */
const Product = () => {
  const size = useWindowSize()
  const product = useSelector(state => state.product)
  const isIframe = useIframe()
  const mediaQuery = window.matchMedia('(max-width: 600px)')

  let styles = {
    width: `${size.width - 1}px`,
    height: `${size.height - 1}px`,
    overflow: "hidden",
    // overflow: "auto",
    background: `rgb(242, 244, 246)`,
    display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    border: isIframe===1?"0.2px solid #404040":"none",
    // boxShadow: "0 0 4px #aaa"
  }

  if (mediaQuery.matches) {
    styles = {
      width: 'auto',
      overflow: "hidden",
      background: `rgb(242, 244, 246)`,
    }
  }

  const[forceUpdateToggle, setForceUpdateToggle] = useState(undefined)
  const[libraryAvailable, setLibraryAvailable] = useState(undefined)

  return (
    <div style={{...styles}}>
      <div className="max-cont" style={{height: mediaQuery.matches?`100vh`:`${size.height}px`}}>
        {!product && <Blur />}
        <Header forceClear={()=>{
          setForceUpdateToggle(((forceUpdateToggle||0)+1)%2)
        }} />
        <Body showLibrary={(lib)=>{
          setLibraryAvailable(lib)
        }} forceClear={forceUpdateToggle} />
        {libraryAvailable?
        <div style={{zIndex: 1000}}>
          <LIbrary firebaseImages={libraryAvailable.firebaseImages} id={libraryAvailable.partId} />
          </div>
          :null}
      </div>
    </div>
  )
}

export default Product
